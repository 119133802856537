import { createSlice } from "@reduxjs/toolkit";

import DraftAgreements from "./DraftAgreements";

const initialState = {
  allDraftAgreements : [],
};

const DraftAgreementsSlice = createSlice({
  name: "DraftAgreements",
  initialState,
  reducers: {
    addDraftAgreements(state, action) {
      console.log("act", action.payload);
      if (Array.isArray(action.payload)) {
        const newDraftAgreements= action.payload.filter(newDraftAgreement =>
          !state.allDraftAgreements.some(existingDraftAgreements => existingDraftAgreements.id === newDraftAgreement.id)
        );
        state.allDraftAgreements.push(...newDraftAgreements);
      } else {
        if (!state.allDraftAgreements.some(existingDraftAgreements => existingDraftAgreements.id === action.payload.id)) {
          state.allDraftAgreements.push(action.payload);
        }
      }
    },
    deleteDraftAgreements(state, action) {
      state.allDraftAgreements = state.allDraftAgreements.filter(
        DraftAgreement => DraftAgreement.id !== action.payload
      );
    },
    deleteAllDraftAgreements(state) {
        state.allDraftAgreements = []; 
      }
  },
});

export const { addDraftAgreements, deleteDraftAgreements, deleteAllDraftAgreements } = DraftAgreementsSlice.actions;
export default DraftAgreementsSlice.reducer;
