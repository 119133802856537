import React from 'react'

import { useState , useEffect } from 'react';
import axios from "axios";
import { useRef } from 'react';
import {addInvoice,} from "../../actions/invoiceActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate} from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import config  from "../../config/config"
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import ReactDOM from "react-dom"
import { MdCalendarMonth } from "react-icons/md";
import { DisabledByDefault } from '@mui/icons-material';
import Checkbox from '@mui/material/Checkbox';
import { addToast } from '../pages/ToastBox/ToastBoxMessageSlice';
import { showToastForAddInvoice } from '../toasts/toastForInvoice';

const InvoiceFormTwo = () => {
  const dispatch = useDispatch()
    const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const [isInputFocused, setIsInputFocused] = useState(true);
    
    const [brandInvoiceNumber, setBrandInvoiceNumber] = useState('');
    
    const [invoiceFuelData, setInvoiceFuelData] = useState([]);
    console.log(invoiceFuelData, 'invoicefueldata')

    const [InvoiceCustomerData, setInvoiceCustomerData] = useState([]);
    const [loading, setLoading] = useState(true);
    
    const [latestPurchaseInvoices, setLatestPurchaseInvoices] = useState(null);

    const [customerIdInvoice, setCustomerIdInvoice] = useState(true);

    const today = new Date().toISOString().split("T")[0];
    const [issuedOn, setIssuedOn] = useState(today);
    const [dueOn, setDueOn] = useState(today);
    const [productOptions, setProductOptions] = useState([]);

    const [vehiclesNames , setVehiclesNames] = useState([])
    const [sellerNames , setSellerNames] = useState([])

    const [originTerminal , setOriginTerminal] = useState([])
    const [destinationTerminal , setDestinationTerminal] = useState([])

  
    const [subtotal, setSubtotal] = useState(0);
    const [tax, setTax] = useState("");
    const [feesOrDiscounts, setFeesOrDiscounts] = useState("");
    const [total, setTotal] = useState(0);
  
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [comments, setComments] = useState("");
    const [termsAndConditions, setTermsAndConditions] = useState(""); 

    const [latestInvoiceNumber , setLatestInvoiceNumber] = useState("");
  
    // const [dueDate, setDueDate] = useState(new Date());
    const [dueDate, setDueDate] = useState("");

    // const [createdDate , setCreatedOnDate ] = useState(new Date()); 
    const [createdDate , setCreatedOnDate ] = useState(""); 

    const businessId = localStorage.getItem("business_id")
  
    
    const [showDropdown, setShowDropdown] = useState(false);
    const [customerOptions , setCustomerOptions] = useState([])
    const [taxTotal ,setTaxTotal] = useState("");
   
    
    const [surchargePercentage, setSurchargePercentage] = useState('');
    const [surchargeAmount, setSurchargeAmount] = useState('');
    const [discountPercentage, setDiscountPercentage] = useState('');
    const [discountAmount, setDiscountAmount] = useState('');
    const [surchargeType, setSurchargeType] = useState('percentage');
    const [discountType, setDiscountType] = useState('percentage');

    const [productData, setProductData] = useState([]);
    const [newTotal, setNewTotal] = useState(0);
    const [taxValues, setTaxValues] = useState({
        freight : "",
        freightSurcharge : "",
        FederalExciseTax : "", 
        FederalOilSpil : "",
        NysPrepaid : "",
        NysExcise : "",
        NysPetroleumBusRcvry : "",
        NysPetroleumTestingFee : "",
        NysSpillRecovery : ""
    });  
  
    const navigate = useNavigate()

    const numericTaxValues = Object.values(taxValues).map(value => parseFloat(value));
    const avgUnitPrice = numericTaxValues.reduce((acc, value) => acc + value, 0);    // Sum of all the tax values
    // console.log('Sum of all tax values:', avgUnitPrice.toFixed(5));

    const [taxValuesResults, setTaxValuesResults] = useState([
     {
        freight : "",
        freightSurcharge : "",
        FederalExciseTax : "",
        FederalOilSpil : "",
        NysPrepaid : "",
        NysExcise : "",
        NysPetroleumBusRcvry : "",
        NysPetroleumTestingFee : "",
        NysSpillRecovery : "",
        avgUnitPrice : "",
        subTotal : "",
    }
    ])

    useEffect(() => {
      const total = taxValuesResults.reduce((acc, product) => {
        const subTotalValue = parseFloat(product.subTotal);
        return isNaN(subTotalValue) ? acc : acc + subTotalValue;
      }, 0);
      setNewTotal(total);
    }, [taxValuesResults]);
    // console.log("new total is ", newTotal);
    
    const [products, setProducts] = useState([
      { 
        product:"",
        productCode : "",
        quantity: "",
        unitPrice: "",
        total: 0,
        editableDescription: false,
      
      },
    ]);

    // console.log("products", products);

    const [formField, setFormField] = useState({
      invoice_number:  "",
      bill_from: "",
      bill_to: "",
      vehicleNumber : "",
      sellersId :"",
      originTerminal : "",
      destinationTerminal  : "",
      created_on: today,
      due_on: today,
    });

    const isFormValid = Object.entries(formField).filter(([key]) => key !== 'invoice_number').every(([, value])=> value !== "")
    // console.log(isFormValid);
    // console.log(formField);
    const isFormComplete = () => {
      // Check if customer information is filled
      const isCustomerInfoComplete =
        formField.bill_to &&
        createdDate &&
        dueDate;
    
      // Check if at least one product is added with quantity and unit price
      const areProductsComplete = InvoiceCustomerData.every(
        (product) => product.quantity > 0 && product.unitPrice > 0
      );
    
      // Additional checks for other fields if needed
      const isPOSelected = Boolean(brandInvoiceNumber);
    
      // Return overall form completeness
      return isCustomerInfoComplete && areProductsComplete && isPOSelected;
    };
    

    const totalQuantity = products.reduce((acc, product) => {
      if (product.product !== "" && product.quantity !== 0 && !isNaN(product.unitPrice)  &&!/^(?:0(?:\.0*)?|)$/.test(product.unitPrice) ) {
        console.log(product, products, 'item checking');
        return acc + parseFloat(product.quantity || 0);
      }
      return acc; // If the condition is not met, return the current accumulator
    }, 0); // Initial accumulator value
    
    const totalPrice = products.reduce((acc, product) => acc + parseFloat(product.unitPrice || 0), 0);
    const subTotal = products.reduce((acc, product) => acc + parseFloat(product.total || 0), 0);

    const grandTotal = parseFloat(taxTotal) + parseFloat(subTotal);
    const [finalInvoiceAmount, setFinalInvoiceAmount] = useState(grandTotal);

    const handleChange = (event) => {
        setCustomerIdInvoice(event.target.value)
      console.log(event.target.value)
      if (event instanceof Date) {
        setCreatedOnDate(event);
      } else {
        const { name, value } = event.target;
        setFormField({
          ...formField,
          [name]: value,
        });
      }
    };

    const handleDeliveryDateChange = (date) => {
      setCreatedOnDate(date);
      setFormField({
        ...formField,
        created_on: date,
      });
    };
  
    const handleDueDateChange = (date) => {
      setDueDate(date);
      setFormField({
        ...formField,
        due_on: date,
      });
    };
    
    
    const datePickerRef = useRef(null);
    const deliveryDatePickerRef = useRef(null);
  
    const showDatePicker = () => {
      if (datePickerRef.current) {
        datePickerRef.current.setOpen(true);
      }
    };
    const handleOuterDivClick = () => {
      if (datePickerRef.current) {
        datePickerRef.current.querySelector('input').click();
      }
    };

    const handleDeliveryDateClick = () => {
      if (deliveryDatePickerRef.current) {
        deliveryDatePickerRef.current.querySelector('input').click();
      }
    };

    useEffect(() => {
      const fetchLatestInvoiceNumber = async () => {
        try {
          const response = await axios.get(`${baseURL}${btoa("invoice/latest_invoice_number")}/${btoa(businessId)}`);
          const latestInvoiceNumber = response.data;
          // console.log("latestInvoiceNumber", latestInvoiceNumber);
          setLatestInvoiceNumber(latestInvoiceNumber)
          // setFormField((prev) => ({ ...prev, invoice_number: latestInvoiceNumber }));
        } catch (error) {
          console.error("Error fetching latest invoice number:", error);
        } 
      };
      fetchLatestInvoiceNumber();
    }, []);

    

    useEffect(()=>{
      const fetchVehiclesNames = async ()=> {
        try{
          const response = await axios.get(`${baseURL}${btoa("invoice/allvehicles")}`)
          setVehiclesNames(response.data.vehicles_data);
        }catch(error){
          console.log("error fetching vehicles names" , error);
        }
      }
      fetchVehiclesNames()
    },[])
    useEffect(()=>{
      const fetchSellersNames = async ()=> {
        try{
          const response = await axios.get(`${baseURL}${btoa("invoice/allsellers")}`)
          console.log('response', response);
          setSellerNames(response.data);
        }catch(error){
          console.log("error fetching seller names" , error);
        }
      }
      fetchSellersNames()
      console.log('setSellerNames', sellerNames)
    },[])

    useEffect(()=>{
      const fetchTerminalNames = async ()=> {
        try{
          const response = await axios.get(`${baseURL}${btoa("invoice/allterminals")}`)
          // console.log("ter" , response)
          setOriginTerminal(response.data.terminals_data);
          setDestinationTerminal(response.data.terminals_data);

        }catch(error){
          console.log("error fetching vehicles names");
        }
      }
      fetchTerminalNames()
    },[])


    
  const calculateFinalInvoiceAmount = (grandTotal, surchargePercentage, surchargeAmount, discountPercentage, discountAmount) => {
    const surchargeFromPercentage = (surchargePercentage / 100) * grandTotal;
    const discountFromPercentage = (discountPercentage / 100) * grandTotal;
    const totalSurcharge = surchargeFromPercentage + surchargeAmount;
    const totalDiscount = discountFromPercentage + discountAmount;
    return grandTotal + totalSurcharge - totalDiscount;
    
  };

// Handler for surcharge percentage change
const handleSurchargePercentageChange = (e) => {
  const percentage = e.target.value;
  setSurchargePercentage(percentage);
  setSurchargeAmount(0); // Reset surcharge amount
  const newFinalInvoiceAmount = calculateFinalInvoiceAmount(grandTotal, percentage, 0, discountPercentage, discountAmount);
  setFinalInvoiceAmount(newFinalInvoiceAmount);
};

// Handler for surcharge amount change
const handleSurchargeAmountChange = (e) => {
  const amount = Number(e.target.value);
  setSurchargeAmount(amount);
  setSurchargePercentage(0); // Reset surcharge percentage
  const newFinalInvoiceAmount = calculateFinalInvoiceAmount(grandTotal, 0, amount, discountPercentage, discountAmount);
  setFinalInvoiceAmount(newFinalInvoiceAmount);
};

// Handler for discount percentage change
const handleDiscountPercentageChange = (e) => {
  const percentage = e.target.value;
  setDiscountPercentage(percentage);
  setDiscountAmount(0); // Reset discount amount

  const newFinalInvoiceAmount = calculateFinalInvoiceAmount(grandTotal, surchargePercentage, surchargeAmount, percentage, 0);
  setFinalInvoiceAmount(newFinalInvoiceAmount);
};

// Handler for discount amount change
const handleDiscountAmountChange = (e) => {
  const amount = e.target.value;
  setDiscountAmount(amount);
  setDiscountPercentage(0); // Reset discount percentage

  const newFinalInvoiceAmount = calculateFinalInvoiceAmount(grandTotal, surchargePercentage, surchargeAmount, 0, amount);
  setFinalInvoiceAmount(newFinalInvoiceAmount);
};

// Usage example (assuming grandTotal is defined elsewhere)
useEffect(() => {
  const newFinalInvoiceAmount = calculateFinalInvoiceAmount(grandTotal, surchargePercentage, surchargeAmount, discountPercentage, discountAmount);
  setFinalInvoiceAmount(newFinalInvoiceAmount);
}, [grandTotal, surchargePercentage, surchargeAmount, discountPercentage, discountAmount]);
    
    
  
    const handleSurchargeTypeChange = (type) => {
      setSurchargeType(type);
      setSurchargePercentage('');
      setSurchargeAmount('');
    };
  
    const handleDiscountTypeChange = (type) => {
      setDiscountType(type);
      setDiscountPercentage('');
      setDiscountAmount('');
    };
  
    
  
    const handleSubmit = (e) => {
     
      e.preventDefault();

      // console.log("products submitted " , products);
      // console.log("result tax values submitted", taxValuesResults );
  
      const formattedDueDate = formField.due_on instanceof Date && !isNaN(formField.due_on)
      ? formField.due_on.toISOString()
      : new Date().toISOString();

      const formattedDeliveryDate = formField.created_on instanceof Date && !isNaN(formField.created_on)
      ? formField.created_on.toISOString()
      : new Date().toISOString();

      // const surchargeValue = surchargeType === "percentage" ? surchargePercentage : surchargeAmount;
      // const discountValue = discountType === "percentage" ? discountPercentage : discountAmount;
        
      // console.log("Formatted due date" , formattedDueDate );
      const formData = new FormData();
  
      formData.append("businessId", localStorage.getItem("business_id"));
      formData.append("managerId", localStorage.getItem("user_id"));
      formData.append("billFromName", formField.bill_from);
      formData.append("customerId", formField.bill_to);
      formData.append("deliveryDate", formattedDeliveryDate);
      formData.append("dueDate", formattedDueDate);
      // formData.append("vehicleNumber", formField.vehicleNumber);
      // formData.append("sellersId", formField.sellersId);
      // formData.append("originTerminal", formField.originTerminal);
      // formData.append("destinationTerminal", formField.destinationTerminal);
      formData.append("invoiceNumber", latestInvoiceNumber);
      formData.append("subTotalAmount", subTotal);
      formData.append("finalTotalAmount", (subTotal + taxTotal));
      // formData.append("surchargeType", surchargeType);
      // formData.append("discountType", discountType);
      // formData.append("surchargeAmount", surchargeValue);
      // formData.append("discountsAmount", discountValue);
      formData.append("finalInvoiceAmount", finalInvoiceAmount);
      
      formData.append("freight", (parseFloat(totalQuantity) * parseFloat(taxValues.freight)).toFixed(5));
      formData.append("freightSurcharge", (parseFloat(totalQuantity) * parseFloat(taxValues.freight) * parseFloat(taxValues.freightSurcharge)).toFixed(5));
      formData.append("federalExciseTax", (parseFloat(totalQuantity) * parseFloat(taxValues.FederalExciseTax)).toFixed(5));
      formData.append("federalOilSpil", (parseFloat(totalQuantity) * parseFloat(taxValues.FederalOilSpil)).toFixed(5));
      formData.append("nysPrepaid", (parseFloat(totalQuantity) * parseFloat(taxValues.NysPrepaid)).toFixed(5));
      formData.append("nysExcise", (parseFloat(totalQuantity) * parseFloat(taxValues.NysExcise)).toFixed(5));
      formData.append("nysPetroleumBusRcvry", (parseFloat(totalQuantity) * parseFloat(taxValues.NysPetroleumBusRcvry)).toFixed(5));
      formData.append("nysPetroleumTestingFee", (parseFloat(totalQuantity) * parseFloat(taxValues.NysPetroleumTestingFee)).toFixed(5));
      formData.append("nysSpillRecovery", (parseFloat(totalQuantity) * parseFloat(taxValues.NysSpillRecovery)).toFixed(5));
      formData.append("salesTaxAmount" , parseFloat(taxTotal).toFixed(5) );

      formData.append("comment", comments);
      formData.append("termsCondition", termsAndConditions);
      
      formData.append("purchasedInvoicePO", brandInvoiceNumber);

      let validIndex = 0; 
      InvoiceCustomerData.forEach((product, index) => {
        console.log(product, index, 'checking that')
        if ( product.product_name && product.quantity   && !isNaN(product.unitPrice)) {
          // const [product_id, product_name] = product.product.split("#");
          
          formData.append(`productId[${validIndex}]`, product.product_id);
          // formData.append(`productCode[${validIndex}]`, product.productCode);
          // formData.append(`itemNames[${index}]`, item_name);
          // formData.append(`itemDescriptions[${index}]`, product_id.description);
          formData.append(`quantities[${validIndex}]`, product.quantity);
          formData.append(`unitPrices[${validIndex}]`, product.unitPrice);
          formData.append(`unitTotals[${validIndex}]`, product.total);
          validIndex++;
        }else {
          console.log(`Skipping product at index ${index} because required fields are missing`);
        }
      });

      taxValuesResults.forEach((values, index) => {
        Object.entries(values).forEach(([key, value]) => {
          const parsedValue = parseFloat(value);
      
          if (!isNaN(parsedValue) && parsedValue > 0) {
            formData.append(`${key}[${index}]`, parsedValue);
          }
        });
      });
      
      console.log("form data: " , formData);
      // console.log("form data new" ,[...formData.entries()]);
      // showToastForAddInvoice('Invoice added Succesfully', 'success')
      dispatch(addInvoice(formData));
      // dispatch(addToast({ type: 'success', message: `Invoice added successfully` }));

     
      navigate("/invoice"); // Use navigate function to redirect
    };
  
    // useEffect(() => {
    //   async function fetchProductOptions() {
    //     try {
    //       const response = await axios.get(
    //         `${baseURL}/${btoa("product/view")}/${btoa(businessId)}`
    //       );
    //       console.log("response");
    //       // Assuming the response.data is an array of products
    //       setProductOptions(response.data.products_data);
    //       console.log("product options" , productOptions);
    //     } catch (error) {
    //       console.error("Error fetching product options:", error);
    //     }
    //   }
    //   fetchProductOptions();
    // }, []);

    useEffect(() => {
      window.scrollTo(0, 0);
      axios
        .get(`${baseURL}/${btoa("product/view")}`)
        .then((response) => {
            // console.log("prod" , response.data.products_data);
         
          setProductData( response.data.products_data);
        })
        .catch((error) => {
          console.error("Error fetching customer data:", error);
        });
    }, []);
    // console.log(productData)
  
    
    useEffect(() => {
      async function fetchCustomerOptions() {
        try {
          const response = await axios.get(
            `${baseURL}/${btoa("customer/view")}/${btoa(businessId)}`
          );
          setCustomerOptions(response.data.customers_data);
          // console.log("customer options" , response.data.customers_data);
        } catch (error) {
          console.error("Error fetching customer options:", error);
        }
      }
      fetchCustomerOptions();
    }, []);

    useEffect(()=>{
     
    }, [])
   
    
    const business_id = localStorage.getItem("business_id")
    const user_id = localStorage.getItem("user_id")
    const user_role = localStorage.getItem("user_role")

    useEffect(() => {
      const fetchCustomerTermsAndConditions = async () => {
        try {
          const response = await axios.get(`${baseURL}${btoa("invoice/fetchtermsconditions")}/${btoa(formField.bill_to)}/${btoa(businessId)}`);
          // console.log("tandc",response , );
          if(response.data.termsconditions_data?.length === 0){
            setTermsAndConditions("")
          }
          setTermsAndConditions(response.data.termsconditions_data[0].terms_conditions);
        } catch (error) {
          console.error('Error fetching customer terms and conditions in create invoice', error);
        }
      };
      fetchCustomerTermsAndConditions();
    }, [formField.bill_to]); 
  
    useEffect(() => {
      axios
        .get(`${baseURL}${btoa("business/profile")}/${btoa(business_id)}/${btoa(user_id)}/${btoa(user_role)}`)
        .then((response) => {
          // console.log(response);
          const businessData = response.data.business_data[0];
          // console.log(businessData);
          // setCustomerDetails(businessData);
          setFormField({
            ...formField,
            bill_from: businessData.business_name,
            // Other fields as needed
          });
          // setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching business data:", error);
          // setLoading(false);
        });
    }, []);
  
    const shouldRenderButtons = products.some(product => {
      return product.product !== "" && product.unitPrice > 0 && product.quantity > 0 ;
    });
    
    // console.log("button ", shouldRenderButtons);
    // console.log("al the products" , products);
    
    useEffect(() => {
        const fetchTaxValues = async () => {
          try {
            const response = await axios.get(`${baseURL}/${btoa("invoice/gettaxval")}`);
          
            const taxes = response.data.taxvalues_data[0]

            setTaxValues({
                freight : taxes.freight,
                freightSurcharge : taxes.freight_surcharge,
                FederalExciseTax : taxes.federal_excise_tax,
                FederalOilSpil : taxes.federal_oil_spil,
                NysPrepaid : taxes.nys_prepaid,
                NysExcise : taxes.nys_excise,
                NysPetroleumBusRcvry : taxes.nys_petroleum_bus_rcvry,
                NysPetroleumTestingFee : taxes.nys_petroleum_testing_fee,
                NysSpillRecovery : taxes.nys_spill_recovery
            })
          } catch (error) {
            console.error('Error fetching tax values:', error);
          }
        };
    
        fetchTaxValues();
      }, []); 


    useEffect(() => {
      // const sumOfTaxes =
      // (parseFloat((totalQuantity * taxValues.freight).toFixed(2))) +
      // parseFloat((totalQuantity * taxValues.freight * taxValues.freightSurcharge).toFixed(2)) +
      // (parseFloat((totalQuantity * taxValues.FederalExciseTax).toFixed(2))) +
      // (parseFloat((totalQuantity * taxValues.FederalOilSpil).toFixed(2))) +
      // (parseFloat((totalQuantity * taxValues.NysPrepaid).toFixed(2))) +
      // (parseFloat((totalQuantity * taxValues.NysExcise).toFixed(2))) +
      // (parseFloat((totalQuantity * taxValues.NysPetroleumBusRcvry).toFixed(2))) +
      // (parseFloat((totalQuantity * taxValues.NysPetroleumTestingFee).toFixed(2))) +
      // (parseFloat((totalQuantity * taxValues.NysSpillRecovery).toFixed(2)));
      // setTaxTotal(sumOfTaxes)

      const sumOfTaxes =
      (parseFloat((totalQuantity * taxValues.freight).toFixed(5))) +
      parseFloat((totalQuantity * taxValues.freight * taxValues.freightSurcharge).toFixed(5)) +
      (parseFloat((totalQuantity * taxValues.FederalExciseTax).toFixed(5))) +
      (parseFloat((totalQuantity * taxValues.FederalOilSpil).toFixed(5))) +
      (parseFloat((totalQuantity * taxValues.NysPrepaid).toFixed(5))) +
      (parseFloat((totalQuantity * taxValues.NysExcise).toFixed(5))) +
      (parseFloat((totalQuantity * taxValues.NysPetroleumBusRcvry).toFixed(5))) +
      (parseFloat((totalQuantity * taxValues.NysPetroleumTestingFee).toFixed(5))) +
      (parseFloat((totalQuantity * taxValues.NysSpillRecovery).toFixed(5)));
      setTaxTotal(sumOfTaxes)
    
    }, [taxTotal]);

    const restrictToFiveDecimals = (value) => {
      if (typeof value === "number") value = value.toString(); // Convert number to string if needed
      
      if (value.includes('.')) {
        const [integerPart, decimalPart] = value.split('.');
        if (decimalPart.length > 5) {
          return parseFloat(`${integerPart}.${decimalPart.slice(0, 5)}`);
        }
      }
      return parseFloat(value); // Convert the final result back to a number
    };
    
    
    // const handleProductChange = (index, field, value) => {
    //   const updatedProducts = [...products];
    //   if (field === 'unitPrice') {
    //     value = restrictToFiveDecimals(value);
    //   }
    
    //   updatedProducts[index][field] = value;
    //   const isEnterDescriptionMode = value === "Enter Description" && field === "description";
    //   const sumOfTaxes = Object.keys(taxValues).reduce((acc, taxKey) => {
    //     const taxValue = parseFloat((totalQuantity * taxValues[taxKey]).toFixed(2));
    //     return acc + taxValue;
    //   }, 0);
    //   setTaxTotal(sumOfTaxes.toFixed(2));
    //   if (isEnterDescriptionMode) {
    //     updatedProducts[index].editableDescription = true;
    //     setShowDropdown(true); 
    //     updatedProducts[index].description = "";
    //   } else {
    //     if (field === "product") {
    //       const selectedProduct = productData.find(product => product.product_id === value);
    
    //       if (selectedProduct) {
    //         updatedProducts[index].productCode = selectedProduct.product_code;
    //         updatedProducts[index].unitPrice = selectedProduct.product_unit_price; // Keep it as string
    //         setProducts(updatedProducts);
    //         // console.log("selected product", products);
    
    //         setShowDropdown(false);
    //       } else {
    //         console.log("Selected Item not found in available products");
    //         updatedProducts[index].unitPrice = ''
    //         updatedProducts[index].quantity =''
    //       }
    //     }
    //   }
    //   updatedProducts[index].quantity = parseFloat(updatedProducts[index].quantity) || 0;
    //   updatedProducts[index].total =updatedProducts[index].product !== "" ? updatedProducts[index].quantity * parseFloat(updatedProducts[index].unitPrice) || 0 : 0;
    //   if (
    //     field === "quantity" &&
    //     index === updatedProducts.length - 1 &&
    //     value !== ""
    //   ) {
    //     updatedProducts.push({
    //       No: updatedProducts.length + 1,
    //       product : "",
    //       productCode : "",
    //       quantity: "",
    //       unitPrice: "",
    //       total: 0,
    //       editableDescription: false,
    //     });
    
    //     setShowDropdown(false); // Close dropdown for the new row
    //   }
    //   const newSubtotal = updatedProducts.reduce((acc, item) => acc + item.total, 0);
    //   const newTotal = newSubtotal + newSubtotal * (tax / 100) + feesOrDiscounts;
    //   // console.log("updated Products", updatedProducts);
    //   setProducts(updatedProducts);
    //   setSubtotal(newSubtotal);
    //   setTotal(newTotal);
    // };

    const handleProductChange = (index, field, value) => {
      const updatedData = [...InvoiceCustomerData];
    
      // Update the specific field in the selected row
      updatedData[index][field] = value;
    
            const sumOfTaxes = Object.keys(taxValues).reduce((acc, taxKey) => {
        const taxValue = parseFloat((totalQuantity * taxValues[taxKey]).toFixed(2));
        return acc + taxValue;
      }, 0);
      setTaxTotal(sumOfTaxes.toFixed(2));
      // Parse quantity and unitPrice as numbers
      const quantity = parseFloat(updatedData[index]?.quantity) || 0;
      const unitPrice = parseFloat(updatedData[index]?.unitPrice) || 0;
    
      // Calculate the total
      const total = quantity * unitPrice;
    
      // Update the total in the object
      updatedData[index].total = total;
    
      // Debugging log
      console.log("Updated Row:", {
        productName: updatedData[index]?.product_name || "N/A",
        quantity,
        unitPrice,
        total,
      });
          const newSubtotal = updatedData.reduce((acc, item) => acc + item.total, 0);
      const newTotal = newSubtotal + newSubtotal * (tax / 100) + feesOrDiscounts;
      // console.log("updated Products", updatedProducts);
      setProducts(updatedData);
      setSubtotal(newSubtotal);
      setTotal(newTotal);
  
    
      // Update the state
      setInvoiceCustomerData(updatedData);
    };
    
    
    
      const handleProductBlur = (index, field, value) => {
        const updatedProducts = [...products];

        if (field === 'unitPrice') {
          // Format the unit price to five decimal places on blur
          const formattedValue = parseFloat(value).toFixed(5);
          updatedProducts[index][field] = formattedValue;
        }

        // Recalculate total with the formatted value
        updatedProducts[index].total = updatedProducts[index].quantity * parseFloat(updatedProducts[index].unitPrice) || 0;

        // Recalculate subtotal and total
        const newSubtotal = updatedProducts.reduce((acc, item) => acc + item.total, 0);
        const newTotal = newSubtotal + newSubtotal * (tax / 100) + feesOrDiscounts;

        // Update state
        setProducts(updatedProducts);
        setSubtotal(newSubtotal);
        setTotal(newTotal);
      };
    
    
  
    const formatNumber = (number) => {
      if (isNaN(number)) {
        return 0;
      }
      const formattedNumber = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 5,
        maximumFractionDigits: 5,
      }).format(number);
      return formattedNumber;
    };
  
   
  
    const render = products.every(
      product =>
        (product.product === "" && product.unitPrice === "" && (product.quantity === "" || isNaN(product.quantity)) && product.product === "")
        ||
        (product.product !== "" && product.unitPrice !== "" && (product.quantity !== "" && !isNaN(product.quantity)) && product.product !== "")
    );
    // console.log("render " , render);

    const showChildRecord = products.every(
      product =>
        (product.product === ""  && (product.quantity === "" || isNaN(product.quantity)) && product.product === "")
        ||
        (product.product !== ""  && (product.quantity !== "" && !isNaN(product.quantity)) && product.product !== "")
    );
    // console.log("chil record" , showChildRecord);
    const customerId = customerIdInvoice
    useEffect(() => {
     
        const encodedId = btoa(customerId)
        const businessId = localStorage.getItem("business_id")
    
        axios
          .get(`${baseURL}Y3VzdG9tZXIvZWRpdA==/${encodedId}/${btoa(businessId)}`)               // http://localhost/InvoiceFlow/customer/edit/{customerId}
          // .get(`${baseURL}/customer/edit/${customerId}`)      
          .then((response) => {
            // const fuelGradedata = response.data.customersFuelsData
            const fuelGradedata = response.data.customersFuelsData;
    
            console.log(fuelGradedata, '');
            setInvoiceCustomerData(fuelGradedata);
            setLoading(false);
          })
          .catch((error) => {
            console.error("Error fetching customer data:", error);
            setLoading(false);
          });
      }, [customerId]);
      useEffect(() => {
        const fetchLatestPurchaseInvoices = async () => {
          try {
            const response = await axios.get(`${baseURL}${btoa("purchasedinvoice/fetchPurchasedInvoiceNumber")}/${btoa(businessId)}`);
            console.log(response, 'response new ......')
            setLatestPurchaseInvoices(response.data.purchasedInvoiceNumbers)
          } catch (error) {
            console.error("Error fetching latest purchase invoices:", error);
          } 
        };
        fetchLatestPurchaseInvoices();
      }, []);
      useEffect(() => {
        const fetchLatestPurchaseInvoicedata = async () => {
          try {
            const response = await axios.get(`${baseURL}${btoa("purchasedinvoice/fetchpurchasedinvoice")}/${btoa(brandInvoiceNumber)}/${btoa(businessId)}`);
            console.log(response, 'response 3 boxes ......')
            // setLatestPurchaseInvoices(response.data)
            setInvoiceFuelData(response.data.purchasedInvoiceData[0].fuelgrades)
          } catch (error) {
            console.error("Error fetching latest purchase invoices:", error);
          } 
        };
        fetchLatestPurchaseInvoicedata();
      }, [brandInvoiceNumber]);

    //   for business logo fetching

    const [businessData , setBusinessData] = useState([])
  console.log(businessData, 'businessData')
    useEffect(() => {
      window.scrollTo(0, 0);
      axios
      .get(`${baseURL}${btoa("business/profile")}/${btoa(business_id)}/${btoa(user_id)}/${btoa(user_role)}`)
        .then((response) => {
          setBusinessData(response.data.business_data[0].image_file);
        })
        .catch((error) => {
          console.error("Error fetching business data:", error);
         ;
        });
    }, []); 
    const handleDelete = (e, product_id) => {
      e.preventDefault()
        const updatedData = InvoiceCustomerData.filter(item => item.product_id !== product_id);
        if (updatedData.length === 0) {
            alert("Cannot delete. At least one row must remain.");
            return;
          }
        setInvoiceCustomerData(updatedData);
      };
      const formatNumberWithCommas = (number) => {
        return new Intl.NumberFormat('en-US').format(number);
      };

  return (
    <>
    {loading && <p>Loading.......</p>}
    <div className="pt-2 pb-5">
   
    <form 
      // onSubmit={handleSubmit}
      >
    <div className="p-8 bg-white min-h- mx-8 rounded-lg pt-2">
    <nav aria-label="breadcrumb">
    <ol className="flex space-x-2 text-lg font-bold text-gray-700">
      <li>
        {/* <a  href="/operations" className="text-blue-600 hover:text-blue-800"> */}
          Sales
        {/* </a> */}
      </li>
      <li>
        <span className="text-gray-400">{'>>'}</span>
      </li>
      <li>
        {/* <a href="/purchases" className="text-blue-600 hover:text-blue-800"> */}
          Create Customer Invoice
        {/* </a> */}
      </li>
    </ol>
  </nav>

  <div className="mb-8 flex items-center justify-between bg-white p-6 shadow-lg rounded-lg mt-6" style={{   boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",}}>
    <div className="flex justify-start">
      <div className="w-48 h-10  borderflex items-center justify-center ">
      <img
          src={`${baseURL}/uploads/img/business/${businessData}`}
          alt="business logo"
          style={{ width: "220px", height: "50px" }}
          className="object-contain"
        />
      </div>
    </div>
    <div className="flex justify-center w-full">
      <select
        className="p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-300 w-full max-w-xs" 
        onChange={(e)=> setBrandInvoiceNumber(e.target.value)}>
        <option>Select PO #</option>
        { latestPurchaseInvoices && latestPurchaseInvoices?.map((customer) => (
                <option
                  key={customer.brand_invoice_number}
                  value={customer.brand_invoice_number}
                >
                  {customer.brand_invoice_number}
                </option>
              ))}
      </select>
    </div>
    {/* <div className="flex justify-end"> */}
    <div className="flex justify-end">
            <label
              for="invoice_number"
              className="  text-base font-bold text-gray-900 w-36 mr-2 mt-2"
            >
              Invoice #
            </label>
            <input
              readOnly
              id="invoice_number"
              name="invoice_number"
              type="text"
              value={latestInvoiceNumber}
              onChange={(e)=>{setLatestInvoiceNumber(e.target.value)}}
              placeholder="Enter Invoice Number"
              className="bg-gray-200 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
            />
        </div>
    {/* </div> */}
  </div>

  <div className="mt-8 text-center">
    <h3 className=" space-x-2 text-[16px] font-semibold text-gray-700 text-center mb-2">AVAILABLE GALLONS FOR DELIVERY</h3>

    <div
  className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4 rounded-lg px-8"
  style={{ boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)" , fontSize:'14px', fontFamily:'poppins'}}
  
>
  {/* Show placeholder content by default */}
  {!invoiceFuelData || invoiceFuelData.length === 0 ? (
    <>
    <div className="bg-white py-2 px-1 border-2 border-gray-300 bg-gray-100 flex items-center justify-center text-center rounded-lg my-4">
      PRODUCT X / XXXX / PURCHASE PRICE $XXXX
    </div>
      <div className="bg-white py-2 px-1 border-2 border-gray-300 bg-gray-100 flex items-center justify-center text-center rounded-lg my-4">
      PRODUCT X / XXXX / PURCHASE PRICE $XXXX
    </div>
      <div className="bg-white py-2 px-1 border-2 border-gray-300 bg-gray-100 flex items-center justify-center text-center rounded-lg my-4">
      PRODUCT X / XXXX / PURCHASE PRICE $XXXX
    </div>
    </>
  ) : (
    // Render actual data when available
    invoiceFuelData.map((item, index) => (
      <div
        key={index}
        className="bg-white py-2 px-1 border-2 border-gray-300 bg-gray-100 flex items-center justify-center text-center rounded-lg my-4"
      >
        {item.fuelgrade_name} / {formatNumberWithCommas(item.gross_quantity)} / PURCHASE PRICE ${Number(item.unit_price).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
      </div>
    ))
  )}
</div>

  </div>

  <div className="mt-8 text-center">
    <h3 className=" space-x-2 text-[16px] font-semibold text-gray-700 text-center mb-2">CUSTOMER INFORMATION</h3>
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 rounded-lg p-8" style={{   boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",}}>
      {/* <select
        className="p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-300">
        <option>Select Customer</option>
      </select> */}
        <select
              id="bill_to"
              name="bill_to"
              required
              value={formField.bill_to}
              onChange={handleChange}
              // required
              className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
            >
              <option value="">Select Customer</option>
              {customerOptions.map((customer) => (
                <option
                  key={customer.customer_id}
                  value={customer.customer_id}
                >
                  {customer.business_name}
                </option>
              ))}
            </select>
      {/* <input
        type="date"
        className="p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-300" /> */}
          <div 
            className="h-11 text-gray-700 font-normal border-gray-300 cursor-pointer border rounded-lg flex products-center justify-between"
            onClick={handleDeliveryDateClick} // Add event handler to the div
          >
          <div ref={deliveryDatePickerRef}>
            <DatePicker
              id="created_on"
              name="created_on"
              selected={createdDate}
              required
              minDate={new Date()}
              onChange={handleDeliveryDateChange}
              dateFormat="MM/dd/yyyy"        
              placeholderText="Delivery Date"
              className="w-40 outline-none border-none rounded-r-lg cursor-pointer focus:outline-none focus:ring-0 bg-transparent"
              onKeyDown={(e) => {e.preventDefault();}}
              shouldCloseOnSelect
            />
            </ div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-5 h-5 mr-2 mt-3"
            >
              <path d="M12.75 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM7.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM8.25 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM9.75 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM10.5 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM12.75 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM14.25 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 13.5a.75.75 0 100-1.5.75.75 0 000 1.5z" />
              <path
                fillRule="evenodd"
                d="M6.75 2.25A.75.75 0 017.5 3v1.5h9V3A.75.75 0 0118 3v1.5h.75a3 3 0 013 3v11.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V7.5a3 3 0 013-3H6V3a.75.75 0 01.75-.75zm13.5 9a1.5 1.5 0 00-1.5-1.5H5.25a1.5 1.5 0 00-1.5 1.5v7.5a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5v-7.5z"
                clipRule="evenodd"
              />
            </svg>
          </div>
      {/* <input
        type="date"
        className="p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-300" /> */}
           <div
                className="h-11 font-normal border-gray-300 cursor-pointer border rounded-lg flex products-center justify-between"
                onClick={handleOuterDivClick}
              >
                <div ref={datePickerRef}>
                  <DatePicker
                    name="due_on"
                    id="due_on"
                    dateFormat="MM/dd/yyyy"
                    required
                    minDate={new Date()}
                    onKeyDown={(e) => {e.preventDefault();}}
                    selected={dueDate}
                    onChange={handleDueDateChange} 
                    placeholderText="Payment Due Date "
                    shouldCloseOnSelect
                    className="w-40 outline-none border-none rounded-r-lg cursor-pointer focus:outline-none focus:ring-0 bg-transparent"
                  />
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-5 h-5 mr-2 mt-3"
                >
                  <path d="M12.75 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM7.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM8.25 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM9.75 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM10.5 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM12.75 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM14.25 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 13.5a.75.75 0 100-1.5.75.75 0 000 1.5z" />
                  <path
                    fillRule="evenodd"
                    d="M6.75 2.25A.75.75 0 017.5 3v1.5h9V3A.75.75 0 0118 3v1.5h.75a3 3 0 013 3v11.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V7.5a3 3 0 013-3H6V3a.75.75 0 01.75-.75zm13.5 9a1.5 1.5 0 00-1.5-1.5H5.25a1.5 1.5 0 00-1.5 1.5v7.5a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5v-7.5z"
                    clipRule="evenodd"
                  />
                </svg>
            </div>
    </div>
  </div>

  <div className="mt-8 text-center">
    <h3 className=" space-x-2 text-[16px] font-semibold text-gray-700 text-center mb-2">PRODUCT SALES</h3>
    <div
  className="bg-white p-4 shadow-lg rounded-lg px-8"
  style={{ boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)" }}
>
  {/* Header Row */}
  <div className="grid grid-cols-1 md:grid-cols-5 gap-4 mb-4 text-gray-500 text-sm font-medium">
    {/* <div className="w-[30px]">ACTION</div>
    <div>PRODUCT</div>
    <div>QUANTITY</div>
    <div>UNIT PRICE</div>
    <div>LINE TOTAL</div> */}
  </div>

  {/* Data Rows */}
  <div className="">
  <table className="w-full">
  <thead>
    <tr className="bg-gray-100">
      <th style={{ width: "60px" }} className="pt-2 pb-2 pl-0 font-semibold text-base">Action</th>
      <th style={{ width: "50px" }} className="pt-2 pb-2 pl-0 font-semibold text-base">#</th>
      <th style={{ width: "150px" }} className="p-5 font-semibold pr-2 text-base">Product</th>
      <th style={{ width: "60px" }} className="p-5 font-semibold text-base">Quantity</th>
      <th style={{ width: "100px" }} className="p-5 font-semibold text-base">Unit Price</th>
      <th style={{ width: "70px" }} className="p-5 font-semibold text-right">Total</th>
    </tr>
  </thead>
  <tbody>

    {InvoiceCustomerData && InvoiceCustomerData?.map((product, index) => (
      <tr key={index}>
        <td>
          <button
            onClick={(e) => handleDelete(e,product.product_id)}
            className="w-[30px] h-8 text-red-500 hover:text-red-700 focus:outline-none border-2 border-red-200 rounded-full flex items-center justify-center"
          >
            ✖
          </button>
        </td>
        <td className="p-2 pl-4">{index + 1}</td>
        <td className="p-2">
          <div className="text-gray-900 text-base">{product.product_name}</div>
        </td>
        <td className="p-2">
          <input
            type="number"
            min="0"
            value={product.quantity || ""}
            placeholder="Enter Quantity"
            onChange={(e) => handleProductChange(index, "quantity", e.target.value)}
            className="border border-gray-300 text-gray-900 text-right rounded-md focus:ring-primary-600 focus:border-primary-600 block p-1"
          />
        </td>
        <td className="p-2">
          <input
            type="number"
            min="0"
            value={product.unitPrice || ""}
            placeholder="Enter Unit Price"
            onChange={(e) => handleProductChange(index, "unitPrice", e.target.value)}
            className="border border-gray-300 text-gray-900 text-right rounded-md focus:ring-primary-600 focus:border-primary-600 block p-1"
          />
        </td>
        <td className="flex justify-end p-2">
          {isNaN(product.total) ? "0.00000" : product.total.toFixed(5)}
        </td>
      </tr>
    ))}
  </tbody>
</table>

  </div>

  {/* Total Section */}
  <div className="flex flex-wrap justify-end items-center font-bold w-3/4 ml-auto mt-4">
    <span className="text-right">TOTAL GROSS SALES AMT:</span>
    <span className="ml-4 text-right text-gray-700"> {subTotal.toLocaleString(undefined, { minimumFractionDigits: 5, maximumFractionDigits: 5 })}</span>
  </div>
</div>

  </div>

  <div class="bg-white rounded-lg  mt-4 w-full  mx-auto text-center">
  <div class=" space-x-2 text-[16px] font-semibold text-gray-700 text-center mb-2">TAX</div>
  <div class="space-y-4 p-8 rounded-lg" style={{   boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",}}>
    <div class="flex flex-wrap justify-end items-center w-1/3 ml-auto">
      <span class="flex-2 text-left font-medium w-[200px]">Freight:</span>
      <span class="flex-1 text-right text-gray-500">{taxValues.freight}</span>
      <span class="flex-1 text-right font-semibold">{(totalQuantity * taxValues.freight).toLocaleString(undefined, { minimumFractionDigits: 5, maximumFractionDigits: 5 })}</span>
    </div>
    <div class="flex flex-wrap justify-end items-center w-1/3 ml-auto">
      <span class="flex-2 text-left font-medium w-[200px]">Freight surcharge :</span>
      <span class="flex-1 text-right text-gray-500">{taxValues.freightSurcharge}</span>
      <span class="flex-1 text-right font-semibold"> 
                                 
  {((totalQuantity*taxValues.freight).toLocaleString(undefined, { minimumFractionDigits: 5, maximumFractionDigits: 5 })* taxValues.freightSurcharge).toLocaleString(undefined, { minimumFractionDigits: 5, maximumFractionDigits: 5 })}
  
      </span>
    </div>
    <div class="flex flex-wrap justify-end items-center  w-1/3 ml-auto">
      <span class="flex-2 text-left font-medium w-[200px]">Federal excise tax :</span>
      <span class="flex-1 text-right text-gray-500">{taxValues.FederalExciseTax}</span>
      <span class="flex-1 text-right font-semibold">{(totalQuantity*taxValues.FederalExciseTax).toLocaleString(undefined, { minimumFractionDigits: 5, maximumFractionDigits: 5 })}</span>
    </div>
   
    <div class="flex flex-wrap justify-end items-center  w-1/3 ml-auto">
      <span class="flex-2 text-left font-medium w-[200px]"> Federal oil spil :</span>
      <span class="flex-1 text-right text-gray-500">{taxValues.FederalOilSpil}</span>
      <span class="flex-1 text-right font-semibold">{(totalQuantity*taxValues.FederalOilSpil).toLocaleString(undefined, { minimumFractionDigits: 5, maximumFractionDigits: 5 })}</span>
    </div>
    <div class="flex flex-wrap justify-end items-center  w-1/3 ml-auto">
      <span class="flex-2 text-left font-medium w-[200px]"> NYS prepaid :</span>
      <span class="flex-1 text-right text-gray-500">{taxValues.NysPrepaid}</span>
      <span class="flex-1 text-right font-semibold">{(totalQuantity*taxValues.NysPrepaid).toLocaleString(undefined, { minimumFractionDigits: 5, maximumFractionDigits: 5 })}</span>
    </div>
    <div class="flex flex-wrap justify-end items-center  w-1/3 ml-auto">
      <span class="flex-2 text-left font-medium w-[200px]"> NYS excise :</span>
      <span class="flex-1 text-right text-gray-500">{taxValues.NysExcise}</span>
      <span class="flex-1 text-right font-semibold">{(totalQuantity*taxValues.NysExcise).toLocaleString(undefined, { minimumFractionDigits: 5, maximumFractionDigits: 5 })}</span>
    </div>
    <div class="flex flex-wrap justify-end items-center  w-1/3 ml-auto">
      <span class="flex-2 text-left font-medium w-[200px]">NYS petroleum bus rcvry :</span>
      <span class="flex-1 text-right text-gray-500">{taxValues.NysPetroleumBusRcvry}</span>
      <span class="flex-1 text-right font-semibold">{(totalQuantity*taxValues.NysPetroleumBusRcvry).toLocaleString(undefined, { minimumFractionDigits: 5, maximumFractionDigits: 5 })}</span>
    </div> <div class="flex flex-wrap justify-end items-center w-1/3 ml-auto">
      <span class="flex-2 text-left font-medium w-[200px]"> NYS petroleum testing fee :</span>
      <span class="flex-1 text-right text-gray-500">{taxValues.NysPetroleumTestingFee}</span>
      <span class="flex-1 text-right font-semibold">{(totalQuantity*taxValues.NysPetroleumTestingFee).toLocaleString(undefined, { minimumFractionDigits: 5, maximumFractionDigits: 5 })}</span>
    </div> <div class="flex flex-wrap justify-end items-center  w-1/3 ml-auto">
      <span class="flex-2 text-left font-medium w-[200px]">NYS spill recovery:</span>
      <span class="flex-1 text-right text-gray-500">{taxValues.NysSpillRecovery}</span>
      <span class="flex-1 text-right font-semibold">{(totalQuantity*taxValues.NysSpillRecovery).toLocaleString(undefined, { minimumFractionDigits: 5, maximumFractionDigits: 5 })}</span>
    </div>
    <hr class="border-dashed border-t-2 border-gray-300 my-4" />
    <div class="flex flex-wrap justify-end items-center font-bold  w-1/3 ml-auto">
    <span className='flex-1 text-left w-[200px]'></span>
      <span class="flex-2 text-right">TOTAL TAX =</span>
      <span class="flex-1 text-right">{formatNumber(taxTotal)}</span>
    </div>
  </div>
</div>




  <div class="mt-8">
    <h3 class=" space-x-2 text-[16px] font-semibold text-gray-700 text-center mb-2">INVOICE AMT</h3>
    <div class="flex items-center justify-center gap-9 w-full rounded-lg p-8" style={{   boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",}}>
      <div class="bg-white p-4 border-t-4 border-blue-300 shadow-lg rounded-lg text-center w-25 px-8">
        <div>GAS SALES AMT</div>
        <div>${subTotal.toLocaleString(undefined, { minimumFractionDigits: 5, maximumFractionDigits: 5 })}</div>
      </div>
      <span class="text-2xl font-bold">+</span>
      <div class="bg-white p-4 border-t-4 border-blue-300 shadow-lg rounded-lg text-center w-25  px-8">
        <div>SALES TAX AMT</div>
        <div>{formatNumber(taxTotal)}</div>
      </div>
      <span class="text-2xl font-bold">+</span>
      <div class="bg-white p-4 border-t-4 border-blue-300 shadow-lg rounded-lg text-center w-25  px-8">
        <div>MISCELLANEOUS AMT</div>
        <div>$000.00</div>
      </div>
      <span class="text-2xl font-bold">=</span>
      <div class="bg-white p-4 border-t-4 border-blue-300 shadow-lg rounded-lg text-center w-25  px-8">
        <div>TOTAL INVOICE AMT</div>
        <div>${grandTotal.toLocaleString(undefined, { minimumFractionDigits: 5, maximumFractionDigits: 5 })}</div>
      </div>
    </div>
  </div>

  <div className="mt-8 text-center">
    <h3 className=" space-x-2 text-[16px] font-semibold text-gray-700 text-center mb-2">TERMS AND CONDITIONS</h3>
    <div className="bg-white p-4 shadow-lg rounded-lg"  style={{   boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",}}>
      {/* <textarea className="w-full h-24 border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring focus:ring-blue-300" placeholder="Enter terms and conditions here..."></textarea> */}
      <textarea
              id="termsAndConditions"
              rows="4"
              name="termsAndConditions"
              value={termsAndConditions}
              onChange={(e) => setTermsAndConditions(e.target.value)}
              className="block p-2.5 mt-3 mb-3 w-full text-base text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 "
              placeholder="Add your Terms & Conditions here..."
              maxLength={100}
            ></textarea>
    </div>
  </div>
  <div className="mt-8 text-center">
    <h3 className=" space-x-2 text-[16px] font-semibold text-gray-700 text-center mb-2">CUSTOMER SPECIFIC COMMENTS - INTERNAL ONLY</h3>
    <div className="bg-white p-4 shadow-lg rounded-lg"  style={{   boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",}}>
    <textarea
            id="description"
            rows="4"
            name="description"
            value={comments}
            onChange={(e) => setComments(e.target.value)}
            className="block p-2.5 w-full text-base text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 "
            placeholder="Write your Comments here..."
            maxLength={100}
          ></textarea>
      {/* <textarea className="w-full h-24 border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring focus:ring-blue-300" placeholder="Enter comments here..."></textarea> */}
    </div>
  </div>
</div>
{ isFormComplete() && (
              <div className="flex justify-end space-x-3 mt-8 mr-8">
                <button
                  onClick={() => navigate(`/invoice`)}
                  type="button"
                  className="hover:bg-red-500 border-[#3479E0] border-2 text-black px-6 py-1.5 text-base font-semibold hover:border-transparent hover:shadow-lg hover:shadow-red-500/60 text-center hover:text-white rounded-lg focus:ring-4 focus:ring-primary-200 hover:bg-primary-800"
                
                >
                  Cancel
                </button>
                <button
                 onClick={handleSubmit}
                  type="submit"
                  className="hover:bg-[#0044AB] border-[#3479E0] border-2 text-black px-6 py-1.5 text-base font-semibold hover:border-transparent hover:shadow-lg hover:shadow-[#0044AB]/50 text-center hover:text-white rounded-lg focus:ring-4 focus:ring-primary-200 hover:bg-primary-800"
                  // disabled={!isFormValid}
                >
                  Done
                </button>
              </div>
            )}
</form>
    </div>
    </>
  )
}

export default InvoiceFormTwo