

import React, { useEffect, useMemo, useState, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { createColumnHelper ,
  useReactTable, 
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel, } from "@tanstack/react-table";
import Table from "./Table";
import Search from "../Search/Search";
import { useDispatch, useSelector } from "react-redux";
import config from "../../config/config";
import { Tooltip } from "react-tippy";
import PopUpModalForDeleteVendor from "./VendorDeleteModel";
import { addVendor } from "../../store/vendorSlice";

import PopUpModalForDeleteItem from "./ItemDeleteModel";
import { addItem } from "../../store/itemSlice";
import IndeterminateCheckbox from "./IndeterminateCheckbox";
import { FaArrowDownLong , FaArrowUpLong} from "react-icons/fa6";
import { MdNavigateNext } from "react-icons/md";
import { MdNavigateBefore } from "react-icons/md";
import ScrollingMessage from "./ScrollingMessage";
import { AiOutlineSearch } from "react-icons/ai";


const ItemDashboard = () => {
  const dispatch = useDispatch(); 
  
  const allItems = useSelector((state)=> state.item.allItems)
  // console.log(allItems);
  const [itemData ,  setItemData] = useState([])
  const columnHelper = createColumnHelper();


  const businessId = localStorage.getItem("business_id")

    useEffect(() => {
      window.scrollTo(0, 0);
      const fetchData = async ()=> {
          try{
            const response = await axios.get(`${baseURL}${btoa("items/view")}/${btoa(businessId)}`)
            console.log("item response" , response);
            dispatch(addItem(response.data.items_data))
            // setItemData(response.data.items_data)
          }catch(error){
            console.log("error fetching all items" , error);
          }
      }
      fetchData()
    }, []);
    console.log(itemData)


 

  const baseURL =
    process.env.NODE_ENV === "production"
      ? config.production.baseURL
      : config.development.baseURL;

  const [selectedRows, setSelectedRows] = useState([]);
  const isEditActive = selectedRows.length === 1;
  const isDeleteActive = selectedRows.length > 0;
  const [searchValue, setSearchValue] = useState("");
  const resetRowSelection = useRef(null);
  const [openDeleteModalId, setOpenDeleteModalId] = useState(null);
  const [rowSelection, setRowSelection] = useState({});
  const [sorting , setSorting] = React.useState([])
  const [filtering , setFiltering] = useState("")
  const [columnFilters, setColumnFilters] = useState("")

  const navigate = useNavigate();
  
  const formatDate = (dateStr) => {
    const [year, month, day] = dateStr.split("-");
    return `${month}/${day}/${year}`;
  };
  const capitalizeFirstLetterWordWords = (str) => {
    return str
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };
  const lowercaseAllWords = (str) => {
    return str
      .toLowerCase()
  };


      const handleSortingChange = (columnId) => {
        setSorting((old) => {
          if (old.length && old[0].id === columnId) {
            // Toggle sorting direction
            return [{
              id: columnId,
              desc: !old[0].desc,
            }];
          }
          // Default sorting to descending initially
          return [{ id: columnId, desc: true }];
        });
      };
      const isRowCheckboxDisabled = (row) => {
        if (rowSelection[row.id]) {
          return false;
        } else {
          return Object.values(rowSelection).some((selected) => selected);
        }
      };
      
      const columns = [
        {
          id: "checkbox",
          accessor: "",
          cell: ({ row }) => (
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                disabled: isRowCheckboxDisabled(row),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
                className: isRowCheckboxDisabled(row) ? "disabled-checkbox" : "",
              }}
            />
          ),
          enableColumnFilter: false, // Disable filter for checkbox column
          enableSorting: false, // Disable sorting for checkbox column
        },
        columnHelper.accessor("added_on", {
          header: "Added On",
          cell: (props) => {
            const date = new Date(props.row.original.added_on);
            const options = { month: 'short', day: '2-digit', year: 'numeric' };
            const formattedDate = `${date.toLocaleDateString("en-US", { month: 'short' })}-${date.toLocaleDateString("en-US", { day: '2-digit' })}-${date.toLocaleDateString("en-US", { year: 'numeric' })}`;
            return (
              <div>{formattedDate}</div>
            );
          },
          enableColumnFilter: false,
          enableSorting: true,
        }),
   
        columnHelper.accessor("Item_name", {
          header: "Item",
          accessorFn: (row) => row?.Item_name || "", // Return empty string if Item_name is undefined
        }),
        columnHelper.accessor("item_unit_price", {
          header: "Unit Price",
          accessorFn: (row) => row?.item_unit_price || "", // Return empty string if item_unit_price is undefined
        }),
        columnHelper.accessor("Item_des", {
          header: "Item Description",
          accessorFn: (row) => row?.Item_des || "", // Return empty string if Item_des is undefined
        }),
      ];
      

      
      const table = useReactTable({
        data: allItems || [],
        columns: columns,
        state: {
          rowSelection: rowSelection,
          globalFilter: filtering,
          sorting: sorting,
        },
        onSortingChange: setSorting,
        onGlobalFilterChange: setFiltering,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        onRowSelectionChange: setRowSelection,
        onColumnFiltersChange: setColumnFilters,
        enableRowSelection: true,
        enableColumnFilter: true,
      });
      const currentPage = table.options.state.pagination.pageIndex;

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleDeleteModalItem = (itemId, itemName) => {
    if (table.getSelectedRowModel().flatRows[0]?.original) {
      setOpenDeleteModalId(itemId);
    }
  };

  

  return (
    <div>
       <div className="w-full ml-5">
        <ScrollingMessage />
        </div>
      <div className="flex justify-between items-center mt-8 pl-9 pr-8">
        <h1 className="font-bold mb-3 text-gray-400 text-lg">
          Items Dashboard - {`${allItems.length} Active items`}
        </h1>

        <div>
          <button
             className="flex items-center justify-center font-bold mr-3 w-55 border-transparent border-2 text-white bg-[#25caed] hover:scale-105 transition-transform duration-300 ease-in-out focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-base px-4 py-1.5 focus:outline-none"
            type="button"
            onClick={() => navigate(`/items/itemform`)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={3}
              stroke="currentColor"
              className="w-5 h-5 mr-3"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
            </svg>
            New Item
          </button>
        </div>
      </div>

            <div className="flex w-full mt-10 pl-9 pr-8 space-x-4">
              <div className="relative w-full">
                <AiOutlineSearch
                  size={18}
                  className="absolute top-1/2 transform -translate-y-1/2 left-3 text-gray-700"
                />
                <input
                  type="text"
                  placeholder="Search..."
                  className="pl-10 px-6 py-1 border-2 rounded-lg border-gray-300 outline-none block w-full focus:ring-3"
                  value={filtering}
                  onChange={e => setFiltering(e.target.value)}
                />
              </div>

            <div className="flex items-center space-x-3 relative">
              {table.getSelectedRowModel().flatRows[0]?.original && (
                <Tooltip title="Edit" position="top" trigger="mouseenter">
                  <button
                    className="flex items-center text-sm font-medium text-center focus:outline-none text-gray-500 hover:text-blue-700 rounded-lg"
                    type="button"
                    onClick={() => {
                      const itemId = table.getSelectedRowModel().flatRows[0]?.original.Item_id;
                      navigate(`/setup/viewitem/${itemId}`);
                    }}
                  >
                    <div className="hover:rounded-full rounded-full p-2 hover:bg-blue-200">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="w-5 h-5"
                      >
                        <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32l8.4-8.4z" />
                        <path d="M5.25 5.25a3 3 0 00-3 3v10.5a3 3 0 003 3h10.5a3 3 0 003-3V13.5a.75.75 0 00-1.5 0v5.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5V8.25a1.5 1.5 0 011.5-1.5h5.25a.75.75 0 000-1.5H5.25z" />
                      </svg>
                    </div>
                  </button>
                </Tooltip>
              )}

              {table.getSelectedRowModel().flatRows[0]?.original && (
                <Tooltip title="Delete" position="top" trigger="mouseenter">
                  <button
                    className="flex items-center text-sm font-medium text-center focus:outline-none text-gray-500 hover:text-red-700 rounded-lg"
                    type="button"
                    onClick={() => {
                      const itemId = table.getSelectedRowModel().flatRows[0]?.original.Item_id;
                      handleDeleteModalItem(itemId)
                    }}
                  >
                    <div className="rounded-full p-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="w-5 h-5"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                  </button>
                </Tooltip>
              )}
            </div>
          </div>



      <div className="rounded-tl-sm rounded-tr-sm mt-2 p-6 pl-9 pr-11">
          <table className="w-full table-auto bg-transparent rounded-xl mt-6">
              <thead className="bg-gray-500/20 text-gray-500">
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id} className="text-center text-xs">
                    {headerGroup.headers.map((header, index) => {
                      const isFirstHeader = index === 0;
                      const isLastHeader = index === headerGroup.headers.length - 1;
                      const headerClasses = [
                        'p-3 font-bold uppercase',
                        isFirstHeader ? 'rounded-l-xl' : '',
                        isLastHeader ? 'rounded-r-xl' : '',
                      ];

                      const isSorted = header.column.getIsSorted();
                      const showSortingIcons = header.column.columnDef.enableSorting !== false;
                      const ArrowUpIcon = (
                        <FaArrowUpLong
                          className={`${isSorted === 'asc' ? 'text-black' : 'text-gray-400'}`}
                          size={12}
                        />
                      );
                      const ArrowDownIcon = (
                        <FaArrowDownLong
                          className={`${isSorted === 'desc' ? 'text-black' : 'text-gray-400'}`}
                          size={12}
                        />
                      );

                      return (
                        <th
                          key={header.id}
                          className={headerClasses.join(' ')}
                          colSpan={header.colSpan}
                          onClick={() => showSortingIcons && handleSortingChange(header.column.id)}
                        >
                          <div className="flex items-center justify-center">
                            {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                            {showSortingIcons && (
                              <div className="flex items-center ml-2">
                                {ArrowDownIcon}
                                {ArrowUpIcon}
                              </div>
                            )}
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => (
                  <tr key={row.id} className="cursor-pointer text-center hover:bg-gray-500/10">
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id} className="p-4 font-semibold text-[#081159] text-[14px]">
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
          </table>

              <div className="flex items-center mr-7 mt-6 gap-2 justify-end">
                  <button
                    className={`px-4 py-2 rounded-l-lg focus:outline-none flex items-center ${
                      table.getCanPreviousPage()
                        ? " hover:bg-[#114FFF] hover:text-white cursor-pointer"
                        : " cursor-not-allowed"
                    }`}
                    onClick={() => table.previousPage()}
                    disabled={!table.getCanPreviousPage()}
                  >
                    <MdNavigateBefore className="w-6 h-6" />
                    Prev
                  </button>
                  {[...Array(table.getPageCount()).keys()].map((page) => (
                    <button
                      key={page}
                      onClick={() => table.setPageIndex(Number(page))}
                      className={`px-4 py-2  focus:outline-none ${
                        currentPage === page
                          ? " rounded-full bg-[#3479E0] text-white "
                          : " rounded-full bg-white dark:text-black hover:bg-[#3479E0]/50 hover:text-white"
                      }`}
                    >
                      {page + 1}
                    </button>
                  ))}
                  <button
                    className={`px-4 py-2 rounded-r-lg  focus:outline-none flex items-center justify-center${
                      table.getCanNextPage()
                        ? " hover:bg-[#114FFF] hover:text-white cursor-pointer"
                        : "bg-gray-300 cursor-not-allowed"
                    }`}
                    onClick={() => table.nextPage()}
                    disabled={!table.getCanNextPage()}
                  >
                    Next
                    <MdNavigateNext className="w-6 h-6" />
                  </button>
                </div>


        {openDeleteModalId && (
          <PopUpModalForDeleteItem
            onClose={() => setOpenDeleteModalId(null)}
            itemId={openDeleteModalId}
            itemName={table.getSelectedRowModel().flatRows[0]?.original.Item_name}
            resetRowSelection={table.resetRowSelection}
          />
        )}

      </div>
    </div>
  );
};

export default ItemDashboard;








