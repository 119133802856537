// // import React, { useEffect, useRef, useState } from 'react';
// // import Modal from 'react-modal';
// // import { FaTimes , FaDownload} from 'react-icons/fa';
// // import config from "../../config/config";

// // Modal.setAppElement('#root');

// // const PurchaseImagesViewModel = ({ isModalOpen, closeModal, selectedImagePath }) => {
// //   const [zoomScale, setZoomScale] = useState(1);
// //   const imageRef = useRef(null);

// //   useEffect(() => {
// //     if (isModalOpen) {
// //       document.body.style.overflow = 'hidden';
// //       window.addEventListener('wheel', handleScrollZoom);
// //     } else {
// //       document.body.style.overflow = 'unset';
// //       window.removeEventListener('wheel', handleScrollZoom);
// //     }

// //     return () => {
// //       document.body.style.overflow = 'unset';
// //       window.removeEventListener('wheel', handleScrollZoom);
// //     };
// //   }, [isModalOpen]);

// //   const handleScrollZoom = (event) => {
// //     if (imageRef.current) {
// //       event.preventDefault();
// //       const scaleAmount = 0.1;
// //       const newZoomScale = zoomScale + (event.deltaY < 0 ? scaleAmount : -scaleAmount);
// //       setZoomScale(Math.max(1, newZoomScale)); // Prevent zooming out below 1x
// //     }
// //   };

// //   if (!isModalOpen) return null;

// //   const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;

// //   return (
// //     <div style={overlayStyles}>
// //       <button onClick={closeModal} style={closeButtonStyles}>
// //         <FaTimes />
// //       </button>
// //       <div style={imageContainerStyles}>
// //         {selectedImagePath ? (
// //           <img
// //             src={`${baseURL}uploads/docs/receipts/${selectedImagePath}`}
// //             alt="Selected"
// //             style={{ ...imageStyles, transform: `scale(${zoomScale})` }}
// //             ref={imageRef}
// //           />
// //         ) : (
// //           <p style={{ fontWeight: 'bold', color: 'red', backgroundColor: '#ffe5e5', padding: '10px', borderRadius: '5px' }}>
// //           No Image for this purchase
// //         </p>
// //         )}
// //       </div>
// //     </div>
// //   );
// // };

// // const overlayStyles = {
// //   position: 'fixed',
// //   top: 0,
// //   left: 0,
// //   width: '100%',
// //   height: '100%',
// //   backgroundColor: 'rgba(0, 0, 0, 0.5)',
// //   display: 'flex',
// //   justifyContent: 'center',
// //   alignItems: 'center',
// //   zIndex: 9999,
// // };

// // const closeButtonStyles = {
// //   position: 'absolute',
// //   top: '10px',
// //   right: '10px',
// //   background: 'none',
// //   border: 'none',
// //   fontSize: '24px',
// //   cursor: 'pointer',
// //   color: 'white',
// //   zIndex: 10000,
// // };

// // const imageContainerStyles = {
// //   display: 'flex',
// //   justifyContent: 'center',
// //   alignItems: 'center',
// //   height: '100%',
// //   width: '100%',
// //   overflow: 'hidden', // Hide any overflow
// // };

// // const imageStyles = {
// //   maxHeight: '100%', // Ensure the image fits within the container
// //   maxWidth: '100%', // Ensure the image fits within the container
// //   objectFit: 'contain', // Maintain the aspect ratio of the image
// //   transition: 'transform 0.2s ease-in-out', // Smooth zoom transition
// // };

// // export default PurchaseImagesViewModel;

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import Modal from 'react-modal';
// import { FaTimes } from 'react-icons/fa';
// import { pdfjs, Document, Page } from 'react-pdf';
// import { DisabledByDefault } from '@mui/icons-material';
// import config  from "../../config/config"


// Modal.setAppElement('#root');

// const PurchaseImagesViewModel = ({  isModalOpen, closeModal, invoiceNumber }) => {
//   const [imageUrls, setImageUrls] = useState([]);
//   const business_id = localStorage.getItem("business_id");
//   const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;
  
//   useEffect(() => {
//     const fetchPdf = async () => {
//       try {
//         const response = await axios.get(`${baseURL}${btoa("invoice/downloadinvoice")}/${btoa(invoiceNumber)}/${btoa(business_id)}`, {
//           responseType: 'arraybuffer',
//         });
  
//         // Convert the PDF data to image URLs
//         const urls = await convertPdfToImages(response.data);
//         setImageUrls(urls);
//       } catch (error) {
//         console.log("Error viewing invoice:", error);
//       }
//     };
  
//     if (isModalOpen) {
//       document.body.style.overflow = 'hidden'; // Disable scrolling when modal is open
//       fetchPdf();
//     } else {
//       document.body.style.overflow = 'auto'; // Enable scrolling when modal is closed
//     }
  
//     return () => {
//       document.body.style.overflow = 'auto'; // Ensure scrolling is restored on unmount
//     };
//   }, [isModalOpen, invoiceNumber, business_id]);
  

//   return (
//     <Modal isOpen={isModalOpen} onRequestClose={closeModal} style={customStyles} shouldCloseOnOverlayClick={false}>
//       <div className="flex justify-end sticky top-0 right-0 m-2 z-50">
//         <button onClick={closeModal} className="text-gray-500 hover:text-gray-700">
//           <FaTimes />
//         </button>
//       </div>

//       <div className="max-h-[calc(100vh-4rem)]">
//         {imageUrls.map((url, index) => (
//           <img key={index} src={url} alt={`Page ${index + 1}`} />
//         ))}
//       </div>
//     </Modal>

  
  
//   );
// };

// const customStyles = {
//   overlay: {
//     backgroundColor: 'rgba(0, 0, 0, 0.5)', // Background color with opacity
//     zIndex: 9999,
  
//   },
//   content: {
//     top: '50%',
//     left: '50%',
//     right: 'auto',
//     bottom: 'auto',
//     marginRight: '-50%',
//     transform: 'translate(-50%, -50%)',
//     width: '80%',
//     height: '80%',
//     padding: '20px',
//     backgroundColor: '#fff',
//     boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
//     borderRadius: '8px',
//     // Prevent scrolling inside the modal
//   },
// };


// // Function to convert PDF to images
// const convertPdfToImages = async (pdfData) => {
//   try {
//     pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

//     const loadingTask = pdfjs.getDocument(new Uint8Array(pdfData));
//     const pdf = await loadingTask.promise;

//     const urls = [];
//     for (let i = 1; i <= pdf.numPages; i++) {
//       const page = await pdf.getPage(i);
//       const viewport = page.getViewport({ scale: 2 });
//       const canvas = document.createElement('canvas');
//       const context = canvas.getContext('2d');
//       canvas.width = viewport.width;
//       canvas.height = viewport.height;

//       const renderContext = {
//         canvasContext: context,
//         viewport: viewport
//       };
//       await page.render(renderContext).promise;

//       // Convert canvas to data URL
//       const imageUrl = canvas.toDataURL('image/jpeg');
//       urls.push(imageUrl);
//     }

//     return urls;
//   } catch (error) {
//     console.log("Error converting PDF to images:", error);
//     return [];
//   }
// };

// export default PurchaseImagesViewModel;


import React, { useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import { FaTimes, FaDownload } from 'react-icons/fa';
import config from '../../config/config';

Modal.setAppElement('#root');

const PurchaseImagesViewModel = ({ isModalOpen, closeModal, selectedImagePath }) => {
  const baseURL =
    process.env.NODE_ENV === 'production' ? config.production.baseURL : config.development.baseURL;

  const pdfUrl = `${baseURL}uploads/docs/receipts/${selectedImagePath}.pdf`;
  const [zoomScale, setZoomScale] = useState(1);
  const containerRef = useRef(null);

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isModalOpen]);

  const handleZoom = (direction) => {
    setZoomScale((prev) => Math.max(1, direction === 'in' ? prev + 0.1 : prev - 0.1));
  };

  if (!isModalOpen) return null;

  return (
    <div style={overlayStyles}>
      <div style={headerStyles}>
        <button onClick={closeModal} style={closeButtonStyles}>
          <FaTimes />
        </button>
      </div>
      <div style={containerStyles} ref={containerRef}>
        {selectedImagePath ? (
          <iframe
            src={pdfUrl}
            title="PDF Viewer"
            style={{ ...iframeStyles, transform: `scale(${zoomScale})` }}
          ></iframe>
        ) : (
          <div style={errorStyles}>No Image for this purchase</div>
        )}
      </div>
  
    </div>
  );
};

// Styling
const overlayStyles = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 9999,
};

const headerStyles = {
  position: 'absolute',
  top: '10px',
  right: '10px',
  display: 'flex',
  gap: '10px',
};

const closeButtonStyles = {
  background: 'none',
  border: 'none',
  fontSize: '24px',
  cursor: 'pointer',
  color: 'white',
};

const downloadButtonStyles = {
  background: 'none',
  border: 'none',
  fontSize: '24px',
  cursor: 'pointer',
  color: 'white',
  textDecoration: 'none',
};

const containerStyles = {
  width: '90%',
  height: '80%',
  backgroundColor: 'white',
  borderRadius: '8px',
  overflow: 'hidden',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const iframeStyles = {
  width: '100%',
  height: '100%',
  border: 'none',
  transition: 'transform 0.2s ease-in-out',
};

const errorStyles = {
  fontWeight: 'bold',
  color: 'red',
  backgroundColor: '#ffe5e5',
  padding: '10px',
  borderRadius: '5px',
};

const zoomControlsStyles = {
  position: 'absolute',
  bottom: '20px',
  display: 'flex',
  gap: '10px',
};

const zoomButtonStyles = {
  padding: '10px 20px',
  borderRadius: '5px',
  backgroundColor: '#3479E0',
  color: 'white',
  border: 'none',
  cursor: 'pointer',
};

export default PurchaseImagesViewModel;
