import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allItems: [],
};

const itemSlice = createSlice({
  name: "item",
  initialState,
  reducers: {
    addItem(state, action) {
    //   console.log("act", action.payload);
      if (Array.isArray(action.payload)) {
        const newItems = action.payload.filter(newItem =>
          !state.allItems.some(existingItems => existingItems.Item_id === newItem.Item_id)
        );
        state.allItems.push(...newItems);
      } else {
        if (!state.allItems.some(existingItem => existingItem.Item_id === action.payload.Item_id)) {
          state.allItems.push(action.payload);
        }
      }
    },
    deleteItem(state, action) {
      state.allItems = state.allItems.filter(
        item => item.Item_id !== action.payload
      );
    },
    deleteAllItem(state) {
        state.allItems = []; 
      }
  },
});

export const { addItem, deleteItem , deleteAllItem } = itemSlice.actions;
export default itemSlice.reducer;
