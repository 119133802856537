import React, { useEffect, useState, useRef } from "react";
import {useNavigate } from "react-router-dom";
import ScrollToTop from "./ScrollTop";
import { createColumnHelper ,
  useReactTable, 
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel, } from "@tanstack/react-table";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { FaCheckCircle } from "react-icons/fa";
import PopUpModalForDeleteCustomer from "./CustomerDeleteModel";
import { FaTimesCircle } from "react-icons/fa";
import { addCustomer, deleteAllCustomers, deleteCustomer } from "../../store/customerSlice";
import { Tooltip } from "react-tippy";
import Search from "../Search/Search";
import config  from "../../config/config"
import { useMemo } from "react";
import { FaArrowDownLong , FaArrowUpLong} from "react-icons/fa6";
import IndeterminateCheckbox from "./IndeterminateCheckbox";
import { MdNavigateNext } from "react-icons/md";
import { MdNavigateBefore } from "react-icons/md";
import ScrollingMessage from "./ScrollingMessage";
import { AiOutlineSearch } from "react-icons/ai";
import ToastNotificationBox from "./ToastBox/ToastNotificationBox";

const columnHelper = createColumnHelper();

function capitalizeFirstLetter(email) {
  return email.charAt(0).toUpperCase() + email.slice(1).toLowerCase();
}

function Customer() {
  const [selectedRows, setSelectedRows] = useState([]);
  const isEditActive = selectedRows.length === 1;
  const isDeleteActive = selectedRows.length > 0;
  const [activeCustomerCount, setActiveCustomerCount] = useState(0);
  const [inactiveCustomerCount, setInactiveCustomerCount] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [rowSelection, setRowSelection] = useState({});
  const [sorting , setSorting] = React.useState([])
  const [filtering , setFiltering] = useState("")
  const [columnFilters, setColumnFilters] = useState("")

  const dispatch = useDispatch();
  const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;
  const customersAll = useSelector(state => state.customer.allCustomers);
  console.log("all customers of business" , customersAll);
  const resetRowSelection = useRef(null);

  const navigate = useNavigate();
  const handleViewCustomer = (customerId) => {
    navigate(`/setup/viewcustomer/${customerId}`);
  };

  const [customerData, setCustomerData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openModalId, setOpenModalId] = useState(null);
  // fetching data from api
  const [openDeleteModalId, setOpenDeleteModalId] = useState(null);

  const handleDeleteModalItem = (customerId , businessName) => {
    if (table.getSelectedRowModel().flatRows[0]?.original) {
      setOpenDeleteModalId(customerId);
    }
  };

  const businessId = localStorage.getItem("business_id")
  
  const [originalItemData, setOriginalItemData] = useState([]);
  

  const encodedId = btoa(`${businessId}`)

  const encodedURL = `${baseURL}Y3VzdG9tZXIvdmlldw==/${encodedId}`;  // VIEW CUSTOMER

  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .get(`${baseURL}Y3VzdG9tZXIvdmlldw==/${btoa(businessId)}`)
      .then((response) => {
        const sortedCustomerData = response.data.customers_data.sort(
          (a, b) => b.customer_id - a.customer_id
        );
        // console.log("sor" , sortedCustomerData);
        dispatch(deleteAllCustomers())
        dispatch(addCustomer(sortedCustomerData))
        // setCustomerData(sortedCustomerData);
        // setOriginalItemData(sortedCustomerData);

        const activeCount = sortedCustomerData.filter(
          (item) => item.customer_status === "1"
        ).length;
        const inactiveCount = sortedCustomerData.filter(
          (item) => item.customer_status === "0"
        ).length;
        setActiveCustomerCount(activeCount);
        setInactiveCustomerCount(inactiveCount);
        setLoading(false); 
      })
      .catch((error) => {
        console.error("Error fetching customer data:", error);
        setLoading(false); // Set loading to false in case of error
      });
  }, []);
  

 
  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const capitalizeFirstLetterWordWords = (str) => {
    return str
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };
  const lowercaseAllWords = (str) => {
    return str
      .toLowerCase()
  };

  const data = useMemo(() => {
    return customersAll.map((customer, index) => ({
      ...customer,
      business_name: capitalizeFirstLetterWordWords(customer.business_name),
      contact_name : capitalizeFirstLetterWordWords(customer.contact_name),
      contact_email : lowercaseAllWords(customer.contact_email)
    }));
  }, [customersAll]);

// console.log(data);

const isRowCheckboxDisabled = (row) => {
  if (rowSelection[row.id]) {
    return false;
  } else {
    return Object.values(rowSelection).some((selected) => selected);
  }
};
useEffect(() => {
  const selected= table.getSelectedRowModel().flatRows[0]?.original;
  if (selected) {
    // console.log("seelcted row", selected);
    setSelectedRows(selected);
  }
}, [rowSelection , selectedRows ]);
// console.log("selected row details" , selectedRows);
// console.log("edit active" , isEditActive);
// console.log("delete active" , isDeleteActive);

const columns = [
  {
    id: "checkbox",
    accessor: "",
    cell: ({ row }) => (
      <IndeterminateCheckbox
        {...{
          checked: row.getIsSelected(),
          disabled: isRowCheckboxDisabled(row),
          indeterminate: row.getIsSomeSelected(),
          onChange: row.getToggleSelectedHandler(),
          className: isRowCheckboxDisabled(row) ? "disabled-checkbox" : "",
        }}
      />
    ),
    enableColumnFilter: false, // Disable filter for checkbox column
    enableSorting: false, // Disable sorting for checkbox column
  },
  columnHelper.accessor("added_on", {
    header: <div className="text-center">Added on</div>,
    // cell: (props) => <div className="text-left">{props.row.original.added_on}</div>,
    cell: (props) => {
      const date = new Date(props.row.original.added_on);
      const options = { month: 'short', day: '2-digit', year: 'numeric' };
      const formattedDate = `${date.toLocaleDateString("en-US", { month: 'short' })}-${date.toLocaleDateString("en-US", { day: '2-digit' })}-${date.toLocaleDateString("en-US", { year: 'numeric' })}`;
      return (
        <div>{formattedDate}</div>
      );
    }
  }),
  columnHelper.accessor("business_name", {
         header: <div className="text-center">Customer Name</div>,
         cell: (props) => <div className="text-left">{props.row.original.business_name}</div>,
  }),
    columnHelper.accessor(
      (data) => {
        const {
          business_address,
          business_city,
          business_zip_code,
          business_state,
        } = data;
        return `${capitalizeFirstLetterWordWords(business_address)}, ${capitalizeFirstLetterWordWords(business_city)}, ${capitalizeFirstLetterWordWords(business_state)}, ${(business_zip_code)}`;
      },
      {
        id: 'business_address_full', 
        header: <div className="text-center">Customer Address</div>,
        cell: (props) => (
          <div className="text-left">
            {props.row.original.business_address}, {props.row.original.business_city}, {props.row.original.business_state}, {props.row.original.business_zip_code}
          </div>
        ),
      }
    ),
        columnHelper.accessor("contact_name", {
      header: <div className="text-center">Contact Name</div>,
      cell: (props) => <div className="text-left">{props.row.original.contact_name}</div>,
    }),
    columnHelper.accessor("contact_no", {
      header: <div className="text-center">Contact No</div>,
      cell: (props) => <div className="text-left">{props.row.original.contact_no}</div>,
    }),
    columnHelper.accessor("contact_email", {
      header: <div className="text-center">Contact Email</div>,
      cell: (props) => <div className="text-left">{capitalizeFirstLetter(props.row.original.contact_email)}</div>,
    }),
];

const handleSortingChange = (columnId) => {
  setSorting((old) => {
    if (old.length && old[0].id === columnId) {
      // Toggle sorting direction
      return [{
        id: columnId,
        desc: !old[0].desc,
      }];
    }
    // Default sorting to descending initially
    return [{ id: columnId, desc: true }];
  });
};

const table = useReactTable({
  data: customersAll || [],
  columns: columns,
  state: {
    rowSelection: rowSelection,
    globalFilter: filtering,
    sorting: sorting,
  },
  onSortingChange: setSorting,
  onGlobalFilterChange: setFiltering,
  getCoreRowModel: getCoreRowModel(),
  getSortedRowModel: getSortedRowModel(),
  getFilteredRowModel: getFilteredRowModel(),
  onRowSelectionChange: setRowSelection,
  onColumnFiltersChange: setColumnFilters,
  enableRowSelection: true,
  enableColumnFilter: true,
});
const currentPage = table.options.state.pagination.pageIndex;

  return (
    <div className=" flex justify-center items-center flex-col w-full pb-5">

      <ScrollingMessage/>

      <div className="flex justify-between items-center w-full px-8 mt-8 mb-8">
        <h1 className="font-bold text-lg text-gray-400">Customers Dashboard  -  {customersAll.length} Active customers</h1>
        <div>
          <button
             className="flex items-center justify-center font-bold mr-3 w-55 border-transparent border-2 text-white bg-[#25caed] hover:scale-105 transition-transform duration-300 ease-in-out focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-base px-4 py-1.5 focus:outline-none"
            type="button"
            onClick={() => navigate(`/customers/customerforms`)}
          >
             <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={3}
              stroke="currentColor"
              className="w-5 h-5 mr-3"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 6v12m6-6H6"
              />
            </svg>
            New Customer
          </button>
        </div>
      </div>
      {/* <CustomerCount /> */}
      {/* <CustomerTableMenu  onSearch={handleSearch}/> */}
      
      <div className="flex w-full justify-end items-center space-x-4 px-7 mt-10">
    <div className="relative w-44 focus-within:w-full transition-all duration-300 max-w-full ml-auto">
              <AiOutlineSearch
                size={18}
                className="absolute top-1/2 transform -translate-y-1/2 left-3 text-gray-700"
              />
              <input
                type="text"
                placeholder="Search..."
                className="pl-10 px-6 py-1 border-2 rounded-lg border-gray-300 outline-none block w-full focus:ring-3"
                value={filtering}
                onChange={e => setFiltering(e.target.value)}
              />
          </div>{" "}

           <div className="flex items-center mt-[4px] mr-12 space-x-4">
              {table.getSelectedRowModel().flatRows[0]?.original && (
                <Tooltip
                  title="Edit"
                  position="top"
                  trigger="mouseenter"
                >
                  <button
                    className="items-center text-sm font-medium text-center focus:outline-none text-gray-500 hover:text-blue-700 rounded-lg"
                    type="button"
                    onClick={() => {
                      const customerId = table.getSelectedRowModel().flatRows[0]?.original.customer_id;
                      navigate(`/customers/viewcustomer/${customerId}`);
                    }}
                  >
                    <div className="rounded-full">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="w-5 h-5"
                      >
                        <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32l8.4-8.4z" />
                        <path d="M5.25 5.25a3 3 0 00-3 3v10.5a3 3 0 003 3h10.5a3 3 0 003-3V13.5a.75.75 0 00-1.5 0v5.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5V8.25a1.5 1.5 0 011.5-1.5h5.25a.75.75 0 000-1.5H5.25z" />
                      </svg>
                    </div>
                  </button>
                </Tooltip>
              )}
              {table.getSelectedRowModel().flatRows[0]?.original && (
                <Tooltip
                  title="Delete"
                  position="top"
                  trigger="mouseenter"
                >
                  <button
                    className="items-center text-sm font-medium text-center focus:outline-none text-gray-500 hover:text-red-700 rounded-lg"
                    type="button"
                    // onClick={handleDeleteModalItem}
                    onClick={() => {
                      const customerId = table.getSelectedRowModel().flatRows[0]?.original.customer_id;
                      const businessName = table.getSelectedRowModel().flatRows[0]?.original.business_name
                      handleDeleteModalItem(customerId ,businessName )
                    }}
                  >
                    <div className="rounded-full">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="w-5 h-5"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                  </button>
                </Tooltip>
              )}
            </div>
      </div>

      {loading ? (
        <p>Loading customer data...</p>
      ) : (
        <>
        <ToastNotificationBox/>
        <div className=" w-full rounded-xl p-8 pl-8 pr-12 bg-white pt-0 mt-4">
          <div className="w-full">

                <table className="w-full table-auto bg-transparent rounded-xl mt-4">
                  <thead className="bg-gray-500/20 text-gray-500">
                    {table.getHeaderGroups().map((headerGroup) => (
                      <tr key={headerGroup.id} className="text-center text-xs">
                        {headerGroup.headers.map((header, index) => {
                          const isFirstHeader = index === 0;
                          const isLastHeader = index === headerGroup.headers.length - 1;
                          const headerClasses = [
                            'p-3 font-bold uppercase',
                            isFirstHeader ? 'rounded-l-xl' : '',
                            isLastHeader ? 'rounded-r-xl' : '',
                          ];

                          const isSorted = header.column.getIsSorted();
                          const showSortingIcons = header.column.columnDef.enableSorting !== false;
                          const ArrowUpIcon = (
                            <FaArrowUpLong
                              className={`${isSorted === 'asc' ? 'text-black' : 'text-gray-400'}`}
                              size={12}
                            />
                          );
                          const ArrowDownIcon = (
                            <FaArrowDownLong
                              className={`${isSorted === 'desc' ? 'text-black' : 'text-gray-400'}`}
                              size={12}
                            />
                          );

                          return (
                            <th
                              key={header.id}
                              className={headerClasses.join(' ')}
                              colSpan={header.colSpan}
                              onClick={() => showSortingIcons && handleSortingChange(header.column.id)}
                            >
                              <div className="flex items-center justify-center">
                                {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                                {showSortingIcons && (
                                  <div className="flex items-center ml-2">
                                    {ArrowDownIcon}
                                    {ArrowUpIcon}
                                  </div>
                                )}
                              </div>
                            </th>
                          );
                        })}
                      </tr>
                    ))}
                  </thead>
                  <tbody>
                    {table.getRowModel().rows.map((row) => (
                      <tr key={row.id} className="cursor-pointer text-center hover:bg-gray-500/10">
                        {row.getVisibleCells().map((cell) => (
                          <td key={cell.id} className="p-4 font-semibold text-[#081159] text-[14px]">
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>

                  <div className="flex items-center mr-7 mt-6 gap-2 justify-end">
                  <button
                    className={`px-4 py-2 rounded-l-lg focus:outline-none flex items-center ${
                      table.getCanPreviousPage()
                        ? " hover:bg-[#114FFF] hover:text-white cursor-pointer"
                        : " cursor-not-allowed"
                    }`}
                    onClick={() => table.previousPage()}
                    disabled={!table.getCanPreviousPage()}
                  >
                    <MdNavigateBefore className="w-6 h-6" />
                    Prev
                  </button>
                  {[...Array(table.getPageCount()).keys()].map((page) => (
                    <button
                      key={page}
                      onClick={() => table.setPageIndex(Number(page))}
                      className={`px-4 py-2  focus:outline-none ${
                        currentPage === page
                          ? " rounded-full bg-[#3479E0] text-white "
                          : " rounded-full bg-white dark:text-black hover:bg-[#3479E0]/50 hover:text-white"
                      }`}
                    >
                      {page + 1}
                    </button>
                  ))}
                  <button
                    className={`px-4 py-2 rounded-r-lg  focus:outline-none flex items-center justify-center${
                      table.getCanNextPage()
                        ? " hover:bg-[#114FFF] hover:text-white cursor-pointer"
                        : "bg-gray-300 cursor-not-allowed"
                    }`}
                    onClick={() => table.nextPage()}
                    disabled={!table.getCanNextPage()}
                  >
                    Next
                    <MdNavigateNext className="w-6 h-6" />
                  </button>
                </div>

          </div>
        </div>
        </>
      )}{" "}
      {openDeleteModalId && (
        <PopUpModalForDeleteCustomer
          onClose={() => setOpenDeleteModalId(null)}
          customerId={openDeleteModalId}
          // customerDelete={handleDeleteItems}
          businessName={table.getSelectedRowModel().flatRows[0]?.original.business_name}
          resetRowSelection={table.resetRowSelection}
        />
      )}
      <ScrollToTop />
    </div>
  );
}

export default Customer;
