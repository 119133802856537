import React from "react";

const InvoicePagination = ({ tableInstance, invoiceResponse }) => {
  // Check if tableInstance is available
  if (!tableInstance) return null;

  // Get the row count from the table instance
  const rowCount = tableInstance.getRowModel().rows.length;

  return (
    (invoiceResponse?.data?.invoice_data?.length) > 25 &&
    <div style={paginationContainerStyle}>
      <div style={selectContainerStyle}>
        <label style={labelStyle}>Rows per page:</label>
        <select
          value={tableInstance.options.state.pagination.pageSize}
          onChange={(e) => tableInstance.setPageSize(Number(e.target.value))}
          style={selectStyle}
        >
          {[25, 50, 100].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {pageSize}
            </option>
          ))}
        </select>
      </div>
      <div style={paginationControlsStyle}>
        {/* First Page Button */}
        <button
          onClick={() => tableInstance.setPageIndex(0)}
          disabled={!tableInstance.getCanPreviousPage()}
          style={buttonStyle(tableInstance.getCanPreviousPage())}
        >
          {"<<"}
        </button>
        <button
          onClick={() => tableInstance.previousPage()}
          disabled={!tableInstance.getCanPreviousPage()}
          style={{
            backgroundColor: '#FFFFFF',
            color: '#3479E0',
            border: 'none',
            borderRadius: '4px',
            padding: '8px 12px',
            cursor: tableInstance.getCanPreviousPage() ? 'pointer' : 'not-allowed',
            opacity: tableInstance.getCanPreviousPage() ? '1' : '0.6',
          }}
        >
          Previous
        </button>

        {/* Page Numbers */}
        <div style={pageNumbersStyle}>
          {(() => {
            const totalPages = tableInstance.getPageCount();
            const currentPage = tableInstance.options.state.pagination.pageIndex;

            // Calculate start and end index for page numbers to display
            let start = Math.max(0, currentPage - 1); // Center current page
            let end = Math.min(totalPages, start + 3); // Display three pages

            // Adjust for the beginning of pagination
            if (currentPage === 0) {
              end = Math.min(totalPages, 3); // Show first three pages
            } else if (currentPage === totalPages - 1) {
              start = Math.max(0, totalPages - 3); // Show last three pages
            }

            return Array.from({ length: end - start }, (_, i) => (
              <button
                key={start + i}
                onClick={() => tableInstance.setPageIndex(start + i)}
                style={pageButtonStyle(start + i, currentPage)}
              >
                {start + i + 1}
              </button>
            ));
          })()}
        </div>
        
        <button
          onClick={() => tableInstance.nextPage()}
          disabled={!tableInstance.getCanNextPage()}
          style={{
            backgroundColor: '#FFFFFF',
            color: '#3479E0',
            border: 'none',
            borderRadius: '4px',
            padding: '8px 12px',
            cursor: tableInstance.getCanNextPage() ? 'pointer' : 'not-allowed',
            opacity: tableInstance.getCanNextPage() ? '1' : '0.6',
          }}
        >
          Next
        </button>

        {/* Last Page Button */}
        <button
          onClick={() => tableInstance.setPageIndex(tableInstance.getPageCount() - 1)}
          disabled={!tableInstance.getCanNextPage()}
          style={buttonStyle(tableInstance.getCanNextPage())}
        >
          {">>"}
        </button>
      </div>
    </div>
  );
};

// Styles
const paginationContainerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '20px',
  backgroundColor: '#f9f9f9', // Light background
  borderRadius: '8px', // Rounded corners
  boxShadow: '0 2px 4px rgba(0,0,0,0.1)', // Subtle shadow
};

const selectContainerStyle = {
  display: 'flex',
  alignItems: 'center',
  gap: '10px', // Space between label and dropdown
};

const labelStyle = {
  fontSize: '14px',
  fontWeight: 'bold',
  color: '#333', // Dark text
};

const selectStyle = {
  padding: '5px 10px',
  borderRadius: '4px',
  width: '60px',
  border: '1px solid #ddd', // Light border
  cursor: 'pointer',
};

const paginationControlsStyle = {
  display: 'flex',
  alignItems: 'center',
  // gap: '8px', // Space between buttons
};

const buttonStyle = (isEnabled) => ({
  backgroundColor: '#FFFFFF',
  color: '#3479E0',
  border: 'none',
  borderRadius: '4px',
  padding: '8px 8px',
  cursor: isEnabled ? 'pointer' : 'not-allowed',
  opacity: isEnabled ? '1' : '0.6',
  transition: 'background-color 0.3s ease', // Transition for hover effect
  '&:hover': isEnabled ? { backgroundColor: '#245ea6' } : {},
});

// Page button styles based on active state
const pageButtonStyle = (index, currentIndex) => ({
  backgroundColor: index === currentIndex ? '#3479E0' : '#FFFFFF',
  color: index === currentIndex ? '#FFFFFF' : '#3479E0',
  border: '1px solid #3479E0',
  borderRadius: '50%',
  padding: '8px 16px',
  cursor: 'pointer',
  transition: 'background-color 0.3s ease', // Transition for hover effect
  '&:hover': {
    backgroundColor: index === currentIndex ? '#245ea6' : '#f0f0f0', // Change color on hover
  },
});

const pageNumbersStyle = {
  display: 'flex',
  gap: '8px',
};

export default InvoicePagination;
