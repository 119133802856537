// AddItemForm.j
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux"; // Import useNavigate
import { addProduct } from "../../actions/customerActions";
import axios from "axios";
import { useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { useTabs } from "../pages/TabContext";
import CustomTooltip from "./CustomeTooltip";
import { FaArrowRightLong } from "react-icons/fa6";
import config  from "../../config/config"
import ReactDOM from "react-dom"
import { showToastForDeleteItem, showToastForUpdateItem } from "../toasts/toastForItem";
import { deleteAllItem } from "../../store/itemSlice";

function ViewItem() {
  const dispatch = useDispatch();
  const { itemId } = useParams();
  const navigate = useNavigate(); 
  const [productOptions, setProductOptions] = useState([]);
  const [itemDetails, setItemDetails] = useState({});
  const [originalFormData, setOriginalFormData] = useState({});
  const [isFormModified, setIsFormModified] = useState(false);
  const [itemNameError, setItemNameError] = useState("")
  const [ desError,setDesError] = useState("")
  const [ invalidPrice, setInvalidPrice] = useState("")
  const [itemData , setItemData] = useState([])
  
  const { changeTab } = useTabs();

  const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;
 
  const handleCancel = () => {
 // Change tab to 'items'
    navigate(`/itemsdashboard`); // Navigate to the 'items' route
  };
  const CustomTooltip1 = ({ content, show, marginLeft }) => {
    return (
      <div
        style={{
          display: show ? 'block' : 'none',
          position: 'absolute',
          backgroundColor: '#ffffff',
          color: '#fff',
          padding: '5px',
          borderRadius: '4px',
          border:'1px solid red',
          marginTop: '-35px',
          marginLeft: `${marginLeft}px`,  // Use the provided marginLeft prop
          boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        }}
      >
        <div
          style={{
            position: 'absolute',
            bottom: '-7px',
            left: '50%',
            marginLeft: '-10px',
            width: '0',
            height: '0',
            borderLeft: '7px solid transparent',
            borderRight: '7px solid transparent',
            borderTop: '7px solid #f98080',
          }}
        ></div>
        <p className="text-xs text-red-500">{content}</p>
      </div>
    );
  };



  const [editMode, setEditMode] = useState(false);// Initialize the navigate function
  const [formData, setFormData] = useState({
    itemName: "",
    itemDes: "",
    oldUnitPrice : "",
    newUnitPrice : "",
    productId:"",
  });
useEffect(() => {
    // Fetch item details using itemId
    axios
      .get(`${baseURL}${btoa("items/edit")}/${btoa(itemId)}/${btoa(businessId)}`)
      .then((response) => {
    
        setItemData(response.data.items_data[0])

        const itemData = response.data.items_data[0];
        setItemDetails(itemData);
        setFormData({
          itemName: itemData.Item_name,
          itemDes: itemData.Item_des,
          productId: itemData.Product_id,
          oldUnitPrice : itemData.item_unit_price

        });
        setOriginalFormData({
          itemName: itemData.Item_name,
          itemDes: itemData.Item_des,
          productId: itemData.Product_id,
        });
      })
      .catch((error) => {
        console.error("Error fetching item data:", error);
      });
  }, [itemId]);

  const businessId = localStorage.getItem("business_id")
  useEffect(() => {
    axios
      .get(`${baseURL}/${btoa("product/view")}/${btoa(businessId)}`)
      .then((response) => {
        setProductOptions(response.data.products_data); // Set product options in state
        // console.log(setProductItemData , response.data.products_data);
      })
      .catch((error) => {
        console.error("Error fetching product options:", error);
      });

    // Fetch item details using itemId (your existing code)
    // ...
  }, [itemId]);
  const capitalizeWords = (input) => {
    return input
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]:(value),
    });
    setIsFormModified(true);
  };

  const handleItemNameChange = (e) => {
    const { name, value } = e.target;
  
    // Validate item name to contain only alphabets and spaces, with a minimum length of 3
    let inputValue = value.replace(/[^a-zA-Z\s]/g, ""); // Remove non-alphabetic characters except spaces
  
    setFormData({
      ...formData,
      [name]: capitalizeWords(inputValue),
    });
  
    // Set error message if item name is not empty and less than 3 characters
    if (inputValue.replace(/\s/g, '').length > 0 && inputValue.length < 3) {
      setItemNameError("Item Name must be at least 3 letters");
    } else {
      setItemNameError("");
    }
    setIsFormModified(true);
  };

  const handleOldPriceChange = (e)=>{
    const {name , value } = e.target
    if(!isNaN(value) || value === ""){
      setFormData({
        ...formData,
        [name] : value
      })
    }
  }
  const handleNewPriceChange = (e) => {
    const { name, value } = e.target;
    const containsNonZero = /[1-9]/.test(value);
  
    if (containsNonZero) {
      setInvalidPrice("valid");
    } else {
      setInvalidPrice("Invalid number");
    }
  
    if (value === "") {
      setInvalidPrice("");
    }
  
    setIsFormModified(true);
  
    if (!isNaN(value) || value === "") {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  
    // console.log("name:", name, "value:", value);
  };
  
  const handleUnitPriceBlur = (e) => {
    const { name, value } = e.target;
  
    if (value && !isNaN(value)) {
      const formattedValue = parseFloat(value).toFixed(5); // Format to 5 decimal places
      setFormData({
        ...formData,
        [name]: formattedValue
      });
    }
  };
  
  
  const handleDesChange = (e) => {
    const { name, value } = e.target;
    let inputValue = value.replace(/[^a-zA-Z0-9,\s]/g, ""); 
    setFormData({
      ...formData,
      [name]: capitalizeWords(inputValue),
    });
  
    // Set error message if item name is not empty and less than 3 characters
    if (inputValue.replace(/\s/g, '').length > 0 && inputValue.length < 3) {
      setDesError("Item Description must be at least 3 letters");
    } else {
      setDesError("");
    }
    setIsFormModified(true);
  };

  const handleEditClick = async (e) => {

    // if(formData.productId === ""){
    //   return
    // }
    if(formData.itemName.replace(/\s/g,"").length < 3){
      return;
    }

    if(formData.itemDes?.replace(/\s/g,"").length > 0 && formData.itemDes?.replace(/\s/g,"").length < 3){
      return;
    } 
    if(formData.newUnitPrice === 0  || formData.newUnitPrice === ""){
      return;
    } 
    
  
      const updatedData = {
        edit_Id: itemId,
        businessId : localStorage.getItem("business_id"),
        managerId : localStorage.getItem("user_id"),
        oldDate : itemData.added_on ,
        edit_itemName: formData.itemName,
        oldUnitPrice : formData.oldUnitPrice,
        newUnitPrice : formData.newUnitPrice,
        edit_itemDes: formData.itemDes,
        edit_productId: formData.productId
      };
      console.log("updated item data" , updatedData);
  
      try {
        const response = await axios.put(`${baseURL}${btoa("items/update")}`, updatedData);
  
        if (response.status === 200) {
          const responseData = await response.data;
          if (responseData.dataUpdatingStatus === true) {
            showToastForUpdateItem(`${formData.itemName} Item Data Updated Successfully`, "success");
            dispatch(deleteAllItem())
          } 
        } else {
          console.log("Request failed with status: " + response.status);
          response.data.then((data) => {
            console.log(data);
          });
          showToastForDeleteItem("Unable to update items, please try again", "error");
        }
      } catch (error) {
        console.error(error);
        console.error("updated item data" , updatedData);
        showToastForDeleteItem("Unable to update items, please try again", "error");
      }
      window.scrollTo(0, 0);
      navigate(`/itemsdashboard`);
    setEditMode(!editMode);
  };
  
  return (
    <div className="pt-16 h-screen">
                  <h1 className="mx-8 font-bold mb-24 text-gray-400 text-lg mt-[-18px]">Items {">>"} Edit Item</h1>

        <div className="w-auto  bg-white rounded-box  mx-8 rounded-xl">
              <div className="flex space-x-6">
                    <h2 class="mb-10 text-xl font-bold text-gray-900 underline underline-offset-8 decoration-gray-300">
                          Item Details 
                      </h2>
                <div className="flex text-xl"><FaArrowRightLong className="mt-1 mr-6" /> {formData.itemName}</div>
              </div>
          <div class="grid gap-4 sm:grid-cols-3 sm:gap-6">
            {/* <div className="flex sm:col-span-3 gap-3"> 
             <div class="w-full">
            <label
          htmlFor="productId"
          className="block mb-2 text-sm font-medium text-gray-900"
        >
          Product Name <span className="text-red-500 text-xl font-extrabold">*</span>
        </label>
        <select
          id="productId"
          name="productId"
          value={formData.productId}
          onChange={handleInputChange}
          required
          className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"        >
          <option value="">Select Product</option>
          {productOptions.map((product) => (
            <option key={product.product_id} value={product.product_id}>
              {product.product_name}
            </option>
          ))}
        </select>
            </div> 

              <div class="w-auto">
                <label
                  htmlFor="itemName"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Item Name <span className="text-red-500 text-xl font-extrabold">*</span>
                </label>
                <CustomTooltip1 content={itemNameError} show={!!itemNameError} marginLeft={308}/>

                <input
                  type="text"
                  id="itemName"
                  name="itemName"
                  readOnly
                  value={formData.itemName}
                  onChange={handleItemNameChange}
             
                  required
                  class={`bg-gray-300 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${
                    itemNameError ? 'border-red-500' : ''
                  }`} />
             </div>
            </div>  */}

              <div className="flex sm:col-span-3 gap-3">
                  <div class="w-full">
                    <label
                      htmlFor="oldPrice"
                      class="block mb-2 text-sm font-medium text-gray-900 "
                    >
                      Current Price <span className="text-red-500 text-xl font-extrabold">*</span>
                    </label>
                    {/* <CustomTooltip1 content={itemNameError} show={!!itemNameError} marginLeft={308}/> */}

                    <input
                      type="text"
                      id="oldUnitPrice"
                      name="oldUnitPrice"
                      value={formData.oldUnitPrice}
                      onChange={handleOldPriceChange}
                      readOnly
                      required
                      class={`bg-gray-300 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${
                        itemNameError ? 'border-red-500' : ''
                      }`} />
                  </div>

                  <div class="w-full">
                    <label
                      htmlFor="newUnitPrice"
                      class="block mb-2 text-sm font-medium text-gray-900 "
                    >
                      New Price <span className="text-red-500 text-xl font-extrabold">*</span>
                    </label>
                     <CustomTooltip1 content={desError} show={!!desError} marginLeft={807}/>
                     <input
                        type="number"
                        id="newUnitPrice"
                        name="newUnitPrice"
                        value={formData.newUnitPrice}
                        onChange={handleNewPriceChange}
                        onBlur={handleUnitPriceBlur}  // Use the same blur handler
                        required
                        className={`bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${
                          itemNameError ? 'border-red-500' : ''
                        }`}
                      />

                     
                  </div>
              </div>
            
            <div class="sm:col-span-3">
              <label
                htmlFor="itemDes"
                class="block mb-2 text-sm font-medium text-gray-900"
              >
                Item Description
              </label>
              <CustomTooltip1 content={desError} show={!!desError} marginLeft={807}/>

              <input
                type="text"
                id="itemDes"
                name="itemDes"
                value={formData.itemDes}
                onChange={handleDesChange}
                maxLength={100}
                  
                required
                class={`bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${
                  desError ? 'border-red-500' : ''
                }`}               />
         
            </div>
             </div>
          <div className="flex justify-end space-x-3">
          {isFormModified && (
              <>
                <button
                  onClick={handleCancel}
                  type="submit"
                  class="hover:bg-red-500 border-[#3479E0] border-2 text-black px-6 py-1.5 mt-4 sm:mt-6 text-sm font-semibold hover:border-transparent hover:shadow-lg hover:shadow-red-500/60 text-center hover:text-white  rounded-lg focus:ring-4 focus:ring-primary-200  hover:bg-primary-800"
                >
                  Cancel
                </button>
                <button
                  disabled={!formData.newUnitPrice?.trim()|| parseFloat(formData.newUnitPrice) <= 0}
                  onClick={handleEditClick}
                  type="submit"
                  class="hover:bg-[#3479E0] border-[#3479E0] border-2 text-black px-6 py-1.5 mt-4 sm:mt-6 text-sm font-semibold hover:border-transparent hover:shadow-lg hover:shadow-[#3479E0]/50 text-center hover:text-white  rounded-lg focus:ring-4 focus:ring-primary-200  hover:bg-primary-800"
                  
                >
                  Done
                </button>
              </>
            )}
        
      
          </div>
        </div>
   
    </div>
  );
}

export default ViewItem;
