import React from "react";

function CustomerCount() {
  const cardData = [
    { name: "Customers as of Last Month", number: 3 },
    { name: "New Customers this Month", number: 6 },
  ];
  return (
    <div className=" p-2 rounded-lg w-auto mt-5 mb-10 border border-gray-300">
    <div className="flex justify-center items-center gap-2">
      {cardData.map((card, index) => (
        <div
          key={index}
          className={`px-8 py-1 rounded-lg ${
            card.name === "Customers as of Last Month"
              ? "bg-gray-100 "
              : card.name === "New Customers this Month"
              ? "bg-blue-100"
              : "bg-red-100 "
          }`}
        >
          <div className="text-center flex items-center space-y-2 px-8 py-2 flex-col">
            <div
              className="text-sm font-bold text-black"
             
            >
              {card.name}
            </div>{" "}
            {/* Card Name */}
            <div
              className={`rounded-full w-10 h-10  flex justify-center items-center text-sm ${
                card.name === "Customers as of Last Month"
                  ? "bg-gray-500 text-white"
                  : card.name === "New Customers this Month"
                  ? "bg-blue-500 text-white"
                  : "bg-red-500 text-white"
              }`}
            >
              {card.number}
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
  );
}

export default CustomerCount;
