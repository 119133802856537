import { createSlice } from "@reduxjs/toolkit";
import ApprovedContracts from "./ApprovedContracts";

const initialState = {
  allApprovedContracts: [],
};

const ApprovedContractsSlice = createSlice({
  name: "ApprovedContracts",
  initialState,
  reducers: {
    addApprovedContracts(state, action) {
      console.log("act", action.payload);
      if (Array.isArray(action.payload)) {
        const newApprovedContracts= action.payload.filter(newApprovedContract =>
          !state.allApprovedContracts.some(existingApprovedContracts => existingApprovedContracts.id === newApprovedContract.id)
        );
        state.allApprovedContracts.push(...newApprovedContracts);
      } else {
        if (!state.allApprovedContracts.some(existingApprovedContracts => existingApprovedContracts.id === action.payload.id)) {
          state.allApprovedContracts.push(action.payload);
        }
      }
    },
    deleteApprovedContracts(state, action) {
      state.allApprovedContracts = state.allApprovedContracts.filter(
        ApprovedContract => ApprovedContract.id !== action.payload
      );
    },
    deleteAllApprovedContracts(state) {
        state.allApprovedContracts = []; 
      }
  },
});

export const { addApprovedContracts, deleteApprovedContracts , deleteAllApprovedContracts } = ApprovedContractsSlice.actions;
export default ApprovedContractsSlice.reducer;
