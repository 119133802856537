
import React, { useState, useRef , useEffect} from "react";
import { MenuItem, FormControl, Select, InputLabel, Box } from "@mui/material";
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import { Grid, IconButton } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import PrintIcon from '@mui/icons-material/Print';
import DownloadIcon from '@mui/icons-material/Download';

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DescriptionIcon from '@mui/icons-material/Description';
import axios from "axios";
import config from "../../config/config";
import { Tooltip } from "react-tippy";
import { Card, CardContent, Typography, Avatar, Paper } from '@mui/material';
import Loader from "./Loader";
import FullScreenLoader from './FullScreenLoader'
import { FullScreenMode } from "@react-pdf-viewer/core";



function SalesTax() {
  const [periodType, setPeriodType] = useState(""); // Tracks the selected period (Year, Month, Quarterly)
  const [tempPeriodType, settempPeriodType] = useState(""); // Tracks the selected month

  const [selectedYear, setSelectedYear] = useState(""); // Tracks the selected year
  const [tempYear, setTempYear] = useState(""); // Tracks the selected month

  const [selectedMonth, setSelectedMonth] = useState(""); // Tracks the selected month
  const [tempMonth, setTempMonth] = useState(""); // Tracks the selected month

  const [selectedQuarter, setSelectedQuarter] = useState(""); // Tracks the selected quarter
  const [tempQuarter, setTempQuarter] = useState(""); // Tracks the selected month

  const [dateTime, setDateTime] = useState('');
  const [salesTaxResponse, setSalestaxReport] = useState([]);
  const [imageSource, setImageSource] = useState();
  const [imageResponse, setimageResponse] = useState([]);
  const [loading, setLoading] = useState(false); // Add a loading state
  const [hovered, setHovered] = useState(false);
  const [taxhovered, setTaxHovered] = useState(false);

  const [excelhovered, setExcelHovered] = useState(false);




  const [RunClicked, setRunClicked] = useState(false); // Tracks the selected quarter
  const [selectedTax, setSelectedTax] = useState('')
  const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;
  const user_id = localStorage.getItem("user_id");
  const user_role = localStorage.getItem("user_role");
  const business_id = localStorage.getItem("business_id");
  const contentRef = useRef();
  const [userRole, setUserRole] = useState("");
  const businessId = localStorage.getItem('business_id')
  const managerId =localStorage.getItem('user_id')

  const from =  salesTaxResponse.data?.from;
  const to =  salesTaxResponse.data?.to;
  const formatToTwoDecimalsWithCommas = (num) => {
    const parts = num.toString().split('.');
    const integerPartWithCommas = parseInt(parts[0], 10).toLocaleString('en-US'); // Adds commas to the integer part
    const decimalPart = parts[1] ? parts[1].substring(0, 2) : ''; // Limits to two decimal places without rounding
  
    return decimalPart ? `${integerPartWithCommas}.${decimalPart}` : integerPartWithCommas;
  };

  useEffect(() => {
    setUserRole(localStorage.getItem("user_role"));
  }, []);
  //useeffect for business logo
  useEffect(() => {
    axios
      .get(`${baseURL}/${btoa("business/profile")}/${btoa(business_id)}/${btoa(user_id)}/${btoa(user_role)}`)
      .then((response) => {
        console.log(response);
        // console.log("response businesstype", response);
        setImageSource(response.data.business_data[0].image_file)
        setimageResponse(response.data.business_data[0])
      })
      .catch((error) => {
        console.error("Error fetching business data:", error);

      });
      console.log(imageResponse);

  }, []);

  // Years and Months for dropdowns
  const years = ["2024", "2023", "2022", "2021"];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const quarters = [{bName: "Q1",
    fName:"Q1 (Mar-May)"
  },{bName: "Q2",
    fName:"Q2 (June-Aug)"},{bName: "Q3",
        fName: "Q3 (Sep-Nov)"},{bName: "Q4",
            fName: "Q4 (Dec-Feb)"}]

  // Handles period type change (Year, Month, Quarterly)
  const handlePeriodChange = (event) => {
    setPeriodType(event.target.value);
    // setRunClicked(false)
    // Reset other fields when changing the main period type
    // setSelectedYear("");
    // setSelectedMonth("");
    // setSelectedQuarter("");

  };
  const isButtonEnabled = () => {
    if (periodType === 'Yearly' && selectedYear && selectedTax) return true;
    if (periodType === 'Monthly' && selectedYear && selectedMonth && selectedTax) return true;
    if (periodType === 'Quarterly' && selectedYear && selectedQuarter && selectedTax) return true;
    return false;
  };
  // const isButtonEnabled = () => {
  //   if (periodType === 'Yearly' && selectedYear ) return true;
  //   if (periodType === 'Monthly' && selectedYear && selectedMonth ) return true;
  //   if (periodType === 'Quarterly' && selectedYear && selectedQuarter ) return true;
  //   return false;
  // };

  const handlePrint = () => {
    const content = contentRef.current.innerHTML; // Get the HTML content
    const newWindow = window.open('', '_blank'); // Open a new window
    newWindow.document.write(`
      <html>
        <head>
          <title>Print</title>
          <style>
            /* Optional: Add styles for printed content */
                      @media print {
  img {
    display: block;
    width: 100px; /* Adjust width as needed */
    height: auto;
  }
              @page {
                margin: 0; /* Remove default margins */
              }
              body {
                margin: 0;
                padding: 0;
              }
              /* Hide headers and footers */
              header, footer {
                display: none; /* Hides header and footer */
              }
              /* You can also add styles to control the printed content */

            body {
              font-family: Arial, sans-serif;
              margin: 60px;
            }
          </style>
        </head>
        <body>
          ${content} 
        </body>
      </html>
    `);
    newWindow.document.close(); // Close the document to apply styles
    newWindow.print(); // Trigger the print dialog
  };
  function formatNumberwithoutzeros(num) {
    return new Intl.NumberFormat('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(num);
  }
  function formatNumber(num) {
    return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(num);
  }
  const generateUSDateTime = () => {
    setRunClicked(true);
    const currentDate = new Date();
    
    // Format date and time in US format
    const formattedDateTime = currentDate.toLocaleString('en-US', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric', // Optional: remove if seconds aren't needed
      hour12: true, // 12-hour format with AM/PM
      timeZone: 'America/New_York' // You can change the timezone if necessary
    });

    setDateTime(formattedDateTime);
  };
  const handleRun = async () => { 
    setLoading(true); // Set loading to true when fetching data
    setTempYear(selectedYear);
    setTempMonth(selectedMonth);
    setTempQuarter(selectedQuarter);
    settempPeriodType(periodType);
    
    // const startTime = Date.now(); // Record the start time for the 3-second minimum loader delay

    try {
        const response = await axios.post(`${baseURL}/${btoa("reports/salestaxreport")}`, {
            reportType: periodType,
            monthName: selectedMonth,
            year: selectedYear,
            quarterName: selectedQuarter,
            businessId: localStorage.getItem('business_id'),
            managerId: localStorage.getItem('user_id'),
            templateSchedule: selectedTax
        });
        console.log('response', response);
        setSalestaxReport(response);
    } catch (error) {
        console.log("error posting details", error);
    } finally {
      setLoading(false);

        // const endTime = Date.now();
        // const duration = endTime - startTime;
        // const remainingTime = 3000 - duration; // Calculate remaining time for 3 seconds

        // // Ensure loader stays for at least 3 seconds
        // if (remainingTime > 0) {
        //     setTimeout(() => setLoading(false), remainingTime);
        // } else {
        //     setLoading(false);
        // }
    }
};

  // const handleRun = async  () => { 
  //   setLoading(true); // Set loading to true when fetching data
  //   setTempYear(selectedYear)
  //   setTempMonth(selectedMonth)
  //   setTempQuarter(selectedQuarter)
  //   settempPeriodType(periodType)
  //   // setUserEmail(email)
  //   try{
  //       const response = await axios.post(`${baseURL}/${btoa("reports/salestaxreport")}` , {
  //           reportType:periodType,
  //           monthName:selectedMonth,
  //           year:selectedYear,
  //           quarterName:selectedQuarter,
  //           businessId:localStorage.getItem('business_id'),
  //           managerId:localStorage.getItem('user_id'),
  //           templateSchedule: selectedTax
  //       })
  //       console.log('response', response)
  //       setSalestaxReport(response)

  //   }
 
  //       catch(error){
  //           console.log("error posting details" , error);
  //         }
  //         finally {
  //           setLoading(false);
  //         }
  // };
  // const handleflatfile = async  () => { 

  //       // Send a GET request to the backend to download the .txt file
  //       try {
  //           const response = await axios.get(`${baseURL}/${btoa("reports/downloadflatfile")}/${btoa(businessId)}/${btoa(from)}/${btoa(to)}/${btoa(managerId)}`, {
  //             responseType: 'blob',
  //           });
  //           console.log('flatfile reaponse.........', response)
  //       // Create a blob from the response data
  //       const blob = new Blob([response.data], { type: 'text/plain' });
  
  //       // Create a temporary download link
  //       const link = document.createElement('a');
  //       link.href = window.URL.createObjectURL(blob);
  //       link.download = 'Flat file.txt'; // The file name to download
  //       link.click(); // Programmatically click the link to trigger the download
  
  //     } catch (error) {
  //       console.error('Error downloading the file:', error);
  //     }
     
  // };
  
  const handleflatfile = async () => {
    try {
        // Show loader if applicable
        setLoading(true);

        // Wait for 3 seconds before starting the download
        // await new Promise((resolve) => setTimeout(resolve, 3000));

        // Send GET request to download the .txt file
        const response = await axios.get(
            `${baseURL}/${btoa("reports/downloadflatfile")}/${btoa(businessId)}/${btoa(from)}/${btoa(to)}/${btoa(managerId)}`, 
            { responseType: 'blob' }
        );

        console.log('Flat file response:', response);

        // Create a blob from the response data
        const blob = new Blob([response.data], { type: 'text/plain' });

        // Create a temporary download link
        const link = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        link.href = url;
        link.download = 'Flat file.txt'; // The file name to download
        document.body.appendChild(link);
        link.click();

        // Cleanup: Remove link and revoke object URL
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    } catch (error) {
        console.error('Error downloading the file:', error);
    } finally {
        // Hide loader if applicable
        setLoading(false);
    }
};

  
  const reportName = 
  salesTaxResponse.data?.monthName || 
  salesTaxResponse.data?.quarterName || salesTaxResponse.data?.year ; 
  const handleExcelfile = async () => {
    // try {
    //   // Determine the report name based on available data
    //   const reportName =
    //     salesTaxResponse.data?.monthName ||
    //     salesTaxResponse.data?.quarterName ||
    //     salesTaxResponse.data?.year ||
    //     'Default Report Name'; // Fallback value if none exist
  
    //   const requestUrl = `${baseURL}/${btoa("reports/salestaxexcelreport")}`;
  
    //   // Send a POST request to the backend to download the Excel file
    //   const response = await axios.post(requestUrl, {
    //     businessId,
    //     managerId,
    //     from,
    //     to,
    //     reportType: salesTaxResponse.data?.reportType,
    //     reportName,
    //     year: salesTaxResponse.data?.year
    //   }, {
    //     responseType: 'blob' // Set response type to blob to handle file download
    //   });
  
    //   // Check if the response is of type Blob and expected content
    //   const contentType = response.headers['content-type'];
    //   if (contentType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
    //     // Create a blob from the response data
    //     const blob = new Blob([response.data], { type: contentType });
  
    //     // Create a temporary download link
    //     const link = document.createElement('a');
    //     link.href = window.URL.createObjectURL(blob);
    //     link.download = 'sales_tax_report.xlsx'; // The file name to download
    //     document.body.appendChild(link); // Append the link to the body
    //     link.click(); // Programmatically click the link to trigger the download
    //     document.body.removeChild(link); // Clean up by removing the link
    //   } else {
    //     // If the response is not an Excel file, log or display the HTML response
    //     // const errorBlob = new Blob([response.data], { type: 'text/html' });
    //     // const errorText = await errorBlob.text();
    //     // console.error('Error response text:', errorText);
    //   }
    // } catch (error) {
    //   console.error('Error downloading the file:', error);
    // }
    const requestUrl = `${baseURL}/${btoa("reports/salestaxexcelreport")}/${btoa(businessId)}/${btoa(managerId)}/${btoa(from)}/${btoa(to)}/${btoa(periodType)}/${btoa(reportName)}/${btoa(selectedYear)}`;
setLoading(true)
try {
  const response = await axios.get(requestUrl, {
    responseType: 'blob' // Expect a binary file
  });

  // Check the content type to handle the response accordingly
  const contentType = response.headers['content-type'];
  
  if (contentType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
    // Handle file download
    const blob = new Blob([response.data], { type: contentType });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'sales_tax_report.xlsx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else if (contentType === 'application/json') {
    // Read the JSON error message
    const reader = new FileReader();
    reader.onload = () => {
      console.error('Error response from server:', reader.result);
      alert('Error: ' + reader.result); // Show the error message
    };
    reader.readAsText(response.data);
  }
} catch (error) {
  console.error('Error during request:', error);
} finally{
  setLoading(false)

}

  };
 
  const totalGallons =() => {
    return (
     salesTaxResponse.data.taxdata.length >0 ? salesTaxResponse.data.taxdata.reduce((sum, item) => {
       return sum + parseInt(item.gallons_withtax_passed_thru, 10);
   }, 0) : ''
    )
  }

  return (
    <div>
    {loading && <FullScreenLoader/> }
    <>
    {/* <div className="flex justify-between items-center w-full px-8 mt-5 pt-3" style={{marginLeft:'30px',marginBottom:'30px'}}>
        <h1 className="font-bold text-xl text-gray-400">SalesTax Report</h1>
        </div> */}
        <div 
  className="flex justify-between items-center w-full  pt-4 mt-4 mb-5" 
  style={{ 
    maxWidth: '1200px', 
    boxSizing: 'border-box', 
    marginLeft: '42px', 
    fontFamily: 'Poppins, sans-serif', // Use Poppins font
    color: '#444444' // Lighter black color
  }}
>
  {/* <h1 className="font-bold text-xl">SalesTax Report</h1> */}
  <nav aria-label="breadcrumb">
    <ol className="flex space-x-2 text-lg font-bold text-gray-700">
      <li>
        {/* <a  href="/operations" className="text-blue-600 hover:text-blue-800"> */}
          Accounting
        {/* </a> */}
      </li>
      <li>
        <span className="text-gray-400">{'>>'}</span>
      </li>
      <li>
        {/* <a href="/purchases" className="text-blue-600 hover:text-blue-800"> */}
          Sales Tax
        {/* </a> */}
      </li>
    </ol>
  </nav>
</div>
    {/* <Box
      sx={{
        display: "flex",
        gap: "60px",
        justifyContent: "start",
        alignItems: "center",
        padding: "20px",
        marginLeft:"43px",
        marginRight:'45px',
        marginTop:'10px',
        // marginBottom:'40px',
        // backgroundColor: "#f5f5f5",
        borderRadius: "20px",
        boxShadow: '0 0 20px 0 rgba(0, 0, 0, .2)',
        height:'auto',
        paddingBottom:'40px'

      }}
    > */}
                <Grid container spacing={4} className="pl-[40px] pr-6 " >
                <Grid item  md={2.5}>

      {/* Period Type Dropdown */}
      <div>
          <select
            id="Select Report Type"
            name="Select Report Type"
            required
            onChange={handlePeriodChange}
            value={periodType}
            label="Select Report Type"
            className=" border border-gray-300 text-sm font-medium rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full text-gray-500 p-2.5"
            >
            <option value="">Select Report Type</option>
            <option value="Monthly">Monthly</option>
            <option value="Quarterly">Quarterly</option>
            <option value="Yearly">Yearly</option>

             
          </select>
        </div>
        </Grid>

      {/* Month Dropdown - Only visible for Month selection */}
      {periodType === "Monthly" && (
                <Grid item  md={2.5}>

        <div>
     
        <select
          id="Select Month"
          name="Select Month"
          required
          value={selectedMonth}
          onChange={(e) => {setSelectedMonth(e.target.value);}}

          // label="Select Month"
          className=" border border-gray-300 text-sm font-medium rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full text-gray-500 p-2.5"
          >
          <option value="">Select Month</option>
          {months.map((month) => (
              <option key={month} value={month} sx={{ margin: 0, padding: '1px 16px' }}>
                {month}
              </option>
            ))}         
        </select>
      </div>
      </Grid>           

      )}

      {/* Quarterly Dropdown - Only visible for Quarterly selection */}
      {periodType === "Quarterly" && (
         <Grid item  md={2.5}>

            <div>
       
            <select
              id="Select Quarter"
              name="Select Quarter"
              required
              value={selectedQuarter}
              onChange={(e) => {setSelectedQuarter(e.target.value);}}  
              label="Select Month"
              className=" border border-gray-300 text-sm font-medium rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full text-gray-500 p-2.5"
              >
              <option value="">Select Quarter</option>
              {quarters.map((quarter) => (
              <option key={quarter.fName} value={quarter.bName} sx={{ margin: 0, padding: '1px 16px' }}>
                {quarter.fName}
              </option>
            ))}      
            </select>
          </div>  
          </Grid>     
      )}
          {/* Year Dropdown - Visible for all selections */}

          {periodType && (
                      <Grid item  md={2.5}>

               <div>
               <select
                 id="Select Year"
                 name="Select Year"
                 required
                 value={selectedYear}
                 onChange={(e) => {setSelectedYear(e.target.value);} }            
                 label="Select Year"
                 className=" border border-gray-300 text-sm font-medium rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full text-gray-500 p-2.5"
                 >
                 <option value="">Select Year</option>
                 {years.map((year) => (
              <option key={year} value={year} sx={{ margin: 0, padding: '1px 16px' }}>
                {year}
              </option>
            ))}        
               </select>
             </div>
             </Grid>

      )}
         <Grid item  md={2}>

<div>

<select
  id="PBT Sales Tax"
  name="PBT Sales Tax"
  required
  value={selectedTax}
  onChange={(e) => {setSelectedTax(e.target.value);} }            
  label="PBT Select Tax"
  className=" border border-gray-300 text-sm font-medium rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full text-gray-500 p-2.5"
>
  <option value="">PBT Sales Tax</option>

<option  value="PT-101.11" sx={{ margin: 0, padding: '1px 16px' }}>
 PT-101.1
</option>
<option  value="PT-101.12" sx={{ margin: 0, padding: '1px 16px' }}>
 PT-101.2
</option><option  value="PT-101.13" sx={{ margin: 0, padding: '1px 16px' }}>
 PT-101.3
</option><option  value="PT-101.14" sx={{ margin: 0, padding: '1px 16px' }}>
 PT-101.4
</option><option  value="PT-101.15" sx={{ margin: 0, padding: '1px 16px' }}>
 PT-101.5
</option>
       
</select>
</div>
</Grid>
      <Grid item  md={2.5}>

      <Button
  variant="contained"
  sx={{
    backgroundColor: 'rgb(231 70 148)',  // Pink color
    color: 'white',
    //  marginTop: '36px',
     height:'43px',
    '&:hover': {
      backgroundColor: 'rgb(231 70 148)',  // Darker shade of pink on hover
    }
  }}
  endIcon={<SendIcon />}
  disabled={!isButtonEnabled()}
  onClick={() => { generateUSDateTime(); handleRun(); }}
>
  Run
</Button>

</Grid>
</Grid>
    {/* </Box> */}
    {
      RunClicked ?
  <div id="runclicked">
   { RunClicked &&
        
    <div style={{  borderRadius: "20px",
      maxWidth: '100%',
      overflowX: 'auto',
      margin: '0 auto', 
      flexDirection: 'column',
                    boxShadow: '0 0 25px 0 rgba(0, 0, 0, 0.2)',
                   margin:'55px',
                   marginTop:'45px',
                   paddingBottom:'80px',
                   margin:'45px',
                   padding:'20px',
                   justifyContent:'center',
                   display:'flex',
                   flexDirection:'column'
          }}>
    {salesTaxResponse && salesTaxResponse.data && salesTaxResponse.data.taxdata.length > 0 ?
    <Grid container alignItems="end" justifyContent="end" >
      {/* PDF Download Icon */}
     <Grid item xs={12} md={4}>
        <Box p={2} className="font-bold text-md text-gray-400">
        {
            RunClicked && <p>Report RunTime: {dateTime} </p>

        }
        </Box>
    
      
     </Grid>
     <Grid item xs={12} md={8}>
     <Grid container alignItems="end" justifyContent="end" spacing={0} >

      <Grid item>
      <Tooltip title="Pdf" position="top" trigger="mouseenter">

        <IconButton aria-label="Download PDF"  
             sx={{
              width: '48px',
              height: '48px',
                backgroundColor: 'transparent', // No background color initially
                borderRadius: '50%', // Circular shape
                padding: '12px', // Adjust padding for the circular button
                '&:hover': {
                  backgroundColor: 'rgba(227, 140, 151, 0.5)', // Light background color on hover
                },
                transition: 'background-color 0.3s ease', // Smooth transition effect
              }}
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
 >
          {hovered ? <FileDownloadIcon sx={{ fontSize: 25, color: 'red' }} />:<PictureAsPdfIcon fontSize="small" color="error" /> }

          
        </IconButton>
        </Tooltip>
      </Grid>
      <Grid item>
      <Tooltip title="Print" position="top" trigger="mouseenter">
        <IconButton aria-label="Print" onClick={handlePrint}
                sx={{
                  width: '48px',
                  height: '48px',
                    backgroundColor: 'transparent', // No background color initially
                    borderRadius: '50%', // Circular shape
                    padding: '12px', // Adjust padding for the circular button
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.1)', // Light background color on hover
                    },
                    transition: 'background-color 0.3s ease', // Smooth transition effect
                  }}
        
        >
          <PrintIcon fontSize="small" sx={{color:'black'}}/>
        </IconButton>
        </Tooltip>
      </Grid>

      {/* Tax File Icon */}
      <Grid item>
      <Tooltip title="Excel" position="top" trigger="mouseenter">

        <IconButton aria-label="Tax File"
            sx={{
              width: '48px',
              height: '48px',
                backgroundColor: 'transparent', // No background color initially
                borderRadius: '50%', // Circular shape
                padding: '12px', // Adjust padding for the circular button
                '&:hover': {
                  backgroundColor: 'rgba(143, 191, 96, 0.3)', // Light background color on hover
                },
                transition: 'background-color 0.3s ease', // Smooth transition effect
              }}
              onMouseEnter={() => setExcelHovered(true)}
              onMouseLeave={() => setExcelHovered(false)}
              onClick={()=>handleExcelfile()}
        >
            {
            excelhovered ? <FileDownloadIcon sx={{ fontSize: 25, color: 'green' }} /> :  <DescriptionIcon fontSize="small" style={{ color: '#4CAF50' }} />

          }
        
        </IconButton>
        </Tooltip>

      </Grid>
      <Grid item>
      <Tooltip title="Tax" position="top" trigger="mouseenter">

        <IconButton aria-label=".txt file"
          sx={{
            width: '48px',
            height: '48px',
            backgroundColor: 'transparent', // No background color initially
            borderRadius: '50%', // Circular shape
            padding: '12px', // Adjust padding for the circular button
            '&:hover': {
              backgroundColor: 'rgba(102, 102, 255, 0.3)', // Light background color on hover
            },
            transition: 'background-color 0.3s ease', // Smooth transition effect
          }}
          onMouseEnter={() => setTaxHovered(true)}
          onMouseLeave={() => setTaxHovered(false)}
          onClick={()=>handleflatfile()}
        
        >
          {
            taxhovered ? <FileDownloadIcon sx={{ fontSize: 25, color: 'blue' }} /> :<DescriptionIcon fontSize="small" color="primary" />

          }
        </IconButton>
        </Tooltip>
      </Grid>
      </Grid>
      </Grid>
    </Grid>
    :''}
    {/* <div className="flex justify-center"> */}

    <div ref={contentRef} style={{width:'100%',
         height:'auto',
         justifyContent:'center',
          alignSelf:"center",
          borderRadius: "10px",
          boxShadow: '0 0 20px 0 rgba(0, 0, 0, .1)',
          padding:'20px',
          marginTop:'20px'
          }}>

<div style={{  display: 'flex',
    alignItems: 'center',
    height:'110px',
    justifyContent: 'space-between',
    marginBottom:'20px',
    backgroundColor: 'rgba(180,180,180,0.1)'}}>
      {/* Left Image */}
      {/* <img
        src="https://via.placeholder.com/60"
        alt="Left Logo"
        style={{ width: '60px', // Set the size of the images
            height: '60px'}}
      /> */}
      {/* <img src="/static/media/logo.0ea4b695a3030ea659a8.png" alt="flownia" class="w-max" ></img> */}
              {userRole !== "1" && (
          <div className="">
            {imageSource ? (
              <img
                style={{ width: '140px',
                    height: '60px'}}
                src={`${baseURL}uploads/img/business/${imageSource}`} alt="Selected" />
            ) : (
              <p>No image selected</p>
            )}
          </div>
        )}

      {/* Middle: Three Texts Stacked */}
      <div style={{textAlign: 'center',marginLeft:'90px'}}>
        <p>    <strong style={{ fontWeight: '600', fontSize: '18px', margin: '5px' }}>
        {imageResponse.business_name}</strong></p>
        <p>    <strong style={{ fontWeight: '600', fontSize: '18px', margin: '5px' }}>
        {imageResponse.business_address}</strong></p>
        <p>    <strong style={{ fontWeight: '600', fontSize: '18px', margin: '5px' }}>
        {imageResponse.business_city}, 
            {imageResponse.business_state} , {imageResponse.business_zip_code}
            </strong></p>
      </div>

      {/* Right Image */}
      <img src="/static/media/logo.0ea4b695a3030ea659a8.png" alt="flownia" class="w-max" style={{ width: '240px', // Set the size of the images
            height: '200px'}}></img>
    </div>
    {RunClicked &&
    (
    <div style={{ color: '#1976d2', textAlign:'center', fontSize:'24px', fontWeight:'bold', marginTop: '50px'}} className="mb-1" >
             {(tempPeriodType === 'Yearly' && tempYear && RunClicked)  &&  `${tempYear} - SalesTax`}
     {(tempPeriodType === 'Monthly' && tempYear && tempMonth && RunClicked) &&`${tempMonth} ${tempYear} - SalesTax`}
         {(tempPeriodType === 'Quarterly' && tempYear && tempQuarter && RunClicked) && `${tempQuarter == 'Q1' ? 'Q1 (Mar-May)' :''} ${tempQuarter == 'Q2' ? 'Q2 (June-Aug)':'' } ${tempQuarter == 'Q3' ? 'Q3 (Sep-Nov)' :'' } ${tempQuarter == 'Q4' ? 'Q4 (Dec-Feb)' :'' } - ${selectedYear} SalesTax`}
         <hr className="border-t border-gray-300 mb-1 mt-2" />
        </div>
   )}

{RunClicked && salesTaxResponse && salesTaxResponse.data && salesTaxResponse.data.sales_data.length > 0 ?

(<div style={{ marginTop: '14px' }}>
  <table style={{ width: '45%', maxWidth: '600px', margin: '30px auto', fontWeight: 'bold', borderCollapse: 'collapse' }}>
    {/* Header Row */}
    <thead style={{ backgroundColor: 'transparent' }}>
      <tr style={{ textAlign: 'center', marginBottom: '10px',  color: '#3479E0' }}>
        <th style={{ padding: '10px',textAlign: 'left', width:'120px', fontSize:'19px' }}>Fuel Grade</th>
        <th style={{ padding: '10px', textAlign: 'right', width:'210px', fontSize:'19px' }}>Gallons</th>
        <th style={{ padding: '10px', textAlign: 'right', fontSize:'19px',fontSize:'19px'}}>Amount</th>
      </tr>
    </thead>

    {/* Data Rows */}
    <tbody>
      {salesTaxResponse.data?.sales_data?.length > 0 &&
        salesTaxResponse.data.sales_data.map((item) => (
          <tr key={item.product_id || item.product_name} style={{ textAlign: 'center', marginBottom: '10px' }}>
            <td style={{ padding: '10px', fontWeight: 'normal', textAlign: 'left',fontSize:'15px' }}>{item.product_name || ''}</td>
            <td style={{ padding: '10px', fontWeight: 'normal', textAlign: 'right' ,fontSize:'16px'}}>{`${formatNumberwithoutzeros(item.total_gallons)} gl`}</td>
            <td style={{ padding: '10px', fontWeight: 'normal', textAlign: 'right' ,fontSize:'16px'}}>$ {formatNumber(item.total_amount) || 0}</td>
          </tr>
        ))}
    </tbody>

    {/* Total Summary Row */}
    <tfoot>
      <tr style={{ textAlign: 'center', padding: '10px', margin: '20px auto', borderRadius: '5px',
         borderTop:'1px solid grey', borderBottom:'1px solid grey'  , backgroundColor:'#F5F5F5'}}>
        <td style={{ padding: '10px', fontWeight: 'normal', textAlign: 'left',fontSize:'16px' }}>Total:</td>
        <td style={{ padding: '10px', fontWeight: 'normal' , textAlign: 'right',fontSize:'16px'}}>{`${formatNumberwithoutzeros(salesTaxResponse.data?.totalGallons)} gl`}</td>
        <td style={{ padding: '10px', fontWeight: 'normal', textAlign: 'right',fontSize:'16px' }}>$ {formatNumber(salesTaxResponse.data?.totalAmount) || 0}</td>
      </tr>
    </tfoot>
  </table>
</div>):(
<p style={{ color: 'red', fontSize: '18px', fontWeight: 'bold', textAlign: 'center', paddingTop:'20px' }}>
No Data Available
</p>
)}
    </div>
   
    
    </div>
}
    <hr/>
    </div>
    :     <div style={{height: '600px'}}></div>

        }

    </>
    </div>
  );
}

export default SalesTax;
