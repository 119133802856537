import React from 'react';
import { TimePicker } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

// Extend dayjs with customParseFormat plugin
dayjs.extend(customParseFormat);

const EditStartTimePickerViews = ({ startTime, onStartTimeChange }) => {
  const handleChange = (time, timeString) => {
    onStartTimeChange(timeString);
  };

  return (
    <div>
      <label htmlFor="startTime" className="block text-gray-700 font-semibold mb-2">
        Start Time
      </label>
      <TimePicker
        id="startTime"
        value={startTime ? dayjs(`2024-01-01 ${startTime}`, 'YYYY-MM-DD hh:mm A') : null}
        onChange={handleChange}
        onSelect={handleChange}
        format="hh:mm A"
        use12Hours
        style={{ height: "40px", boxShadow: "0 0 7px rgba(0, 0, 0, 0.2)" }}
        className="w-full px-3 py-2 border-none rounded-md shadow-lg custom-time-picker"
        dropdownClassName="custom-time-picker-dropdown"
        defaultOpenValue={dayjs('00:00:00', 'HH:mm:ss')}
      />
    </div>
  );
};

export default EditStartTimePickerViews;
