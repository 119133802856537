import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../../config/config';
import { useNavigate, useParams } from 'react-router-dom';
import { showToastForDeleteVendor, showToastForUpdateVendor } from '../toasts/toastForVendor';
import { useDispatch } from 'react-redux';
import { deleteAllVendors } from '../../store/vendorSlice';
const ViewVendor = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {vendorid} = useParams()
  const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;

  const [formData, setFormData] = useState({
    vendorName: '',
    einNumber: '',
    contactName: '',
    contactNumber: '',
    contactEmail: '',
    businessId: localStorage.getItem("business_id"),
    managerId: localStorage.getItem("user_id"),
    edit_Id : '',
  });
  
 const [vendorNameError , setVendorNameError] = useState("")
   const [einNumberError , setEinNumberError] = useState("")
   const [contactNameError , setContactNameError] = useState("")
   const [contactNumberError , setContactNumberError] = useState("")
   const [contactEmailError , setContactEmailError] = useState("")

   const noError =
        !vendorNameError &&
        !einNumberError &&
        !contactNameError &&
        !contactNumberError &&
        !contactEmailError;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(()=>{
    async function fetchData(){
        try {
            const response = await axios.get(`${baseURL}${btoa("vendor/viewvendorbyid")}/${btoa(vendorid)}`);
            console.log("view", response);
            const data = response.data.vendor_data[0]
            setFormData({
                ...formData,
                vendorName: data.truck_company_name,
                einNumber: data.ein_number,
                contactName: data.contact_name,
                contactNumber: data.contact_no,
                contactEmail: data.contact_email,
                edit_Id : data.id
            })
        } catch (error) {
            console.log("error fetching details" , error);
        }
    }
    fetchData()
  },[])

  const areAllFieldsFilled = () => {
    return Object.entries(formData)
      .filter(([key]) => !['businessId', 'managerId'].includes(key))
      .every(([key, value]) => value?.trim() !== '');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(`${baseURL}/${btoa("vendor/update")}`, formData);
      // console.log("res", response);
      dispatch(deleteAllVendors())
      showToastForUpdateVendor(`${formData.vendorName} details updated successfully`, "success" , "")
      navigate('/vendors');
    } catch (error) {
      console.log("error updating vendor", error);
      showToastForDeleteVendor('Error updating vendor details' , "error" , "")
    }
  };

  const CustomTooltip1 = ({ content, show, marginLeft }) => {
    return (
      <div
        style={{
          display: show ? 'block' : 'none',
          position: 'absolute',
          backgroundColor: '#ffffff',
          color: '#fff',
          padding: '5px',
          borderRadius: '4px',
          border:'1px solid red',
          marginTop: '-35px',
          marginLeft: `${marginLeft}px`,  // Use the provided marginLeft prop
          boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        }}
      >
        <div
          style={{
            position: 'absolute',
            bottom: '-7px',
            left: '50%',
            marginLeft: '-10px',
            width: '0',
            height: '0',
            borderLeft: '7px solid transparent',
            borderRight: '7px solid transparent',
            borderTop: '7px solid #f98080',
          }}
        ></div>
        <p className="text-xs text-red-500">{content}</p>
      </div>
    );
  };
  
  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    cleaned = cleaned.slice(0, 10);
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = (match[1] ? '+1 ' : '');
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return cleaned; // Return cleaned input if no match
  }

  return (
    <div className="pt-16 h-screen">
      <h1 className="mx-8 font-bold mb-24 text-gray-400 text-lg mt-[-20px]">
        vendors {">>"} Edit vendor
      </h1>

      <form onSubmit={handleSubmit}>
        <div className="w-auto bg-white rounded-box mx-8 rounded-xl" style={{ marginTop: "20px" }}>
          <h2 className="mb-10 text-xl font-bold text-gray-900 underline underline-offset-8 decoration-gray-300">
            Edit Vendor Info
          </h2>
          <div className="grid gap-4 sm:grid-cols-3 sm:gap-6">
            <div className="w-full">
              <label
                htmlFor="vendorName"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Vendor Name{" "}
                <span className="text-red-500 text-xl font-extrabold">*</span>
              </label>
              {vendorNameError && (
                <CustomTooltip1
                    content={vendorNameError}
                    show={vendorNameError}
                    marginLeft={120}
                />
                )}
              <input
                type="text"
                id="vendorName"
                name="vendorName"
                value={formData.vendorName}
                // onChange={handleChange}
                onChange={(e) => {
                    if(e.target.value.length < 3){
                        setVendorNameError("Name should be at least 3 char's")
                    }else{
                        setVendorNameError(null)
                    }
                    const inputValue = e.target.value;
                    const sanitizedValue = inputValue.replace(/[^A-Za-z0-9\s]/g, "");
                    const capitalizedValue = sanitizedValue.charAt(0).toUpperCase() + sanitizedValue.slice(1);
                      // .toLowerCase()
                      // .replace(/(?:^|\s)\S/g, (char) => char.toUpperCase());
                    handleChange({
                      target: {
                        name: "vendorName",
                        value: capitalizedValue,
                      },
                    });
                  }}
                required
                className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                placeholder="Enter Vendor Name"
              />
            </div>

            <div className="w-full">
              <label
                htmlFor="einNumber"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                EIN Number{" "}
                <span className="text-red-500 text-xl font-extrabold">*</span>
              </label>
              <input
  type="text"
  id="einNumber"
  name="einNumber"
  value={formData.einNumber}
  onChange={(e) => {
    let inputValue = e.target.value;

    // Remove any non-numeric characters
    inputValue = inputValue.replace(/[^0-9]/g, "");

    // Limit input to 9 digits
    if (inputValue.length > 9) {
      inputValue = inputValue.slice(0, 9);
    }

    // Only insert dash after the 9th digit
    if (inputValue.length === 9) {
      inputValue = inputValue.slice(0, 2) + "-" + inputValue.slice(2);
    }

    // Update the form state with the input value
    handleChange({
      target: {
        name: "einNumber",
        value: inputValue,
      },
    });
  }}
  required
  className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
  placeholder="Enter EIN Number"
  maxLength={10}
/>




            </div>

            <div className="w-full">
              <label
                htmlFor="contactName"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Contact Name{" "}
                <span className="text-red-500 text-xl font-extrabold">*</span>
              </label>
              {contactNameError && (
                <CustomTooltip1
                    content={contactNameError}
                    show={contactNameError}
                    marginLeft={120}
                />
                )}
              <input
                type="text"
                id="contactName"
                name="contactName"
                value={formData.contactName}
                onChange={(e) => {
                    if(e.target.value.length < 3){
                        setContactNameError("Name should be at least 3 char's")
                    }else{
                        setContactNameError(null)
                    }
                    const inputValue = e.target.value;
                    const sanitizedValue = inputValue.replace(/[^A-Za-z\s]/g, "");
                    const capitalizedValue = sanitizedValue.charAt(0).toUpperCase() + sanitizedValue.slice(1);
                      // .toLowerCase()
                      // .replace(/(?:^|\s)\S/g, (char) => char.toUpperCase());
                    handleChange({
                      target: {
                        name: "contactName",
                        value: capitalizedValue,
                      },
                    });
                  }}
                required
                className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                placeholder="Enter Contact Name"
              />
            </div>

            <div className="w-full">
              <label
                htmlFor="contactNumber"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Contact Number{" "}
                <span className="text-red-500 text-xl font-extrabold">*</span>
              </label>
              {contactNumberError && (
                <CustomTooltip1
                    content={contactNumberError}
                    show={contactNumberError}
                    marginLeft={120}
                />
                )}
              <input
                type="text"
                id="contactNumber"
                name="contactNumber"
                value={formData.contactNumber}
                onChange={(e) => {
                  const inputPhoneNumber = e.target.value.replace(/[^0-9]/g, '');
                  const formattedValue = formatPhoneNumber(inputPhoneNumber);
                  const containsNonZero = /[1-9]/.test(formattedValue);
                  if (containsNonZero) {
                    setContactNumberError("");
                  } else {
                    setContactNumberError("Invalid number");
                  }
                  if (formattedValue === "") {
                    setContactNumberError("");
                  }
                  handleChange({
                    target: {
                      name: "contactNumber",
                      value: formattedValue,
                    }
                  });
                }}
                required
                className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                placeholder="Enter Contact Number"
              />
            </div>

            <div className="w-full">
              <label
                htmlFor="contactEmail"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Contact Email{" "}
                <span className="text-red-500 text-xl font-extrabold">*</span>
              </label>
              {contactEmailError && (
                <CustomTooltip1
                    content={contactEmailError}
                    show={contactEmailError}
                    marginLeft={120}
                />
                )}
              <input
                type="text"
                id="contactEmail"
                name="contactEmail"
                value={formData.contactEmail}
                onChange={(e)=>{
                    let value = e.target.value;
                    if (value.length > 1) {
                      value = value[0] + value.slice(1).toLowerCase();
                    }
                    if(value?.length > 0 && !value.includes(".") && !value.includes("@")){
                        setContactEmailError("Invalid email")
                    }else{
                        setContactEmailError(null)
                    }
                    handleChange({
                        target : {
                            name : "contactEmail",
                            value : value
                        }
                    }) 
                }}
                required
                className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                placeholder="Enter Contact Email"
              />
            </div>
          </div>
          {areAllFieldsFilled() && noError && (
            <div className="flex justify-end space-x-3">
              <button
                type="button"
                onClick={() => navigate('/vendors')}
                className="hover:bg-red-500 border-[#3479E0] border-2 text-black px-6 py-1.5 mt-4 sm:mt-6 text-sm font-semibold hover:border-transparent hover:shadow-lg hover:shadow-red-500/60 text-center hover:text-white rounded-lg focus:ring-4 focus:ring-primary-200 hover:bg-primary-800"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="hover:bg-[#0044AB] border-[#3479E0] border-2 text-black px-6 py-1.5 mt-4 sm:mt-6 text-sm font-semibold hover:border-transparent hover:shadow-lg hover:shadow-[#0044AB]/50 text-center hover:text-white rounded-lg focus:ring-4 focus:ring-primary-200 hover:bg-primary-800"
              >
                Done
              </button>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default ViewVendor;
