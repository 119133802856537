import React, { useState } from 'react';
import { TimePicker } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

// Extend dayjs with customParseFormat plugin
dayjs.extend(customParseFormat);

const StartTimePickerViews = ({ onStartTimeChange }) => {
  const [startTime, setStartTime] = useState(null);

  const handleChange = (time) => {
    const formattedTime = time ? time.format('hh:mm A') : '';
    setStartTime(time);
    onStartTimeChange(formattedTime);
  };

  return (
    <div>
      <label htmlFor="startDate" className="block text-gray-700 font-semibold mb-2">
        Start time
      </label>
      <TimePicker
        value={startTime}
        onChange={handleChange}
        onSelect={handleChange}
        defaultOpenValue={dayjs('00:00:00', 'HH:mm:ss')}
        format="hh:mm A"
        use12Hours
        style={{ height: "40px", boxShadow: "0 0 7px rgba(0, 0, 0, 0.2)" }}
        className="custom-time-picker"
        dropdownClassName="custom-time-picker-dropdown"
      />
    </div>
  );
};

export default StartTimePickerViews;
