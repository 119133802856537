import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allVendors: [],
};

const vendorSlice = createSlice({
  name: "vendor",
  initialState,
  reducers: {
    addVendor(state, action) {
    //   console.log("act", action.payload);
      if (Array.isArray(action.payload)) {
        const newVendors = action.payload.filter(newVendor =>
          !state.allVendors.some(existingVendors => existingVendors.id === newVendor.id)
        );
        state.allVendors.push(...newVendors);
      } else {
        // Add single customer only if it doesn't already exist
        if (!state.allVendors.some(existingVendor => existingVendor.id === action.payload.id)) {
          state.allVendors.push(action.payload);
        }
      }
    },
    deleteVendor(state, action) {
      state.allVendors = state.allVendors.filter(
        vendor => vendor.id !== action.payload
      );
    },
    deleteAllVendors(state) {
        state.allVendors = []; // Clear all invoices in the first array
      }
  },
});

export const { addVendor, deleteVendor , deleteAllVendors } = vendorSlice.actions;
export default vendorSlice.reducer;
