import React, { createContext, useContext, useState } from 'react';

const TabsContext = createContext();

export const TabsProvider = ({ children }) => {
  const [selectedTab, setSelectedTab] = useState('customers');

  const changeTab = (tab) => {
    setSelectedTab(tab);
  };

  return (
    <TabsContext.Provider value={{ selectedTab, changeTab }}>
      {children}
    </TabsContext.Provider>
  );
};

export const useTabs = () => {
  const context = useContext(TabsContext);
  if (!context) {
    throw new Error('useTabs must be used within a TabsProvider');
  }
  return context;
};
