import { Button, Modal } from "flowbite-react";
import { useState } from "react";
// Adjust the import path according to your project structure

export default function PopUpModal({ onClose, itemId, itemDelete, itemName }) {
  const [openModal, setOpenModal] = useState(true);

  const handleDelete = () => {
    // Call the itemDelete function with the itemId to perform the deletion
    itemDelete(itemId);
    // Close the modal
    onClose();
  };

  return (
    <Modal show={openModal} size="md" popup onClose={onClose}>
      <Modal.Header />
      <Modal.Body>
        <div className="text-center">
          <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400 mt-3">
            Are you sure you want to delete
            <span className="block uppercase p-2 font-semibold text-red-700 underline underline-offset-8">" {itemName} "</span>
          </h3>
          <div className="flex justify-center gap-4">
            <Button color="failure" onClick={handleDelete}>
              Yes, I'm sure
            </Button>
            <Button color="gray" onClick={onClose}>
              No, cancel
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
