import React, { useState } from 'react'
import NotificationBell from './Notification'
import MonthYearPicker from './DatePicker'
import { useEffect } from 'react';
import CustomCalendar from './DatePicker';

function Inventory() {
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
  };
  return (
    <div>
    <h1 className='flex justify-center place-items-center h-screen text-3xl font-bold text-purple-600'>Work in Progress..</h1>
</div>
  )
}

export default Inventory