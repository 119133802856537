import React, { useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import { FaTimes , FaDownload} from 'react-icons/fa';
import config from "../../../config/config";

Modal.setAppElement('#root');

const PurchaseImagesViewModel = ({ isModalOpen, closeModal, selectedImagePath }) => {
  const [zoomScale, setZoomScale] = useState(1);
  const imageRef = useRef(null);

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = 'hidden';
      window.addEventListener('wheel', handleScrollZoom);
    } else {
      document.body.style.overflow = 'unset';
      window.removeEventListener('wheel', handleScrollZoom);
    }

    return () => {
      document.body.style.overflow = 'unset';
      window.removeEventListener('wheel', handleScrollZoom);
    };
  }, [isModalOpen]);

  const handleScrollZoom = (event) => {
    if (imageRef.current) {
      event.preventDefault();
      const scaleAmount = 0.1;
      const newZoomScale = zoomScale + (event.deltaY < 0 ? scaleAmount : -scaleAmount);
      setZoomScale(Math.max(1, newZoomScale)); // Prevent zooming out below 1x
    }
  };

  if (!isModalOpen) return null;

  const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;

  return (
    <div style={overlayStyles}>
      <button onClick={closeModal} style={closeButtonStyles}>
        <FaTimes />
      </button>
      <div style={imageContainerStyles}>
        {selectedImagePath ? (
          <img
            src={`${baseURL}uploads/docs/draft_agreements/${selectedImagePath}`}
            alt="Selected"
            style={{ ...imageStyles, transform: `scale(${zoomScale})` }}
            ref={imageRef}
          />
        ) : (
          <p style={{ fontWeight: 'bold', color: 'red', backgroundColor: '#ffe5e5', padding: '10px', borderRadius: '5px' }}>
          No Image for this purchase
        </p>
        )}
      </div>
    </div>
  );
};

const overlayStyles = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 9999,
};

const closeButtonStyles = {
  position: 'absolute',
  top: '10px',
  right: '10px',
  background: 'none',
  border: 'none',
  fontSize: '24px',
  cursor: 'pointer',
  color: 'white',
  zIndex: 10000,
};

const imageContainerStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  width: '100%',
  overflow: 'hidden', // Hide any overflow
};

const imageStyles = {
  maxHeight: '100%', // Ensure the image fits within the container
  maxWidth: '100%', // Ensure the image fits within the container
  objectFit: 'contain', // Maintain the aspect ratio of the image
  transition: 'transform 0.2s ease-in-out', // Smooth zoom transition
};

export default PurchaseImagesViewModel;
