import axios from "axios";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { toast } from "react-toastify";
import config  from "../../src/config/config"
import ReactDOM from 'react-dom';
import { showToastForAddInvoice, showToastForDeleteInvoice } from "../components/toasts/toastForInvoice";
import { addToast } from '../components/pages/ToastBox/ToastBoxMessageSlice';



// Action types
export const ADD_INVOICE_REQUEST = "ADD_INVOICE_REQUEST";
export const ADD_INVOICE_SUCCESS = "ADD_INVOICE_SUCCESS";
export const ADD_INVOICE_FAILURE = "ADD_INVOICE_FAILURE";
export const DELETE_INVOICE_REQUEST = "DELETE_INVOICE_REQUEST";
export const DELETE_INVOICE_SUCCESS = "DELETE_INVOICE_SUCCESS";
export const DELETE_INVOICE_FAILURE = "DELETE_INVOICE_FAILURE";
export const ADD_PRODUCT_REQUEST = "ADD_PRODUCT_REQUEST";
export const ADD_PRODUCT_SUCCESS = "ADD_PRODUCT_SUCCESS";
export const ADD_PRODUCT_FAILURE = "ADD_PRODUCT_FAILURE";
export const ADD_ITEM_REQUEST = "ADD_ITEM_REQUEST";
export const ADD_ITEM_SUCCESS = "ADD_ITEM_SUCCESS";
export const ADD_ITEM_FAILURE = "ADD_ITEM_FAILURE";
export const DELETE_PRODUCT_REQUEST = "DELETE_PRODUCT_REQUEST";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_FAILURE = "DELETE_PRODUCT_FAILURE";
export const DELETE_ITEM_REQUEST = "DELETE_ITEM_REQUEST";
export const DELETE_ITEM_SUCCESS = "DELETE_ITEM_SUCCESS";
export const DELETE_ITEM_FAILURE = "DELETE_ITEM_FAILURE";

export const FETCH_LATEST_INVOICE_NUMBER_REQUEST =
  "FETCH_LATEST_INVOICE_NUMBER_REQUEST";
export const FETCH_LATEST_INVOICE_NUMBER_SUCCESS =
  "FETCH_LATEST_INVOICE_NUMBER_SUCCESS";
export const FETCH_LATEST_INVOICE_NUMBER_FAILURE =
  "FETCH_LATEST_INVOICE_NUMBER_FAILURE";

// Action creators
export const fetchLatestInvoiceNumberRequest = () => {
  return {
    type: FETCH_LATEST_INVOICE_NUMBER_REQUEST,
  };
};

export const fetchLatestInvoiceNumberSuccess = (latestInvoiceNumber) => {
  return {
    type: FETCH_LATEST_INVOICE_NUMBER_SUCCESS,
    payload: latestInvoiceNumber,
  };
};

export const fetchLatestInvoiceNumberFailure = (error) => {
  return {
    type: FETCH_LATEST_INVOICE_NUMBER_FAILURE,
    payload: error,
  };
};

export const deleteItemRequest = () => {
  return {
    type: DELETE_ITEM_REQUEST,
  };
};

export const deleteItemSuccess = (itemId) => {
  return {
    type: DELETE_ITEM_SUCCESS,
    payload: itemId,
  };
};

export const deleteItemFailure = (error) => {
  return {
    type: DELETE_ITEM_FAILURE,
    payload: error,
  };
};

export const deleteProductRequest = () => {
  return {
    type: DELETE_PRODUCT_REQUEST,
  };
};

export const deleteProductSuccess = (productId) => {
  return {
    type: DELETE_PRODUCT_SUCCESS,
    payload: productId,
  };
};

export const deleteProductFailure = (error) => {
  return {
    type: DELETE_PRODUCT_FAILURE,
    payload: error,
  };
};
export const addInvoiceRequest = () => {
  return {
    type: ADD_INVOICE_REQUEST,
  };
};

export const addInvoiceSuccess = (invoice) => {
  return {
    type: ADD_PRODUCT_SUCCESS,
    payload: invoice,
  };
};

export const addInvoiceFailure = (error) => {
  return {
    type: ADD_INVOICE_FAILURE,
    payload: error,
  };
};
export const addItemRequest = () => {
  return {
    type: ADD_ITEM_REQUEST,
  };
};

export const addItemSuccess = (item) => {
  return {
    type: ADD_ITEM_SUCCESS,
    payload: item,
  };
};

export const addItemFailure = (error) => {
  return {
    type: ADD_ITEM_FAILURE,
    payload: error,
  };
};
export const addCustomerRequest = () => {
  return {
    type: ADD_INVOICE_REQUEST,
  };
};

export const addCustomerSuccess = (customer) => {
  return {
    type: ADD_INVOICE_SUCCESS,
    payload: customer,
  };
};

export const addCustomerFailure = (error) => {
  return {
    type: ADD_INVOICE_FAILURE,
    payload: error,
  };
};

export const deleteCustomerRequest = () => {
  return {
    type: DELETE_INVOICE_REQUEST,
  };
};

export const deleteCustomerSuccess = (customerId) => {
  return {
    type: DELETE_INVOICE_SUCCESS,
    payload: customerId,
  };
};

export const deleteCustomerFailure = (error) => {
  return {
    type: DELETE_INVOICE_FAILURE,
    payload: error,
  };
};


export const addInvoice = (invoiceData) => {
  
  const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;
  console.log("form data new " , Object.entries(invoiceData));

  return (dispatch) => {
    dispatch(addInvoiceRequest());
    axios
      .post(`${baseURL}${btoa("invoice/create")}`, invoiceData)
      .then((response) => {
        console.log('RESPONCE FROM CREATE', response);
        console.log('SUBMITTING INVOICE', invoiceData);
        const invoice = response.data;
        // dispatch(addInvoiceSuccess(invoice));
        const successMessage = `Invoice for ${invoice.customerName} created successfully`;
        // showToastForAddInvoice(successMessage, "success", invoice.customerName);
        dispatch(addToast({ type: 'success', message: successMessage }));

       
      })
      .catch((error) => {
        dispatch(addInvoiceFailure(error.message));
        console.log("customer " , error);
        const errorMessage = "Unable to add new invoice, please try again";
        // showToastForDeleteInvoice(errorMessage, "error");
        dispatch(addToast({ type: 'danger', message: errorMessage }));        

      });
  };
};


// Thunk action to add an item
export const addItem = (itemData) => {
  return (dispatch) => {
    dispatch(addItemRequest());

    const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;
    // Adjust the API endpoint according to your backend API
    axios
      .post(
          `${baseURL}/setup/Item_ctrl/create_items`,
        itemData
      )
      .then((response) => {
        // Check the structure of the response data from the server
        const item = response.data; // Assuming the response contains the item object
        dispatch(addItemSuccess(item));
      })
      .catch((error) => {
        dispatch(addItemFailure(error.message));
      });
  };
};

// Thunk action to delete a customer
export const deleteCustomer = (customerId, businessName) => {
  return (dispatch) => {
    dispatch(deleteCustomerRequest());

    const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;
    const apiUrl = `${baseURL}/customer/delete/${customerId}`;

    axios
      .delete(apiUrl)
      .then(() => {
        dispatch(deleteCustomerSuccess(customerId));
      })
      .catch((error) => {
        dispatch(deleteCustomerFailure(error.message));
      });
  };
};

export const deleteProduct = (productId, productName) => {
  return (dispatch) => {
    dispatch(deleteProductRequest());

    const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;
    const apiUrl = `${baseURL}/product/delete/${productId}`;

    axios
      .delete(apiUrl)
      .then(() => {
        dispatch(deleteProductSuccess(productId));
      })
      .catch((error) => {
        dispatch(deleteProductFailure(error.message));
      });
  };
};

export const deleteItem = (itemId, itemName) => {
  return (dispatch) => {
    dispatch(deleteItemRequest());

    const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;
    const apiUrl = `${baseURL}/items/delete/${itemId}`;

    axios
      .delete(apiUrl)
      .then(() => {
        dispatch(deleteItemSuccess(itemId));
      }) 
      .catch((error) => {
        dispatch(deleteItemFailure(error.message));
      });
  };
};

const businessId = localStorage.getItem("business_id")

// export const fetchLatestInvoiceNumber = () => {
//   return (dispatch) => {
//     // You can dispatch an action to indicate the start of the request if needed
//     // dispatch(fetchLatestInvoiceNumberRequest());
//     const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;
//     axios
//       .get(`${baseURL}/${btoa("invoice/latest_invoice_number")}/${btoa(businessId)}`)
//       .then((response) => {
//         // Check the structure of the response data from the server
//         const latestInvoiceNumber = response.data;
//         console.log("latestInvoiceNumber", latestInvoiceNumber);
//         // Dispatch the action with the latest invoice number
//         dispatch(fetchLatestInvoiceNumberSuccess(latestInvoiceNumber));
//       })
//       .catch((error) => {
//         // Dispatch an action to handle the failure if needed
//         // dispatch(fetchLatestInvoiceNumberFailure(error.message));
//         console.error("Error fetching latest invoice number:", error);
//       });
//   };
// };
