// import React, { useState ,useEffect} from "react";
// import { NavLink } from "react-router-dom";
// import logo from "../../assets/images/logo.png";
// import logo2 from "../../assets/images/logo2.png";
// import axios from "axios";
// import { AiOutlineHome, AiFillHome, AiFillPieChart, AiOutlinePieChart, AiFillAccountBook, AiOutlineAccountBook } from "react-icons/ai";
// import { FaBalanceScale } from "react-icons/fa";
// import { useLocation } from "react-router-dom";
// import { FaRegMessage , FaMessage } from "react-icons/fa6";
// import { routes } from "../../components/constants/routes.constants";
// import { menuList } from "../../components/constants/menu.constants";
// import { HiOutlineUserGroup, HiUserGroup } from "react-icons/hi2";
// import { BsFileEarmarkRichtext, BsFileEarmarkRichtextFill } from "react-icons/bs";
// import { IoStatsChart } from "react-icons/io5";
// import { IoStatsChartOutline } from "react-icons/io5";
// import { MdOutlineBusinessCenter } from "react-icons/md";
// import { MdBusinessCenter } from "react-icons/md";
// import Shop2OutlinedIcon from '@mui/icons-material/Shop2Outlined';
// import Shop2Icon from '@mui/icons-material/Shop2';
// import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
// import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
// import { MdOutlinePayments } from "react-icons/md";
// import { MdPayments } from "react-icons/md";
// import { RiLuggageDepositFill } from "react-icons/ri";
// import { RiLuggageDepositLine } from "react-icons/ri";
// import DescriptionIcon from '@mui/icons-material/Description';
// import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
// import config  from "../../config/config"
// function SideBar({ isOpen }) {
//   const location = useLocation();
//   const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;
//   const user_id = localStorage.getItem("user_id")
//   const user_role = localStorage.getItem("user_role")
//   const business_id = localStorage.getItem("business_id")
//   const managerId = localStorage.getItem("user_id")
//   const [imageSource , setImageSource] = useState()

//   const [hoveredMenu, setHoveredMenu] = useState(null);

//   const handleMouseEnter = (index) => {
//     setHoveredMenu(index);
//   };

//   const handleMouseLeave = () => {
//     setHoveredMenu(null);
//   };

//   const isActive = (path) => {
//     if (path === routes.HOME) {
//       return location.pathname === path;
//     } else if(path.includes('invoice')){
//       return location.pathname.includes('invoice');
//     }else {
//       return location.pathname.startsWith(path);
//     }
//   }

//   const [userRole , setUserRole] = useState("")

//   useEffect(()=>{
//     setUserRole(localStorage.getItem("user_role"));
//   },[])

//   const menusForAdmin = [
//     { name: menuList.HOME,
//        path: routes.HOME,
//        icon: hoveredMenu === 0 || isActive(routes.HOME) 
//        ? <AiFillHome size={20} /> 
//        : <AiOutlineHome size={20} />
//     },

//      { name: menuList.BUSINESS,
//        path: routes.ADMIN_BUSINESS_DASHBOARD,
//         icon: hoveredMenu === 2|| isActive(routes.ADMIN_BUSINESS_DASHBOARD) 
//         ? <MdBusinessCenter size={20} /> 
//         : <MdOutlineBusinessCenter size={20} />
//      },
//      { name: menuList.CUSTOMERS,
//         path: routes.USERS_DASHBOARD ,
//         icon: hoveredMenu === 2|| isActive(routes.CUSTOMERS) 
//          ? <HiUserGroup size={20} /> 
//          : <HiOutlineUserGroup size={20} />
//        } ,
       
//       { name: menuList.NOTIFICATIONS,
//         path: routes.NOTIFICATIONS, 
//         icon: hoveredMenu === 3|| isActive(routes.NOTIFICATIONS)
//           ? <FaMessage    size={20} /> 
//           : <FaRegMessage  size={20} />
//       },
  
//       { name: menuList.REPORTS,
//         path: routes.REPORTS, 
//         icon: hoveredMenu === 3|| isActive(routes.REPORTS)
//           ? <AiFillPieChart size={20} /> 
//           : <AiOutlinePieChart size={20} />
//       },
//     { name: menuList.ACCOUNTING,
//        path: routes.ACCOUNTING,
//         icon: hoveredMenu === 4|| isActive(routes.ACCOUNTING) 
//        ? <FaBalanceScale  size={20} />
//         : <FaBalanceScale size={20} />
//     },
//     { name: menuList.ANALYTICS,
//        path: routes.ANALYTICS, 
//        icon: hoveredMenu === 5|| isActive(routes.ANALYTICS) 
//        ? <IoStatsChart size={20} /> 
//        : <IoStatsChartOutline size={20} />
//     },
//     { name: menuList.DOCUMENTS,
//       path: routes.DOCUMENTS, 
//       icon: hoveredMenu === 3|| isActive(routes.DOCUMENTS)
//        ? <DescriptionIcon  size={20} /> 
//        : <DescriptionOutlinedIcon  size={20} />
//    },

//   ];
  
//   const menusForRetail = [
//     { name: menuList.HOME,
//        path: routes.HOME,
//        icon: hoveredMenu === 0 || isActive(routes.HOME) 
//        ? <AiFillHome size={20} /> 
//        : <AiOutlineHome size={20} />
//     },

//      userRole === '3' ? 
//     {
//        name: menuList.INVOICE, 
//       path: routes.INVOICE,
//        icon: hoveredMenu === 1 || isActive(routes.INVOICE)
//        ? <BsFileEarmarkRichtextFill size={20} />
//         : <BsFileEarmarkRichtext size={20} />
//      } : ""
//      ,

//      userRole === '1' ?
//      { name: menuList.BUSINESS,
//        path: routes.ADMIN_BUSINESS_DASHBOARD,
//         icon: hoveredMenu === 2|| isActive(routes.ADMIN_BUSINESS_DASHBOARD) 
//         ? <MdBusinessCenter size={20} /> 
//         : <MdOutlineBusinessCenter size={20} />
//      } : "",

//      userRole !== '2' ?
//     { name: menuList.CUSTOMERS,
//       path: userRole === '1' ? routes.USERS_DASHBOARD : routes.CUSTOMERS,
//        icon: hoveredMenu === 2|| isActive(routes.CUSTOMERS) 
//        ? <HiUserGroup size={20} /> 
//        : <HiOutlineUserGroup size={20} />
//     } : ""
//     ,
//     { name: menuList.REPORTS,
//        path: routes.REPORTS, 
//        icon: hoveredMenu === 3|| isActive(routes.REPORTS)
//         ? <AiFillPieChart size={20} /> 
//         : <AiOutlinePieChart size={20} />
//     },
//     { name: menuList.ACCOUNTING,
//        path: routes.ACCOUNTING,
//         icon: hoveredMenu === 4|| isActive(routes.ACCOUNTING) 
//        ? <FaBalanceScale  size={20} />
//         : <FaBalanceScale size={20} />
//     },
//     { name: menuList.ANALYTICS,
//        path: routes.ANALYTICS, 
//        icon: hoveredMenu === 5|| isActive(routes.ANALYTICS) 
//        ? <IoStatsChart size={20} /> 
//        : <IoStatsChartOutline size={20} />
//     },

//   ];

//   const menusForGas = [
//     { 
//       name: menuList.HOME,
//        path: routes.HOME,
//        icon: hoveredMenu === 0 || isActive(routes.HOME) 
//        ? <AiFillHome size={20} /> 
//        : <AiOutlineHome size={20} />
//     },
//     { name: menuList.PURCHASES,
//       path: routes.PURCHASES,
//       icon: hoveredMenu === 0 || isActive(routes.PURCHASES) 
//       ? <Shop2Icon style={{ fontSize: 20 }} />
//       : <Shop2OutlinedIcon style={{ fontSize: 20 }} />
//    },
//    { name: menuList.EXPENSES,
//     path: routes.EXPENSES,
//     icon: hoveredMenu === 0 || isActive(routes.EXPENSES) 
//     ? <MonetizationOnIcon   size={20} /> 
//     : <MonetizationOnOutlinedIcon  size={20} />
//  },
//     {
//        name: menuList.SALES, 
//       path: routes.INVOICE,
//        icon: hoveredMenu === 1 || isActive(routes.INVOICE) || isActive(routes.VIEW_INVOICE_FORM) || isActive(routes.INVOICE_FORM_TWO) || isActive(routes.VIEW_INVOICE_FORM_TWO)
//        ? <BsFileEarmarkRichtextFill size={20} />
//         : <BsFileEarmarkRichtext size={20} />
//      }
//      ,
//      { name: menuList.PAYMENTS,
//       path: routes.PAYMENTS,
//       icon: hoveredMenu === 0 || isActive(routes.PAYMENTS) 
//       ? <MdPayments  size={20} /> 
//       : <MdOutlinePayments  size={20} />
//    },
//     { name: menuList.DEPOSITS,
//       path: routes.DEPOSITS,
//       icon: hoveredMenu === 0 || isActive(routes.DEPOSITS) 
//       ? <RiLuggageDepositFill    size={20} /> 
//       : <RiLuggageDepositLine   size={20} />
//   },

//     //  { name: menuList.BUSINESS,
//     //    path: routes.ADMIN_BUSINESS_DASHBOARD,
//     //     icon: hoveredMenu === 2|| isActive(routes.ADMIN_BUSINESS_DASHBOARD) 
//     //     ? <MdBusinessCenter size={20} /> 
//     //     : <MdOutlineBusinessCenter size={20} />
//     //  },

//     // { name: menuList.CUSTOMERS,
//     //   path: userRole === '1' ? routes.USERS_DASHBOARD : routes.CUSTOMERS,
//     //    icon: hoveredMenu === 2|| isActive(routes.CUSTOMERS) 
//     //    ? <HiUserGroup size={20} /> 
//     //    : <HiOutlineUserGroup size={20} />
//     // } ,

//     { name: menuList.REPORTS,
//        path: routes.REPORTS, 
//        icon: hoveredMenu === 3|| isActive(routes.REPORTS)
//         ? <AiFillPieChart size={20} /> 
//         : <AiOutlinePieChart size={20} />
//     },
//     { name: menuList.DOCUMENTS,
//       path: routes.DOCUMENTS, 
//       icon: hoveredMenu === 3|| isActive(routes.DOCUMENTS)
//        ? <DescriptionIcon  size={20} /> 
//        : <DescriptionOutlinedIcon  size={20} />
//    },
//   ];

//   const filteredMenus = userRole === "2"
//   ? menusForRetail.filter(menuItem => menuItem !== "" && menuItem !== undefined)
//   : userRole === "3"
//   ? menusForGas.filter(menuItem => menuItem !== "" && menuItem !== undefined)
//   : userRole === "1"
//   ? menusForAdmin.filter(menuItem => menuItem !== "" && menuItem !== undefined)
//   : [];

//   useEffect(() => {
//     axios
//       .get(`${baseURL}/${btoa("business/profile")}/${btoa(business_id)}/${btoa(user_id)}/${btoa(user_role)}`)
//       .then((response) => {
//         console.log("response businesstype" , response);
//         setImageSource(response.data.business_data[0].image_file)
//       })
//       .catch((error) => {
//         console.error("Error fetching business data:", error);
       
//       });
//   },[] );


//   return (
//     <div className="flex h-full sticky top-0">
//       <aside
//         aria-hidden={isOpen}
//         className={` bg-[white] shadow-md h-screen ${
//           isOpen ? "w-44" : "w-16"
//         } duration-500 text-black-100 px-4`}
//       >
//         <div className="bg-white h-10 mt-3 mb-7 flex justify-center items-center">
//           <img
//             src={isOpen ? logo : logo2}
//             alt="flownia"
//             className={`${isOpen ? "w-max" : "w-14"}`}
//           />
//         </div>

//         <div className="mt-5 flex flex-col gap-3 relative">
//           {filteredMenus?.map((menu, i) => (
//             <NavLink
//               to={menu?.path}
//               key={i}
//               onMouseEnter={() => handleMouseEnter(i)}
//               onMouseLeave={handleMouseLeave}
//               className={`
//                 group flex items-center text-[15px] gap-3.5 font-semibold p-2 rounded-md hover:bg-gray-100 hover:border  hover:border-gray-500/6 hover:text-[#3479E0] 
//                 ${
//                   isActive(menu.path)
//                     ? "bg-gray-100 border border-gray-500/6 text-[#3479E0] font-semibold"
//                     : "text-black"
//                 }
//               `}
//               activeClassName="bg-red-500 text-white border border-gray-500/6"
//             >
//               <div>{menu.icon}</div>
//               <h2
//                 className={`whitespace-pre ${
//                   !isOpen && "opacity-0 translate-x-28 overflow-hidden"
//                 }`}
//               >
//                 {menu?.name}
//               </h2>
//               <h2
//                 className={`${
//                   isOpen && "hidden"
//                 } absolute left-0 bg-white font-semibold whitespace-pre text-gray-900 rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-12 group-hover:duration-300 group-hover:w-fit`}
//               >
//                 {menu?.name}
//               </h2>
//             </NavLink>
//           ))}
//         </div>

//           {userRole !== "1" && (
//          <div className="mt-5">
//               {imageSource ? (
//                 <img 
//                   className="w-full h-12 pl-2 pr-2"
//                   src={`${baseURL}uploads/img/business/${imageSource}`} alt="Selected" />
//               ) : (
//                 <p>No image selected</p>
//               )}
//           </div>
//           )}
             

//       </aside>
//     </div>
//   );
// }

// export default SideBar;











import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import logoHeader from "../../assets/images/logoHeader.png";
import logo2 from "../../assets/images/logo2.png";
import axios from "axios";
import {
  AiOutlineHome,
  AiFillHome,
  AiFillPieChart,
  AiOutlinePieChart,
  AiFillAccountBook,
  AiOutlineAccountBook
} from "react-icons/ai";
import { FaBalanceScale } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { FaRegMessage, FaMessage } from "react-icons/fa6";
import { routes } from "../../components/constants/routes.constants";
import { menuList } from "../../components/constants/menu.constants";
import { HiOutlineUserGroup, HiUserGroup } from "react-icons/hi2";
import { BsFileEarmarkRichtext, BsFileEarmarkRichtextFill } from "react-icons/bs";
import { IoStatsChart, IoStatsChartOutline } from "react-icons/io5";
import { MdOutlineBusinessCenter, MdBusinessCenter } from "react-icons/md";
import Shop2OutlinedIcon from '@mui/icons-material/Shop2Outlined';
import Shop2Icon from '@mui/icons-material/Shop2';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { MdOutlinePayments, MdPayments } from "react-icons/md";
import { RiLuggageDepositFill, RiLuggageDepositLine } from "react-icons/ri";
import DescriptionIcon from '@mui/icons-material/Description';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import config from "../../config/config"
import {  MenuItem,  Typography,} from "@material-tailwind/react";
import { MdCalculate, MdAttachMoney } from "react-icons/md"; // Billing icons (outline and fill)
import { MdAccountBalance, MdAccountBalanceWallet } from "react-icons/md"; // Accounting icons (outline and fill)
import { MdInsertChart, MdShowChart } from "react-icons/md"; // Analytics icons (outline and fill)
import { AiFillMessage, AiOutlineMessage } from "react-icons/ai"; // Message icons
import { AiFillFolder, AiOutlineFolder } from "react-icons/ai"; // Folder icons
import { AiFillWallet, AiOutlineWallet } from "react-icons/ai"; // Ant Design icons for accounting
import LocalGasStationOutlinedIcon from '@mui/icons-material/LocalGasStationOutlined';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import ToastNotificationBox from "./ToastBox/ToastNotificationBox";
import { BorderLeft } from "@mui/icons-material";


function SideBar({ isOpen, handleSignOut, setOpen }) {
  const location = useLocation();
  const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;
  const user_id = localStorage.getItem("user_id");
  const user_role = localStorage.getItem("user_role");
  const business_id = localStorage.getItem("business_id");
  const managerId = localStorage.getItem("user_id");
  const [imageSource, setImageSource] = useState();

  const [hoveredMenu, setHoveredMenu] = useState(null);
  // const [activeSubmenu, setActiveSubmenu] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredMenu(index);
  };

  const handleMouseLeave = () => {
    setHoveredMenu(null);
  };
  const [expandedSubmenu, setExpandedSubmenu] = useState(null);

  const toggleSubmenu = (index) => {
    setExpandedSubmenu(expandedSubmenu === index ? null : index);
  };


  const isActive = (path) => {
    const currentPath = location.pathname;
  
    // Check for Home route
    if (path === 'Home') {
      return currentPath === path;
    }
    else if (path === 'Operations') {
      return currentPath.includes('purchases') || currentPath.includes('expenses')|| currentPath.includes('Losses');
    }
    else if (path === 'Payments') {
      return currentPath.includes('payIns') || currentPath.includes('payouts')|| currentPath.includes('deposits');
    }
    
    else if (path ==='Sales') {
      return  (currentPath.includes( 'invoice') && !currentPath.includes('invoiceReport'));
    }
    
    else if (path === 'report') {
      return  currentPath.includes('SalesReport') ||currentPath.includes('invoiceReport');
    }
    else if (path === 'MyDocs') {
      return  currentPath.includes('draftAgreemnets') ||currentPath.includes('approvedContracts') ||currentPath.includes('Licenses');
    }

    
    // Check for Accounting route
    else if (path === 'Accounting') {
      return  currentPath.includes('salesTax');
    }

    // else if (path === currentPath) {
    //   return true
    // }
    // Default case if none of the above matched
    return false;
  };

 
  const [activeMenu, setActiveMenu] = useState(null);
  const [SubactiveMenu, setSubActiveMenu] = useState(null);

  useEffect(() => {
    if (activeMenu) {
      localStorage.setItem('activemenu', activeMenu);
    }
    if(SubactiveMenu){
      localStorage.setItem('Subactivemenu', SubactiveMenu);

    }
  }, [activeMenu, SubactiveMenu]);
  useEffect(() => {
    const savedActiveMenu = localStorage.getItem('activemenu');
    if (savedActiveMenu) {
      setActiveMenu(savedActiveMenu);  // Set the active menu based on localStorage
      setSubActiveMenu('')
    }
    const savedSubActiveMenu = localStorage.getItem('Subactivemenu');
    if (savedSubActiveMenu) {
      setSubActiveMenu(savedSubActiveMenu);  // Set the active menu based on localStorage
      setActiveMenu('')
    }
    const savedExpandedSubmenu = localStorage.getItem('expandedSubmenu');
    if (savedExpandedSubmenu) {
      setExpandedSubmenu(Number(savedExpandedSubmenu));  // Set the expanded submenu index from localStorage
    }
  }, []);
  useEffect(() => {
    if (expandedSubmenu !== null) {
      localStorage.setItem('expandedSubmenu', expandedSubmenu);
    }
  }, [expandedSubmenu]);
  


  const handleMenuClick = (menuName, i) => {
    setActiveMenu(menuName);
    setSubActiveMenu('')
  };
  const handleSubMenuClick = (menuName, i) => {
    console.log(menuName, 'menuname sub...')
    setSubActiveMenu(menuName);
    setActiveMenu('')
  };
  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    setUserRole(localStorage.getItem("user_role"));
  }, []);

  const menusForAdmin = [
    {
      name: menuList.HOME,
      path: routes.HOME,
      icon: hoveredMenu === 0 || isActive(routes.HOME)
        ? <AiFillHome size={20} />
        : <AiOutlineHome size={20} />
    },

    {
      name: menuList.CUSTOMERS,
      path: routes.ADMIN_BUSINESS_DASHBOARD,
      icon: hoveredMenu === 1 || isActive(routes.ADMIN_BUSINESS_DASHBOARD)
        ? <MdBusinessCenter size={20} />
        : <MdOutlineBusinessCenter size={20} />
    },
    // {
    //   name: menuList.CUSTOMERS,
    //   path: routes.USERS_DASHBOARD,
    //   icon: hoveredMenu === 2 || isActive(routes.CUSTOMERS)
    //     ? <HiUserGroup size={20} />
    //     : <HiOutlineUserGroup size={20} />
    // },`ō

    {
      name: menuList.NOTIFICATIONS,
      path: routes.NOTIFICATIONS,
      icon: hoveredMenu === 3 || isActive(routes.NOTIFICATIONS)
        ? <FaMessage size={18} />
        : <FaRegMessage size={18} />
    },

    {
      name: menuList.REPORTS,
      path: routes.REPORTSADMIN,
      icon: hoveredMenu === 4 || isActive(routes.REPORTS)
        ? <AiFillPieChart size={20} />
        : <AiOutlinePieChart size={20} />
    },
    {
      name: menuList.ACCOUNTING,
      path: routes.ACCOUNTINGADMIN,
      icon: hoveredMenu === 5 || isActive(routes.ACCOUNTING)
        ? <FaBalanceScale size={20} />
        : <FaBalanceScale size={20} />
    },
    {
      name: menuList.ANALYTICS,
      path: routes.ANALYTICSADMIN,
      icon: hoveredMenu === 6 || isActive(routes.ANALYTICS)
        ? <IoStatsChart size={20} />
        : <IoStatsChartOutline size={20} />
    },
    {
      name: menuList.DOCUMENTS,
      path: routes.DOCUMENTS,
      icon: hoveredMenu === 7 || isActive(routes.DOCUMENTS)
        ? <DescriptionIcon size={20} />
        : <DescriptionOutlinedIcon size={20} />
    },

  ];

  const menusForRetail = [
    {
      name: menuList.HOME,
      path: routes.HOME,
      icon: hoveredMenu === 0 || isActive(routes.HOME)
        ? <AiFillHome size={20} />
        : <AiOutlineHome size={20} />
    },

    userRole === '3' ?
      {
        name: menuList.INVOICE,
        path: routes.INVOICE,
        icon: hoveredMenu === 1 || isActive(routes.INVOICE)
          ? <BsFileEarmarkRichtextFill size={20} />
          : <BsFileEarmarkRichtext size={20} />
      } : "",

    userRole === '1' ?
      {
        name: menuList.BUSINESS,
        path: routes.ADMIN_BUSINESS_DASHBOARD,
        icon: hoveredMenu === 2 || isActive(routes.ADMIN_BUSINESS_DASHBOARD)
          ? <MdBusinessCenter size={20} />
          : <MdOutlineBusinessCenter size={20} />
      } : "",

    userRole !== '2' ?
      {
        name: menuList.CUSTOMERS,
        path: userRole === '1' ? routes.USERS_DASHBOARD : routes.CUSTOMERS,
        icon: hoveredMenu === 3 || isActive(routes.CUSTOMERS)
          ? <HiUserGroup size={20} />
          : <HiOutlineUserGroup size={20} />
      } : ""
    ,
    {
      name: menuList.REPORTS,
      path: routes.REPORTS,
      icon: hoveredMenu === 4 || isActive(routes.REPORTS)
        ? <AiFillPieChart size={20} />
        : <AiOutlinePieChart size={20} />
    },
    {
      name: menuList.ACCOUNTING,
      path: routes.ACCOUNTING,
      icon: hoveredMenu === 5 || isActive(routes.ACCOUNTING)
        ? <FaBalanceScale size={20} />
        : <FaBalanceScale size={20} />
    },
    {
      name: menuList.ANALYTICS,
      path: routes.ANALYTICS,
      icon: hoveredMenu === 6 || isActive(routes.ANALYTICS)
        ? <IoStatsChart size={20} />
        : <IoStatsChartOutline size={20} />
    },

  ];

  const menusForGas = [
    {
      name: menuList.HOME,
      path: routes.HOME,
      icon: hoveredMenu === 0 
        ? <AiFillHome size={20} />
        : <AiOutlineHome size={20} />
    },
    {
      name: menuList.OPERATIONS,
      // path: routes.OPERATIONS,
      icon: hoveredMenu === 1 
        ? <MdBusinessCenter size={20} />
        : <MdOutlineBusinessCenter size={20} />,
        submenu: [
          {
            name: menuList.PURCHASES,
            path: routes.PURCHASES,
          },
          {
            name: menuList.EXPENSES,
            path: routes.EXPENSES,
          },
          {
            name: menuList.LOSSES,
            path: routes.LOSSES,
          },
        ],
    },
    // {
    //   name: menuList.PURCHASES,
    //   path: routes.PURCHASES,
    //   icon: hoveredMenu === 1 || isActive(routes.PURCHASES)
    //     ? <Shop2Icon style={{ fontSize: 20 }} />
    //     : <Shop2OutlinedIcon style={{ fontSize: 20 }} />
    // },
    // {
    //   name: menuList.EXPENSES,
    //   path: routes.EXPENSES,
    //   icon: hoveredMenu === 2 || isActive(routes.EXPENSES)
    //     ? <MonetizationOnIcon size={20} />
    //     : <MonetizationOnOutlinedIcon size={20} />
    // },
    {
      name: menuList.SALES,
      // path: routes.SALESHOME,
      icon: hoveredMenu === 2 
        ? <BsFileEarmarkRichtextFill size={20} />
        : <BsFileEarmarkRichtext size={20} />,
        submenu: [
          {
            name: menuList.INVOICE,
            path: routes.INVOICE,
          },
       
        ],
    }
    ,
    {
      name: menuList.PAYMENTS,
      // path: routes.PAYMENTS,
      icon: hoveredMenu === 3 || isActive(menuList.PAYMENTS) || isActive(routes.PAYINS) || isActive(routes.PAYOUTS)  || isActive(routes.DEPOSITS) 
              ? <MdAttachMoney size={20} />
        : <MdAttachMoney size={20} />,
        submenu: [
          {
            name: menuList.PAYINS,
            path: routes.PAYINS,
          },
          {
            name: menuList.PAYOUTS,
            path: routes.PAYOUTS,
          },
          {
            name: menuList.DEPOSITS,
            path: routes.DEPOSITS,
          },
        ],
    }
    ,
    {
      name: menuList.REPORTS,
      // path: routes.REPORTS ,
      icon: hoveredMenu === 4 
        ? <AiFillPieChart size={20} />
        : <AiOutlinePieChart size={20} />,
        submenu: [
          {
            name: menuList.PURCHASES,
            path: routes.PURCHASEREPORT,
          },
          {
            name: menuList.EXPENSES,
            path: routes.EXPENSES,
          },
          {
            name: menuList.SALESREPORT,
            path: routes.SALESREPORT,
          },
        
          {
            name: menuList.INVOICEREPORT,
            path: routes.INVOICEREPORT,
          },
          {
            name: menuList.PRICELOG,
            path: routes.PRICELOG,
          },
          {
            name: menuList.BILLING,
            path: routes.BILLING,
          },  {
            name: menuList.LOSSES,
            path: routes.LOSSES,
          },
        ],
    },
    {
      name: menuList.TANKREADING,
      path: routes.TANKREADING ,
      icon: hoveredMenu === 5 || isActive( menuList.TANKREADING)
        ? <LocalGasStationIcon size={20} />
        : <LocalGasStationOutlinedIcon size={20} />
    },

    {
      name: menuList.ACCOUNTING,
      // path: routes.ACCOUNTING ,
      icon: hoveredMenu === 6 || isActive(routes.ACCOUNTING)
        ? <MdCalculate size={20} />
        : <MdCalculate size={20} />,
        submenu: [
          {
            name: menuList.SALESTAX,
            path: routes.SALESTAX,
          },
          {
            name: menuList.CORPORATIONTAX,
            path: routes.CORPORATIONTAX,
          },
          {
            name: menuList.MARGINS,
            path: routes.MARGINS,
          },
        
          {
            name: menuList.PROFITANDLOSS,
            path: routes.PROFITANDLOSS,
          },
          {
            name: menuList.PRICELOG,
            path: routes.PRICELOG,
          },
       
        ],
    },
    {
      name: menuList.ANALYTICS,
      // path: routes.ANALYTICS ,
      icon: hoveredMenu === 7 || isActive(menuList.ACCOUNTING)
        ? <AiFillPieChart size={20} />
        : <AiOutlinePieChart size={20} />,
        submenu: [
          {
            name: menuList.KPIS,
            path: routes.KPIS,
          },
          {
            name: menuList.TRENDING,
            path: routes.TRENDING,
          },
        ],
    },
    {
      name: menuList.NOTIFICATIONS,
      // path: routes.NOTIFICATIONS ,
      icon: hoveredMenu === 8 || isActive( menuList.MESSAGES)
        ? <AiFillMessage size={20} />
        : <AiOutlineMessage size={20} />,
        submenu: [
          {
            name: menuList.SMS,
            path: routes.SMS,
          },
          {
            name: menuList.EMAIL,
            path: routes.EMAIL,
          },
        ],
    },
    {
      name: menuList.MYDOCS,
      // path: routes.MYDOCS ,
      icon: hoveredMenu === 9 || isActive( menuList.MYDOCS)
        ? <AiFillFolder size={20} />
        : <AiOutlineFolder size={20} />,
        submenu: [
          {
            name: menuList.DRAFTAGREEMENTS,
            path: routes.DRAFTAGREEMENTS,
          },
          {
            name: menuList.APPROVEDCONTRACTS,
            path: routes.APPROVEDCONTRACTS,
          },
          {
            name: menuList.LICENSES,
            path: routes.LICENSES,
          },
        ],
    },
 
  ];

  const menusForCustomer = [
    {
      name: menuList.HOME,
      path: routes.HOME,
      icon: hoveredMenu === 0 || isActive(routes.HOME)
        ? <AiFillHome size={20} />
        : <AiOutlineHome size={20} />
    },
    {
      name: menuList.REPORTS,
      path: routes.CUSTOMERREPORTS,
      icon: hoveredMenu === 4 || isActive(routes.REPORTS)
        ? <AiFillPieChart size={20} />
        : <AiOutlinePieChart size={20} />
    },
 
    {
      name: menuList.ANALYTICS,
      path: routes.ANALYTICS,
      icon: hoveredMenu === 6 || isActive(routes.ANALYTICS)
        ? <IoStatsChart size={20} />
        : <IoStatsChartOutline size={20} />
    },
  

  ];

  // Ensure that the filteredMenus logic includes the menus for the Customer role (user_role = '4')
  const filteredMenus = user_role === "2"
    ? menusForRetail.filter(menuItem => menuItem !== "" && menuItem !== undefined)
    : user_role === "3"
      ? menusForGas.filter(menuItem => menuItem !== "" && menuItem !== undefined)
      : user_role === "1"
        ? menusForAdmin.filter(menuItem => menuItem !== "" && menuItem !== undefined)
        : user_role === "5" // Assuming '5' is the Customer role ID
          ? menusForCustomer.filter(menuItem => menuItem !== "" && menuItem !== undefined)
          : [];


  // const filteredMenus = userRole === "2"
  //   ? menusForRetail.filter(menuItem => menuItem !== "" && menuItem !== undefined)
  //   : userRole === "3"
  //     ? menusForGas.filter(menuItem => menuItem !== "" && menuItem !== undefined)
  //     : userRole === "1"
  //       ? menusForAdmin.filter(menuItem => menuItem !== "" && menuItem !== undefined)
  //       : [];

  useEffect(() => {
    axios
      .get(`${baseURL}/${btoa("business/profile")}/${btoa(business_id)}/${btoa(user_id)}/${btoa(user_role)}`)
      .then((response) => {
        // console.log("response businesstype", response);
        setImageSource(response.data.business_data[0].image_file)
      })
      .catch((error) => {
        console.error("Error fetching business data:", error);

      });
  }, []);


  return (
    <>
    <style>{scrollableContainerScrollbarStyles}</style>

    <div className="flex h-full sticky top-0 "  >

      <aside
        aria-hidden={isOpen}
        className={` bg-[white] shadow-md h-screen ${isOpen ? "w-[220px]" : "w-16"
          } duration-500 text-black-100 px-4`}
          style={{ overflowY: 'auto' }} 
      >
        <div className="bg-white h-10 mt-3 mb-5 flex justify-center items-center">
          <img
           style={{ width: isOpen && '250px',
            height: isOpen && '70px'}}
            src={isOpen ? logoHeader : logo2}
            alt="flownia"
            className={`${isOpen ? "w-max" : "w-14"}`}
          />
        </div>

        <div className="mt-5 flex flex-col gap-2 ">
    {filteredMenus?.map((menu, i) => (
      <div key={i} className="relative">
        {/* Main Menu Item */}
        {
          menu.path ?
          <NavLink
          to={menu?.path}
          onClick={() => {toggleSubmenu(i); handleMenuClick(menu.name , i)}}
          className={`
            group flex items-center text-[15px] gap-3.5 font-semibold p-2 rounded-md  hover:border hover:border-gray-500/6 hover:text-[#FFFFFF] 
            ${isActive(menu.path ) || activeMenu === menu.name 
              // ? "bg-[#F5F5F5] border border-gray-500/6 text-[#3479E0] font-semibold"
              ? "bg-[#25caed] border border-gray-500/6 text-[#FFFFFF] font-semibold"

              : "hover:bg-[#d7f3f8] hover:text-black"
            }
          `}
        >
          <div>{menu.icon}</div>
          <h2 className="whitespace-pre">{isOpen ? menu?.name : ''}</h2>
        </NavLink>
        :
        <div
        onClick={() => {toggleSubmenu(i); handleMenuClick(menu.name, i)}}
        className={`
          group flex items-center text-[15px] gap-3.5 font-semibold p-2 rounded-md  hover:border hover:border-gray-500/6 hover:text-[#FFFFFF] 
          cursor-pointer
            ${ activeMenu === menu.name 
              // ? "bg-[#F5F5F5] border border-gray-500/6 text-[#3479E0] font-semibold"
              ? "bg-[#25caed] border border-gray-500/6 text-[#FFFFFF] font-semibold"

              : "hover:bg-[#d7f3f8] hover:text-black"
            }
        `}
      >
        <div>{menu.icon}</div>
        <h2 className="whitespace-pre">{isOpen ? menu?.name : ''}</h2>
      </div>
      

        }
      

        {/* Submenu */}
        {menu.submenu && (
      
            <div className="overflow-hidden transition-all duration-300 ml-6 "     style={{
              maxHeight: isOpen && expandedSubmenu === i 
              ? `${menu.submenu.length * 50}px` 
              : "0",  }} >
            
              {menu.submenu.map((submenuItem, j) => (
                      <div
                      className={` flex flex-col mt-1 `}
                      onClick={() => { handleSubMenuClick(submenuItem.name, i)}}
          
                    >
                <NavLink
                  to={submenuItem.path}
                  key={j}

                  // className="px-4 py-2 text-md hover:bg-gray-200  text-gray-950"
                  className={({ isActive }) =>
                    `px-4 py-2 text-md  text-gray-950 rounded-md  ${SubactiveMenu === submenuItem.name ? 'bg-[#25caed] border-l-[3px] border-[#FFFFFF] text-[#FFFFFF] font-semibold ' : 'hover:bg-[#d7f3f8] hover:text-black'}`
                  }
                >
                  {isOpen ? submenuItem.name : ''}
                </NavLink>
                </div>

              ))}
            </div>
        )}
      </div>
    ))}

    {/* Log Out */}
    <div className="pb-9 mb-9 mt-3  cursor-pointer">
  <div
    onClick={(e) => {
      e.preventDefault();
      handleSignOut();
    }}
    className="ml-[10px] inline-flex items-center gap-2 cursor-pointer border-b-2 border-red-500 pb-1 hover:bg-red-100 transition"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={3}
      stroke="currentColor"
      className="h-4 w-4 text-red-500 mr-2"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M5.636 5.636a9 9 0 1012.728 0M12 3v9"
      />
    </svg>
    {isOpen && (
      <span className="font-bold text-red-500">
        Log Out
      </span>
    )}
  </div>
</div>
  </div>
{/* 
        {userRole !== "1" && (
          <div className="mt-5">
            {imageSource ? (
              <img
                className="w-full h-12 pl-2 pr-2"
                src={`${baseURL}uploads/img/business/${imageSource}`} alt="Selected" />
            ) : (
              <p>No image selected</p>
            )}
          </div>
        )} */}


      </aside>
    </div>
    </>
  );
}

export default SideBar;


/* Add scrollbar styling */
const scrollableContainerScrollbarStyles = `
  ::-webkit-scrollbar {
    width: 8px; /* Scrollbar width */
  }
  ::-webkit-scrollbar-track {
    background: #F8F8F8; /* Track background color */
    border-radius: 10px;
    height:'30px !important'
  }
  ::-webkit-scrollbar-thumb {
    background: #E0E0E0; /* Thumb color */
    border-radius: 10px;
    border: 1px solid #f1f1f1;
    min-height: 60px !important; /* Minimum thumb height */
    max-height: 60px !important; /* Maximum thumb height */
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #E0E0E0; /* Hover color for thumb */
  }
`;


