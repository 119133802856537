import React, { useState, useEffect } from "react";
import { MdKeyboardDoubleArrowUp } from "react-icons/md";

function ScrollToTop() {
  const [isVisible, setIsVisible] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" }); // Use smooth scrolling
  };

  useEffect(() => {
    // Add a scroll event listener to check the scroll position
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsVisible(true); // Show the button when scrolled down 100px
      } else {
        setIsVisible(false); // Hide the button when at the top
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={`fixed bottom-5 right-4 z-50 ${isVisible ? "" : "hidden"}`}>
      {/* Scroll-to-top button */}
      <button
        onClick={scrollToTop}
        className={`bg-[#3479E0]/50 text-white p-2 rounded-full shadow-lg transition-opacity hover:bg-[#3479E0] ${
          isVisible ? "opacity-100" : "opacity-0" // Apply opacity transition
        }`}
      >
        <MdKeyboardDoubleArrowUp size={24} />
      </button>
    </div>
  );
}

export default ScrollToTop;
