import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allProducts : [],
};

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    addProduct(state, action) {
    //   console.log("act", action.payload);
      if (Array.isArray(action.payload)) {
        const newProducts = action.payload.filter(newProduct =>
          !state.allProducts.some(existingProducts => existingProducts.product_id === newProduct.product_id)
        );
        state.allProducts.push(...newProducts);
      } else {
        if (!state.allProducts.some(existingProduct => existingProduct.product_id === action.payload.product_id)) {
          state.allProducts.push(action.payload);
        }
      }
    },
    deleteProduct(state, action) {
      state.allProducts = state.allProducts.filter(
        product => product.product_id !== action.payload
      );
    },
    deleteAllProduct(state) {
        state.allProducts = []; 
      }
  },
});

export const { addProduct, deleteProduct , deleteAllProduct } = productSlice.actions;
export default productSlice.reducer;
