


import React, { useEffect, useState } from "react";
import axios from "axios";
import { IoIosArrowDown } from "react-icons/io";
import config  from "../../config/config"


function InvoiceItems({
  total,
  setTotal,
  subtotal,
  setSubtotal,
  tax,
  setTax,
  feesOrDiscounts,
  setFeesOrDiscounts,
  onFocus,
  onBlur,
  items,
  setItems,
}) {
  const [availableItems, setAvailableItems] = useState([]);
  const [availableDescriptions, setAvailableDescriptions] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [productOptions, setProductOptions] = useState([]);
  const [selectedProductId, setSelectedProductId] = useState("");
  const [selectedProductItems, setSelectedProductItems] = useState([]);
  const businessId = localStorage.getItem("business_id")
  
  const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;
  // useEffect(() => {
  //   axios
  //     .get(`http://localhost/InvoiceFlow/items/getitemsbyproduct/${businessId}/${selectedProductId}`)
  //     .then((response) => {
  //       // console.log("items response" , response) ;
  //       setAvailableItems(response.data.itemsByProduct);
  //       console.log("available items: "  , availableItems);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching available items:", error);
  //     });
  // }, []);

  // console.log("available items : " , availableItems);

  const [availableDescriptionsMap, setAvailableDescriptionsMap] = useState({});

  const handleProductChange = (index, field, value) => {
    setSelectedProductId(value);
    // console.log("selected product id" , value);
    if (!value) {
      // If no product is selected, clear the selectedProductItems and update items
      const updatedItems = [...items];
      updatedItems[index].selectedProductItems = [];
      updatedItems[index][field] = ""; // Clear the product value
      updatedItems[index].description = ""; // Clear the description
      updatedItems[index].unitPrice = ""; // Clear the unit price
      setItems(updatedItems);
      setSelectedProductItems([]); // Clear selected product items as well
  } else {
      // Fetch the items based on the selected product
      axios
          .get(`${baseURL}${btoa("items/getitemsbyproduct")}/${btoa(businessId)}/${btoa(value)}`)
          .then((response) => {
              const allItems = response.data.itemsByProduct;

              // Get all selected item IDs across all rows
              const selectedItemIds = items.flatMap(item => item.item).filter(Boolean);

              // Filter out the items that have already been selected
              const filteredItems = allItems.filter(item => !selectedItemIds.includes(item.Item_id));

              // Update the selectedProductItems for the current row
              const updatedItems = [...items];
              updatedItems[index].selectedProductItems = filteredItems;
              updatedItems[index][field] = value; // Set the product value

              // Clear description and unit price, only to be set when an item is selected
              updatedItems[index].description = "";
              updatedItems[index].unitPrice = "";

              // Update the state
              setItems(updatedItems);
              setSelectedProductItems(filteredItems); // Update selectedProductItems to show in the dropdown
          })
         
        .catch((error) => {
          console.error("Error fetching items for the selected product:", error);
        });
    }
    // axios
    // .get(`http://localhost/InvoiceFlow/items/getitemsbyproduct/${businessId}/${value}`)
    // .then((response) => {
    //   console.log("selectedProductItems" , response);
    //   // Assuming the response.data is an array of items for the selected product
    //   setSelectedProductItems(response.data.itemsByProduct);
    //   const updatedItems = [...items];
    //   updatedItems[index].selectedProductItems = response.data.itemsByProduct;

    //   const selectedProduct = productOptions.find((product) => product.product_id === value)
    //   updatedItems[index].product = selectedProduct ? selectedProduct.product_name : "";
    //   updatedItems[index].selectedProductItems = response.data.itemsByProduct;

    //   setItems(updatedItems);
     
    // })
    // .catch((error) => {
    //   console.error("Error fetching items for the selected product:", error);
    // });
  };
 

  const handleItemChange = (index, field, value) => {
    
    const updatedItems = [...items];
    updatedItems[index][field] = value;
  
    // Check if the user is in "Enter Description" mode
    const isEnterDescriptionMode =
      value === "Enter Description" && field === "description";
  
    if (isEnterDescriptionMode) {
      // Set the editableDescription flag to true
      updatedItems[index].editableDescription = true;
      setShowDropdown(true); // Set dropdown to be visible when in "Enter Description" mode
      updatedItems[index].description = "";
    } else {
      // Update the description field only if the user selects an item from the dropdown
      if (field === "item") {
        const matchingItem = selectedProductItems.find(
          (item) => item.Item_id === value
        );
  
        if (matchingItem) {
          updatedItems[index].description = matchingItem.Item_des;
          updatedItems[index].unitPrice = matchingItem.item_unit_price;
          // Update availableDescriptionsMap based on the selected item
          setAvailableDescriptionsMap((prevMap) => ({
            ...prevMap,
            [value]: [matchingItem.Item_des],
          }));
  
          updatedItems[index].editableDescription = false; // Change to dropdown
          // Ensure that dropdown is not visible when selecting an item from the list
          setShowDropdown(false);
        } else {
          // Log if the selected item is not found
          console.log("Selected Item not found in availableItems");
        }
      }
    }
  
    updatedItems[index].total =
      updatedItems[index].quantity * updatedItems[index].unitPrice;
  
    // If the user is editing the last row's quantity, add a new row
    if (
      field === "quantity" &&
      index === updatedItems.length - 1 &&
      value !== ""
    ) {
      updatedItems.push({
        No: updatedItems.length + 1,
        product : "",
        item: "",
        description: "",
        quantity: "",
        unitPrice: "",
        total: 0,
        editableDescription: false,
      });
  
      setShowDropdown(false); // Close dropdown for the new row
    }
  
    // Calculate subtotal and total
    const newSubtotal = updatedItems.reduce((acc, item) => acc + item.total, 0);
    const newTotal = newSubtotal + newSubtotal * (tax / 100) + feesOrDiscounts;
  
    // Update state
    setItems(updatedItems);
    setSubtotal(newSubtotal);
    setTotal(newTotal);
  };

 
  useEffect(() => {
    async function fetchProductOptions() {
      try {
        const response = await axios.get(
          `${baseURL}${btoa("retailproduct/view")}/${btoa(businessId)}`
        );
        console.log("response");
        // Assuming the response.data is an array of products
        setProductOptions(response.data.products_data);
        console.log("product options" , productOptions);
      } catch (error) {
        console.error("Error fetching product options:", error);
      }
    }
    fetchProductOptions();
  }, []);
  
  
  
  
  

  const handleTaxChange = (value) => {
    // Extract only numeric and decimal part using slice
    const numericValue = value === "" ? "" : value.replace(/[^0-9.]/g, '').slice(0, 15);
    if (/^\d*\.?\d*$/.test(numericValue)) {
      const newTax = numericValue === "" ? "" : numericValue;
      setTax(newTax);
  
      const newTotal = subtotal + subtotal * (newTax / 100) + feesOrDiscounts;
      setTotal(newTotal);
    } 
  };
  

  // const handleFeesChange = (value) => {
  //   const newFees = !value ? 0 : parseFloat(value);
  //   if (!value) setFeesOrDiscounts("");
  //   else setFeesOrDiscounts(newFees);

  //   const newTotal = subtotal + subtotal * (tax / 100) - newFees;
  //   setTotal(newTotal);
  // };

  const handleFeesChange = (value) => {
    const numericValue = value === "" ? "" : value.replace(/[^0-9.]/g, '').slice(0, 15);
    if (/^\d*\.?\d*$/.test(numericValue)) {
      const newFees = numericValue === "" ? "" : numericValue;
      setFeesOrDiscounts(newFees); 
      const newTotal = subtotal + subtotal * (tax / 100) - newFees;
      setTotal(newTotal);   
    } 
   
  };

  const formatNumber = (number) => {

    if (isNaN(number)) {
      return 0;
    }
    const formattedNumber = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 5,
      maximumFractionDigits: 5,
    }).format(number);
  
    return formattedNumber;
  };

  return (
    <div className="mb-4">
      <h3 className="text-lg font-bold underline mb-2 mt-10 text-gray-900 ">
        Invoice Items
      </h3>
      <table className="w-full">
        <thead>
          <tr className="bg-gray-100">
            <th style={{ width: "50px" }} className="pt-2 pb-2 pl-0 font-semibold text-base">#</th>
            <th style={{ width: "150px" }}  className="p-2 font-semibold pr-8 text-base">Product</th>
            <th style={{ width: "150px" }}  className="p-2 font-semibold pr-8 text-base">Item</th>
            <th style={{ width: "200px" }}  className="p-2 font-semibold pr-10 text-base">Item Description</th>
            <th style={{ width: "240px" }} className="p-2 font-semibold pr-[70px] text-base">Quantity</th>
            <th style={{ width:"px" , marginLeft: "-50px" , position:"absolute"  }} className="p-2 font-semibold text-base">Unit Price</th>
            <th style={{ width : "70px",  marginRight: "-10px"}} className="p-2 font-semibold text-base">Total</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => (
            <tr key={index}>
              <td className="p-2 pl-4">{index + 1}</td>

              <td className="p-2">
                <select
                  // value={item.item}
                  onChange={(e) => {
                    handleProductChange(index, "product", e.target.value );
                  }}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-md focus:ring-primary-600 focus:border-primary-600 block p-1"
                  style={{
                    width: "150px"
                  }}
                >
                  <option value="">Select Product</option>
                  {productOptions?.map((product) => {
                    return (
                      <option
                        key={product.product_id}
                        // value={`${item.Item_id}#${item.Item_name}`}
                        value={product.product_id}
                      >
                        {product.product_name}
                      </option>
                    );
                  })}
                </select>
              </td>

              <td className="p-2">
                <select
                  value={item.item}
                  onChange={(e) => {
                    handleItemChange(index, "item", e.target.value);
                  }}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-md focus:ring-primary-600 focus:border-primary-600 block p-1"
                  style={{
                    width: "150px"
                  }}
                >
                  <option value="">Select Item</option>
                  {item.selectedProductItems?.map((selectedItem) => {
                    return (
                      <option
                      key={selectedItem.Item_id}
                      value={selectedItem.Item_id}
                      >
                         {selectedItem.Item_name}
                      </option>
                    );
                  })}
                </select>
              </td>

              <td className="p-2">
                {item.editableDescription ? (
                  <div className="relative">
                    <div className="flex items-center">
                      <input
                        type="number"
                        value={
                          item.description
                        }
                        placeholder="Enter Description"
                        onChange={(e) =>
                          handleItemChange(index, "description", e.target.value)
                        }
                        // Show dropdown on click
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-md focus:ring-primary-600 focus:border-primary-600 block  p-1"
                        style={{width:"200px",
                            marginLeft:"10px"  
                           }}
                         // Disable input field if an option is selected
                      />
                      <IoIosArrowDown
                        onClick={() => setShowDropdown(true)}
                        className="-ml-5 cursor-pointer"
                      />
                    </div>
                    {showDropdown && (
                      <div className="absolute top-10 left-0 z-10">
                        <ul className="bg-white border border-gray-300 text-gray-900 text-base rounded-md shadow-md">
                          {availableDescriptions?.map(
                            (description, descIndex) => (
                              <li
                                key={descIndex}
                                onClick={() =>
                                  handleItemChange(
                                    index,
                                    "description",
                                    description
                                  )
                                }
                                className="cursor-pointer p-2 hover:bg-gray-100"
                              >
                                {description}
                              </li>
                            )
                          )}
                        </ul>
                      </div>
                    )}
                  </div>
                ) : (
                  <select
                    value={item.description}
                    onChange={(e) =>
                      handleItemChange(index, "description", e.target.value)
                    }
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-md focus:ring-primary-600 focus:border-primary-600 block p-1"
                    style={{width:"200px",
                            marginLeft:"10px"  
                     }}
                  >
                    <option value="">Select Description</option>
                    <option value="Enter Description">Enter Description</option>
                    {Array.isArray(availableDescriptionsMap[item.item]) &&
                      availableDescriptionsMap[item.item].map((description, descIndex) => (
                        <option key={descIndex} value={description}>
                          {description}
                        </option>
                      ))}
                  </select>
                )}
              </td>

              <td className="p-2 ">
                <input
                  type="number"
                  value={item.quantity}
                  placeholder="Enter Quantity"
                  onChange={(e) =>
                    handleItemChange(index, "quantity", parseInt(e.target.value))
                  }
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-base text-right rounded-md focus:ring-primary-600 focus:border-primary-600 block  p-1"
                  style={{
                    width :  "130px",
                    marginLeft:"10px",
                  }}
                />
              </td>

              <td className="p-2 ">
                <input
                  type="number"
                  value={item.unitPrice}
                  placeholder="Enter Unit price"
                  readOnly
                  onChange={(e) =>
                    handleItemChange(index, "unitPrice", e.target.value)
                  }
                  className="bg-gray-200 border border-gray-300 text-gray-900 text-base text-right rounded-md focus:ring-primary-600 focus:border-primary-600 block p-1"
                  style={{
                    width :  "140px",
                    marginLeft:"-75px"
                  }}
                />
              </td>

              <td className=" flex justify-end p-2" style={{marginLeft:""}}>
                {formatNumber(item.total)} {/* Assuming item.total is a number */}
                {/* {Number(product.total).toLocaleString(undefined, { minimumFractionDigits: 5, maximumFractionDigits: 5 })} */}
              </td>

            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex justify-end mt-4">
        <div className="w-1/4">
          <div className="flex justify-between mb-1">
            <span>Subtotal:</span>
            <span>{formatNumber(subtotal)}</span>
          </div>
          <div className="flex justify-between mb-1">
            <span>Tax (%):</span>
            <input
                type="text"
                placeholder="0"
                value={tax}
                onChange={(e) => handleTaxChange(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-right text-base rounded-md focus:ring-primary-600 focus:border-primary-600 w-1/2 p-1"
              />

          </div>
          <div className="flex justify-between mb-1">
            <span>Fees/Discounts:</span>
            <input
              type="text"
              placeholder="0"
              value={feesOrDiscounts}
              onChange={(e) => handleFeesChange(e.target.value)}
              onFocus={onFocus}
              onBlur={onBlur}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-right text-base rounded-md focus:ring-primary-600 focus:border-primary-600 w-1/2 p-1"
            />
          </div>
          <div className="flex justify-between font-semibold mt-2 ">
            <span>Total:</span>
            {/* <span>{formatNumber(total)}</span> */}
            <span>{Number(total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InvoiceItems;  


