
import React, { useEffect } from "react";
import { useState } from "react";
import { FaEnvelope, FaLock, FaSignInAlt } from "react-icons/fa";
// import img1 from "../../assets/images/Login.jpg"
// import img2 from "../../assets/images/snapedit_1701426729284.png";
import { IoPerson } from "react-icons/io5";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup"
import Header from "../Headerside";
import { RiLoginCircleFill } from "react-icons/ri";
import {  FaEye, FaEyeSlash } from 'react-icons/fa';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import App from "../../App";
import { MdBusinessCenter } from "react-icons/md";
import CloseIcon from '@mui/icons-material/Close';
import { FaArrowRightLong } from "react-icons/fa6";
// import { FaXmark } from 'react-icons/fa6'
import config  from "../../config/config"

const UserLoginPage = ({ onLogin , handleSignUpClick  ,handleShowOTP , openForgotPassword}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [domainError, setDomainError] = useState("");

  
  const [formData, setFormData] = useState({
    email: "",
  });
  const [user , setUser] = useState({contact_name : "" , contact_email : ""})
  const [isModalOpen, setIsModalOpen] = useState(false);
  
  const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;

  const [passwordVisible, setPasswordVisible] = useState(false);


  const [showSignUp , setShowSignUp] = useState(false)

  const handleLocalSignUpClick =()=>{
    setShowSignUp(!showSignUp)
  }

  const handleSignOutModel=()=>{
    setIsModalOpen(!isModalOpen)
    window.location.reload();
}
const [realEmail, setRealEmail] = useState("");
const formik = useFormik({
  initialValues: {
    email: "",
  },
  validationSchema: Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .test({
        name: 'custom-email-validation',
        test: (value) => {
          return /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/.test(value);
        },
        message: 'Invalid email address',
      }),
  }),
  initialTouched: { 
    email: true,
  },
  onSubmit: (values) => {
    console.log("Submitting real email:", realEmail);

  },
});

const handleInputChange = (e) => {
  const { name, value } = e.target;
  const trimmedValue = value.trim();
  setRealEmail(trimmedValue);
  formik.setFieldValue(name, trimmedValue);
  console.log("Trimmed real email:", trimmedValue);
};
const handleBlur = (e) => {
  const { name } = e.target;
  const formattedValue = formatEmail(realEmail);
  formik.setFieldValue(name, formattedValue);
  console.log("EormattedValue email:", formattedValue);
};
const formatEmail = (email) => {
  if (!email) { return ''; }
  if (!email.includes("@")) return email;
  const [localPart, domain] = email.split('@');
    const maskedLocalPart = localPart.slice(0, 2) + "*****";
    const domainTLD = domain.slice(domain.lastIndexOf('.'));
    return `${maskedLocalPart}${domainTLD}`;
};

const handleLoginAsOwner = async() => {
  try {
    const response = await axios.post(`${baseURL}/${btoa("login/loginasou")}` , {

      loginAs : 2,
      // email: formik.values.email
      email: realEmail
      // userotp1: otpValues[0] || "",
      // userotp2: otpValues[1] || "",
      // userotp3: otpValues[2] || "",
      // userotp4: otpValues[3] || "",
      // userotp5: otpValues[4] || "",
      // userotp6: otpValues[5] || "",
    });

    console.log('OTP Response foe owner', response);

    setUser({contact_name : response.data.loggedin[0].contact_name , 
            contact_email : response.data.loggedin[0].contact_email})
    
            localStorage.setItem("userName" , response.data.loggedin[0].contact_name)
            localStorage.setItem("userEmail" , response.data.loggedin[0].contact_email)


    window.location.reload()
    
    localStorage.setItem('user_role' , response.data.loggedin[0].user_role);
    localStorage.setItem('id' , response.data.loggedin[0].id);
    localStorage.setItem('status' , 'true');
    localStorage.setItem("token" , response.data.token)
    localStorage.setItem("userName" , response.data.loggedin[0].contact_name)
    localStorage.setItem("userEmail" , response.data.loggedin[0].contact_email)

    if(response.data.loggedin[0].user_role === '1'){
      localStorage.setItem("user_id", response.data.loggedin[0].id)
    }
    else if(response.data.loggedin[0].user_role === "2"){
      localStorage.setItem("business_id", response.data.loggedin[0].business_id)
        localStorage.setItem("user_id", response.data.loggedin[0].owner_id)
    }else if(response.data.loggedin[0].user_role === "3"){
      localStorage.setItem("business_id", response.data.loggedin[0].business_id)
        localStorage.setItem("user_id", response.data.loggedin[0].manager_id)
    }
    // setIsModalOpen(false); 
  }
   catch (error) {
    console.log("error posting details", error);
  }
};


const handleLoginAsManager = async() => {
  try {
    const response = await axios.post(`${baseURL}/${btoa("login/loginasou")}`, {

      loginAs : 3,
      // email: formik.values.email
      email: realEmail,
      // userotp1: otpValues[0] || "",
      // userotp2: otpValues[1] || "",
      // userotp3: otpValues[2] || "",
      // userotp4: otpValues[3] || "",
      // userotp5: otpValues[4] || "",
      // userotp6: otpValues[5] || "",
    });

    console.log('OTP Response', response);

    setUser({contact_name : response.data.loggedin[0].contact_name , 
            contact_email : response.data.loggedin[0].contact_email})
    
            localStorage.setItem("userName" , response.data.loggedin[0].contact_name)
            localStorage.setItem("userEmail" , response.data.loggedin[0].contact_email)


    window.location.reload()
    
    localStorage.setItem('user_role' , response.data.loggedin[0].user_role);
    localStorage.setItem('id' , response.data.loggedin[0].id);
    localStorage.setItem('status' , 'true');
    localStorage.setItem("token" , response.data.token)
    localStorage.setItem("userName" , response.data.loggedin[0].contact_name)
    localStorage.setItem("userEmail" , response.data.loggedin[0].contact_email)

    if(response.data.loggedin[0].user_role === '1'){
      localStorage.setItem("user_id", response.data.loggedin[0].id)
    }
    else if(response.data.loggedin[0].user_role === "2"){
      localStorage.setItem("business_id", response.data.loggedin[0].business_id)
        localStorage.setItem("user_id", response.data.loggedin[0].owner_id)
    }else if(response.data.loggedin[0].user_role === "3"){
      localStorage.setItem("business_id", response.data.loggedin[0].business_id)
        localStorage.setItem("user_id", response.data.loggedin[0].manager_id)
    }
    // setIsModalOpen(false); 
  }
   catch (error) {
    console.log("error posting details", error);
  }
};


 


  const CustomTooltip = ({ content, styles }) => {
    return (
      <div
        style={{
          display: content ? "block" : "none",
          position: "absolute",
          backgroundColor: "#ffffff",
          color: "#fff",
          padding: "5px",
          borderRadius: "4px",
          border: `1px solid ${styles.borderColor || "red"}`,
          marginTop: "-35px",
          marginLeft: "175px",
          marginRight: "auto",
          ...styles,
        }}
      >
        <div
          style={{
            position: "absolute",
            bottom: "-7px",
            left: "50%",
            marginLeft: "-10px",
            width: "0",
            height: "0",
            borderLeft: "7px solid transparent",
            borderRight: "7px solid transparent",
            borderTop: `7px solid ${styles.arrowStyle?.borderTopColor || "red"}`,
          }}
        ></div>
        <p className="text-xs text-red-500">{content}</p>
      </div>
    );
  };
  
  


  let navigate = useNavigate();

  const handleForgotPassword = () => {
    openForgotPassword()
  };

 

  const handleLoginClick = async (e) => {
    e.preventDefault()

    // if (!validateEmail(formik.values.email)) {
    if (!validateEmail(realEmail)) {
      setEmailError("Email / Password is invalid ");
      return;
    }
    if(password === ""){
      setEmailError('Please enter password')
    }
    if(formik.values.domainName === ""){
      setDomainError('Please enter domainName')
    }
    // setEmailError("");
    console.log("email:", realEmail, "password:", password , "domainName :" , formik.values.domainName);

    // onLogin( formik.values.email, password);

    try{
      if(realEmail && password && formik.values.domainName){

      const response = await axios.post(`${baseURL}${btoa("login/managerlogin")}` , {
        submit : "true" , email: realEmail, password : password ,domainName : formik.values.domainName
        
      })
        if(response.data.login_status === true && response.data.userStatus === 4){
          setIsModalOpen(!isModalOpen)
        }
      console.log("login response :" , response);
     
      localStorage.setItem("userStatus" , response.data.userStatus)
      localStorage.setItem("userOtp" , response.data.otp)
      const userData = response.data 
      
      // console.log("userData" ,userData);
      // console.log("status" ,userData.login_status);
      // console.log("token" ,userData.token);
  
      if (response.data.otpSentStatus === true &&  response.data.oldIpAddress === false) {
        // alert("true")
        handleShowOTP(true);
        // onLogin(formik.values.email, password);
        onLogin(realEmail, password);
        // toast.success('Login successful!', {
        //   position: 'top-right',
        //   autoClose: 3000, // 3 seconds
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        // });
        // <Header contact_email={userData.contact_email} contact_name={userData.contact_name}  />
        
      } 
      if(response.data.oldIpAddress === true && response.data.selectUserRole === "twoOrThree"){
        setIsModalOpen(!isModalOpen)
        return
      }
      if(response.data.oldIpAddress === true && response.data.userStatus === 4){
        setIsModalOpen(!isModalOpen)
        return 
      }

      const token = userData.token
      // localStorage.setItem("token", token)
      axios.defaults.headers.common['Authorization'] = `Bearer ${userData.token}`

      if(response.data.oldIpAddress === true && response.data.userStatus !== 4){
         setUser({contact_name : response.data.loggedin[0].contact_name , 
          contact_email : response.data.loggedin[0].contact_email})
            window.location.reload()
            localStorage.setItem('user_role' , response.data.loggedin[0].user_role);
            localStorage.setItem('id' , response.data.loggedin[0].id);
            localStorage.setItem('status' , 'true');
            localStorage.setItem("token" , response.data.token)
            localStorage.setItem("userName" , response.data.loggedin[0].contact_name)
            localStorage.setItem("userEmail" , response.data.loggedin[0].contact_email)

            if(response.data.loggedin[0].user_role === '1'){
              localStorage.setItem("user_id", response.data.loggedin[0].id)
            }
            else if(response.data.loggedin[0].user_role === "2"){
              localStorage.setItem("business_id", response.data.loggedin[0].business_id)
                localStorage.setItem("user_id", response.data.loggedin[0].owner_id)
            }else if(response.data.loggedin[0].user_role === "3"){
              localStorage.setItem("business_id", response.data.loggedin[0].business_id)
                localStorage.setItem("user_id", response.data.loggedin[0].manager_id)
            }
      }
       
    }
      navigate("/")
    }
    catch(error){
      console.log("error posting details" , error);
      if (error.response.data.emailPassStatus === "Email or password is not correct") {

        setEmailError("Email / Password is invalid")
         
      } 
      else if (error.response.data.domainStatus === "Domain name does not exist") {
        toast.error("Invalid Domain Name" , {autoClose : 2000})
        setEmailError("")
      } 
      else if(error.response.data.domainStatus === "Domain name does not exist" && error.response.data.login_status === false){
        setEmailError("Email / Password is invalid ")
      }
      // if (error.response.data.login_status === false && error.response.data.domainStatus === "Domain name does not exist") {
      //   setEmailError("Email / Password is invalid ")
      //   toast.error("Invalid Domain Name" , {autoClose : 2000})
      //   return
      // } 
    }
  };

  const validateEmail =(email)=>{
    const emailRegex = /^.+@.+\..+$/
    return emailRegex.test(email)
  }

  const showTooltip = () => {

    const isInvalidEmail = !validateEmail(realEmail);
    return (
      formik.touched.email &&
      realEmail.length > 0 &&
      isInvalidEmail && {
        borderColor: "red",
        arrowStyle: {
          borderTopColor: "red",
        },
      }
    );
  };

  const handleInputChangeDomain = (e) => {
    const { name, value } = e.target;
    formik.handleChange(e);
    formik.setFieldValue(name, value.trim());
    setFormData((prevData) => ({
      ...prevData,
      [name]: value.trim(), 
    }));
    // console.log("Email value:", value.trim());
  };
  
  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   const formattedValue = formatEmail(value.trim()); // Format the email
  //   formik.setFieldValue(name, value.trim());
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     [name]: value.trim(),
  //   }));
  //   // Optionally, if you want to keep the formatted value displayed
  //   formik.setFieldValue('email', formattedValue);
  // };
  



  //   const formatEmail = (email) => {
  //   if (!email) { return ''; }
  //   const [localPart, domain] = email.split('@');
  //   const maskedLocalPart = localPart.slice(0, 2) + "*****";
  //   const domainTLD = domain.slice(domain.lastIndexOf('.'));
  //   return `${maskedLocalPart}${domainTLD}`;
  // };
  // const formatEmail = (email) => {
  //   const [localPart, domain] = email.split("@");
  //   if (!localPart || !domain) return email; // In case of invalid input
  //   if (localPart.length > 2) {
  //     const maskedPart = localPart.slice(0, 2) + "*****";
  //     return `${maskedPart}@${domain}`;
  //   }
  //   return email; // Return as is if local part is too short
  // };
  

  return (
    <div className="flex w-screen h-screen justify-center items-center flex-col">
      {!isModalOpen &&
      <div className="w-full h-screen bg-[url('./assets/images/LoginImage.png')] bg-no-repeat bg-center bg-[length:100%_100%]">

       <div className="fixed flex flex-col ml-[250px] md:mt-36 z-50">
         
            <div>
              <h2 className="text-4xl  font-light tracking-widest text-black-700">
                Welcome to InvoiceFile
              </h2>
            </div>
         
          <div>
            <p className="text-l mt-10 text-gray-500 tracking-widest font-sans ">
              USER LOGIN
            </p>
            <form className="mt-4" onSubmit={formik.handleSubmit}>
              <div className="rounded-md">
              {showTooltip() && (
                  <CustomTooltip content="Invalid email address" styles={showTooltip()} />
                )}
                <div className="flex items-center">
                  <div className="relative">
                    <IoPerson
                      className="text-gray-500 absolute top-1/2 transform -translate-y-1/2 left-3"
                      style={{ color: "#20beca", fontSize: "18px" }}
                    />
                    <input
                      id="email"
                      name="email"
                      value={formik.values.email}
                      onChange={(e) => {
                        handleInputChange(e);
                        // formik.setFieldValue('email', e.target.value.trim());
                      }}
                      onBlur={handleBlur}
                      type="email"
                      required
                      className={`input-field ${
                        showTooltip() ? "border-red-500" : "border-gray-300"
                      }`}
                      placeholder="Email"
                      style={{
                        borderRadius: "5px",
                        border: "2px solid grey",
                        width: "300px",
                        height: "38px",
                        paddingLeft: "40px",
                      }}
                    />
                  </div>
                </div>
                
                <div className="flex items-center ">
                  <div className="relative mt-5">
                    <FaLock
                      className="text-gray-500 absolute top-1/2 transform -translate-y-1/2 left-3"
                      style={{ color: "#20beca" }}
                    />
                    <input
                      id="password"
                      name="password"
                      type={passwordVisible ? 'text' : 'password'}
                      value={password}
                      onChange={(e) => {
                        // Ensure only numeric values are entered
                      
                        // const sanitizedValue = e.target.value.replace(/[^0-9]/g, '');
                        const sanitizedValue = e.target.value.replace(/[^a-zA-Z0-9]/g, '');

                        // Ensure only six digits are considered
                        const truncatedValue = sanitizedValue.slice(0, 6);
                        setPassword(truncatedValue);
                      }}
                      required
                     
                      className="input-field"
                      placeholder="Password"
                      style={{
                        borderRadius: "5px",
                        border: "2px solid grey",
                        width: "300px",
                        height: "38px",
                       
                        paddingLeft: "40px",
                      }}
                    />
                         {password && password.length > 0 &&(
                            <div
                              className="absolute top-1/2 transform -translate-y-1/2 right-3 cursor-pointer"
                              onClick={() => setPasswordVisible(!passwordVisible)}
                            >
                              {passwordVisible ? <FaEye /> : <FaEyeSlash /> }
                            </div>
                          )}
                  </div>
                </div>
                {emailError && <div style={{color:"red"}}>{emailError}</div>}
              </div>

              <div className="flex items-center">
                  <div className="relative  mt-5">
                    <MdBusinessCenter
                      className="text-gray-500 absolute top-1/2 transform -translate-y-1/2 left-3"
                      style={{ color: "#20beca", fontSize: "20px" }}
                    />
                    <input
                      id="domainName"
                      name="domainName"
                      value={formik.values.domainName}
                      onChange={(e) => {
                        handleInputChangeDomain(e);
                        formik.setFieldValue('domainName', e.target.value);
                      }}
                      onBlur={formik.handleBlur}
                      type="text"
                      required
                      className={`input-field ${
                         "border-gray-300"
                      }`}
                      placeholder="Enter Domain Name"
                      
                      style={{
                        borderRadius: "5px",
                        border: "2px solid grey",
                        width: "300px",
                        height: "38px",
                        paddingLeft: "40px",
                      }} 
                    />
                    {domainError && <div style={{color:'red'}}>{domainError}</div>}
                  </div>
                </div>

              <div className="mt-2" onClick={handleForgotPassword}>
                <Link style={{ color: "grey", fontSize: "15px" }}>
                  Forgot Password ?
                </Link>
              </div>

              <div className="mt-5 flex ml-5" style={{ marginTop: "25px" }}>

                <button
                  type="submit"
                  className="group relative shadow-2xl shadow-[#20beca] w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  style={{
                    width: "120px",
                    borderRadius: "25px",
                    fontWeight: "bold",
                    letterSpacing: "1px",
                    backgroundColor: "#20beca",
                    height: "36px",
                    alignItems: "center",
                  }}
                  onClick={handleLoginClick}
                >
                  <div
                    style={{
                      position: "absolute",
                      left: "85px", // Adjust as needed for the positioning of the circle
                      top: "50%",
                      transform: "translateY(-50%)",
                      width: "30px",
                      height: "30px",
                      borderRadius: "50%",
                      backgroundColor: "#247eaf",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <FaEnvelope />
                  </div>
                  <span className="mr-7">LOGIN</span>
                </button>


                <button
                  type=""
                  className="ml-6 group relative shadow-2xl shadow-[#20beca] w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  style={{
                    width: "120px",
                    borderRadius: "25px",
                    fontWeight: "bold",
                    letterSpacing: "1px",
                    backgroundColor: "#20beca",
                    height: "36px",
                    alignItems: "center",
                  }}
                  onClick={()=>handleSignUpClick()}
                >
                  <div
                    style={{
                      position: "absolute",
                      left: "85px", // Adjust as needed for the positioning of the circle
                      top: "50%",
                      transform: "translateY(-50%)",
                      width: "30px",
                      height: "30px",
                      borderRadius: "50%",
                      backgroundColor: "#247eaf",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <RiLoginCircleFill />
                  </div>

                  <span className="mr-7" style={{fontSize:"15px"}}>SIGNUP</span>
                </button>

                
              </div>
            </form>
            
          </div>
        </div>
      </div>
}


                      {/* Modal for switching user */}

                      {isModalOpen && (
    // <div className="fixed inset-0 bg-gray-500 bg-opacity-50 z-50 flex items-center justify-center">
    <div className="w-full h-screen bg-[url('./assets/images/LoginImage.png')] bg-no-repeat bg-center bg-[length:100%_100%]">
    <div className="flex flex-row w-full h-full">
      {/* Left Half for Centered Content */}
      <div className="flex justify-center items-center w-1/2 h-full bg-white bg-opacity-0 p-6">
   <div className="flex flex-col justify-center items-center space-y-6 w-full max-w-xl bg-white rounded-xl p-8 mb-[100px] ">
     
     {/* Title */}
     <h1 className="text-[#3479E0] font-bold text-xl md:text-2xl mb-6 text-center">
       Please Select the User Role to Login
     </h1>
 
     {/* Owner Button */}
     <div className="flex flex-col items-center space-y-3 w-full">
       <button
         onClick={handleLoginAsOwner}
         className="hover:bg-green-500 border-[#3479E0] border-2 text-gray-900 w-full sm:w-[180px] flex justify-between px-6 py-3 text-sm font-semibold hover:border-transparent hover:shadow-lg hover:shadow-green-500/60 text-center hover:text-white rounded-lg focus:ring-4 focus:ring-primary-200 transition-all duration-300"
       >
         <span className="flex items-center justify-center">
           <span className="ml-6">OWNER</span>
           <span className="ml-5"><FaArrowRightLong size={20} /></span>
         </span>
       </button>
     </div>
       {/* Divider with "OR" */}
  <div className="flex items-center w-full ml-[50%]">
    <hr className="w-1/6 border-t border-gray-300" />
    <div
      className="mx-4 bg-gradient-to-r from-blue-500 to-indigo-500 text-white text-sm font-semibold py-1 px-3 rounded-full shadow-md transition-all duration-300"
    >
      OR
    </div>
    <hr className="w-1/6 border-t border-gray-300" />
  </div>
 
     {/* User Button */}
     <div className="flex flex-col items-center space-y-3 w-full">
       <button
         onClick={handleLoginAsManager}
         className="hover:bg-[#0044AB] border-[#3479E0] border-2 text-gray-900 w-full sm:w-[180px] flex justify-between px-6 py-3 text-sm font-semibold hover:border-transparent hover:shadow-lg hover:shadow-[#0044AB]/50 text-center hover:text-white rounded-lg focus:ring-4 focus:ring-primary-200 transition-all duration-300"
         style={{ marginTop: "-10px" }}
       >
         <span className="flex items-center justify-center">
           <span className="ml-6">USER</span>
           <span className="ml-7"><FaArrowRightLong size={20} /></span>
         </span>
       </button>
     </div>
 
     {/* Cancel Button */}
     {/* <div
       onClick={handleSignOutModel}
       className="flex justify-center items-center cursor-pointer text-red-500 mt-6 w-full text-center font-medium hover:text-red-700 transition-all duration-300"
     >
       Cancel
     </div> */}
   <div className="flex flex-col items-center w-full mt-6 space-y-6">
  {/* Divider with "OR" */}
  <div className="flex items-center w-full ml-[50%]">
    <hr className="w-1/6 border-t border-gray-300" />
    <div
      className="mx-4 bg-gradient-to-r from-blue-500 to-indigo-500 text-white text-sm font-semibold py-1 px-3 rounded-full shadow-md transition-all duration-300"
    >
      OR
    </div>
    <hr className="w-1/6 border-t border-gray-300" />
  </div>

  {/* Cancel Button */}
  <div className="flex flex-col items-center space-y-3 w-full">
    <button
      onClick={handleSignOutModel}
      className="hover:bg-red-500 border-gray-300 border-2 text-gray-900 w-full sm:w-[180px] flex justify-center px-6 py-[10px] text-sm font-semibold hover:border-transparent hover:shadow-lg hover:shadow-red/50 text-center hover:text-white rounded-lg focus:ring-4 focus:ring-red-200 transition-all duration-300"
    >
      <span className="flex items-center justify-center">
        CANCEL
      </span>
      <span className="ml-7"><CloseIcon size={14} /></span>

    </button>
  </div>
</div>

   </div>
 </div>
 
 
      
      {/* Right Half (empty, just to keep the background) */}
      <div className="w-1/2 h-full"></div>
    </div>
  </div>
  )}

     </div>
  );
};

export default UserLoginPage;



