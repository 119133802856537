import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import config from "../../config/config";
import axios from 'axios';
import dayjs from 'dayjs';
import EditEndTimePickerViews from './timepickers/EditEndTimePicker';
import EditStartTimePickerViews from './timepickers/EditStartTimePicker';
import { FaCalendarAlt } from 'react-icons/fa';

const EditNotification = () => {
  const baseURL = process.env.NODE_ENV === "production"
    ? config.production.baseURL
    : config.development.baseURL;
  const { id } = useParams();
  const user_id = localStorage.getItem("user_id");
  const [selectedBusiness, setSelectedBusiness] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [scrollingMessage, setScrollingMessage] = useState('');
  const [businessDetails, setBusinessDetails] = useState([]);
  const [notificationType, setNotificationType] = useState("");
  const [isNotificationTypeReadOnly, setIsNotificationTypeReadOnly] = useState(false);
  const [deploymentStartDate, setDeploymentStartDate] = useState(null);
  const [startTime, setStartTime] = useState(null);  // Initialize as null
  const [endTime, setEndTime] = useState(null);  // Initialize as null
  const navigate = useNavigate();

  const formatDateForApi = (date) => {
    if (!date) return '';
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const parseDate = (dateString) => {
    const [year, month, day] = dateString.split('-');
    return new Date(year, month - 1, day);
  };

  const handleStartTimeChange = (timeString) => {
    if (dayjs(timeString, 'hh:mm A', true).isValid()) {
      setStartTime(timeString);
    } else {
      console.error("Invalid start time", timeString);
    }
  };

  const handleEndTimeChange = (timeString) => {
    if (dayjs(timeString, 'hh:mm A', true).isValid()) {
      setEndTime(timeString);
    } else {
      console.error("Invalid end time", timeString);
    }
  };

  useEffect(() => {
    const fetchBusinessDetails = async () => {
      try {
        const response = await axios.get(`${baseURL}/${btoa("business/view")}`);
        setBusinessDetails(response.data.business_data);
      } catch (error) {
        console.error("Error fetching business details", error);
      }
    };
    fetchBusinessDetails();
  }, [baseURL]);

  useEffect(() => {
    const fetchScrollingMessage = async () => {
      try {
        const response = await axios.get(`${baseURL}/${btoa("scrollingmsg/scrollingmsg")}/${btoa(id)}`);
        const data = response.data.scrollingmsg_data[0];
        console.log("Fetched data:", data);

        const fromDandt = dayjs(data.from_dandt, 'YYYY-MM-DD HH:mm:ss', true);
        const toDandt = dayjs(data.to_dandt, 'YYYY-MM-DD HH:mm:ss', true);

        if (!fromDandt.isValid() || !toDandt.isValid()) {
          throw new Error("Invalid date format");
        }

        setSelectedBusiness(data.business_id);
        setStartDate(dayjs(data.start_date).toDate());
        setEndDate(dayjs(data.end_date).toDate());
        setDeploymentStartDate(fromDandt.toDate());
        setStartTime(fromDandt.format('hh:mm A'));  // Update state with time value
        setEndTime(toDandt.format('hh:mm A'));  // Update state with time value
        setScrollingMessage(data.message);
        setNotificationType(data.mesg_type);
      } catch (error) {
        console.error("Error fetching scrolling message by id", error);
      }
    };
    fetchScrollingMessage();
  }, [id, baseURL]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      businessId: selectedBusiness,
      userId: user_id,
      startDate: formatDateForApi(startDate),
      endDate: formatDateForApi(endDate),
      message: scrollingMessage,
      userName: localStorage.getItem("userName"),
      rowId: id,
      notificationType,
      fromDate: formatDateForApi(deploymentStartDate),
      fromTime: startTime,
      toTime: endTime
    };
    console.log("Updating  data:", data);
    try {
      const response = await axios.put(`${baseURL}/${btoa("scrollingmsg/update")}`, data);
      console.log("update response" , response);
      toast.success("Message updated successfully", { autoClose: 2500 });
      navigate("/notifications");
    } catch (error) {
      console.error("Error updating scrolling message", error);
      toast.error("Error updating message", { autoClose: 2500 });
    }
  };

  useEffect(() => {
    if (selectedBusiness !== "0" && selectedBusiness !== "") {
      setNotificationType("Info");
      setIsNotificationTypeReadOnly(true);
    } else {
      setNotificationType("");
      setIsNotificationTypeReadOnly(false);
    }
  }, [selectedBusiness]);

  return (
    <div className="mt-8 flex justify-center">
      <form onSubmit={handleSubmit} className="bg-white rounded-lg overflow-hidden p-6">
        <div className='flex flex-row space-x-4 w-full'>
          
        <div className="mb-4 w-full">
            <label htmlFor="notification" className="block text-gray-700 font-semibold mb-2">
              Notification type
            </label>
            <select
              id="notification"
              value={notificationType}
              onChange={(e) => setNotificationType(e.target.value)}
              className={`w-full px-3 py-2 border-none rounded-md shadow-lg ${isNotificationTypeReadOnly ? 'bg-gray-200' : ''}`}
              required
              style={{ boxShadow: '0 0 8px rgba(0, 0, 0, 0.2)' }}
              disabled={isNotificationTypeReadOnly}
            >
              <option value="">--Select--</option>
              <option value="Info">Info</option>
              <option value="Release">Release</option>
            </select>
          </div>

          <div className="mb-4 w-full">
            <label htmlFor="business" className="block text-gray-700 font-semibold mb-2">
              Select Business*
            </label>
            <select
              id="business"
              value={selectedBusiness}
              onChange={(e) => setSelectedBusiness(e.target.value)}
              className="w-full px-3 py-2 border-none rounded-md shadow-lg"
              required
              style={{ boxShadow: '0 0 8px rgba(0, 0, 0, 0.2)' }}
            >
              <option value="">--Select--</option>
              <option value="0">All Business</option>
              {businessDetails.map((selected, index) => (
                <option value={selected.business_id} key={index}>{selected.business_name}</option>
              ))}
            </select>
          </div>

          
        </div>

        <div className="flex flex-row w-full space-x-4">
          <div className="mb-4">
            <label htmlFor="startDate" className="block text-gray-700 font-semibold mb-2">
              From
            </label>
            <div className="w-72 rounded-md" style={{ boxShadow: '0 0 8px rgba(0, 0, 0, 0.2)' }}>
              <DatePicker
                id="startDate"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                className="w-full px-3 py-2 border-none rounded-md"
                placeholderText="Select Start Date"
                dateFormat="MM/dd/yyyy"
              />
            </div>
          </div>
          <div className="mb-4">
            <label htmlFor="endDate" className="block text-gray-700 font-semibold mb-2">
              To
            </label>
            <div className="w-72 rounded-md" style={{ boxShadow: '0 0 8px rgba(0, 0, 0, 0.2)' }}>
              <DatePicker
                id="endDate"
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                className="w-full px-3 py-2 border-none rounded-md"
                placeholderText="Select End Date"
                dateFormat="MM/dd/yyyy"
              />
            </div>
          </div>
        </div>



        <div>
            {selectedBusiness === "0" && notificationType === "Release" && (
                        <div>
                          <h2 className="text-lg font-semibold text-gray-600 mb-1">Select Deployment Date and Time</h2>
                          <hr className="border-t border-gray-300 mb-1" />
                          
                            <div className="flex flex-row w-full space-x-4">
                              <div className="mb-4">
                                <label htmlFor="deploymentStartDate" className="block text-gray-700 font-semibold mb-2">
                                  Select date
                                </label>
                                <div
                                  className="w-72 flex items-center rounded-md bg-white shadow-sm relative"
                                  style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
                                >
                                  <DatePicker
                                    id="deploymentStartDate"
                                    selected={deploymentStartDate}
                                    minDate={new Date()}
                                    autoComplete='off'
                                    onChange={(date) => setDeploymentStartDate(date)}
                                    className="w-full px-3 py-2 border-none rounded-md focus:outline-none focus:ring-0"
                                    placeholderText="Select Start Date"
                                    dateFormat="MM/dd/yyyy"
                                  />
                                  <FaCalendarAlt className="text-gray-500 absolute right-3 pointer-events-none" />
                                </div>
                              </div>

                              <EditStartTimePickerViews
                                startTime={startTime}
                                onStartTimeChange={handleStartTimeChange}
                              />
                              <EditEndTimePickerViews
                                endTime={endTime}
                                onEndTimeChange={handleEndTimeChange}
                              />
                            </div>

                        </div>
              )}
          </div>

        <div className="mb-4">
          <label htmlFor="scrollingMessage" className="block text-gray-700 font-semibold mb-2">
            Scrolling Message
          </label>
          <textarea
            id="scrollingMessage"
            value={scrollingMessage}
            onChange={(e) => setScrollingMessage(e.target.value)}
            className="w-full px-3 py-2 border-none rounded-md shadow-lg"
            rows="4"
            style={{ boxShadow: '0 0 8px rgba(0, 0, 0, 0.2)' }}
          />
        </div>

        <div className="flex justify-end space-x-4">
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded-md shadow-lg hover:bg-blue-600"
            style={{ boxShadow: '0 0 8px rgba(0, 0, 0, 0.2)' }}
          >
            Update
          </button>
          <button
            type="button"
            onClick={() => navigate("/notifications")}
            className="bg-gray-500 text-white px-4 py-2 rounded-md shadow-lg hover:bg-gray-600"
            style={{ boxShadow: '0 0 8px rgba(0, 0, 0, 0.2)' }}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditNotification;
