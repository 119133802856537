import React from 'react';
import { FaEnvelope, FaLock, FaSignInAlt } from 'react-icons/fa';
// import img1 from "../../assets/images/Login.jpg"
import img2  from  "../../assets/images/Loginold.jpg"
import { IoPerson } from "react-icons/io5";
import { useNavigate ,Link } from 'react-router-dom';
import { MdBusinessCenter } from "react-icons/md";
import { MdLockReset } from "react-icons/md";
import { GiCancel } from "react-icons/gi";
import axios from 'axios';
import { useState } from 'react';
import { toast } from 'react-toastify';
import config  from "../../config/config"

const ForgotPassword = ({handleForgotCancelClick}) => {

  const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;
    const divStyle = {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        height: '100vh',
        width: "100vw",
        display: 'flex',
        backgroundImage: `url(${img2})`,
        backgroundSize: '100% 100%',
        backgroundPosition: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 999,
      };

     
      let navigate = useNavigate()

      const [formData, setFormData] = useState({
        email: "",
        domainName: ""
      });

      const handleEmailChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      };

      const handleDomainNameChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name] : value,
        }));
      };




      const handleChangePassword= async ()=>{
        
        console.log("reset password data" , formData);
        
        try{
          const response = await axios.post(`${baseURL}/${btoa("login/forgotpassword")}` ,{
            email : formData.email,
            domainName : formData.domainName
          })
          console.log(response);
          if(response.data.updateStatus === "Password updated successfully"){
            toast.success("New Password has been sent to you registered mobile number" , {autoClose : 2000})
          }
          handleForgotCancelClick()

        }catch(error){
            console.log("error resetting password");
            toast.error("Invalid email or domain name" ,  {autoClose : 2000})
        }
       
      }


    
  return (
    <div className="flex w-screen h-screen justify-center items-center flex-col">
    <div className="w-full h-screen bg-[url('./assets/images/LoginImage.png')] bg-no-repeat bg-center bg-[length:100%_100%]">

     <div className="fixed flex flex-col ml-[250px] md:mt-36 z-50">
       
          <div>
            <h2 className="text-4xl  font-light tracking-widest text-black-700">
              Welcome to InvoiceFile
            </h2>
          </div>
       
        <div>
          <p className="text-l mt-10 text-gray-500 tracking-widest font-sans ">
           FORGOT PASSWORD
          </p>
        
            <div className="rounded-md mt-3">
            {/* {showTooltip() && (
                <CustomTooltip content="Invalid email address" styles={showTooltip()} />
              )} */}
              <div className="flex items-center">
                <div className="relative">
                  <IoPerson
                    className="text-gray-500 absolute top-1/2 transform -translate-y-1/2 left-3"
                    style={{ color: "#20beca", fontSize: "18px" }}
                  />
                  <input
                    id="email"
                    name="email"
                    type="email"
                    onChange={handleEmailChange}
                    required
                    // className={`input-field ${
                    //   showTooltip() ? "border-red-500" : "border-gray-300"
                    // }`}
                    placeholder="Email"
                    
                    style={{
                      borderRadius: "5px",
                      
                      border: "2px solid grey",
                      width: "300px",
                      height: "38px",
                      paddingLeft: "40px",
                    }}
                  />
                </div>
              </div>
              
             
            </div>

            <div className="flex items-center">
                <div className="relative  mt-5">
                  <MdBusinessCenter
                    className="text-gray-500 absolute top-1/2 transform -translate-y-1/2 left-3"
                    style={{ color: "#20beca", fontSize: "20px" }}
                  />
                  <input
                    id="domainName"
                    name="domainName"
                    type="text"
                    required
                    onChange={handleDomainNameChange}
                    className={`input-field ${
                       "border-gray-300"
                    }`}
                    placeholder="Enter Domain Name"
                    
                    style={{
                      borderRadius: "5px",
                      border: "2px solid grey",
                      width: "300px",
                      height: "38px",
                      paddingLeft: "40px",
                    }} 
                  />
                </div>
              </div>

            {/* <div className="mt-2" onClick={handleForgotPassword}>
              <Link style={{ color: "grey", fontSize: "15px" }}>
                Forgot Password ?
              </Link>
            </div> */}

            <div className="mt-5 flex ml-5" style={{ marginTop: "25px" }}>

              <button
                // type="submit"
                className="group relative shadow-2xl shadow-[#20beca] w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                style={{
                  width: "120px",
                  borderRadius: "25px",
                  fontWeight: "bold",
                  letterSpacing: "1px",
                  backgroundColor: "#20beca",
                  height: "36px",
                  alignItems: "center",
                }}
                onClick={handleChangePassword}
              >
                <div
                  style={{
                    position: "absolute",
                    left: "85px", // Adjust as needed for the positioning of the circle
                    top: "50%",
                    transform: "translateY(-50%)",
                    width: "27px",
                    height: "27px",
                    borderRadius: "50%",
                    backgroundColor: "#247eaf",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                 <MdLockReset size={20}/>
                </div>
                <span className="mr-7">RESET</span>
              </button>


              <button
                type=""
                className="ml-6 group relative shadow-2xl shadow-[#20beca] w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                style={{
                  width: "120px",
                  borderRadius: "25px",
                  fontWeight: "bold",
                  letterSpacing: "1px",
                  backgroundColor: "#20beca",
                  height: "36px",
                  alignItems: "center",
                }}
                onClick={handleForgotCancelClick}
              >
                <div
                  style={{
                    position: "absolute",
                    left: "85px", // Adjust as needed for the positioning of the circle
                    top: "50%",
                    transform: "translateY(-50%)",
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    backgroundColor: "#247eaf",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <GiCancel  />
                </div>

                <span className="mr-7" style={{fontSize:"15px"}}>CANCEL</span>
              </button>

            </div>
          
          
        </div>
      </div>
    </div>
  </div>

               
  )
};

export default ForgotPassword;

