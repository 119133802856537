import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { MdOutlinePhoneInTalk } from 'react-icons/md';
import axios from 'axios';
import { toast } from 'react-toastify';
import config from '../../config/config';
import smsIcon from '../../../src/assets/images/smsIcon.png'
import { Tooltip } from "react-tippy"; 

const EditOwner = ({ contactDetails , businessId }) => {
  console.log("business id" , businessId)
  console.log("contactDetails" , contactDetails)
  const navigate = useNavigate();
  const [addNewOwner , setAddNewOwner] = useState(false)
  const [contactNameError, setContactNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [inputPhoneError, setInputPhoneError] = useState("");
  const [invalidPhoneNumber , setInvalidPhoneNumber] = useState("");
  const [formData, setFormData] = useState({
    businessId: businessId,
    contactName: '',
    contactEmail: '',
    contactPhone: '',
  });

  const baseURL = process.env.NODE_ENV === 'production' ? config.production.baseURL : config.development.baseURL;
  const { id } = useParams();

  const handleOwnerDetails = async () => {
    const payload = {
      businessId: contactDetails[0].business_id,
      adminId : localStorage.getItem("id"),
      contactPersonName: formData.contactName,
      contactPersonEmail: formData.contactEmail,
      contactPersonPhoneNumber: formData.contactPhone,
    };
    console.log("payload" , payload)

    try {
      const response = await axios.post(`${baseURL}/${btoa('owner/create')}`, payload);
      console.log("add new owner / update" , response)
      navigate('/adminbusinessdashboard');
      toast.success('Owner details added successfully', { autoClose: 2500 });
    } catch (error) {
      console.log("add owner error" , error)
      if (error.response.data.userStatus === 'Contact number already exist' && error.response.data.dataSavingStatus === false) {
        toast.error('Contact number is already registered with another user', { autoClose: 2000 });
      } else if (error.response.data.userStatus === 'Email already exist' && error.response.data.dataSavingStatus === false) {
        toast.error('Email is already registered with another user', { autoClose: 2000 });
      } else if (error.response.data.dataSavingStatus === false && error.response.data.sendPasswordStatus === false) {
        toast.error('Something went wrong while creating user', { autoClose: 2000 });
      }
    }
  };

  useEffect(() => {
    const fetchOwnerDetails = async () => {
      try {
        const response = await axios.get(`${baseURL}${btoa('owner/fetch')}/${btoa(id)}`);
        const data = response.data.owner_data[0];
        setFormData({
          ...formData,
          businessId: data.business_id,
          contactName: data.contact_name,
          contactEmail: data.contact_email,
          contactPhone: data.contact_no,
        });
      } catch (error) {
        console.error('Error fetching owner edit details', error);
      }
    };
    fetchOwnerDetails();
  }, [id, baseURL]);

  const CustomTooltip1 = ({ content, show, marginLeft }) => {
    return (
      <div
        style={{
          display: show ? "block" : "none",
          position: "absolute",
          backgroundColor: "#ffffff",
          color: "#fff",
          padding: "5px",
          borderRadius: "4px",
          border: "1px solid red",
          marginTop: "-35px",
          marginLeft: `${marginLeft}px`, // Use the provided marginLeft prop
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div
          style={{
            position: "absolute",
            bottom: "-7px",
            left: "50%",
            marginLeft: "-10px",
            width: "0",
            height: "0",
            borderLeft: "7px solid transparent",
            borderRight: "7px solid transparent",
            borderTop: "7px solid #f98080",
          }}
        ></div>
        <p className="text-xs text-red-500">{content}</p>
      </div>
    );
  };

  const capitalizeWords = (input) => {
    return input.charAt(0).toUpperCase() + input.slice(1);
    };

  const handleContactNameChange = (e) => {
    const { name, value } = e.target;
    let inputValue = value.replace(/[^a-zA-Z\s]/g, "");
    setFormData({
        ...formData,
        [name]: capitalizeWords(inputValue),
      });
      if (inputValue.replace(/\s/g, "").length > 0 && inputValue.length < 3) {
        setContactNameError(" Name must be at least 3 letters");
      } else {
        setContactNameError("");
      }
  };
  const validateEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };
  const handleEmailChange = (e) => {
    const { name, value } = e.target;
    const isValidEmail = validateEmail(value);
    setFormData({
      ...formData,
      [name]: value,
    });
    if (value.length > 0 && !isValidEmail) {
      setEmailError("Invalid email address");
    } else {
      setEmailError("");
    }

  };
  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    cleaned = cleaned.slice(0, 10);
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = (match[1] ? '+1 ' : '');
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return cleaned;
  }
  
  const handleInputPhoneChange = (e) => {
    const { name, value } = e.target;
    let inputValue = formatPhoneNumber(value);
    const containsNonZero = /[1-9]/.test(inputValue);
    const allZeros = /^0+$/.test(inputValue);
    if (inputValue.length > 0 && inputValue.length < 10) {
      setInvalidPhoneNumber("Invalid number");
    } else if (!containsNonZero || allZeros) {
      setInvalidPhoneNumber("Invalid number");
    } else {
      setInvalidPhoneNumber("valid");
    }
  
    setFormData({
      ...formData,
      [name]: inputValue,
    });
  };

  return (
      <div>
        {contactDetails?.map((contact, index) => (
          <div key={index} className="w-auto bg-white rounded-box mx-8 rounded-xl mt-16 mb-6">
            <div className="flex items-center">
              <MdOutlinePhoneInTalk className="text-gray-700 w-6 h-6 mr-2 mb-10" />
              <h2 className="mb-10 text-xl font-bold text-gray-900 underline underline-offset-8 decoration-gray-300">
                Edit Owner
              </h2>
            </div>

            <div className="grid gap-4 sm:grid-cols-3 sm:gap-6">

              <div className="w-full">
                <label
                  htmlFor={`contactName${index}`}
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Contact Person Name
                  {/* <span className="text-red-500 text-xl font-extrabold ml-1">*</span> */}
                </label>
                <input
                  className={`border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${"bg-gray-100"}`}
                  placeholder="Enter Name"
                  type="text"
                  id={`contactName${index}`}
                  name="contactName"
                  value={contact.contact_name}
                  readOnly
                />
              </div>

              <div className="w-full">
                <label
                  htmlFor={`contactEmail${index}`}
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Email
                  {/* <span className="text-red-500 text-xl font-extrabold ml-1">*</span> */}
                </label>
                <input
                  className={`border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${"bg-gray-100"}`}
                  placeholder="Enter Email"
                  type="email"
                  id={`contactEmail${index}`}
                  name="contactEmail"
                  value={contact.contact_email}
                  readOnly
                />
              </div>

              <div className="w-full">
                <label
                  htmlFor={`contactPhone${index}`}
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Phone Number
                  {/* <span className="text-red-500 text-xl font-extrabold ml-1">*</span> */}
                </label>
                <input
                  className={`border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${"bg-gray-100"}`}
                  placeholder="Enter Phone Number"
                  type="text"
                  id={`contactPhone${index}`}
                  name="contactPhone"
                  value={contact.contact_no}
                  readOnly
                />
              </div>
              
            </div>

            {/* Add New Owner button only for the last contact */}
            {index === contactDetails.length - 1 && (
              <div className="mt-6 flex justify-start">
                <button
                    className="flex items-center justify-center font-bold mr-3 w-55 border-transparent border-2 text-white bg-[#25caed] hover:scale-105 transition-transform duration-300 ease-in-out focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-base px-4 py-1.5 focus:outline-none"
                    type="button"
                    onClick={()=> setAddNewOwner(!addNewOwner)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={3}
                      stroke="currentColor"
                      className="w-5 h-5 mr-3"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 6v12m6-6H6"
                      />
                    </svg>
                    Add New Owner
                </button> 
              </div>
            )}
          </div>
        ))}

        {addNewOwner && (
          <div className="w-auto bg-white rounded-box  mx-8 rounded-xl mt-16 mb-6">
            <div className="flex items-center">
              <MdOutlinePhoneInTalk className="text-gray-700 w-6 h-6 mr-2 mb-10" />
              <h2 class="mb-10 text-xl font-bold text-gray-900 underline underline-offset-8 decoration-gray-300">
                Add New Owner
              </h2>
            </div>

            <div class="grid gap-4 sm:grid-cols-3 sm:gap-6">
              <div class="w-full">
                <label
                  htmlFor="contactName"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Contact Person Name
                  <span className="text-red-500 text-xl font-extrabold ml-1">*</span>
                </label>
                <CustomTooltip1
                  content={contactNameError}
                  show={!!contactNameError}
                  marginLeft={120}
                />

                <input
                  className={`border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${
                  "bg-white" 
                  }`}
                  placeholder="Enter Name"
                  type="text"
                  id="contactName"
                  name="contactName"
                  value={formData.contactName}
                  onChange={handleContactNameChange}
                  required
                
                />
              </div>

              <div class="w-full">
                <label
                  htmlFor="contactEmail"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Email
                  <span className="text-red-500 text-xl font-extrabold ml-1">*</span>
                </label>
                <CustomTooltip1 content={emailError} show={!!emailError}   marginLeft={206}/>

                <input
                  className={`border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${
                     "bg-white"
                  }`}
                  placeholder="Enter Email"
                  type="email"
                  id="contactEmail"
                  name="contactEmail"
                  value={formData.contactEmail}
                  onChange={handleEmailChange}
                  required
                
                />
              </div>

              <div class="w-full">
                <label
                  htmlFor="contactPersonPhoneNumber"
                  class="block mb-2 text-sm font-medium text-gray-900  "
                >
                  <div className="flex items-center">
      <span>Phone Number</span>
      <span className="text-red-500 text-xl font-extrabold ml-1">*</span>
      <Tooltip title="SMS will be sent" position="top" trigger="mouseenter">

      <img src={smsIcon} alt="SMS Icon" style={{ width: '30px', height: '20px', marginLeft: '8px' }} />
      </Tooltip>
    </div>

                </label>
                <CustomTooltip1
                  content={inputPhoneError}
                  show={!!inputPhoneError}
                  marginLeft={163}
                />
                 {invalidPhoneNumber === "Invalid number" && !inputPhoneError &&  (
                  <CustomTooltip1
                    content={"Invalid Number"}
                    show={"Invalid Number"}
                    marginLeft={163}
                    />
                  )}

                <input
                  className={`border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${
                     "bg-white" }`
                }
                  placeholder="Enter Phone Number"
                  type="text"
                  id="contactPhone"
                  name="contactPhone"
                  value={formData.contactPhone}
                  onChange={handleInputPhoneChange}
                  required
                 
                />
              </div>
            </div>



            <div className="flex justify-end space-x-3">
               
                   <div className='flex space-x-4'>
                  <button
                    onClick={()=> navigate("/adminbusinessdashboard")}
                    type="submit"
                    class="hover:bg-red-500 border-[#3479E0] border-2 text-black px-6 py-1.5 mt-4 sm:mt-6 text-sm font-semibold hover:border-transparent hover:shadow-lg hover:shadow-red-500/60 text-center hover:text-white  rounded-lg focus:ring-4 focus:ring-primary-200  hover:bg-primary-800"
                  >
                    Cancel
                  </button>

                  <button
                      onClick={handleOwnerDetails}
                    type="submit"
                    disabled={
                      contactNameError || 
                      emailError ||
                      inputPhoneError || invalidPhoneNumber === "Invalid number"
                  }
                    class="hover:bg-[#0044AB] border-[#3479E0] border-2 text-black px-6 py-1.5 mt-4 sm:mt-6 text-sm font-semibold hover:border-transparent hover:shadow-lg hover:shadow-[#0044AB]/50 text-center hover:text-white  rounded-lg focus:ring-4 focus:ring-primary-200  hover:bg-primary-800"
                    >
                    Save
                    </button>
                    </div>
           
            </div>

          </div>
        )}
    </div>
  );
};

export default EditOwner;
