import React from 'react'

const PayOuts = () => {
  return (
    <div>
      Payouts
    </div>
  )
}

export default PayOuts
