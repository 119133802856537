
import React, { useEffect } from "react";
import { useState } from "react";
import { FaEnvelope, FaLock, FaSignInAlt } from "react-icons/fa";
// import img1 from "../../assets/images/Login.jpg"
// import img2 from "../../assets/images/snapedit_1701426729284.png";
import { IoPerson } from "react-icons/io5";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup"
import Header from "../Headerside";
import { RiLoginCircleFill } from "react-icons/ri";
import {  FaEye, FaEyeSlash } from 'react-icons/fa';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import App from "../../App";
import { MdBusinessCenter } from "react-icons/md";
import config  from "../../config/config"


const CustomerPortalHome = ({ onLogin , handleSignUpClick  ,handleShowOTP , openForgotPassword}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [formData, setFormData] = useState({
    email: "",
  });

  const [passwordVisible, setPasswordVisible] = useState(false);


  const [showSignUp , setShowSignUp] = useState(false)

  const handleLocalSignUpClick =()=>{
    setShowSignUp(!showSignUp)
  }



  const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;


  const CustomTooltip = ({ content, styles }) => {
    return (
      <div
        style={{
          display: content ? "block" : "none",
          position: "absolute",
          backgroundColor: "#ffffff",
          color: "#fff",
          padding: "5px",
          borderRadius: "4px",
          border: `1px solid ${styles.borderColor || "red"}`,
          marginTop: "-35px",
          marginLeft: "175px",
          marginRight: "auto",
          ...styles,
        }}
      >
        <div
          style={{
            position: "absolute",
            bottom: "-7px",
            left: "50%",
            marginLeft: "-10px",
            width: "0",
            height: "0",
            borderLeft: "7px solid transparent",
            borderRight: "7px solid transparent",
            borderTop: `7px solid ${styles.arrowStyle?.borderTopColor || "red"}`,
          }}
        ></div>
        <p className="text-xs text-red-500">{content}</p>
      </div>
    );
  };
  

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email("Invalid email address")
        .test({
          name: 'custom-email-validation',
          test: (value) => {
            return /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/.test(value);
          },
          message: 'Invalid email address',
        }),
    }),
    initialTouched: { 
      email: true,
    },
  });

  const handleLogin = () => {

  };

  let navigate = useNavigate();

  const handleForgotPassword = () => {
    openForgotPassword()
  };

 

  const handleLoginClick = async (e) => {
    e.preventDefault()

    if (!validateEmail(formik.values.email)) {
      setEmailError("Email / Password is invalid ");
      return;
    }
    setEmailError("");

    console.log("email:", formik.values.email, "password:", password , "domainName :" , formik.values.domainName);

    // onLogin( formik.values.email, password);

    try{
      const response = await axios.post(`${baseURL}/${btoa("login/managerlogin")}` , {
        submit : "true" , email: formik.values.email , password : password ,
        domainName : 'Invoicefile', userStatus: 5     
      }
    )
      console.log("response :" , response);
      localStorage.setItem("userStatus" , response.data.userStatus)
      localStorage.setItem("userOtp" , response.data.otp)
     
      const userData = response.data 
      
      // console.log("userData" ,userData);
      // console.log("status" ,userData.login_status);
      // console.log("token" ,userData.token);
    
    
      if (userData.otpSentStatus === true) {
        // alert("true")
        handleShowOTP(true, true);
        onLogin(formik.values.email, password);
        // toast.success('Login successful!', {
        //   position: 'top-right',
        //   autoClose: 3000, // 3 seconds
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        // });
        // <Header contact_email={userData.contact_email} contact_name={userData.contact_name}  />
      } else {
        
        setEmailError("Email / Password is invalid ");
      }
     if(userData.oldIpAddress === true){
      localStorage.setItem('user_role' , response.data.userStatus);
      // window.location.reload()
      localStorage.setItem("token" , response.data.token)
      localStorage.setItem("userEmail" , response.data.loggedin[0].contact_email)
      // localStorage.setItem('customer_id', response.data.loggedin[0].customer_id )
      localStorage.setItem('customer_id', response.data.loggedin[0].customer_id )
      localStorage.setItem('userName', response.data.loggedin[0].contact_name )

      localStorage.setItem("business_id", response.data.loggedin[0].business_id)
      localStorage.setItem("contact_id", response.data.loggedin[0].contact_id)

      if(response.data.loggedin[0].userStatus === 5 ){
        localStorage.setItem("business_id", response.data.loggedin[0].business_id)
          localStorage.setItem("contact_id", response.data.loggedin[0].contact_id)
          localStorage.setItem('customer_id', response.data.loggedin[0].customer_id )
      }
      navigate("/")

     }
    
      const token = userData.token
      // localStorage.setItem("token", token)
      axios.defaults.headers.common['Authorization'] = `Bearer ${userData.token}`
      
      navigate("/")
    }
    catch(error){
      console.log("error posting details" , error);
      if (error.response.data.emailPassStatus === "Domain name exist, email or password is not correct") {
        setEmailError("Email / Password is invalid")
         
      } 
      else if (error.response.data.domainStatus === "Domain name does not exist") {
        toast.error("Invalid Domain Name" , {autoClose : 2000})
        setEmailError("")
      } 
      else if(error.response.data.domainStatus === "Domain name does not exist" && error.response.data.login_status === false){
        setEmailError("Email / Password is invalid ")
      }
      // if (error.response.data.login_status === false && error.response.data.domainStatus === "Domain name does not exist") {
      //   setEmailError("Email / Password is invalid ")
      //   toast.error("Invalid Domain Name" , {autoClose : 2000})
      //   return
      // } 
    }
  };

  const validateEmail =(email)=>{
    const emailRegex = /^.+@.+\..+$/
    return emailRegex.test(email)
  }

  const showTooltip = () => {
    const isInvalidEmail = !validateEmail(formik.values.email);
    return (
      formik.touched.email &&
      formik.values.email.length > 0 &&
      isInvalidEmail && {
        borderColor: "red",
        arrowStyle: {
          borderTopColor: "red",
        },
      }
    );
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    formik.handleChange(e);
    formik.setFieldValue(name, value.trim());
    setFormData((prevData) => ({
      ...prevData,
      [name]: value.trim(), 
    }));
    console.log("Email value:", value.trim());
  };

  return (
    <div className="flex w-screen h-screen justify-center items-center flex-col">
      <div className="w-full h-screen bg-[url('./assets/images/LoginImage.png')] bg-no-repeat bg-center bg-[length:100%_100%]">

       <div className="fixed flex flex-col ml-[250px] md:mt-36 z-50">
         
            <div>
              <h2 className="text-4xl  font-light tracking-widest text-black-700">
                Welcome to InvoiceFile
              </h2>
            </div>
         
          <div>
            <p className="text-l mt-10 text-gray-500 tracking-widest font-sans ">
              CUSTOMER LOGIN
            </p>
            <form className="mt-4">
              <div className="rounded-md">
              {showTooltip() && (
                  <CustomTooltip content="Invalid email address" styles={showTooltip()} />
                )}
                <div className="flex items-center">
                  <div className="relative">
                    <IoPerson
                      className="text-gray-500 absolute top-1/2 transform -translate-y-1/2 left-3"
                      style={{ color: "#20beca", fontSize: "18px" }}
                    />
                    <input
                      id="email"
                      name="email"
                      value={formik.values.email}
                      onChange={(e) => {
                        handleInputChange(e);
                        formik.setFieldValue('email', e.target.value.trim());
                      }}
                      onBlur={formik.handleBlur}
                      type="email"
                      required
                      className={`input-field ${
                        showTooltip() ? "border-red-500" : "border-gray-300"
                      }`}
                      placeholder="Email"
                      
                      style={{
                        borderRadius: "5px",
                        
                        border: "2px solid grey",
                        width: "300px",
                        height: "38px",
                        paddingLeft: "40px",
                      }}
                    />
                  </div>
                </div>
                
                <div className="flex items-center ">
                  <div className="relative mt-5">
                    <FaLock
                      className="text-gray-500 absolute top-1/2 transform -translate-y-1/2 left-3"
                      style={{ color: "#20beca" }}
                    />
                    <input
                      id="password"
                      name="password"
                      type={passwordVisible ? 'text' : 'password'}
                      value={password}
                      onChange={(e) => {
                        // Ensure only numeric values are entered
                        
                        // const sanitizedValue = e.target.value.replace(/[^0-9]/g, '');
                        const sanitizedValue = e.target.value.replace(/[^a-zA-Z0-9]/g, '');

                        // Ensure only six digits are considered
                        const truncatedValue = sanitizedValue.slice(0, 6);
                        setPassword(truncatedValue);
                      }}
                      required
                     
                      className="input-field"
                      placeholder="Password"
                      style={{
                        borderRadius: "5px",
                        border: "2px solid grey",
                        width: "300px",
                        height: "38px",
                       
                        paddingLeft: "40px",
                      }}
                    />
                         {password && password.length > 0 &&(
                            <div
                              className="absolute top-1/2 transform -translate-y-1/2 right-3 cursor-pointer"
                              onClick={() => setPasswordVisible(!passwordVisible)}
                            >
                              {passwordVisible ? <FaEye /> : <FaEyeSlash /> }
                            </div>
                          )}
                  </div>
                </div>
                {emailError && <div style={{color:"red"}}>{emailError}</div>}
              </div>

              {/* <div className="flex items-center">
                  <div className="relative  mt-5">
                    <MdBusinessCenter
                      className="text-gray-500 absolute top-1/2 transform -translate-y-1/2 left-3"
                      style={{ color: "#20beca", fontSize: "20px" }}
                    />
                    <input
                      id="domainName"
                      name="domainName"
                      value={formik.values.domainName}
                      onChange={(e) => {
                        handleInputChange(e);
                        formik.setFieldValue('domainName', e.target.value.trim());
                      }}
                      onBlur={formik.handleBlur}
                      type="text"
                      required
                      className={`input-field ${
                         "border-gray-300"
                      }`}
                      placeholder="Enter Domain Name"
                      
                      style={{
                        borderRadius: "5px",
                        border: "2px solid grey",
                        width: "300px",
                        height: "38px",
                        paddingLeft: "40px",
                      }} 
                    />
                  </div>
                </div> */}

              {/* <div className="mt-2" onClick={handleForgotPassword}>
                <Link style={{ color: "grey", fontSize: "15px" }}>
                  Forgot Password ?
                </Link>
              </div> */}

              <div className="mt-5 flex" style={{ marginTop: "25px" }}>

                <button
                  type="submit"
                  className="group relative shadow-2xl shadow-[#20beca] w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  style={{
                    width: "120px",
                    borderRadius: "25px",
                    fontWeight: "bold",
                    letterSpacing: "1px",
                    backgroundColor: "#20beca",
                    height: "36px",
                    alignItems: "center",
                  }}
                  onClick={handleLoginClick}
                >
                  <div
                    style={{
                      position: "absolute",
                      left: "85px", // Adjust as needed for the positioning of the circle
                      top: "50%",
                      transform: "translateY(-50%)",
                      width: "30px",
                      height: "30px",
                      borderRadius: "50%",
                      backgroundColor: "#247eaf",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <FaEnvelope />
                  </div>
                  <span className="mr-5">LOGIN</span>
                </button>

{/* 
                <button
                  type=""
                  className="ml-6 group relative shadow-2xl shadow-[#20beca] w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  style={{
                    width: "120px",
                    borderRadius: "25px",
                    fontWeight: "bold",
                    letterSpacing: "1px",
                    backgroundColor: "#20beca",
                    height: "36px",
                    alignItems: "center",
                  }}
                  onClick={()=>handleSignUpClick()}
                >
                  <div
                    style={{
                      position: "absolute",
                      left: "85px", // Adjust as needed for the positioning of the circle
                      top: "50%",
                      transform: "translateY(-50%)",
                      width: "30px",
                      height: "30px",
                      borderRadius: "50%",
                      backgroundColor: "#247eaf",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <RiLoginCircleFill />
                  </div>

                  <span className="mr-7" style={{fontSize:"15px"}}>SIGNUP</span>
                </button> */}

                
              </div>
            </form>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerPortalHome;



