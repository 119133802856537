import React from 'react'

const PayIns = () => {
  return (
    <div>
      PayIns
    </div>
  )
}

export default PayIns
