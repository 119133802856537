import React from 'react'

function Losses() {
  return (
    <div>
      
      Losses
    </div>
  )
}

export default Losses
