import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allPurchases : [],
};

const purchasesSlice = createSlice({
  name: "purchase",
  initialState,
  reducers: {
    addPurchases(state, action) {
    //   console.log("act", action.payload);
      if (Array.isArray(action.payload)) {
        const newPurchases = action.payload.filter(newPurchase =>
          !state.allPurchases.some(existingPurchases => existingPurchases.id === newPurchase.id)
        );
        state.allPurchases.push(...newPurchases);
      } else {
        if (!state.allPurchases.some(existingPurchases => existingPurchases.id === action.payload.id)) {
          state.allPurchases.push(action.payload);
        }
      }
    },
    deletePurchases(state, action) {
      state.allPurchases = state.allPurchases.filter(
        purchase => purchase.id !== action.payload
      );
    },
    deleteAllPurchases(state) {
        state.allPurchases = []; 
      }
  },
});

export const { addPurchases, deletePurchases , deleteAllPurchases } = purchasesSlice.actions;
export default purchasesSlice.reducer;
