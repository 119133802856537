// AddItemForm.js
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux"; // Import useNavigate
import { addProduct } from "../../actions/customerActions";
import axios from "axios";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { useTabs } from "../pages/TabContext";
import { FaArrowRightLong } from "react-icons/fa6";
import config  from "../../config/config"


function ViewProduct() {

  const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;
  const { changeTab } = useTabs();
  const [originalFormData, setOriginalFormData] = useState({});
  const [isFormModified, setIsFormModified] = useState(false);
  const [itemNameError, setItemNameError] = useState("");
  const [desError, setDesError] = useState("");
  const handleCancel = () => {
    // Change tab to 'items'
    navigate(`/products`); // Navigate to the 'items' route
  };
  const CustomTooltip1 = ({ content, show, marginLeft }) => {
    return (
      <div
        style={{
          display: show ? 'block' : 'none',
          position: 'absolute',
          backgroundColor: '#ffffff',
          color: '#fff',
          padding: '5px',
          borderRadius: '4px',
          border:'1px solid red',
          marginTop: '-35px',
          marginLeft: `${marginLeft}px`,  // Use the provided marginLeft prop
          boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        }}
      >
        <div
          style={{
            position: 'absolute',
            bottom: '-7px',
            left: '50%',
            marginLeft: '-10px',
            width: '0',
            height: '0',
            borderLeft: '7px solid transparent',
            borderRight: '7px solid transparent',
            borderTop: '7px solid #f98080',
          }}
        ></div>
        <p className="text-xs text-red-500">{content}</p>
      </div>
    );
  };
  const dispatch = useDispatch();
  const { productId } = useParams();
  const navigate = useNavigate();
  const [productDetails, setProductDetails] = useState({});

  const [editMode, setEditMode] = useState(false); // Initialize the navigate function
  const [formData, setFormData] = useState({
    productName: "",
    productDes: "",
  });
  const handleItemNameChange = (e) => {
    const { name, value } = e.target;

    // Validate item name to contain only alphabets and spaces, with a minimum length of 3
    let inputValue = value.replace(/[^a-zA-Z0-9\s]/g, ""); // Remove non-alphabetic characters except spaces

    setFormData({
      ...formData,
      [name]: capitalizeWords(inputValue),
    });

    // Set error message if item name is not empty and less than 3 characters
    if (inputValue.replace(/\s/g, "").length > 0 && inputValue.length < 3) {
      setItemNameError("Product Name must be at least 3 letters");
    } else {
      setItemNameError("");
    }
    setIsFormModified(true);
  };

  const handleDesChange = (e) => {
    const { name, value } = e.target;

    // Validate item name to contain only alphabets and spaces, with a minimum length of 3
    let inputValue = value.replace(/[^a-zA-Z0-9,\s]/g, ""); // Remove non-alphabetic characters except spaces

    setFormData({
      ...formData,
      [name]: capitalizeWords(inputValue),
    });

    // Set error message if item name is not empty and less than 3 characters
    if (inputValue.replace(/\s/g, "").length > 0 && inputValue.length < 3) {
      setDesError("Product Description must be at least 3 letters");
    } else {
      setDesError("");
    }
    setIsFormModified(true);
  };

  useEffect(() => {
    axios
      .get(`${baseURL}/${btoa("retailproduct/fetch")}/${btoa(productId)}`)
      .then((response) => {
        const productData = response.data.products_data[0];
        setProductDetails(productData);
        setFormData({
          productName: productData.product_name,
          productDes: productData.product_des,
        });
        setOriginalFormData({
          productName: productData.product_name,
          productDes: productData.product_des,
        });
      })
      .catch((error) => {
        console.error("Error fetching customer data:", error);
      });
  }, [productId]);

  const capitalizeWords = (input) => {
    return input
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: capitalizeWords(value),
    });
  };
  const showToast = (message, type) => {
    let toastColorClass;
    let iconComponent;

    if (type === "success") {
      toastColorClass = "bg-blue-50 text-blue-500";
      iconComponent = (
        <FaCheckCircle className="mr-6 text-[#1D72F3] bg-blue-50" size={24} />
      );
    } else if (type === "error") {
      toastColorClass = "bg-red-50 text-red-500";
      iconComponent = <FaTimesCircle className="mr-6 text-red-500" size={24} />;
    } else {
      // Default to success style for other types
      toastColorClass = "bg-red-50 text-red-500";
      iconComponent = <FaTimesCircle className="mr-6 text-red-500" size={24} />;
    }

    toast.error(
      <div className={`flex justify-between ${toastColorClass}`}>
        <div className=" rounded-lg">{message}</div>
        <div className=" rounded-lg "> {iconComponent}</div>
      </div>,
      {
        position: "top-left",
        hideProgressBar: true,
        autoClose: 4000,
        icon: false,
        className: "w-[864px] ml-[450px] mt-[290px]",
        style: {
          borderLeft:
            type === "success" ? "5px solid #1D72F3" : "5px solid red",
          background: type === "success" ? "#ebf5ff" : "#fdf2f2",
          minHeight: "10px",
          // borderRadius: "20px",
        },
      }
    );
  };
  const handleEditClick = async () => {
    if(formData.productName.replace(/\s/g,"").length < 3){
      return;
    }
    
    if(formData.productDes.replace(/\s/g,"").length > 0  && formData.productDes.replace(/\s/g,"").length < 3 ){
      return;
    }
    

    const updatedData = {
      edit_Id: productId,
      edit_productName: formData.productName,
      edit_productDes: formData.productDes,
    };

    try {
      const response = await axios.put(
        `${baseURL}/product/update`,
        updatedData
      );

      if (response.status === 200) {
        // Request was successful (status code 200)
        const responseData = await response.data;
        if (responseData.dataUpdatingStatus === true) {
          showToast(
            `${formData.productName} Data Updated Successfully`,
            "success"
          ); // Include the product name in the success message
        } else {
          showToast(
            `${formData.productName} Data Updated Successfully`,
            "success"
          );
        }
      } else {
        // Handle non-successful responses here
        console.log("Request failed with status: " + response.status);
        // You can also log the response data for debugging
        response.data.then((data) => {
          console.log(data);
        });
        showToast("Unable to update product, please try again", "error");
      }
    } catch (error) {
      // Handle unexpected errors here
      console.error(error);
      showToast("Unable to update product, please try again", "error");
    }
    window.scrollTo(0, 0);

    navigate(`/products`);
  };

  return (
    <div className="pt-16 h-screen">
      <h1 className="mx-8 font-bold mb-24 text-gray-400 text-lg mt-[-15px]">
        Products {">>"} Edit Product
      </h1>

      <div className="w-auto  bg-white rounded-box  mx-8 rounded-xl">
            <div className="flex space-x-5">
                <h2 class="mb-10 text-xl font-bold text-gray-900 underline underline-offset-8 decoration-gray-300">
                  Product Details
                </h2>
                <div className="flex text-xl"><FaArrowRightLong className="mt-1 mr-6" /> {formData.productName}</div>
            </div>
        <div class="grid gap-4 sm:grid-cols-3 sm:gap-6">
          <div className="flex sm:col-span-3 gap-3">
            {/* <div class="w-full">
              <label
                htmlFor="productName"
                class="block mb-2 text-sm font-medium text-gray-900 "
              >
                Product Name{" "}
                <span className="text-red-500 text-xl font-extrabold">*</span>
              </label>
              <CustomTooltip1 content={itemNameError} show={!!itemNameError} marginLeft={817}/>

              <input
                type="text"
                id="productName"
                name="productName"
                value={formData.productName}
                onChange={handleItemNameChange}
                readOnly
                required
                class={`bg-gray-300 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${
                  itemNameError ? "border-red-500" : ""
                }`}
                placeholder="Enter Prodcut Name"
              />
            </div> */}
          </div>
          <div class="sm:col-span-3">
            <label
              htmlFor="productDes"
              class="block mb-2 text-sm font-medium text-gray-900"
            >
              Product Description
            </label>
            <CustomTooltip1 content={desError} show={!!desError} marginLeft={790}/>

            <input
              type="text"
              id="productDes"
              name="productDes"
              value={formData.productDes}
              onChange={handleDesChange}
              className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
              placeholder="Enter Product Description"
              class={`bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${
                desError ? 'border-red-500' : ''
              }`}
            />
          
          </div>
        </div>
        <div className="flex justify-end space-x-3">
          {isFormModified && (
            <>
              <button
                onClick={handleCancel}
                type="submit"
                class="hover:bg-red-500 border-[#3479E0] border-2 text-black px-6 py-1.5 mt-4 sm:mt-6 text-sm font-semibold hover:border-transparent hover:shadow-lg hover:shadow-red-500/60 text-center hover:text-white  rounded-lg focus:ring-4 focus:ring-primary-200  hover:bg-primary-800"
              >
                Cancel
              </button>
              <button
                onClick={handleEditClick}
                type="submit"
                class="hover:bg-[#3479E0] border-[#3479E0] border-2 text-black px-6 py-1.5 mt-4 sm:mt-6 text-sm font-semibold hover:border-transparent hover:shadow-lg hover:shadow-[#3479E0]/50 text-center hover:text-white  rounded-lg focus:ring-4 focus:ring-primary-200  hover:bg-primary-800"
              >
                Done
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default ViewProduct;
