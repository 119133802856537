import { configureStore } from "@reduxjs/toolkit";
import invoiceSlice from "./invoiceSlice";
import customerSlice from "./customerSlice";
import vendorSlice from "./vendorSlice";
import itemSlice from "./itemSlice";
import ProductSlice from "./ProductSlice";
import purchasesSlice from "./purchasesSlice";
import DraftAgreementsSlice from '../../src/components/pages/DraftAgrrements/DraftAgreementsSlice'
import ApprovedContractsSlice from '../../src/components/pages/ApprovedContracts/ApprovedContractsSlice'
import LicensesSlice from '../../src/components/pages/Licenses/LicensesSlice'
import adminNotificationSlice from "./adminNotificationSlice";
import scrollingMessageSlice from "./scrollingMessageSlice";
import toastReducer from "./toastSlice";
import toastBox from '../components/pages/ToastBox/ToastBoxMessageSlice'
import DraftAgreements from "../components/pages/DraftAgrrements/DraftAgreements";
import Licenses from "../components/pages/Licenses/Licenses";



const store = configureStore({
    reducer : {
        invoice : invoiceSlice,
        customer: customerSlice,
        vendor : vendorSlice,
        item : itemSlice,
        product : ProductSlice,
        purchase : purchasesSlice,
        DraftAgreements:DraftAgreementsSlice,
        ApprovedContracts: ApprovedContractsSlice,
        Licenses:LicensesSlice,
        notification : adminNotificationSlice,
        scrollingMessage : scrollingMessageSlice,
        toast: toastReducer,
        toastBox: toastBox

    },
   
})

export default store;