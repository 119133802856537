import React, { useState, useRef, useEffect } from 'react';
import { Box, Grid, Button } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import CustomerPortalTable from "./CustomerPortalTable";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import config from '../../config/config';
import axios from "axios";



function CustomerPortalHome() {
  const datePickerRef = useRef(null);

  const [periodType, setPeriodType] = useState(""); // Tracks the selected period (Year, Month)
  const [selectedYear, setSelectedYear] = useState(""); // Tracks the selected year
  const [selectedMonth, setSelectedMonth] = useState(""); // Tracks the selected month
  const [selectedQuarter, setSelectedQuarter] = useState("");

  const [selectedDate, setSelectedDate] = useState(null); // Initialize as null
  const [customerResponse, setCustomerResponse] = useState([]); // Initialize as null
const[isRunClicked, setIsRunClicked] = useState(false);
const [tempMonth, setTempMonth] = useState(""); // Tracks the selected year
const [tempDate, setTempDate] = useState("");

const [tempYear, setTempYear] = useState(""); // Tracks the selected year
const [tempQuarterName, settempQuarterName] = useState(""); // Tracks the selected year

const [tempPeriodType, setTempPeriodType] = useState(""); // Tracks the selected year


  const years = ["2024", "2023", "2022", "2021"];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const quarters = [{bName: "Q1",
    fName:"Q1 (Mar-May)"
  },{bName: "Q2",
    fName:"Q2 (June-Aug)"},{bName: "Q3",
        fName: "Q3 (Sep-Nov)"},{bName: "Q4",
            fName: "Q4 (Dec-Feb)"}]
  const handleDateChange = (date) => {
    if (date) {
      setSelectedDate(date); // Only set the date when the user actually picks a valid date
    }
  };
  // Handles period type change (Year, Month)
  const handlePeriodChange = (event) => {
    setPeriodType(event.target.value);
    setSelectedYear("");
    setSelectedMonth("");
    setSelectedDate('')
  };
  useEffect(() => {
    if (periodType && datePickerRef.current) {
      datePickerRef.current.setFocus(); // Focus on the DatePicker input to open the calendar
    }
  }, [periodType]);
  // Enables the Run button only when valid selections are made
  const isButtonEnabled = () => {
    if (periodType === 'Yearly' && selectedYear) return true;
    if (periodType === 'Monthly' && selectedYear && selectedMonth) return true;
    if (periodType === 'dateWise' && selectedDate ) return true;
    if (periodType === 'Quarterly' && selectedYear && selectedQuarter) return true;

    return false;
  };
  const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;
  const formattedDate = selectedDate ? selectedDate.toLocaleDateString('en-CA') : null;
  function formatDatetodisplay(dateInput) {
    const date = new Date(dateInput); // Convert the input to a Date object
  
    const month = date.toLocaleString('default', { month: 'long' }); // Full month name
    const day = date.getDate(); // Day of the month
    const year = date.getFullYear(); // Year
  
    return `${month} ${day} ${year}`
  }
  const formattedDateCustomer = selectedDate ? selectedDate.toLocaleDateString('en-CA') : null;

  const handleRun = async() => {
    console.log('runclicked')
    setTempPeriodType(periodType)
    setTempDate(selectedDate)
    setTempMonth(selectedMonth)
    setTempYear(selectedYear)
    settempQuarterName(selectedQuarter)
    setIsRunClicked(true)
    // Handle the action when the "Run" button is clicked
    try{
      const response = await axios.post(`${baseURL}/${btoa("reports/viewinvoicecustportfilter")}` , {
             reportType: periodType,
          monthName: selectedMonth,
          quarterName: selectedQuarter,
          year: selectedYear,
          selectDate: formattedDateCustomer,
          businessId: localStorage.getItem('business_id'),
          customerId: localStorage.getItem('customer_id'),
          contactId: localStorage.getItem('contact_id'),
        })
      console.log('response.....', response)
      setCustomerResponse(response)

  }
      catch(error){
          console.log("error posting details" , error);
        }
  };
useEffect(()=>{
  setTempPeriodType('Monthly')
  setTempMonth(months[new Date().getMonth()])
  setTempYear(new Date().getFullYear())
},[])
  
  useEffect(() => {
    setPeriodType('Monthly');
     setSelectedMonth(months[new Date().getMonth()])
     setSelectedYear(new Date().getFullYear())
    const fetchInvoiceData = async () => {
      try {
        const response = await axios.post(`${baseURL}/${btoa("reports/viewinvoicecustportfilter")}`, {
     

          reportType: 'Monthly',
          monthName: months[new Date().getMonth()],
          quarterName:null,
          year: new Date().getFullYear(),
          businessId: localStorage.getItem('business_id'),
          customerId: localStorage.getItem('customer_id'),
          contactId: localStorage.getItem('contact_id'),
        });
        console.log('response.....', response);
        setCustomerResponse(response);
      } catch (error) {
        console.log("error posting details", error);
      }
    };
  
    fetchInvoiceData();
    
  }, []); // Add dependencies if needed
  

  return (
    <div style={{  borderRadius: "20px",
      // maxWidth: '100%',
      overflowX: 'auto',
      margin: '0 auto', 
      flexDirection: 'column',
                    boxShadow: '0 0 25px 0 rgba(0, 0, 0, 0.2)',
                   margin:'25px',
                   marginTop:'10px',
                   paddingBottom:'80px',
                   padding:'20px',
                   justifyContent:'center',
                   display:'flex',
                   flexDirection:'column'
          }}>
      {/* <div 
  className="flex justify-between items-center w-full  pt-4 mt-4 " 
  style={{ 
    maxWidth: '1200px', 
    boxSizing: 'border-box', 
    marginLeft: '40px', 
    fontFamily: 'Poppins, sans-serif', // Use Poppins font

  }}
>
  <h1 className="font-bold text-xl" style={{borderBottom: '4px solid #3479E0', paddingBottom: '8px'}}>Customer Portal Report</h1>
</div> */}
    <Box
      sx={{
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        padding: "20px",
        marginLeft: "43px",
        marginRight: '30px',
        marginTop: "30px",
        borderRadius: "20px",
        boxShadow: '0 0 20px 0 rgba(0, 0, 0, .2)',
        paddingBottom:'30px',
        marginBottom:'30px'
      }}
    >
      <Grid container spacing={4}>
        <Grid item md={3}>
          {/* Period Type Dropdown */}
          <div>
            <label className="block mb-2 text-base font-medium text-gray-900">
              Select Report Type
              <span className="text-red-500 text-xl font-extrabold pl-2 pt-3">*</span>
            </label>
            <select
              required
              onChange={handlePeriodChange}
              value={periodType}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
            >
              <option value="">Select Report Type</option>
              <option value="dateWise">Date</option>
              <option value="Monthly">Monthly</option>
              <option value="Quarterly">Quarterly</option>
              <option value="Yearly">Yearly</option>
            </select>
          </div>
        </Grid>
        {(periodType === 'dateWise')  &&
       <Grid item md= {3}>
            <label
            for="Select Date"
            className="block mb-2 text-base font-medium text-gray-900 "
          >
            Select Date
            <span class="text-red-500 text-xl font-extrabold pl-2 pt-3">*</span>
          </label>
          <DatePicker
            ref={datePickerRef}
            selected={selectedDate}
            onChange={handleDateChange}
            dateFormat="MM-dd-yyyy"
            className="date-picker"
            style={{ height:'43px', width:'100%'}}
          />
      </Grid>
}
        {/* Month Dropdown - Only visible for Month selection */}
        {periodType === "Monthly" && (
          <Grid item md={3}>
            <div>
              <label className="block mb-2 text-base font-medium text-gray-900">
                Select Month
                <span className="text-red-500 text-xl font-extrabold pl-2 pt-3">*</span>
              </label>
              <select
                required
                value={selectedMonth}
                onChange={(e) => {setSelectedMonth(e.target.value);}}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
              >
                <option value="">Select Month</option>
                {months.map((month) => (
                  <option key={month} value={month}>
                    {month}
                  </option>
                ))}
              </select>
            </div>
          </Grid>
        )}
         { (periodType === 'Quarterly') &&
                     <Grid item md={3} >
          <div>
          <label
            for="Select Quarter"
            className="block mb-2 text-base font-medium text-gray-900 "
          >
            Select Quarter
            <span class="text-red-500 text-xl font-extrabold pl-2 pt-3">*</span>
          </label>
          <select
            id="Select Quarter"
            name="Select Quarter"
            required
            value={selectedQuarter}
            onChange={(e) => {setSelectedQuarter(e.target.value);}}  
            label="Select Quarter"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
          >
            <option value="">--Select Quarter--</option>
            {quarters.map((quarter) => (
            <option key={quarter.fName} value={quarter.bName} sx={{ margin: 0, padding: '1px 16px' }}>
              {quarter.fName}
            </option>
          ))}      
          </select>
        </div> 
        </Grid>
      }

        {/* Year Dropdown */}
        {(periodType === "Monthly" || periodType === "Yearly" || periodType === "Quarterly") && (
          <Grid item md={3}>
            <div>
              <label className="block mb-2 text-base font-medium text-gray-900">
                Select Year
                <span className="text-red-500 text-xl font-extrabold pl-2 pt-3">*</span>
              </label>
              <select
                required
                value={selectedYear}
                onChange={(e) => {setSelectedYear(e.target.value);}}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
              >
                <option value="">Select Year</option>
                {years.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div>
          </Grid>
        )}

        <Grid item md={3}>
          {/* Run Button */}
          <Button
            variant="contained"
            sx={{
              backgroundColor: 'rgb(231 70 148)', // Pink color
              color: 'white',
              marginTop: '36px',
              height: '43px',
              '&:hover': {
                backgroundColor: 'rgb(231 70 148)', // Darker shade of pink on hover
              }
            }}
            endIcon={<SendIcon />}
            disabled={!isButtonEnabled()}
            onClick={handleRun}
          >
            Run
          </Button>
        </Grid>
      </Grid>
    </Box>
    <>
    {!isRunClicked &&

    <div 
    style={{ 
      color: '#1976d2', 
      fontSize: '20px', 
      fontWeight: 'bold', 
      display: 'flex', 
      justifyContent: 'center', 
      textAlign: 'center', // Center text within the div
      marginBottom: '10px', // Added margin for separation from the second div
      marginRight:'110px'
    }} 
  >
    {tempPeriodType === 'Monthly' && tempYear && tempMonth && `${tempMonth} - ${tempYear} - Invoices`}

  </div>
}
  </>
    {isRunClicked &&
    <>
    <div 
    style={{ 
      color: '#1976d2', 
      fontSize: '20px', 
      fontWeight: 'bold', 
      display: 'flex', 
      justifyContent: 'center', 
      textAlign: 'center', // Center text within the div
      marginBottom: '10px', // Added margin for separation from the second div
      marginRight:'110px'
    }} 
  >
    {tempPeriodType === 'Yearly' && tempYear && isRunClicked && `${tempYear} - Invoices`}
    {tempPeriodType === 'Monthly' && tempYear && tempMonth && `${tempMonth} - ${tempYear} - Invoices`}
    {tempPeriodType === 'dateWise' && tempDate && isRunClicked && `${formatDatetodisplay(tempDate)} - Invoices`}
    {(tempPeriodType === 'Quarterly' && tempYear && tempQuarterName && isRunClicked) && ` ${tempQuarterName == 'Q1' ? 'Q1 (Mar-May)' :''} ${tempQuarterName == 'Q2' ? 'Q2 (June-Aug)':'' } ${tempQuarterName == 'Q3' ? 'Q3 (Sep-Nov)' :'' } ${tempQuarterName == 'Q4' ? 'Q4 (Dec-Feb)' :'' } - ${tempYear} - Invoices`}

  </div>
  <hr className="border-t border-gray-300 mb-1 mt-2" />
  </>

}
    <CustomerPortalTable customerResponse= {customerResponse}/>
    </div>
  );
}

export default CustomerPortalHome;
