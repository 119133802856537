import React from 'react'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { MdOutlineBusinessCenter } from "react-icons/md";
import { FiUser } from "react-icons/fi";
import { useNavigate } from 'react-router-dom';
const SuperAdminHome = () => {


  const navigate = useNavigate()

  return (

   <div className='flex ml-6 mt-6 mb-6 text-center'>

        <Card 
        onClick={()=> navigate("/createbusiness")}
        className="cursor-pointer w-auto bg-white rounded-box mx-8 rounded-xl mt-16 mb-6 h-40 transition-transform duration-300 transform hover:scale-105" style={{ width: '13rem' }}>
          {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
          <Card.Body className=''>
          <Card.Title className='flex flex-col items-center'>
                  <div className="w-14 h-14 bg-blue-500 rounded-full flex items-center justify-center mr-2 mt-2">
                  <MdOutlineBusinessCenter className='text-white  w-6 h-6' />
                  </div>
                    <div className='mt-3'>Create Business</div>
              </Card.Title>
          </Card.Body>
        </Card>

        <Card
         onClick={()=> navigate("/createownermain")}
         className="cursor-pointer w-36 bg-white rounded-box mx-8 rounded-xl mt-16 mb-6 h-40 transition-transform duration-300 transform hover:scale-105" style={{ width: '13rem' }}>
              {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
              <Card.Body className=''>
              <Card.Title className='flex flex-col items-center'>
                  <div className="w-14 h-14 bg-blue-500 rounded-full flex items-center justify-center mr-2 mt-2">
                  <FiUser className='text-white w-6 h-6' />
                  </div>
                  <div className='mt-3'>Create Owner</div>
                  </Card.Title>
              </Card.Body>
         </Card>

   </div>
  )
}

export default SuperAdminHome