// src/components/Toast.js
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import { hideToast } from '../../store/toastSlice';
import './Toast.css'; // Import your CSS for styling

const Toast = () => {
  const dispatch = useDispatch();
  const { message, visible, type } = useSelector((state) => state.toast);

  useEffect(() => {
    if (visible) {
      const timer = setTimeout(() => dispatch(hideToast()), 5000);
      return () => clearTimeout(timer);
    }
  }, [visible, dispatch]);

  const toastColorClass = type === "success" ? "bg-green-50 text-green-500" : "bg-red-50 text-red-500";
  const iconComponent = type === "success" ? <FaCheckCircle className="mr-2 text-[#2BDE3F]" size={24} /> : <FaTimesCircle className="mr-2 text-red-500" size={24} />;

  return (
    <div
      className={`fixed top-16 right-4 w-full max-w-md mx-auto p-4 rounded-lg shadow-lg transition-transform transform ${visible ? 'translate-x-0 opacity-100' : 'translate-x-full opacity-0'} ${toastColorClass}`}
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          {iconComponent}
          <span>{message}</span>
        </div>
        <button className="text-2xl" onClick={() => dispatch(hideToast())}>×</button>
      </div>
    </div>
  );
};

export default Toast;
