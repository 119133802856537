import * as React from "react";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  createColumnHelper,
  getFilteredRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import PropTypes from "prop-types";

import { rankItem } from "@tanstack/match-sorter-utils";
import Pagination from "./Pagination";
import Loader from "./Loader";
import IndeterminateCheckbox from "./IndeterminateCheckbox";

const globalFilterFn = (row, columnId, value, addMeta) => {
  const itemRank = rankItem(row.getValue(columnId), value);
  addMeta({ itemRank });
  return itemRank.passed;
};

Table.propTypes = {
  tableData: PropTypes.array.isRequired,
  enableRowSelection: PropTypes.bool,
  columns: PropTypes.array.isRequired,
  selectedRows: PropTypes.func,
  disableHeaderCheckBox: PropTypes.bool,
  searchInput: PropTypes.string,
  noPagination: PropTypes.bool,
  isLoading: PropTypes.bool,
  resetRowSelection: PropTypes.func.isRequired,
};

Table.defaultProps = {
  enableRowSelection: false,
  disableHeaderCheckBox: false,
  selectedRows: () => {},
  searchInput: "",
  noPagination: false,
  isLoading: false,
 
};

function Table({
  tableData,
  columns,
  enableRowSelection,
  selectedRows,
  disableHeaderCheckBox,
  searchInput,
  noPagination,
  isLoading,
  resetRowSelection,

}) {
  const [rowSelection, setRowSelection] = React.useState({});
  const [globalFilter, setGlobalFilter] = React.useState("");

  const isRowCheckboxDisabled = (row) => {
    if (rowSelection[row.id]) {
      return false;
    } else {
      return Object.values(rowSelection).some((selected) => selected);
    }
  };

 

  const columnHelper = createColumnHelper();

  const columnsWithCheckBox = enableRowSelection
    ? [
        columnHelper.accessor(" ", {
          cell: ({ row }) => (
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                disabled: isRowCheckboxDisabled(row),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
                className: isRowCheckboxDisabled(row)
                  ? "disabled-checkbox"
                  : "",
              }}
            />
          ),
        }),
        ...columns,
      ]
    : columns;

  const table = useReactTable({
    data: tableData,
    columns: columnsWithCheckBox,
    state: {
      rowSelection,
      globalFilter,
    },
    filterFns: {
      globalFilterFn,
    },
    enableRowSelection,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onRowSelectionChange: setRowSelection,
    globalFilterFn,
    resetRowSelection,
  });

  
  React.useEffect(() => {
    resetRowSelection.current = () => table.resetRowSelection();
  }, [table]);

  React.useEffect(() => {
    if (selectedRows) {
      selectedRows(
        table.getSelectedRowModel().flatRows.map((row) => row.original)
      );
    }
  }, [rowSelection, selectedRows, table]);

  React.useEffect(() => {
    if (searchInput?.trim()) {
      setGlobalFilter(searchInput);
    } else {
      setGlobalFilter("");
    }
  }, [searchInput]);

  return (
    <>
      <div className="w-full mt-7">
        <table className="w-full table-auto bg-transparent rounded-xl">
          <thead className="bg-gray-500/20 text-gray-500 ">
            {table.getHeaderGroups().map((headerGroup, index) => (
              <tr key={headerGroup.id} className="text-center text-xs">
                {headerGroup.headers.map((header, index) => {
                  const isFirstHeader = index === 0;
                  const isLastHeader = index === headerGroup.headers.length - 1;

                  const headerClasses = [
                    "p-3 font-bold uppercase",
                    isFirstHeader ? "rounded-l-xl" : "", // Add 'rounded-tl-xl' to the first header
                    isLastHeader ? "rounded-r-xl" : "", // Add 'rounded-tr-xl' to the last header
                  ];

                  return (
                    <th key={header.id} className={headerClasses.join(" ")}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr
                key={row.id}
                className={`cursor-pointer text-center hover:bg-gray-500/10`}
              >
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    className={`p-4 font-semibold text-[#081159] text-[14px]`}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        {isLoading && (
          <div className="w-full h-[200px] flex items-center justify-center ">
            <Loader />
          </div>
        )}
        {!tableData.length && (
          <div className="w-full h-[200px] flex items-center justify-center ">
            There is nothing to show here
          </div>
        )}
      </div>
      {!noPagination ? <Pagination table={table} /> : null}
    </>
  );
}

export default Table;
