import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function useHideUrl() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if(localStorage.getItem('token')) {
    const savedRoute = localStorage.getItem('currentRoute');
    if (savedRoute && savedRoute !== location.pathname) {
      localStorage.removeItem('currentRoute'); // Clear to prevent loops
      navigate(savedRoute, { replace: true });
    }}
  }, [location.pathname, navigate]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.setItem('currentRoute', location.pathname);
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [location.pathname]);

  return null;
}

export default useHideUrl;
