import React, { useState, useRef, useEffect } from 'react';
import { FormControl, InputLabel, MenuItem, Select, Grid, Box, Paper, TextField , IconButton, Typography, Card, CardContent} from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../Sales/sales.css'; // Add custom styles if needed
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import {  useSelector , useDispatch} from "react-redux";
import axios from "axios";
import '../Sales/sales.css'
import { addCustomer } from "../../../store/customerSlice";
import config  from "../../../config/config"
import { Tooltip } from "react-tippy";
import Loader from "./../Loader";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import PrintIcon from '@mui/icons-material/Print';
import DescriptionIcon from '@mui/icons-material/Description';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  createColumnHelper
} from "@tanstack/react-table";
import InvoiceReportTable from './InvoiceReportTable';
import InvoicePagination from './InvoicePagination';
import { AiOutlineFileText, AiOutlineCheckCircle, AiOutlineDollar, AiOutlineSend, AiOutlinePercentage, AiOutlineWarning } from 'react-icons/ai'; // Example icons
import FullScreenLoader from '../FullScreenLoader';



const InvoiceReport = () => {
  const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;

  useEffect(() => {
    setUserRole(localStorage.getItem("user_role"));
  }, []);
  useEffect(() => {
    axios
      .get(`${baseURL}/${btoa("business/profile")}/${btoa(business_id)}/${btoa(user_id)}/${btoa(user_role)}`)
      .then((response) => {
        setImageSource(response.data.business_data[0].image_file)
        setimageResponse(response.data.business_data[0])
      })
      .catch((error) => {
        console.error("Error fetching business data:", error);
      });
  }, []);
  const businessId = localStorage.getItem('business_id')
  const [customer, setCustomer] = useState('');
  const [selectedMonth, setSelectedMonth] = useState(""); // Tracks the selected month
  const [tempMonth, setTempMonth] = useState(""); // Tracks the selected year

  const [selectedYear, setSelectedYear] = useState(""); // Tracks the selected year
  const [tempYear, setTempYear] = useState(""); // Tracks the selected year

  const [selectedDate, setSelectedDate] = useState(null); // Initialize as null
  const [tempDate, setTempDate] = useState(""); // Tracks the selected year
  
  const [tempQuarterName, settempQuarterName] = useState(""); // Tracks the selected year

  const [userRole, setUserRole] = useState("");
  const [imageSource, setImageSource] = useState();
  const [imageResponse, setimageResponse] = useState([]);
  const [invoiceType, setInvoiceType] = useState('');
  const [tempinvoiceType, setTempInvoiceType] = useState('');

  const [periodType, setPeriodType] = useState('');
  const [tempPeriodType, setTempPeriodType] = useState(""); // Tracks the selected year
  const [selectedQuarter, setSelectedQuarter] = useState("");

  const [RunClicked, setRunClicked] = useState(false); // Tracks the selected quarter
  const [loading, setLoading] = useState(false); // Add a loading state
  const [invoiceResponse, setInvoiceResponse] = useState([]);
  const [dateTime, setDateTime] = useState('');
  const customersAll = useSelector(state => state.customer.allCustomers);
  const user_id = localStorage.getItem("user_id");
  const user_role = localStorage.getItem("user_role");
  const business_id = localStorage.getItem("business_id");
  const [pdfhovered, setPdfHovered] = useState(false);
  const [taxhovered, setTaxHovered] = useState(false);
  // const [userRole, setUserRole] = useState("");
  // const from =  salesResponse.data?.from;
  // const to =  salesResponse.data?.to;
  const dispatch = useDispatch();
  const contentRef = useRef();

// console.log(customersAll);
const formatToTwoDigits = (num) => {
  // Convert number to string
  const numStr = num.toString();
  
  // Find the position of the decimal point
  const decimalIndex = numStr.indexOf('.');

  // If there is no decimal point, just return the number with ".00"
  if (decimalIndex === -1) {
    return `${numStr}.00`;
  }

  // Slice the string to ensure there are exactly two digits after the decimal
  const wholePart = numStr.slice(0, decimalIndex); // The part before the decimal
  const fractionalPart = numStr.slice(decimalIndex + 1, decimalIndex + 3); // The first two digits after the decimal

  // If there are less than two digits, pad with zeros
  const formattedFractionalPart = fractionalPart.padEnd(2, '0');

  // Return the formatted number
  return `${wholePart}.${formattedFractionalPart}`;
};
const generateUSDateTime = () => {
  setRunClicked(true);
  const currentDate = new Date();
  // Format date and time in US format
  const formattedDateTime = currentDate.toLocaleString('en-US', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric', // Optional: remove if seconds aren't needed
    hour12: true, // 12-hour format with AM/PM
    timeZone: 'America/New_York' // You can change the timezone if necessary
  });
  setDateTime(formattedDateTime);
};
useEffect(() => {
  window.scrollTo(0, 0);
  axios
    .get(`${baseURL}Y3VzdG9tZXIvdmlldw==/${btoa(businessId)}`)
    .then((response) => {
      const sortedCustomerData = response.data.customers_data.sort(
        (a, b) => b.customer_id - a.customer_id
      );
      dispatch(addCustomer(sortedCustomerData))
   
    })
    .catch((error) => {
      console.error("Error fetching customer data:", error);
    });
}, []);
  const datePickerRef = useRef(null);
  const handleCustomerChange = (event) => {
    setCustomer(event.target.value);
    // setSelectedDate(null); // Reset selected date
  };
  const handleInvoiceTypeChange = (event) => {
    setInvoiceType(event.target.value);
    // setSelectedDate(null); // Reset selected date
  };
  const handlePeriodChange = (event) => {
    // setRunClicked(false)
    setPeriodType(event.target.value);
    setSelectedDate(''); // Reset selected date
    setSelectedMonth('')
    setSelectedYear('')
  };
  // const handleRun = async  () => { 
  //   setLoading(true); // Set loading to true when fetching data
  //   setTempYear(selectedYear)
  //   setTempMonth(selectedMonth)
  //   setTempDate(selectedDate)
  //   settempQuarterName(selectedQuarter)
  //   setTempPeriodType(periodType)
  //   try{
  //       const response = await axios.post(`${baseURL}/${btoa("invoice/viewinvoicefilter")}` , {
  //         customerId: customer,
  //         status:invoiceType,
  //         reportType:periodType,
  //         monthName:selectedMonth,
  //         quarterName:selectedQuarter,
  //         selectedDate:formattedDate,
  //         year:selectedYear,
  //         businessId:localStorage.getItem('business_id'),
  //         managerId:localStorage.getItem('user_id'),
  //           fromMdy:null,
  //           toMdy:null, 
  //       })
  //       console.log('response.....', response)
  //       setInvoiceResponse(response)

  //   }
  //       catch(error){
  //           console.log("error posting details" , error);
  //         }
  //         finally {
  //           setLoading(false); // Set loading to false after data is fetched
  //         }
  // };
  const handleRun = async () => { 
        setTempYear(selectedYear)
    setTempMonth(selectedMonth)
    setTempDate(selectedDate)
    settempQuarterName(selectedQuarter)
    setTempPeriodType(periodType)
    setLoading(true); // Show loader
setTempInvoiceType(invoiceType)
    // Introduce a 3-second delay
    // const delay = new Promise(resolve => setTimeout(resolve, 3000));

    try {
        // await delay; // Wait for at least 3 seconds

        // Proceed with the API call after the delay
        const response = await axios.post(`${baseURL}/${btoa("invoice/viewinvoicefilter")}`, {
            customerId: customer,
            status: invoiceType,
            reportType: periodType,
            monthName: selectedMonth,
            quarterName: selectedQuarter,
            selectedDate: formattedDate,
            year: selectedYear,
            businessId: localStorage.getItem('business_id'),
            managerId: localStorage.getItem('user_id'),
            fromMdy: null,
            toMdy: null,
        });

        console.log('response.....', response);
        setInvoiceResponse(response);
    } catch (error) {
        console.log("Error posting details:", error);
    } finally {
        setLoading(false); // Hide loader
    }
};

  const handlePrint = () => {
    const content = contentRef.current.innerHTML; // Get the HTML content
    const newWindow = window.open('', '_blank'); // Open a new window
    newWindow.document.write(`
      <html>
        <head>
          <title>Print</title>
          <style>
            /* Optional: Add styles for printed content */
                      @media print {
  img {
    display: block;
    width: 100px; /* Adjust width as needed */
    height: auto;
  }
              @page {
                margin: 0; /* Remove default margins */
              }
              body {
                margin: 0;
                padding: 0;
              }
              /* Hide headers and footers */
              header, footer {
                display: none; /* Hides header and footer */
              }
              /* You can also add styles to control the printed content */

            body {
              font-family: Arial, sans-serif;
              margin: 60px;
            }
          </style>
        </head>
        <body>
          ${content} 
        </body>
      </html>
    `);
    newWindow.document.close(); // Close the document to apply styles
    newWindow.print(); // Trigger the print dialog
  };
  useEffect(() => {
    if (periodType && datePickerRef.current) {
      datePickerRef.current.setFocus(); // Focus on the DatePicker input to open the calendar
    }
  }, [periodType]);
  const formatDate = (dateStr) => {
    const [month, day, year] = dateStr.split('-');
    return `${year}-${month}-${day}`;
  };

  const years = ["2024", "2023", "2022", "2021", "2020"];
  const months = [ "January", "February",  "March", "April","May","June","July","August","September","October","November","December"];
  const quarters = [{bName: "Q1",
    fName:"Q1 (Mar-May)"
  },{bName: "Q2",
    fName:"Q2 (June-Aug)"},{bName: "Q3",
        fName: "Q3 (Sep-Nov)"},{bName: "Q4",
            fName: "Q4 (Dec-Feb)"}]
  const formattedDate = selectedDate ? selectedDate.toLocaleDateString('en-CA') : null;

  
  
  const handleDateChange = (date) => {
    if (date) {
      // setRunClicked(false)
      setSelectedDate(date); // Only set the date when the user actually picks a valid date
    }
  };
  const roundToTwoDecimals = (num) => {
    const parts = num.toString().split('.');
    if (parts.length === 1) {
        return parts[0]; // No decimal part, return whole number
    }
    return `${parts[0]}.${(parts[1] || '').substring(0, 2)}`; // Limit to two decimals
};
const formatToTwoDecimalsWithCommas = (num) => {
  const parts = num.toString().split('.');
  const integerPartWithCommas = parseInt(parts[0], 10).toLocaleString('en-US'); // Adds commas to the integer part
  const decimalPart = parts[1] ? parts[1].substring(0, 2) : ''; // Limits to two decimal places without rounding

  return decimalPart ? `${integerPartWithCommas}.${decimalPart}` : integerPartWithCommas;
};
const invoiceAmount = () => {
  return invoiceResponse.data?.invoice_sum_data?.reduce((total, { total_final_invoice_amount }) => {
    return total + (total_final_invoice_amount ? Number(total_final_invoice_amount) : 0);
  }, 0) || 0; // Default to 0 if reduce is called on an undefined array
};

function formatNumber(num) {
  return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(num);
}
function formatNumberwithoutzeros(num) {
  return  new Intl.NumberFormat('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(num) ;
}

const items = [
  { 
    name: 'Total Invoices', 
    number: formatNumberwithoutzeros(invoiceResponse?.data?.totalInvoices || 0) || 0, 
    icon: <AiOutlineFileText size={30}  /> 
  },
  { 
    name: 'Sent Amount', 
    number: formatNumber(invoiceResponse?.data?.invoice_sum_data?.[2]?.total_final_invoice_amount || 0) || 0,  
    icon: <AiOutlineSend  size={30} />  
  },
  { 
    name: 'PartialPaid Amount',
    number: formatNumber(invoiceResponse?.data?.invoice_sum_data?.[3]?.total_final_invoice_amount || 0) || 0, 
    icon: <AiOutlinePercentage  size={30} />   
  },
  { 
    name: 'FullPaid Amount', 
    number: formatNumber(invoiceResponse?.data?.invoice_sum_data?.[4]?.total_final_invoice_amount || 0) || 0,  
    icon: <AiOutlineCheckCircle  size={30} />  
  },
  { 
    name: 'PastDue Amount', 
    number: formatNumber(invoiceResponse?.data?.invoice_sum_data?.[5]?.total_final_invoice_amount ||0) || 0,
    icon: <AiOutlineWarning  size={30} />  
  },
  { 
    name: 'Invoice Amount', 
    number: formatNumber(invoiceAmount() || 0) || 0,
    icon: <AiOutlineDollar  size={30} /> 
  },
];


  
  const isFormComplete = () => {
    // Ensure the customer, invoice type, and duration type are filled
    if (customer === '' || invoiceType === '' || periodType === '') {
      return false;
    }
  
    // If the duration type is 'Date', ensure a valid date is selected
    if (periodType === 'dateWise' && !selectedDate) {
      return false;
    }
    // For 'Month', ensure both month and year are selected
    if (periodType === 'Monthly' && (selectedMonth === '' || selectedYear === '')) {
      return false;
    }
    if (periodType === 'Quarterly' && (selectedQuarter === '' || selectedYear === '')) {
      return false;
    }
    // For 'Year', ensure a year is selected
    if (periodType === 'Yearly' && selectedYear === '') {
      return false;
    }
    // If all checks pass, the form is complete
    return true;
  };
  function formatDatetodisplay(dateInput) {
    const date = new Date(dateInput); // Convert the input to a Date object
  
    const month = date.toLocaleString('default', { month: 'long' }); // Full month name
    const day = date.getDate(); // Day of the month
    const year = date.getFullYear(); // Year
  
    return `${month} ${day} ${year}`
  }
  const Quantity =() => {
    return (
     invoiceResponse.data.invoice_data.length >0 ? invoiceResponse.data.invoice_data.reduce((accumulator, currentValue) => {
         return accumulator + +currentValue.quantity; // Sum the gallons property
       }, 0) : ''
    )
  }
  const FinalInvoiceAmount =() => {
    return (
     invoiceResponse.data.invoice_data.length >0 ? invoiceResponse.data.invoice_data.reduce((accumulator, currentValue) => {
         return accumulator + +currentValue.final_invoice_amount; // Sum the gallons property
       }, 0) : ''
    )
  }
  
  const finalData = React.useMemo(() => {
    return (invoiceResponse?.data && invoiceResponse?.data?.invoice_data) ? invoiceResponse.data.invoice_data : [];
  }, [invoiceResponse]);
  
  const columnDef = React.useMemo(() => [
    {
      accessorFn: (row, i) => i + 1,
      header: 'Sl No',
    },
    {
      accessorKey: 'invoice_number',
      header: "Invoice_number",
    },
    ...(invoiceResponse?.data?.customerId === "all" ? [{
      accessorKey: 'business_name',
      header: "Customer_Name",
    }] : []),
    {
      accessorKey: 'added_on',
      header: "invoice_date",
      cell: (props) => {
        const date = new Date(props.row.original.added_on);
        const options = { month: 'short', day: '2-digit', year: 'numeric' };
        const formattedDate = `${date.toLocaleDateString("en-US", { month: 'short' })}-${date.toLocaleDateString("en-US", { day: '2-digit' })}-${date.toLocaleDateString("en-US", { year: 'numeric' })}`;
        return (
          <div>{formattedDate}</div>
        );
      },
    },
    {
      accessorKey: 'due_date',
      header: "due_date",
      cell: (props) => {
        const date = new Date(props.row.original.due_date);
        const formattedDate = `${date.toLocaleDateString("en-US", { month: 'short' })}-${date.toLocaleDateString("en-US", { day: '2-digit' })}-${date.toLocaleDateString("en-US", { year: 'numeric' })}`;
        return (
          <div>{formattedDate}</div>
        );
      },
    },
    {
      accessorKey: 'final_invoice_amount',
      header: "final_invoice_amount",
      cell: (props) => (
        <div className="text-center">
           {Number(props.row.original.final_invoice_amount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
        </div>
      ),
    },
  ], [invoiceResponse]);
  
  const tableInstance = useReactTable({
    columns: columnDef,
    data: finalData,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 25,
      },
    },
  });
  
  
  
  return (
    <div>
      {loading && <FullScreenLoader/>}
    <>
     <div 
  className="flex justify-between items-center w-full  pt-4 mt-4 " 
  style={{ 
    maxWidth: '1200px', 
    boxSizing: 'border-box', 
    marginLeft: '36px', 
    fontFamily: 'Poppins, sans-serif', // Use Poppins font
    color: '#444444' // Lighter black color
  }}
>
<nav aria-label="breadcrumb">
    <ol className="flex space-x-2 text-lg font-bold text-gray-700">
      <li>
        {/* <a  href="/operations" className="text-blue-600 hover:text-blue-800"> */}
          Reports
        {/* </a> */}
      </li>
      <li>
        <span className="text-gray-400">{'>>'}</span>
      </li>
      <li>
        {/* <a href="/purchases" className="text-blue-600 hover:text-blue-800"> */}
          Invoice 
        {/* </a> */}
      </li>
    </ol>
  </nav>
  {/* <h1 className="font-bold text-[20px]">Invoice Report</h1> */}
</div>



      <Box sx={{ padding: 4, paddingTop:2, paddingBottom:2, maxWidth: '100%', backgroundColor: '#ffff' }}>
        {/* <Paper elevation={3}
             style={{
              padding: '40px',
              paddingTop: '20px',
marginLeft:'15px',
              display: 'flex',
              justifyContent: 'center',  // Centers horizontally
              alignItems: 'center',      // Centers vertically
            }}
         sx={{ padding: 4,paddingTop:'0px', borderRadius: 4,alignItems:'center', boxShadow: '0 0 20px 0 rgba(0, 0, 0, .2)',
 }}> */}
          <Grid container spacing={2} >
            {/* Customer Dropdown */}
            <Grid item md={2.1}>
              <div>
            {/* <label
              for="Select Customer"
              className="block mb-2 text-base font-medium text-gray-900 "
            >
              Select Customer
              <span class="text-red-500 text-[23px] font-extrabold pl-2 pt-3">*</span>
            </label> */}
            <select
              id="Select Customer"
              name="Select Customer"
              required
              onChange={handleCustomerChange}
              value = {customer}
              className=" border border-gray-300 text-sm font-medium rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full text-gray-500 p-2.5"

              // className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
            >
              <option value="">Select Customer</option>
              <option value="all">All Customers</option>
         
                 {
                customersAll.map((customer)=>{
                  return  <option value={customer.customer_id} >{customer.business_name}</option>

                })
               }
            </select>
          </div>
            </Grid>

            {/* Invoice Type Dropdown */}
            <Grid item md={2.3}>
            <div>
            {/* <label
              for="Select Invoice Type"
              className="block mb-2 text-base font-medium text-gray-900 "
            >
              Select Invoice Type
              <span class="text-red-500 text-[23px] font-extrabold pl-2 pt-3">*</span>

            </label> */}
            <select
              id="Select Invoice Type"
              name="Select Invoice Type"
              required
              onChange={handleInvoiceTypeChange}
              value = {invoiceType}
              className=" border border-gray-300 text-sm font-medium rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full text-gray-500 p-2.5"

              // className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
            >
              <option value=""> Select Invoice Type</option>
              <option value="all">All Statuses</option>

              <option value="draft">Draft</option>
              <option value="approved">Approved</option>
              <option value="sent">Sent</option>
              <option value="partialpaid">Partial Pay</option>
              <option value="fullpaid">Full Paid</option>
              <option value="overDue">Past Due</option>
        
            </select>
          </div>
            </Grid>

            {/* Duration Dropdown */}
            <Grid item md={2.2} >
            <div>
            {/* <label
              for="Select Report type"
              className="block mb-2 text-base font-medium text-gray-900 "
            >
             Select Report type
             <span class="text-red-500 text-[23px] font-extrabold pl-2 pt-3">*</span>

            </label> */}
            <select
              id="Select Report type"
              name="Select Report type"
              required
              onChange={handlePeriodChange}
              value = {periodType}
              // className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
              className=" border border-gray-300 text-sm font-medium rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full text-gray-500 p-2.5"

            >
              <option value=""> Select Report type</option>
              <option value="dateWise">Date</option>
              <option value="Monthly">Month</option>
              <option value="Quarterly">Quarterly</option>

              <option value="Yearly">Year</option>        
            </select>
          </div>
            </Grid>
            {/* Date/Month/Year Picker */}
            {(periodType === 'Monthly') &&
               <Grid item md={2.2} >    
          <div>
          {/* <label
            for="Select Month"
            className="block mb-2 text-base font-medium text-gray-900 "
          >
            Select Month
            <span class="text-red-500 text-[23px] font-extrabold pl-2 pt-3">*</span>
          </label> */}
          <select
            id="Select Month"
            name="Select Month"
            required
            onChange={(e) => {setSelectedMonth(e.target.value);}}
            value={selectedMonth}
            className=" border border-gray-300 text-sm font-medium rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full text-gray-500 p-2.5"

            // className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
          >
            <option value="">Select Month</option>
            {months.map((month) => (
              <option key={month} value={month} sx={{ margin: 0, padding: '1px 16px' }}>
                {month}
              </option>
            ))}  
          </select>
        </div>
        </Grid>
      } 
         {(periodType === 'Quarterly') &&
               <Grid item md={2.2} >    
          <div>
          {/* <label
            for="Select Quarter"
            className="block mb-2 text-base font-medium text-gray-900 "
          >
            Select Quarter
            <span class="text-red-500 text-[23px] font-extrabold pl-2 pt-3">*</span>
          </label> */}
          <select
            id="Select Quarter"
            name="Select Quarter"
            required
            onChange={(e) => {setSelectedQuarter(e.target.value);}}
            value={selectedQuarter}
            className=" border border-gray-300 text-sm font-medium rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full text-gray-500 p-2.5"

            // className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
          >
            <option value="">Select Quarter</option>
            {quarters.map((quarter) => (
            <option key={quarter.fName} value={quarter.bName} sx={{ margin: 0, padding: '1px 16px' }}>
              {quarter.fName}
            </option>
          ))}  
          </select>
        </div>
        </Grid>
      } 
      {(periodType === 'dateWise')  &&
       <Grid item md= {2.2}>
            {/* <label
            for="Select Date"
            className="block mb-2 text-base font-medium text-gray-900 "
          >
            Select Date
            <span class="text-red-500 text-[23px] font-extrabold pl-2 pt-3">*</span>
          </label> */}
          <DatePicker
            ref={datePickerRef}
            selected={selectedDate}
            onChange={handleDateChange}
            dateFormat="MM-dd-yyyy"
            maxDate= {new Date()}
            minDate= {new Date("2020-01-01")}
            className="date-picker"
            style={{ height:'43px'}}
          />
      </Grid>
      }
      {(periodType === 'Monthly' ||periodType === 'Yearly' || periodType === 'Quarterly') &&
              <Grid item md={1.8}>
          <div>
          {/* <label
            for="Select Year"
            className="block mb-2 text-base font-medium text-gray-900 "
          >
            Select Year
            <span class="text-red-500 text-[23px] font-extrabold pl-2 pt-3">*</span>
          </label> */}
          <select
            id="Select Year"
            name="Select Year"
            required
            onChange={(e) => {setSelectedYear(e.target.value); } }            
            value={selectedYear}        
                className=" border border-gray-300 text-sm font-medium rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full text-gray-500 p-2.5"

            // className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
          >
            <option value="">Select Year</option>      
            {years.map((year) => (
              <option key={year} value={year} sx={{ margin: 0, padding: '1px 16px' }}>
                {year}
              </option>
            ))}               
          </select>
        </div>
        </Grid>       

      }        
            <Grid item  >
            <Button variant="contained" 
          endIcon={<SendIcon />}  onClick={()=>{handleRun(); generateUSDateTime()}}  disabled={!isFormComplete()}  
          sx={{
            backgroundColor: 'rgb(231 70 148)',  // Pink color
            color: 'white',
            //  marginTop: '36px',
             height:'43px',
            '&:hover': {
              backgroundColor: 'rgb(231 70 148)',  // Darker shade of pink on hover
            }
          }}
          >
               Run
           </Button>
           </Grid>
          </Grid>
        {/* </Paper> */}
      </Box>
      {RunClicked &&
//       <Box sx={{ padding: 4, maxWidth: '100%', backgroundColor: '#ffff', paddingTop:0, paddingBottom:0 }}>
//         <Paper elevation={3}
//              style={{
//               padding: '8px',
//               paddingTop: '8px',
//               display: 'flex',
//               justifyContent: 'center',  // Centers horizontally
//               alignItems: 'center',      // Centers vertically
//             }}
//          sx={{ padding: 4,paddingTop:'0px', borderRadius: 4,alignItems:'center', boxShadow: '0 0 20px 0 rgba(0, 0, 0, .2)',
//  }}>


<div className="flex justify-between space-x-4 p-8 ml-[6px] mr-[10px]">
  {/* Card 1 */}
  <div className="flex flex-col items-center justify-between w-1/5 bg-white rounded-lg p-4 relative hover:scale-105"
  
  style={{ boxShadow: '0 0 20px 0 rgba(0, 0, 0,.2)',
    height: '90px', // Adjust as per your desired height
  
  }}
  >
  {/* Label */}
  <div className="flex justify-between w-full">
    <span className="text-sm text-gray-500 font-bold">{items[0].name}</span>
  </div>

  {/* Number */}
  <div>
  <h2 className="absolute bottom-2 left-3  text-[23px] font-bold text-blue-500 mt-2"

  >{items[0].number}</h2>
  </div>

  {/* Arc with Icon */}
  <div className="absolute bottom-3 right-3 rounded-tl-full   flex items-center justify-center text-blue-500">
    {/* <div className="bg-red-300 p-2  mt-4 ml-4"> */}
    {/* <div className='  className="w-11 h-11 object-contain mb-4 mr-5"
'> */}
  {items[0].icon}
  {/* </div> */}

    {/* </div> */}
  </div>
</div>


  {/* Card 2 */}
  <div className="flex flex-col items-center justify-between w-1/5 bg-white rounded-lg  p-4 relative hover:scale-105"
  style={{ boxShadow: '0 0 20px 0 rgba(0, 0, 0,.2)',
    height: '90px' // Adjust as per your desired height

  }}

  >
  <div className="flex justify-between w-full">
    <span className="text-sm text-gray-500 font-bold">{items[1].name}</span>
  
  </div>
  <div>

  <h2 className="absolute bottom-2 left-3    text-[23px] font-bold text-green-500 mt-2"

  >${items[1].number}</h2>
  </div>
  {/* Arc with Icon */}
  <div className="absolute bottom-3 right-3 rounded-tl-full   flex items-center justify-center text-green-500">

 {items[1].icon}
  </div>
</div>


  {/* Card 3 */}
  <div className="flex flex-col items-center justify-between w-1/5 bg-white rounded-lg p-4 relative hover:scale-105"
    style={{ boxShadow: '0 0 20px 0 rgba(0, 0, 0,.2)',
      height: '90px' // Adjust as per your desired height

    }}

  >
    <div className="flex justify-between w-full">
      <span className="text-sm text-gray-500 font-bold">{items[2].name}</span>
    
    </div>
    <div>
    <h2 className="absolute bottom-2 left-3   text-[23px] font-bold text-purple-500 mt-2"
    >${items[2].number}</h2>
    </div>
    <div className="absolute bottom-3 right-3 rounded-tl-full   flex items-center justify-center text-purple-500">

    {items[2].icon}
    </div>
  </div>

  {/* Card 4 */}
  <div className="flex flex-col items-center justify-between w-1/5 bg-white rounded-lg  p-4 relative hover:scale-105"
    style={{ boxShadow: '0 0 20px 0 rgba(0, 0, 0,.2)',
      height: '90px' // Adjust as per your desired height

    }}

  >
    <div className="flex justify-between w-full">
      <span className="text-sm text-gray-500 font-bold">{items[3].name}</span>
    </div>
    <div>
    <h2 className="absolute bottom-2 left-3   text-[23px] font-bold text-green-500 mt-2 "
     >${items[3].number}</h2>
     </div>
    <div className="absolute bottom-3 right-3 rounded-tl-full   flex items-center justify-center text-green-500">

    {items[3].icon}

    </div>
  </div>

  {/* Card 5 */}
  <div className="flex flex-col items-center justify-between w-1/5 bg-white rounded-lg p-4 relative hover:scale-105 "
    style={{ boxShadow: '0 0 20px 0 rgba(0, 0, 0,.2)',
      height: '90px' // Adjust as per your desired height

    }}

  >
    <div className="flex justify-between w-full">
      <span className="text-sm text-gray-500 font-bold">{items[4].name}</span>
     
    </div>
    <div>
    <h2 className="absolute bottom-2 left-3    text-[23px] font-bold text-red-500 mt-2"
   
    >${items[4].number}</h2>
    </div>
    <div className="absolute bottom-3 right-3 rounded-tl-full   flex items-center justify-center text-red-500">

    {items[4].icon}
    </div>
  </div>
{/* card 6*/}
<div className="flex flex-col items-center justify-between w-1/5 bg-white rounded-lg p-4 relative hover:scale-105 "
    style={{ boxShadow: '0 0 20px 0 rgba(0, 0, 0,.2)',
      height: '90px' // Adjust as per your desired height

    }}

  >
    <div className="flex justify-between w-full">
      <span className="text-sm text-gray-500 font-bold">{items[5].name}</span>
     
    </div>
    <div>
    <h2 className="absolute bottom-2 left-3   text-[23px] font-bold text-yellow-500 mt-2"
    
    >${items[5].number}</h2>
    </div>
    <div className="absolute bottom-3 right-3 rounded-tl-full   flex items-center justify-center text-yellow-500">

    {items[5].icon}
    </div>
  </div>

</div>

      //   </Paper>
      // </Box>
}
      {
      RunClicked ?
  <div id="runclicked">
   {RunClicked &&
        
    <div style={{  borderRadius: "20px",
      maxWidth: '100%',
      overflowX: 'auto',
      margin: '0 auto', 
      flexDirection: 'column',
                    boxShadow: '0 0 25px 0 rgba(0, 0, 0, 0.2)',
                   margin:'55px',
                   marginTop:'45px',
                   paddingBottom:'80px',
                   margin:'35px',
                   padding:'20px',
                   justifyContent:'center',
                   display:'flex',
                   flexDirection:'column'
          }}>
    {invoiceResponse && invoiceResponse.data && Array.isArray(invoiceResponse.data.invoice_data) && invoiceResponse.data.invoice_data.length > 0 ?
    <Grid container alignItems="end" justifyContent="end" >
      {/* PDF Download Icon */}
     <Grid item xs={12} md={4}>
        <Box p={2} className="font-bold text-md text-gray-400">
        {
            RunClicked && <p>Report RunTime: {dateTime} </p>

        }
        </Box>
    
      
     </Grid>
     <Grid item xs={12} md={8}>
     <Grid container alignItems="end" justifyContent="end" >

      <Grid item>
      <Tooltip title="Pdf" position="top" trigger="mouseenter">

        <IconButton aria-label="Download PDF"  
             sx={{
                backgroundColor: 'transparent', // No background color initially
                borderRadius: '50%', // Circular shape
                padding: '12px', // Adjust padding for the circular button
                '&:hover': {
                  backgroundColor: 'rgba(227, 140, 151, 0.3)', // Light background color on hover
                },
                transition: 'background-color 0.3s ease', // Smooth transition effect
              }}
              onMouseEnter={() => setPdfHovered(true)}
              onMouseLeave={() => setPdfHovered(false)}
 >
         { pdfhovered ?<FileDownloadIcon sx={{ fontSize: 20, color: 'red' }} /> : <PictureAsPdfIcon fontSize="small" color="error" />}
        </IconButton>
        </Tooltip>
      </Grid>
      <Grid item>
      <Tooltip title="Print" position="top" trigger="mouseenter">
        <IconButton aria-label="Print" onClick={handlePrint}
                sx={{
                    backgroundColor: 'transparent', // No background color initially
                    borderRadius: '50%', // Circular shape
                    padding: '12px', // Adjust padding for the circular button
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.1)', // Light background color on hover
                    },
                    transition: 'background-color 0.3s ease', // Smooth transition effect
                  }}
        
        >
          <PrintIcon fontSize="small" sx={{color:'black'}}/>
        </IconButton>
        </Tooltip>
      </Grid>

      {/* Tax File Icon */}
      <Grid item>
      <Tooltip title="Excel" position="top" trigger="mouseenter">

        <IconButton aria-label="Tax File"
            sx={{
                backgroundColor: 'transparent', // No background color initially
                borderRadius: '50%', // Circular shape
                padding: '12px', // Adjust padding for the circular button
                '&:hover': {
                  backgroundColor: 'rgba(143, 191, 96, 0.3)', // Light background color on hover
                },
                transition: 'background-color 0.3s ease', // Smooth transition effect
              }}
              onMouseEnter={() => setTaxHovered(true)}
              onMouseLeave={() => setTaxHovered(false)}
        
        >
          {
            taxhovered ? <FileDownloadIcon sx={{ fontSize: 20, color: 'red' }} />: <DescriptionIcon fontSize="small" style={{ color: '#4CAF50' }} />

          }
        </IconButton>
        </Tooltip>

      </Grid>
      </Grid>
      </Grid>
    </Grid>
    :''}

    <div ref={contentRef} style={{width:'100%',
         height:'auto',
         justifyContent:'center',
          alignSelf:"center",
          borderRadius: "10px",
          boxShadow: '0 0 20px 0 rgba(0, 0, 0, .1)',
          padding:'20px',
          marginTop:'20px'
          }}>

<div style={{  display: 'flex',
    alignItems: 'center',
    height:'110px',
    justifyContent: 'space-between',
    marginBottom:'20px',
    backgroundColor: 'rgba(180,180,180,0.1)'}}>
      {/* Left Image */}
      {/* <img
        src="https://via.placeholder.com/60"
        alt="Left Logo"
        style={{ width: '60px', // Set the size of the images
            height: '60px'}}
      /> */}
      {/* <img src="/static/media/logo.0ea4b695a3030ea659a8.png" alt="flownia" class="w-max" ></img> */}
              {userRole !== "1" && (
          <div className="">
            {imageSource ? (
              <img
                style={{ width: '140px',
                    height: '60px'}}
                src={`${baseURL}uploads/img/business/${imageSource}`} alt="Selected" />
            ) : (
              <p>No image selected</p>
            )}
          </div>
        )}

      {/* Middle: Three Texts Stacked */}
      <div style={{textAlign: 'center',}}>
        <p>    <strong style={{ fontWeight: '600', fontSize: '18px', margin: '5px' }}>
        {imageResponse.business_name}</strong></p>
        <p>    <strong style={{ fontWeight: '600', fontSize: '18px', margin: '5px' }}>
        {imageResponse.business_address}</strong></p>
        <p>    <strong style={{ fontWeight: '600', fontSize: '18px', margin: '5px' }}>
        {imageResponse.business_city}, 
            {imageResponse.business_state} , {imageResponse.business_zip_code}
            </strong></p>
      </div>

      {/* Right Image */}
      <img src="/static/media/logo.0ea4b695a3030ea659a8.png" alt="flownia" class="w-max" style={{ width: '240px', // Set the size of the images
            height: '200px'}}></img>
    </div>
    {RunClicked &&
    (
      <>
  <div 
    style={{ 
      color: '#1976d2', 
      fontSize: '20px', 
      fontWeight: 'bold', 
      display: 'flex', 
      justifyContent: 'center', 
      textAlign: 'center', // Center text within the div
      marginBottom: '10px', // Added margin for separation from the second div
      marginRight:'110px'
    }} 
  >
             {(tempPeriodType === 'Yearly' && tempYear && RunClicked)  &&  `${tempYear} - Invoices - ${invoiceResponse?.data?.customerName === "all" ? "All Customers" : invoiceResponse?.data?.customerName} -  ${tempinvoiceType === "all" ? "All Statuses" : tempinvoiceType}  `}
     {(tempPeriodType === 'Monthly' && tempYear && tempMonth && RunClicked) &&`  ${tempMonth} - ${tempYear} -Invoices - ${invoiceResponse?.data?.customerName === "all" ? "All Customers" : invoiceResponse?.data?.customerName} - ${tempinvoiceType === "all" ? "All Statuses" : tempinvoiceType}  `}
         {(tempPeriodType === 'Quarterly' && tempYear && tempQuarterName && RunClicked) && ` ${tempQuarterName == 'Q1' ? 'Q1 (Mar-May)' :''} ${tempQuarterName == 'Q2' ? 'Q2 (June-Aug)':'' } ${tempQuarterName == 'Q3' ? 'Q3 (Sep-Nov)' :'' } ${tempQuarterName == 'Q4' ? 'Q4 (Dec-Feb)' :'' } - ${tempYear} - ${invoiceResponse?.data?.customerName === "all" ? "All Customers" : invoiceResponse?.data?.customerName} - ${tempinvoiceType === "all" ? "All Statuses" : tempinvoiceType}`}
    {tempPeriodType === 'dateWise' && tempDate && RunClicked && `${formatDatetodisplay(tempDate)} - Invoices - ${tempinvoiceType === "all" ? "All Statuses" : tempinvoiceType} `}

  </div>
  <hr className="border-t border-gray-300 mb-1 mt-2" />

  
  {/* <div 
    style={{ 
      display: 'flex', 
      justifyContent: 'space-between', 
      alignItems: 'center', // Center vertically
      padding: '10px', 
      color: '#1976d2',  
      fontSize: '18px', 
      fontWeight: 'bold', 
      margin:'auto'
    }}
  >
    <div className='ml-32'>{`Total Invoices: ${invoiceResponse.data?.totalInvoices}`}</div>
    
    <div className='mr-8' >
      {invoiceResponse?.data?.customerId === "all" 
        ? "All Customers" 
        : invoiceResponse?.data?.invoice_data[0]?.business_name}
    </div>
    
    <div className='mr-24'>{`Final Invoice Amount: ${formatNumber(FinalInvoiceAmount())}`}</div>
  </div>
  
  <hr className="border-t border-gray-300 mb-1 mt-2" /> */}
</>

    
      
   )}

  <InvoiceReportTable tableInstance={tableInstance} invoiceResponse = {invoiceResponse}/>
    </div>
   <InvoicePagination tableInstance= {tableInstance} invoiceResponse = {invoiceResponse} />
    
    </div>
}
    <hr/>
    </div>
    :
    <div style={{height: '600px'}}></div>
        }
    </>
    </div>
  );
};

export default InvoiceReport;
