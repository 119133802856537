import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allNotifications: [],
};

const adminNotificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    addNotification(state, action) {
      // console.log("act", action.payload);
      if (Array.isArray(action.payload)) {
        const newNotifications = action.payload.filter(newNotification =>
          !state.allNotifications.some(existingNotification => existingNotification.id === newNotification.id)
        );
        state.allNotifications.push(...newNotifications);
      } else {
        // Add single customer only if it doesn't already exist
        if (!state.allNotifications.some(existingNotification => existingNotification.id === action.payload.id)) {
          state.allNotifications.push(action.payload);
        }
      }
    },
    deleteNotification(state, action) {
      state.allNotifications = state.allNotifications.filter(
        notification => notification.id !== action.payload
      );
    },
    deleteAllNotification(state) {
      state.allNotifications = []; 
    }
  },
});

export const { addNotification, deleteNotification , deleteAllNotification} = adminNotificationSlice.actions;
export default adminNotificationSlice.reducer;
