
import React, { useEffect } from "react";
import { useState } from "react";
import { FaEnvelope, FaLock, FaSignInAlt } from "react-icons/fa";
// import img1 from "../../assets/images/Login.jpg"
// import img2 from "../../assets/images/snapedit_1701426729284.png";
import { IoPerson } from "react-icons/io5";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup"
import Header from "../Headerside";
import { RiLoginCircleFill } from "react-icons/ri";
import { MdOutlineCancel } from "react-icons/md";
import { MdAttachEmail } from "react-icons/md";  
import { FaMobileButton } from "react-icons/fa6";
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { FaCheckCircle } from "react-icons/fa";
import {  FaEye, FaEyeSlash } from 'react-icons/fa';
import { MdBusinessCenter } from "react-icons/md";
import config  from "../../config/config"



const SignUpPage = ({ onLogin  , handleCancelClick}) => {

  const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError , setPasswordError] = useState("")

  const [formData, setFormData] = useState({
    email: "",
  });

  // const [passwordVisible, setPasswordVisible] = useState(false);
  // const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);


  const [showDomainNameTooltip, setShowDomainNameTooltip] = useState(null);
  const [showUserNameTooltip, setShowUserNameTooltip] = useState(null);
  const [showMobileNumberTooltip, setShowMobileNumberTooltip] = useState(null);

  const [showPasswordTooltip, setShowPasswordTooltip] = useState(null);
  const [showConfirmPasswordTooltip, setShowConfirmPasswordTooltip] = useState(null);
  const [invalidPhoneNumber , setInvalidPhoneNumber] = useState("")

  const CustomTooltip1 = ({ content, show, marginLeft }) => {
    return (
      <div
        style={{
          display: show ? 'block' : 'none',
          position: 'absolute',
          backgroundColor: '#ffffff',
          color: '#fff',
          padding: '5px',
          borderRadius: '4px',
          border:'1px solid red',
          marginTop: '-35px',
          marginLeft: `${marginLeft}px`,  // Use the provided marginLeft prop
          boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        }}
      >
        <div
          style={{
            position: 'absolute',
            bottom: '-7px',
            left: '50%',
            marginLeft: '-10px',
            width: '0',
            height: '0',
            borderLeft: '7px solid transparent',
            borderRight: '7px solid transparent',
            borderTop: '7px solid #f98080',
          }}
        ></div>
        <p className="text-xs text-red-500">{content}</p>
      </div>
    );
  };

  const DomainNameTooltip = ({ content, styles }) => {
    return (
      <div
        style={{
          display: content ? "block" : "none",
          position: "absolute",
          backgroundColor: "#ffffff",
          color: "#fff",
          padding: "5px",
          borderRadius: "4px",
          border: `1px solid ${styles.borderColor || "red"}`, // Adjust border color as needed
          left: "309px", // Adjust this value as needed
          marginTop: "-3px",
          right: "20px", // Adjust this value as needed
          zIndex: 1,
          width: "225px",
          ...styles,
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "-7px",
            marginTop: "-7px",
            width: "0",
            height: "0",
            borderTop: "7px solid transparent",
            borderBottom: "7px solid transparent",
            borderRight: `7px solid ${
              styles.arrowStyle?.borderTopColor || "red" // Adjust arrow color as needed
            }`,
          }}
        ></div>
        <p className="text-xs text-red-500">{content}</p>
      </div>
    );
  };
  


  const UsernameTooltip = ({ content, styles }) => {
       return (
      <div
        style={{
          display: content ? "block" : "none",
          position: "absolute",
          backgroundColor: "#ffffff",
          color: "#fff",
          padding: "5px",
          borderRadius: "4px",
          border: `1px solid ${styles.borderColor || "red"}`,
          left : "309px", // Adjust this value as needed
          marginTop: "-3px",
          right: "20px", // Adjust this value as needed
          zIndex: 1,
          width : "205px",
          ...styles,
        }}
      >
        <div
          style={{
          position: "absolute",
          top: "50%",
          left: "-7px",
          marginTop: "-7px",
          width: "0",
          height: "0",
          borderTop: "7px solid transparent",
          borderBottom: "7px solid transparent",
          borderRight: `7px solid ${styles.arrowStyle?.borderTopColor || "red"}`,
          }}
        ></div>
        <p className="text-xs text-red-500">{content}</p>
      </div>
    );
  };
  
 


  const EmailTooltip = ({ content, styles }) => {
    return (
      <div
      style={{
        display: content ? "block" : "none",
        position: "absolute",
        backgroundColor: "#ffffff",
        color: "#fff",
        padding: "5px",
        borderRadius: "4px",
        border: `1px solid ${styles.borderColor || "red"}`,
        left : "309px", // Adjust this value as needed
        marginTop: "-3px",
        right: "20px", // Adjust this value as needed
        zIndex: 1,
        width : "125px",
        ...styles,
      }}
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "-7px",
            marginTop: "-7px",
            width: "0",
            height: "0",
            borderTop: "7px solid transparent",
            borderBottom: "7px solid transparent",
            borderRight: `7px solid ${styles.arrowStyle?.borderTopColor || "red"}`,
            }}
        ></div>
        <p className="text-xs text-red-500">{content}</p>
      </div>
    );
  };
  

  const MobileNumberTooltip = ({ content, styles }) => {
    return (
      <div
        style={{
          display: content ? "block" : "none",
          position: "absolute",
          backgroundColor: "#ffffff",
          color: "#fff",
          padding: "5px",
          borderRadius: "4px",
          border: `1px solid ${styles.borderColor || "red"}`,
          left : "308px", 
          marginTop: "-3px",
          right: "18px", 
          zIndex: 1,
          width: "140px", // Adjust this value as needed
          ...styles,
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "-7px",
            marginTop: "-7px",
            width: "0",
            height: "0",
            borderTop: "7px solid transparent",
            borderBottom: "7px solid transparent",
            borderRight: `7px solid ${styles.arrowStyle?.borderTopColor || "red"}`,
          }}
        ></div>
        <p className="text-xs text-red-500">{content}</p>
      </div>
    );
  };

  const PasswordTooltip = ({ content, styles }) => {
    return (
      <div
        style={{
          display: content ? "block" : "none",
          position: "absolute",
          backgroundColor: "#ffffff",
          color: "#fff",
          padding: "5px",
          borderRadius: "4px",
          border: `1px solid ${styles.borderColor || "red"}`,
          left : "309px",
          marginTop: "19px",
          right: "18px",
          width : "165px",
          zIndex: 1,
          ...styles,
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "-7px",
            marginTop: "-7px",
            width: "0",
            height: "0",
            borderTop: "7px solid transparent",
            borderBottom: "7px solid transparent",
            borderRight: `7px solid ${styles.arrowStyle?.borderTopColor || "red"}`,
          }}
        ></div>
        <p className="text-xs text-red-500">{content}</p>
      </div>
    );
  };

  const ConfirmPasswordTooltip = ({ content, styles }) => {
    return (
      <div
        style={{
          display: content ? "block" : "none",
          position: "absolute",
          backgroundColor: "#ffffff",
          color: "#fff",
          padding: "5px",
          borderRadius: "4px",
          border: `1px solid ${styles.borderColor || "red"}`,
          left : "309px",
          marginTop: "19px",
          right: "18px",
          width : "165px",
          zIndex: 1,
          ...styles,
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "-7px",
            marginTop: "-7px",
            width: "0",
            height: "0",
            borderTop: "7px solid transparent",
            borderBottom: "7px solid transparent",
            borderRight: `7px solid ${styles.arrowStyle?.borderTopColor || "red"}`,
          }}
        ></div>
        <p className="text-xs text-red-500">{content}</p>
      </div>
    );
  };

  // const shouldDisableSubmit = () => {
  //   return (
  //     showTooltip() ||
  //     showUserNameTooltip ||
  //     showMobileNumberTooltip ||
  //     (formik.values.password &&
  //       typeof formik.values.password === 'string' &&
  //       formik.values.password.length > 0 &&
  //       formik.values.password.length < 6) ||
  //     (formik.values.confirmPassword &&
  //       typeof formik.values.confirmPassword === 'string' &&
  //       formik.values.confirmPassword.length > 0 &&
  //       formik.values.confirmPassword.length < 6)
  //   );
  // };
  
  

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email("Invalid email address")
        .test({
          name: 'custom-email-validation',
          test: (value) => {
            return /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/.test(value);
          },
          message: 'Invalid email address',
        }),
    }),
    initialTouched: { 
      email: true,
    },
  });

  const handleLogin = () => {


  };

  let navigate = useNavigate();

  const handleForgotPassword = () => {
    navigate("/forgotpassword");
  };

  const handleSignUpClick =(e)=>{
      e.preventDefault()

    console.log("Register Data:" , {
      domainName : formik.values.domainName,
      userName :  formik.values.userName,
      userEmail : formik.values.email,
      contactNumber : formik.values.mobileNumber
     });

   
              const response = axios.post(`${baseURL}/${btoa("login/managersignup")}` , {
                domainName : formik.values.domainName,
                userName :  formik.values.userName,
                userEmail : formik.values.email,
                contactNumber : formik.values.mobileNumber
              })
              .then((response)=>{
               
                console.log("response" , response);
               
                if (response.data.dataSavingStatus === true && response.data.otpSentStatus === true) {
                  // alert(`signUp successfull "This is your password for login ${response.data.passWord}`)
                  toast.success(`${formik.values.userName} you have been registered successfully` , {autoClose:1500}) // Call the function to show the login success toast
                 
                } else {
                  // Handle other conditions based on your API response
                  console.log("Signup was not successful");
                }
                handleCancelClick();
              })
             
              .catch((error)=>{
                console.log("error posting details " , error);
                if( error.response.data.userStatus === "Email already exist" && error.response.data.dataSavingStatus === false){
                  setPasswordError("Email / Mobile number already exist")
                }
                if(error.response.data.userStatus === "Domain name does not exist" &&
                   error.response.data.dataSavingStatus === false
                ){
                  toast.error("Domain name does not exist")
                }
              })
      }
  

  const showLoginSuccessToast = (userName) => {
    toast.success(
      <div className="flex justify-between items-center text-green-500">
        <div className="rounded-lg">{`${userName} , you have been successfully registered`}</div>
        <div className="rounded-lg">
          <FaCheckCircle className="mr-4 text-green-500" size={24} />
        </div>
      </div>,
      {
        position: "top-left",
        hideProgressBar: true,
        autoClose: 4000,
        icon: false,
        className: "w-[643px] ml-[430px] mt-[70px]", // Full width

        style: {
          borderLeft: "4px solid #4CAF50", // Green border
          background: "#e8f5e9", // Light green background
          minHeight: '10px',
        },
      }
    );
  };
  


  const validateEmail =(email)=>{
    const emailRegex = /^.+@.+\..+$/
    return emailRegex.test(email)
  }

  const showTooltip = () => {
    const isInvalidEmail = !validateEmail(formik.values.email);
    return (
      formik.touched.email &&
      formik.values.email.length > 0 &&
      isInvalidEmail && {
        borderColor: "red",
        arrowStyle: {
          borderTopColor: "red",
        },
      }
    );
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    formik.handleChange(e);
    formik.setFieldValue(name, value.trim());
    setFormData((prevData) => ({
      ...prevData,
      [name]: value.trim(), 
    }));
   
  };

  function formatPhoneNumber(value) {
    // Check if value is undefined or null
    if (value == null) {
      return '';
    }
  
    // Remove non-digit characters
    const digitsOnly = value.replace(/\D/g, '');
  
    // Limit to a maximum of 10 digits
    const limitedDigits = digitsOnly.slice(0, 10);
  
    // Apply formatting based on the length
    if (limitedDigits.length >= 4 && limitedDigits.length <= 6) {
      return `(${limitedDigits.slice(0, 3)})${limitedDigits.slice(3)}`;
    } else if (limitedDigits.length >= 7) {
      return `(${limitedDigits.slice(0, 3)}) ${limitedDigits.slice(3, 6)}-${limitedDigits.slice(6)}`;
    } else {
      return limitedDigits;
    }
  }
  



  const handlePhoneNumberChange = (fieldName, e) => {
    const inputValue = e.target?.value ?? ''; 
  
    const formattedValue = formatPhoneNumber(inputValue);
  
    formik.handleChange({
      target: {
        name: fieldName,
        value: formattedValue,
      },
    });
  };


  const isUserNameValid = (userName) => {
   
    return /^[a-zA-Z]+$/.test(userName) && userName.length >= 3;
  };

  const isDomainNameValid = (domainName) => {
   
    return /^[a-zA-Z]+$/.test(domainName) && domainName.length >= 3;
  };
  



  return (
    <div className="flex w-screen h-screen justify-center items-center flex-col">
      <div className="w-full h-screen bg-[url('./assets/images/LoginImage.png')] bg-no-repeat bg-center bg-[length:100%_100%]">

      <div className="fixed flex flex-col t  md:ml-[250px] md:mt-28 z-50">
            <div>
              <h2 className="text-2xl  font-light tracking-widest text-gray-700">
              Please register your account with given Domain name
              </h2>
            </div>
         
          <div>
          <p className="text-l mt-10 text-gray-500 tracking-widest font-sans ">
              USER REGISTER
            </p>
            <form className="mt-4">
              <div className="rounded-md">
      

                <div className="flex items-center mt-5">
                  <UsernameTooltip content={showUserNameTooltip} styles={{}} />
                  <div className="relative">
                    <IoPerson
                      className="text-gray-500 absolute top-1/2 transform -translate-y-1/2 left-3"
                      style={{ color: "#20beca", fontSize: "20px" }}
                    />
                    <input
                      id="userName"
                      name="userName"
                      value={formik.values.userName}
                      onChange={(e) => {
                        const inputUserName = e.target.value;
                    
                        // Restrict input to alphabets only
                        const sanitizedUserName = inputUserName.replace(/[^a-zA-Z\s]/g, '');
                    
                        formik.setFieldValue('userName', sanitizedUserName);
                    
                        if (sanitizedUserName.trim().length < 3) {
                          const tooltipMessage = "Username should be at least 3 char";
                          setShowUserNameTooltip(tooltipMessage);
                        } else {
                          setShowUserNameTooltip(null);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      type="text"
                      required
                      className={`input-field ${
                        showTooltip() || showUserNameTooltip ? "border-red-500" : "border-gray-300"
                      }`}
                      placeholder="User name"
                      
                      style={{
                        borderRadius: "5px",
                        
                        width: "300px",
                        height: "38px",
                        border: "2px solid grey",
                        paddingLeft: "40px",
                      }}  
                    />
                  </div>
                </div>

                


                <div className="flex items-center mt-5 ">
                    {showTooltip() && (
                      <EmailTooltip content="Invalid email address" styles={showTooltip()} />
                    )}
                  <div className="relative">
                    <MdAttachEmail
                      className="text-gray-500 absolute top-1/2 transform -translate-y-1/2 left-3"
                      style={{ color: "#20beca", fontSize: "18px" }}
                    />
                    <input
                      id="email"
                      name="email"
                      value={formik.values.email}
                      onChange={(e) => {
                        handleInputChange(e);
                        formik.setFieldValue('email', e.target.value.trim());
                      }}
                      onBlur={formik.handleBlur}
                      type="email"
                      required
                      className={`input-field ${
                        showTooltip() ? "border-red-500" : "border-gray-300"
                      }`}
                      placeholder="Email"
                      
                      style={{
                        borderRadius: "5px",
                      
                        width: "300px",
                        height: "38px",
                        border: "2px solid grey",
                        paddingLeft: "40px",
                      }}
                      
                      
                    />
                  </div>
                </div>

                    {/* Mobile number field */}


                <div className="flex items-center mt-5">
                  <MobileNumberTooltip content={showMobileNumberTooltip} styles={{}} />
                  {invalidPhoneNumber === "Invalid number" && showMobileNumberTooltip === null && (
                        <MobileNumberTooltip content={"Invalid number"} styles={{}} />
                    )} 


                  <div className="relative">
                    <FaMobileButton 
                      className="text-gray-500 absolute top-1/2 transform -translate-y-1/2 left-3"
                      style={{ color: "#20beca", fontSize: "18px" }}
                    />
                    <input
                      id="mobileNumber"
                      name="mobileNumber"
                      
                     
                      value={formatPhoneNumber(formik.values.mobileNumber)}
                      onChange={(e) => {
                        handleInputChange(e);
                        formik.setFieldValue('mobileNumber', e.target.value.trim());
                        const containsNonZero = /[1-9]/.test(e.target.value);
                        if(containsNonZero){
                          setInvalidPhoneNumber("valid")
                        }else{
                          setInvalidPhoneNumber("Invalid number")
                        }
                        if(e.target.value === ""){
                          setInvalidPhoneNumber("")
                        }
                        
                        if (e.target.value.trim().length < 13) {
                          const tooltipMessage = "Invalid Mobile no format";
                          setShowMobileNumberTooltip(tooltipMessage);
                        } else {
                          setShowMobileNumberTooltip(null);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      
                      required
                      className={`input-field ${
                        showTooltip() ? "border-red-500" : "border-gray-300"
                      }`}
                      placeholder="Mobile no"
                      
                      style={{
                        borderRadius: "5px",
                        width: "300px",
                        height: "38px",
                        border: "2px solid grey",
                        paddingLeft: "40px",
                      }}
                      
                      
                    />
                  </div>
                </div>


                <div className="flex items-center mt-5">
                  <DomainNameTooltip content={showDomainNameTooltip} styles={{}} />
                  <div className="relative">
                    <MdBusinessCenter
                      className="text-gray-500 absolute top-1/2 transform -translate-y-1/2 left-3"
                      style={{ color: "#20beca", fontSize: "18px" }}
                    />
                    <input
                      id="domainName"
                      name="domainName"
                      value={formik.values.sanitizedDomainName}
                      onChange={(e) => {
                        const inputDomainName = e.target.value;
                        const sanitizedDomainName = inputDomainName.replace(/[^a-zA-Z0-9\s]/g, '');

                        formik.setFieldValue('domainName', sanitizedDomainName);
                        if (sanitizedDomainName.trim().length < 3) {
                          const tooltipMessage = "Domain Name should be at least 3 char";
                          setShowDomainNameTooltip(tooltipMessage);
                        } else {
                          setShowDomainNameTooltip(null);
                        }
                  
                      }}
                      onBlur={formik.handleBlur}
                      type="text"
                      required
                      className={`input-field ${
                        showTooltip() || showDomainNameTooltip  ? "border-red-500" : "border-gray-300"
                      }`}
                      placeholder="Domain name"
                      
                      style={{
                        borderRadius: "5px",
                        
                        width: "300px",
                        height: "38px",
                        border: "2px solid grey",
                        paddingLeft: "40px",
                      }}  
                    />
                  </div>
                </div>

                {/* Password Field */}
                
                {/* <div className="flex items-center ">
                <PasswordTooltip
                  content={
                    formik.values.password &&
                    typeof formik.values.password === 'string' &&
                    formik.values.password.length > 0 &&
                    formik.values.password.length < 6
                      ? "Password should be 6 digits"
                      : null
                  }
                  styles={{}}
                />
                  <div className="relative mt-5">
                    <FaLock
                      className="text-gray-500 absolute top-1/2 transform -translate-y-1/2 left-3"
                      style={{ color: "#20beca" }}
                    />
                    <input
                      id="password"
                      name="password"
                      type={passwordVisible ? 'text' : 'password'}
                      value={formik.values.password}
                      onChange={(e) => {
                        const sanitizedValue = e.target.value.replace(/[^0-9]/g, '');
                        const truncatedValue = sanitizedValue.slice(0, 6);

                        formik.handleChange(e); 
                        formik.setFieldValue('password', truncatedValue); 
                      }}
                      required
                     
                      className="input-field"
                      placeholder="Password"
                      style={{
                        borderRadius: "5px",
                       
                        width: "300px",
                        height: "38px",
                        border: "2px solid grey",
                        paddingLeft: "40px",
                      }}
                      
                    />
                          {formik.values && formik.values.password && formik.values.password.length > 0 &&(
                            <div
                              className="absolute top-1/2 transform -translate-y-1/2 right-3 cursor-pointer"
                              onClick={() => setPasswordVisible(!passwordVisible)}
                            >
                              {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                            </div>
                          )}
                  </div>
                </div> */}

                {/* Confirm Password field */}
{/* 
                <div className="flex items-center ">
                      <ConfirmPasswordTooltip
                        content={
                          formik.values.confirmPassword &&
                          typeof formik.values.confirmPassword === 'string' &&
                          formik.values.confirmPassword.length > 0 &&
                          formik.values.confirmPassword.length < 6
                            ? "Password should be 6 digits"
                            : null
                        }
                        styles={{}}
                      />
                  <div className="relative mt-5">
                    <FaLock
                      className="text-gray-500 absolute top-1/2 transform -translate-y-1/2 left-3"
                      style={{ color: "#20beca" }}
                    />
                    <input
                      id="confirmPassword"
                      name="confirmPassword"
                      type={confirmPasswordVisible ? 'text' : 'password'}
                      value={formik.values.confirmPassword || ''}
                      onChange={(e) => {
                        const sanitizedValue = e.target.value.replace(/[^0-9]/g, '');
                        const truncatedValue = sanitizedValue.slice(0, 6);
                
                        formik.handleChange(e);
                        formik.setFieldValue('confirmPassword', truncatedValue);
                      }}
                      required
                     
                      className="input-field"
                      placeholder="Confirm Password"
                      style={{
                        borderRadius: "5px",
                       
                        width: "300px",
                        height: "40px",
                        border: "2px solid grey",
                        paddingLeft: "40px",
                      }}
                    />
                       {formik.values && formik.values.confirmPassword && formik.values.confirmPassword.length > 0 &&(
                          <div
                            className="absolute top-1/2 transform -translate-y-1/2 right-3 cursor-pointer"
                            onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
                          >
                            {confirmPasswordVisible ? <FaEyeSlash /> : <FaEye />}
                          </div>
                        )}

                  </div>
                </div> */}
                {passwordError && <div style={{color:"red"}}>{passwordError}</div>}
              </div>

           

              <div className="mt-5 ml-2 flex space-x-2" style={{ marginTop: "25px" }}>

                  <button
                    type="submit"
                    // disabled={shouldDisableSubmit()}
                    className="ml-5 group relative shadow-2xl shadow-[#20beca] w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    style={{
                      width: "120px",
                      borderRadius: "25px",
                      fontWeight: "bold",
                      letterSpacing: "1px",
                      backgroundColor: "#20beca",
                      height: "36px",
                      alignItems: "center",
                    }}
                    onClick={handleSignUpClick}
                    disabled={
                      !formik.values.domainName || formik.values.domainName.length < 3 ||
                      !formik.values.userName || formik.values.userName.length < 3 ||
                      !formik.values.email || !formik.values.email.includes('@') || !formik.values.email.includes('.') ||
                      !formik.values.mobileNumber || formik.values.mobileNumber.length < 13 || invalidPhoneNumber === "Invalid number"
                    }
                    
                  >
                    <div
                      style={{
                        position: "absolute",
                        left: "85px", // Adjust as needed for the positioning of the circle
                        top: "50%",
                        transform: "translateY(-50%)",
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        backgroundColor: "#247eaf",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <RiLoginCircleFill size={"17px"} />
                    </div>

                    <span className="mr-7" style={{fontSize:"15px"}}>SUBMIT</span>
                  </button>

                  <button
                  type="submit"
                  className=" group relative shadow-2xl shadow-[#20beca] w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  style={{
                    width: "120px",
                    borderRadius: "25px",
                    fontWeight: "bold",
                    letterSpacing: "1px",
                    backgroundColor: "#20beca",
                    height: "36px",
                    alignItems: "center",
                  }}
                  onClick={handleCancelClick}
                >
                  <div
                    style={{
                      position: "absolute",
                      left: "85px", // Adjust as needed for the positioning of the circle
                      top: "50%",
                      transform: "translateY(-50%)",
                      width: "30px",
                      height: "30px",
                      borderRadius: "50%",
                      backgroundColor: "#247eaf",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <MdOutlineCancel size={"17px"} />
                  </div>

                  <span className="mr-7" style={{fontSize:"15px"}}>CANCEL</span>
                  </button>


                
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpPage;



