import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allCustomers: [],
};

const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    addCustomer(state, action) {
      // console.log("act", action.payload);
      if (Array.isArray(action.payload)) {
        const newCustomers = action.payload.filter(newCustomer =>
          !state.allCustomers.some(existingCustomer => existingCustomer.customer_id === newCustomer.customer_id)
        );
        state.allCustomers.push(...newCustomers);
      } else {
        // Add single customer only if it doesn't already exist
        if (!state.allCustomers.some(existingCustomer => existingCustomer.customer_id === action.payload.customer_id)) {
          state.allCustomers.push(action.payload);
        }
      }
    },
    deleteCustomer(state, action) {
      state.allCustomers = state.allCustomers.filter(
        customer => customer.customer_id !== action.payload
      );
    },
    deleteAllCustomers(state) {
      state.allCustomers = []; 
    }
  },
});

export const { addCustomer, deleteCustomer , deleteAllCustomers} = customerSlice.actions;
export default customerSlice.reducer;
