import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allLicenses : [],
};

const LicensesSlice = createSlice({
  name: "Licenses",
  initialState,
  reducers: {
    addLicenses(state, action) {
      console.log("act", action.payload);
      if (Array.isArray(action.payload)) {
        const newLicenses = action.payload.filter(newLicense =>
          !state.allLicenses.some(existingLicenses => existingLicenses.id === newLicense.id)
        );
        state.allLicenses.push(...newLicenses);
      } else {
        if (!state.allLicenses.some(existingLicenses => existingLicenses.id === action.payload.id)) {
          state.allLicenses.push(action.payload);
        }
      }
    },
    deleteLicenses(state, action) {
      state.allLicenses = state.allLicenses.filter(
        License => License.id !== action.payload
      );
    },
    deleteAllLicenses(state) {
        state.allLicenses = []; 
      }
  },
});

export const { addLicenses, deleteLicenses , deleteAllLicenses } = LicensesSlice.actions;
export default LicensesSlice.reducer;
