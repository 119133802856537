import React, { useState, useEffect } from 'react';
import { ColorRing } from 'react-loader-spinner';

const FullScreenLoader = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Set a timer to hide the loader after 3 seconds
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);

    // Clear the timer if the component unmounts before the 3 seconds are up
    return () => clearTimeout(timer);
  }, []);

  return (
    loading ? (
      <div style={overlayStyle}>
        <ColorRing
          visible={true}
          height="80"
          width="80"
          ariaLabel="blocks-loading"
          wrapperStyle={{}}
          wrapperClass="blocks-wrapper"
          colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
        />
      </div>
    ) : null
  );
};

export default FullScreenLoader;

const overlayStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  backgroundColor: 'rgba(0, 0, 0, 0.4)',  // Semi-transparent background
  zIndex: 10000,  // High z-index to stay above all other elements
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
