import { createSlice } from '@reduxjs/toolkit';

// Initial state for the notifications
const initialState = {
  notifications: []
};

const ToastBoxMessageSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    addToast: (state, action) => {
      state.notifications.push({
        id: Date.now(), // Unique ID for each toast
        type: action.payload.type, // success, danger, info
        message: action.payload.message
      });
    },
    removeToast: (state, action) => {
      state.notifications = state.notifications.filter(
        (toast) => toast.id !== action.payload.id
      );
    }
  }
});

export const { addToast, removeToast } = ToastBoxMessageSlice.actions;

export default ToastBoxMessageSlice.reducer;
