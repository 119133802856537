import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { HiSpeakerWave } from "react-icons/hi2";

const HeaderScrollingMessage = ({ linkHref }) => {
  const user_role = localStorage.getItem("user_role");
  const scrollingMessages = useSelector((state) => state.scrollingMessage.message);

  if (user_role === "1" || !scrollingMessages || scrollingMessages.length === 0) {
    return null; // Return null if user_role is "1", no scrolling message, or an empty array
  }

  const today = new Date();
  const firstMessage = scrollingMessages[0];

  const startDate = new Date(firstMessage.start_date);
  const endDate = new Date(firstMessage.end_date);
  endDate.setHours(23, 59, 59, 999);

  if (today < startDate || today > endDate) {
    return null; // Skip rendering if today is not between start and end dates
  }

  const getMessageColor = (msg) => {
    if (msg.mesg_type === "Release") {
      return msg.business_id === "0" ? "#e11969" : "#e11969";
    } else if (msg.mesg_type === "Info") {
      return msg.business_id === "0" ? "black" : "red";
    }
    return "black";
  };

  return (
    <div
      key={firstMessage.id}
      className="w-[99%] mb-5 mt-5  text-black  overflow-hidden py-3 relative rounded-lg"
      // style={{ boxShadow: "0 0 15px rgba(0, 0, 0, 0.1)" }}
    >
      <div className="absolute top-0 left-0 bottom-0 border-pink-500 -ml-1 rounded-l-lg"></div>
      <div className="flex items-center whitespace-nowrap">
        <span className="mr-2 ml-3 text-2xl text-pink-500">
          <HiSpeakerWave />
        </span>
        <marquee
          className="scrolling-message flex-grow"
          behavior="scroll"
          direction="left"
          scrollamount="5"
          onMouseOver={(e) => { e.currentTarget.stop(); }}
          onMouseOut={(e) => { e.currentTarget.start(); }}
        >
          <b>
            {`Alert (${firstMessage.mesg_type}) : `}
            <a
              href={linkHref}
              className="hover:underline"
              style={{ color: getMessageColor(firstMessage) }}
            >
              {firstMessage.message}
            </a>
          </b>
        </marquee>
      </div>
    </div>
  );
};



export default HeaderScrollingMessage;
