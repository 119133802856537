import React, { useState, useMemo, useEffect, useRef } from 'react';
// import DatePicker from 'react-datepicker';
// import "react-datepicker/dist/react-datepicker.css";
import '../../components/pages/Css/viewbusinessusers.css'

// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DatePicker, Space } from "antd";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { FaChevronLeft, FaChevronRight , FaDownload} from 'react-icons/fa';
import { Tooltip } from "react-tippy";
import { AiFillEye } from "react-icons/ai";
import { FiDownload } from "react-icons/fi";
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField'; // Make sure this import is at the top
import { FaSearch, FaCalendarAlt } from 'react-icons/fa';
import { createColumnHelper ,
  useReactTable, 
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel, } from "@tanstack/react-table";
  import { useSelector , useDispatch } from 'react-redux'; 
  import { FaArrowDownLong , FaArrowUpLong} from "react-icons/fa6";
  import IndeterminateCheckbox from "./IndeterminateCheckbox";
  import { MdNavigateNext } from "react-icons/md";
  import { MdNavigateBefore } from "react-icons/md";
  import { FaEye, FaTrashAlt } from 'react-icons/fa';
  import config from "../../config/config";
import axios from 'axios';
import axiosInstance from '../../authentication/axiosInstance';
import { BorderColor, Receipt } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { addPurchases, deleteAllPurchases } from '../../store/purchasesSlice';
import PurchaseImagesViewModel from '../models/PurchaseImagesViewModel';
import PopUpModalForDeletePurchases from '../models/PurchasesDeleteModel';
import ScrollingMessage from './ScrollingMessage';
import FileUploadModal from './FileUploadModal';
import { useNavigate, Link } from "react-router-dom";
import FullScreenLoader from './FullScreenLoader';
import outlined from '@material-tailwind/react/theme/components/timeline/timelineIconColors/outlined';

  const Purchases = () => {
  
    const baseURL =
    process.env.NODE_ENV === "production"
      ? config.production.baseURL
      : config.development.baseURL;

  const columnHelper = createColumnHelper();
  const [selectedFuelBrand, setSelectedFuelBrand] = useState('');
  const [selectedBrandName , setSelectedBrandName] = useState("all")
  const [productOptions, setProductOptions] = useState([]);
  const [filteredStatus, setFilteredStatus] = useState("all");
  const [selectedInvoiceNumber , setSelectedInvoiceNumber] = useState("");
  const [clicked, setClicked] = useState(null);

  const [selectedInvoiceNumberforModel , setSelectedInvoiceNumberforModel] = useState("");

  const [value, setValue] = React.useState('draft');
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);

  const handleChange = (newValue) => {
    // if(newValue === "overdue") return;
    setValue(newValue);
    setFilteredStatus(newValue)
  };
  const [selectedDateFrom, setSelectedDateFrom] = useState(null);
  const [error, setError] = useState(false);
  const handleDateChangeFrom = (from) => {
 
     
    if (!from || !dayjs(from).isValid()) {
      // If the date is invalid or null, clear the state
      setSelectedDateFrom(null);
      return;
    }
    setSelectedDateFrom((from));
    setError(false)
  };
  const handleIconClick = (ref) => {
    if (ref.current) {
      ref.current.setFocus();
    }
  };
  const [selectedDateTo, setSelectedDateTo] = useState(null);

  const handleDateChangeTo = (to) => {
 
    if (!to || !dayjs(to).isValid()) {
      // If the date is invalid or null, clear the state
      setSelectedDateTo(null);
      return;
    }
    console.log(to, 'selectedDateTo.......')

    setSelectedDateTo((to));
  };
  console.log(selectedDateTo, 'selectedDateTo')
  const datePickerFromRef = useRef(null);
  const datePickerToRef = useRef(null);


  // This function will be passed to the child to handle the selected fuel brand
  const handleFuelBrandChange = (fuelBrand) => {
    setSelectedFuelBrand(fuelBrand.value);
    setSelectedText(fuelBrand.text)
  };
  const [createdDate, setCreatedOnDate] = useState(new Date());
  const [uploadModal, setUploadModal] = useState(false);

  const [currentMonthYear, setCurrentMonthYear] = useState('');
  const [fileName, setFileName] = useState(null);
  const [file, setFile] = useState(null);
  const [rowSelection, setRowSelection] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [sorting , setSorting] = React.useState([])
  const [filtering , setFiltering] = useState("")
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploadCount, setUploadCount] = useState(0);
  const fileInputRef = useRef(null);
  const [selectedImagePath , setSelectedImagePath] = useState("")
  const [imagesByDate , setImagesByDate] = useState([])
  const [openDeleteModalId, setOpenDeleteModalId] = useState(null);
  const [columnFilters, setColumnFilters] = useState("")
  const businessId = localStorage.getItem("business_id")
  const [errorMessage, setErrorMessage] = useState('');
  const managerId = localStorage.getItem("user_id")
  const dispatch = useDispatch()

  const allPurchases = useSelector(state => state.purchase.allPurchases)
  console.log("all purchases" , allPurchases);
  const handleChangeFuelBrands = (e) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    setSelectedBrandName(selectedOption.value);
    setSelectedText(selectedOption.text);
  };

  useEffect(() => {
    async function fetchPurchases() {
      setloading(true)
      try {
        const response = await axios.post(
          `${baseURL}${btoa("purchase/view")}`, {
            businessId:businessId,
              managerId:managerId,
              brandId:selectedBrandName,
            // fromMdy:(selectedDateFrom) === null ? '' : formatDatefordatepicker(selectedDateFrom) ,
            //   toMdy:(selectedDateTo) === null ? '': formatDatefordatepicker(selectedDateTo),
            monthName: formattedMonthName(),
            year:formattedYear(),
             brandName:selectedText
          }
        );
        console.log(response, 'purchase response')
        dispatch(deleteAllPurchases())
        dispatch(addPurchases(response.data.receiptmages))
        setSellerNames(response.data.brandsAndTotals);

        setMonthaname(response.data.monthName)
        setYear(response.data.year)
        setSunocoGallons(response.data.sunoco_totals[0].total_gallons)
        setGulfGallons(response.data.gulf_totals[0].total_gallons)
        console.log("fetch images" , response);
      } catch (error) {
        console.error("Error fetching receipt images :", error);
      } finally{
        setloading(false)

      }
    }
    fetchPurchases();

}, [uploadCount, selectedBrandName, selectedDateFrom, selectedDateTo, selectedInvoiceNumber, openDeleteModalId]);

useEffect(() => {
  async function fetchPurchases() {
    setloading(true)

    try {
      const response = await axios.post(
        `${baseURL}${btoa("purchase/view")}`, {
          businessId:businessId,
            managerId:managerId,
            brandId:"all",
          // fromMdy:(selectedDateFrom) === null ? '' : formatDatefordatepicker(selectedDateFrom) ,
          //   toMdy:(selectedDateTo) === null ? '': formatDatefordatepicker(selectedDateTo),
          monthName: null,
          year:null,
           brandName:"all"
        }
      );
      console.log(response, 'purchase response')
      dispatch(deleteAllPurchases())
      dispatch(addPurchases(response.data.receiptmages))
      setSellerNames(response.data.brandsAndTotals)
      setMonthaname(response.data.monthName)
      setYear(response.data.year)
      setSunocoGallons(response.data.sunoco_totals[0].total_gallons)
      setGulfGallons(response.data.gulf_totals[0].total_gallons)
      console.log("fetch images" , response);
    } catch (error) {
      console.error("Error fetching receipt images :", error);
    } finally{
      setloading(false)

    }
  }
  fetchPurchases();

}, [uploadCount]);

  const closeModal = () => {
    setIsModalOpen(false);
  };
 
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      // Get the file type
      const fileType = selectedFile.type;
  
      // Show an alert with the file type for debugging purposes (optional)
      // alert(`Selected file type: ${fileType}`);
  
      // Check if the file type is JPEG, JPG, or any other image
      if ( fileType === 'application/pdf' ) {
        setFileName(selectedFile.name);
        setFile(selectedFile);
        setErrorMessage('');
      } else {
        setErrorMessage('Only  Pdf formats are allowed.');
        setFileName('');
        setFile(null);
      }
    } else {
      setFileName('');
      setFile(null);
      setErrorMessage('');
    }
  };
  
  

  const formattedUploadDate = (createdDate instanceof Date && !isNaN(createdDate.getTime()))
  ? createdDate.toISOString()
  : new Date().toISOString();

  useEffect(() => {
    const currentDate = new Date();
    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    const currentMonth = monthNames[currentDate.getMonth()];
    const currentYear = currentDate.getFullYear();
    setCurrentMonthYear(`${currentMonth} ${currentYear}`);
  }, []); 


  const handleDateChange = (date) => {
    setCreatedOnDate(date);
  };

  const handlePrevDay = () => {
    setCreatedOnDate(new Date(createdDate.setDate(createdDate.getDate() - 1)));
  };

  const handleNextDay = () => {
    const today = new Date();
    const isToday =
      createdDate.getDate() === today.getDate() &&
      createdDate.getMonth() === today.getMonth() &&
      createdDate.getFullYear() === today.getFullYear();
    if (isToday) {
      return;
    }
    setCreatedOnDate(new Date(createdDate.setDate(createdDate.getDate() + 1)));
  };
    
  function formatDatefordatepicker(date) {
    // const day = date.getDate();
    // const month = date.getMonth() + 1; // Months are zero-based
    // const year = date.getFullYear().toString(); // Extract last two digits of year
    // return `${month}-${day}-${year}`;
    const year = date.$y;
    const month = String(date.$M+ 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(date.$D).padStart(2, '0');
    return `${month}-${day}-${year}`;
  }

  const formatDate = (dateStr) => {
    const [year, month, day] = dateStr.split("-");
    return `${month}/${day}/${year}`;
  };
  const formatDateForApi = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const capitalizeFirstLetterWordWords = (str) => {
    return str
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };
  const lowercaseAllWords = (str) => {
    return str
      .toLowerCase()
  };

  const handleUploadImage = async (e) => {
    if (isClicked) return;

     console.log("upload image" , file);
    // console.log("date date" , formatDateForApi(createdDate));
    e.preventDefault();
    if (!file) {
      alert('Please select a file first!');
      return;
    }
    const formData = new FormData();
    formData.append('receipt', file);
    formData.append('businessId', localStorage.getItem("business_id"));
    formData.append('brandId', selectedFuelBrand);

    formData.append('managerId', localStorage.getItem("user_id"));
    formData.append('selectedDate', formatDateForApi(createdDate));
    formData.append('businessName', "Hp Gas");
    // for (let [key, value] of formData.entries()) {
    //   console.log(`${key}: ${value}`);
    // }
    setloading(true)
    setIsClicked(true);
    // const token = localStorage.getItem('token');


    try {
      const response = await axiosInstance.post(`${baseURL}${btoa("purchase/create")}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          // 'Authorization': `${token}`,
        }
      });
      console.log(response, 'response')
      if(response.data.success === true){
        setFile(null); 
        setFileName(''); 
        fileInputRef.current.value = ''; 
        toast.success(`${response.data.message}` , {autoClose : 2500})
        setUploadCount(prevCount => prevCount + 1);
        setCreatedOnDate(new Date())
      }
      // if(response.data.success === false){
      //   setFile(null); 
      //   setFileName(''); 
      //   toast.error(`Image uploading failed` , {autoClose : 2500})
      // }

      // console.log("upload image response", response);, 
    } catch (error) {
      if(error.response.data.success === false){
        setFile(null); 
        setFileName(''); 
        toast.error(`${error.response.data.message}` , {autoClose : 2500})
      }
      console.log("upload image failed", error);
    } finally {
      setUploadModal(false)
      setloading(false)
      setIsClicked(false)
    }
  };
  // const handleDownloadInvoice = async () => {
  //   const invoiceNumbers = table.getSelectedRowModel().flatRows[0]?.original.invoice_number;
  //     // console.log("selected rrr" , selectedRows);
  //   try {
  //     const response = await axios.get(`${baseURL}/${btoa("invoice/downloadinvoice")}/${btoa(invoiceNumbers)}/${btoa(business_id)}`, {
  //       responseType: 'blob',
  //     });
  //     const filename = `${table.getSelectedRowModel().flatRows[0]?.original.business_name}_${table.getSelectedRowModel().flatRows[0]?.original.invoice_number}_${formatDate(new Date(table.getSelectedRowModel().flatRows[0]?.original.added_on))}.pdf`;
  //     const blob = new Blob([response.data], { type: 'application/pdf' });
  //     const url = window.URL.createObjectURL(blob);
  //     const link = document.createElement('a');
  //     link.href = url;
  //     link.download = filename; // Set the filename here
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //     table.resetRowSelection();
  //     window.URL.revokeObjectURL(url);

     
  //     // console.log("Invoice downloaded successfully.");
  //   } catch (error) {
  //     console.log("Error downloading invoice:", error);
  //   }
  // };
  const data = useMemo(() => {
    return allPurchases.map((purchase, index) => ({
      ...purchase,
      // sl_no: index + 1,
      // brand_invoice_number: formatDate(purchase.brand_invoice_number),
      // image_name: capitalizeFirstLetterWordWords(purchase.image_name),
      // contact_name : capitalizeFirstLetterWordWords(vehicle.contact_name),
      // contact_email : lowercaseAllWords(vehicle.contact_email)
    }));
  }, [allPurchases]);
console.log(data, 'dataforpurchase table')
  useEffect(() => {
    // alert(formatDateForApi(createdDate))
    async function fetchImageByDate(){
      try {
        const response = await axios.get(`${baseURL}${btoa("purchase/fetchimagebydate")}/${btoa(formatDateForApi(createdDate))}/${btoa(businessId)}}`);
        setImagesByDate(response.data.image_data)
        // console.log("images by date" , response.data.image_data);
      }catch (error) {
        console.error("error fetching images by date", error) ;
    }
    }
    fetchImageByDate()
  }, [createdDate, uploadCount]);

  const handleView = (row) => {
    // console.log('View action for row:', row);
    // console.log('Constructed Image Path:', imagePath);
    setSelectedInvoiceNumberforModel(row)
    setSelectedImagePath(row)
    setIsModalOpen(true)
   
    // Add your view logic here
  };
  
  const handleDelete = (row) => {
    setOpenDeleteModalId(row)
    // console.log('Delete action for row:', row);
  };

  const handleDownload = (fileName) => { 
    const downloadUrl = `${baseURL}uploads/docs/receipts/${fileName}.pdf`;
    window.open(downloadUrl, '_blank');
  };
  

  const isRowCheckboxDisabled = (row) => {
    if (rowSelection[row.id]) {
      return false;
    } else {
      // If the row is not selected, check if any other rows are selected
      return Object.values(rowSelection).some((selected) => selected);
    }
  };
  const formatNumberWithCommas = (number) => {
    return new Intl.NumberFormat('en-US').format(number);
  };
  const columns = [
    {
      id: "checkbox",
      accessor: "",
      cell: ({ row }) => (
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled: isRowCheckboxDisabled(row),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
            className: isRowCheckboxDisabled(row) ? "disabled-checkbox" : "",
          }}
        />
      ),
      enableColumnFilter: false, // Disable filter for checkbox column
      enableSorting: false, // Disable sorting for checkbox column
    },
    {
      accessorFn: (row, i) => row ? i + 1 : '',
      header: '#',
      },
    
      columnHelper.accessor("brand_logo", {
        header: <div className="text-center">Brand Logo</div>,
        cell: (props) => {
          // const brandId = props.row.original.brand_id;
          // const brandLogo = sellerNames[brandId-1]?.brand_logo;
      
          return (
            <div className="flex items-center justify-center">
              {props.row.original.brand_logo ? (
                <img
                  src={`${baseURL}/img/${(props.row.original.brand_logo)}`}
                  alt={` ${props.row.original.seller_name} Logo`}
                  className="w-14 h-18 object-contain"
                />
              ) : (
                <span className="text-gray-500 italic">No Logo</span> // Fallback in case logo doesn't exist
              )}
            </div>
          );
        },
        enableColumnFilter: false,
        enableSorting: true,
      }),
      
      columnHelper.accessor("brand_invoice_number", {
        header: <div className="text-center"> Purchase Invoice #</div>,
        cell: (props) => (
          <div className="text-center">
            { (props.row.original.brand_invoice_number)
            }
          </div>
        ),
        enableColumnFilter: false,
        enableSorting: true,
      }),
      // columnHelper.accessor("brand_invoice_date", {
      //   header: <div className="text-center"> Invoice Date</div>,
      //   cell: (props) => (
      //     <div className="text-center">
      //       { (props.row.original.brand_invoice_date)
      //       }
      //     </div>
      //   ),
      //   enableColumnFilter: false,
      //   enableSorting: true,
      // }),
      columnHelper.accessor("brand_invoice_date", {
        header: "Invoice Date",
        cell: (props) => {
          const indianDate = props.row.original.brand_invoice_date;
          const [day, month, year] = indianDate.split('/');      
          const date = new Date(`${year}-${month}-${day}`);      
      const formattedDate = `${date.toLocaleDateString("en-US", { month: 'short' })}-${date.toLocaleDateString("en-US", { day: '2-digit' })}-${date.toLocaleDateString("en-US", { year: 'numeric' })}`;
  
    return (
            <div>{formattedDate}</div>
          );
  
        },
        enableColumnFilter: false,
        enableSorting: true,
      }),
      // columnHelper.accessor("total_gallons", {
      //   header: <div className="text-center">Gallons</div>,
      //   cell: (props) => (
      //     <div className="text-center">
      //       { (props.row.original.total_gallons)
      //       }
      //     </div>
      //   ),
      //   enableColumnFilter: false,
      //   enableSorting: true,
      // }), 
      
      columnHelper.accessor("total_gallons", {
        header: <div className="text-center">Gallons</div>,
        cell: (props) => (
          <div className="text-center">
            { (formatNumberWithCommas(props.row.original.total_gallons))
            }
          </div>
        ),
        enableColumnFilter: false,
        enableSorting: true,
      }),
      columnHelper.accessor("total_amount", {
        header: <div className="text-center">Amount</div>,
        cell: (props) => (
          <div className="text-center">
            { Number(props.row.original.total_amount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
            }
          </div>
        ),
        enableColumnFilter: false,
        enableSorting: true,
      }),
      
    
   
      
      // columnHelper.accessor("image_name", {
      //   header: <div className="text-center">Image Name</div>,
      //   cell: () => <div className="text-center">Fuel Purchased Image</div>,  // Set the cell value to "Fuel Purchase Image"
      // }),
      // columnHelper.accessor('contact_email', {
      //   header: <div className="text-center">Action</div>,
      //   cell: ({ row }) => {
      //     const { img_file, id } = row.original;
      //     const isPdf = img_file.endsWith('.pdf');
      
      //     return (
      //       <div className="flex justify-center space-x-3">
      //         {isPdf ? (
      //           <button
      //             onClick={() => handleDownload(img_file)}
      //             className="text-gray-500 hover:text-blue-700 hover:bg-blue-200 p-2 rounded-full cursor-pointer"
      //           >
      //             <FaDownload size={20} />
      //           </button>
      //         ) : (
      //           <button
      //             onClick={() => handleView(img_file)}
      //             className="text-gray-500 hover:text-blue-700 hover:bg-blue-200 p-2 rounded-full cursor-pointer"
      //           >
      //             <FaEye size={20} />
      //           </button>
      //         )}
      //         <button
      //           onClick={() => handleDelete(id)}
      //           className="text-gray-500 hover:text-red-700 hover:bg-red-200 p-2 rounded-full cursor-pointer"
      //         >
      //           <FaTrashAlt size={18} />
      //         </button>
      //       </div>
      //     );
      //   },
      //   enableColumnFilter: false,
      //   enableSorting: false,
      // })
  ]

  const handleSortingChange = (columnId) => {
    setSorting((old) => {
      if (old.length && old[0].id === columnId) {
        // Toggle sorting direction
        return [{
          id: columnId,
          desc: !old[0].desc,
        }];
      }
      // Default sorting to descending initially
      return [{ id: columnId, desc: true }];
    });
  };
  const [sellerNames , setSellerNames] = useState([])
  const [selectedText, setSelectedText] = useState("");
  const [sunocoGallons, setSunocoGallons] = useState('')
  const [GulfGallons, setGulfGallons] = useState('')
  const [monthName, setMonthaname] = useState('')
  const [yearName, setYear] = useState('')

  
  const [loading, setloading] = useState(false)
  const [isClicked, setIsClicked] = useState(false);
  const formattedDateTo = selectedDateTo ? selectedDateTo: '';
  const formattedDatefrom = selectedDateFrom ? selectedDateFrom : '';
  // console.log(formattedDatefrom.format('MMM-YYYY').split('-'), 'formattedDatefrom')
  // const [year, month, day] = value.split('-');
   const formattedMonthName = () => {
   let [month]  =  formattedDatefrom.format('MMMM-YYYY').split('-')
   return month
   }
  const formattedYear = () => {
    let [ , year]  =  formattedDatefrom.format('MMMM-YYYY').split('-')
    return year
   }

const colors = [
  'red-500',
  'blue-500',
  'yellow-500',
  'purple-500',
  'green-500',
  '[#25caed]',
]; // Add more colors if needed

const cardsData =
sellerNames.length > 0 ?
sellerNames.map((sellerName, index) => ({
  id: sellerName.brand_id,
  brandName: sellerName.seller_name, // Assuming brand_name is a property
  logo: sellerName.brand_logo,
  gallons: sellerName.total_gallons, // Adjust gallons data as needed
  borderColor: colors[index % colors.length],
}))
:[];


  const finalData = React.useMemo(() => {
    return allPurchases.length>0 ? allPurchases : [];
  }, [allPurchases]); 
  const [expandedRow, setExpandedRow] = useState(null);


  const toggleRow = (id) => {
    setExpandedRow((prevRow) => (prevRow === id ? null : id));
  };

  const table = useReactTable({
    data: finalData || [],
    columns: columns,
    state: {
      rowSelection: rowSelection,
      globalFilter: filtering,
      sorting: sorting,
    },
    onSortingChange: setSorting,
    onGlobalFilterChange: setFiltering,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onRowSelectionChange: setRowSelection,
    onColumnFiltersChange: setColumnFilters,
    enableRowSelection: true,
    enableColumnFilter: true,
  });
  const currentPage = table.options.state.pagination.pageIndex;

  return (
    <div className='pb-10 pageHeightinvoice'>
          {loading && <FullScreenLoader />}

        {/* <div className="w-full ml-10">
        <ScrollingMessage />
        </div> */}
        {/* <div>
      <h1 className="font-bold text-xl mt-6"   style={{ 
    maxWidth: '1200px', 
    boxSizing: 'border-box', 
    marginLeft: '42px', 
    fontFamily: 'Poppins, sans-serif', // Use Poppins font
    color: '#444444' // Lighter black color
  }}>Purchases</h1>
  </div> */}
  <div className=" p-4 rounded-lg ml-4 mt-[8px]">
  <nav aria-label="breadcrumb">
    <ol className="flex space-x-2 text-lg font-bold text-gray-700">
      <li>
        {/* <a  href="/operations" className="text-blue-600 hover:text-blue-800"> */}
          Operations
        {/* </a> */}
      </li>
      <li>
        <span className="text-gray-400">{'>>'}</span>
      </li>
      <li>
        {/* <a href="/purchases" className="text-blue-600 hover:text-blue-800"> */}
          Gas Purchases
        {/* </a> */}
      </li>
    </ol>
  </nav>
</div>
<div className="flex justify-start space-x-4 p-10">
    {cardsData.map((card, index) => (
      <div
        key={card.id}
        className={`flex flex-col items-start justify-between w-1/5 bg-white rounded-lg p-4 relative 
          hover:scale-105 hover:border-l-[6px] hover:border-${card.borderColor} transition-all duration-300 
          ${clicked === `card${index + 1}` ? `border-l-[6px] border-${card.borderColor}` : ''}`}
        style={{
          boxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.2)',
          height: '90px',
        }}
        onClick={() => {
          setSelectedBrandName(card.id);
          setSelectedText(card.brandName);
          setClicked(`card${index + 1}`);
        }}
      >
        <div className="flex justify-start w-full">
          <span className="text-sm text-gray-500 font-bold">Gallons</span>
        </div>
        <div>
          <h2
            className={`absolute bottom-2 left-4 text-2xl font-bold text-${card.borderColor} mt-2`}
          >
            {formatNumberWithCommas(card.gallons)}
          </h2>
        </div>
        <div className="absolute bottom-0 right-0 rounded-tl-full shadow-lg flex items-center justify-center">
          <img
            src={`${baseURL}/img/${card.logo}`}
            alt={card.brandName}
            // className="w-14 h-18 object-contain mb-2 mr-3"
            className={`${card.id === '1' && 'w-14 h-12'} 
            ${card.id === '2' && 'w-12 h-14'} 
            ${card.id === '3' && 'w-[80px] h-16 mb-[-8px]'} 
            ${card.id === '4' && 'w-14 h-18'} 
            ${card.id === '5' && 'w-14 h-18'} 
            ${card.id === '6' && 'w-14 h-18'} 
            ${card.id === '7' && 'w-14 h-18'} 
             ${card.id === '8' && 'w-14 h-18'}
              ${card.id === '9' && 'w-14 h-18'}
            object-contain mb-2 mr-3`}
          />
        </div>
      </div>
    ))}
  </div>

   {/* <div className="relative flex justify-end mt-[80px]">
  <button
    className="relative flex items-center font-bold mr-[28px] w-55 border-transparent border-2 text-white bg-[#25caed] hover:scale-105 transition-transform duration-300 ease-in-out focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-base px-4 focus:outline-none"
    type="button"
    onClick={() => setUploadModal(true)}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={3}
      stroke="currentColor"
      className="w-5 h-9 mr-3"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 6v12m6-6H6"
      />
    </svg>
    Upload PO
  </button>
</div> */}
{ (
      <div className="flex justify-between gap-2 items-center w-full px-10 mt-40">
          <div className="flex-grow">
              <select
                id="customerId"
                className=" border border-gray-300 text-sm font-medium rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full text-gray-500 p-2.5"
                onChange={(e) => handleChangeFuelBrands(e)}
              >
                <option value="all">All Fuel Brands</option>
                {sellerNames.map((seller) => (
                <option
                  key={seller.id}
                  value={seller.id}
                >
                  {seller.seller_name}
                </option>
              ))}
              </select>
          </div>
{/* 
          <div>
            <select
              id="category"
              value={filteredStatus}
              onChange={(e) => handleChange(e.target.value)}
              className="cursor-pointer font-medium border-gray-300 h-[41px] text-gray-500 text-sm rounded-lg focus:ring-0 focus:ring-blue-500 focus:border-blue-500 focus:outline-none block w-fit p-2"
            >
              <option value="all">All statuses</option>
              <option value="draft">Draft</option>
              <option value="approved">Approved</option>
              <option value="sent">Sent</option>
              <option value="partialpaid">Partial Paid</option>
              <option value="fullpaid">Full Paid</option>
              <option value="overDue">Past Due</option>
            </select>
          </div> */}

      
          {/* <div className="flex items-center"> */}
            {/* <div className="h-fit font-normal cursor-pointer border border-gray-300 rounded-l-lg flex items-center justify-between"> */}
            {/* <LocalizationProvider dateAdapter={AdapterDayjs}> */}
            <Space direction="vertical" style={{height:'100%'}}>
  <DatePicker
    label="Select Month"
    picker="month"
    format="MMMM-YYYY"
    views={['year', 'month', 'day']}
    value={formattedDatefrom}
    onChange={handleDateChangeFrom}
    placeholder="Select Month"
    renderInput={(params) => (
      <TextField
        {...params}
        style={{height:'35px'}}
        value={formattedDatefrom ? formattedDatefrom.format('MMMM-YYYY') : ''}
        sx={{
          '& .MuiOutlinedInput-root': {
            height: '100px', // Increase container height
            display: 'flex', // Align items properly
            alignItems: 'center',
            fontSize: '1.2rem', // Increase font size
            '& input': {
              height: '80px', // Adjust the input field's height
              padding: '40px 20px', // Add padding for larger appearance
            },
            '&.Mui-focused': {
              borderColor: 'blue', // Change focus border color
              boxShadow: '0 0 8px rgba(0, 0, 255, 0.5)', // Add shadow on focus
            },
            border: '1px solid #ccc', // Default border
            borderRadius: '6px', // Rounded corners
          },
          '& .MuiInputLabel-root': {
            fontSize: '1rem', // Increase label font size
          },
          '& .MuiOutlinedInput-input::placeholder': {
                   fontWeight: 'bold',
}
        }}
      />
    )}
  />
</Space>



{/* </LocalizationProvider> */}


              {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-5 h-5 mr-2 cursor-pointer"
                onClick={() => handleIconClick(datePickerFromRef)}
              >
                <path d="M12.75 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM7.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM8.25 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM9.75 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM10.5 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM12.75 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM14.25 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 13.5a.75.75 0 100-1.5.75.75 0 000 1.5z" />
                <path
                  fillRule="evenodd"
                  d="M6.75 2.25A.75.75 0 017.5 3v1.5h9V3A.75.75 0 0118 3v1.5h.75a3 3 0 013 3v11.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V7.5a3 3 0 013-3H6V3a.75.75 0 01.75-.75zm13.5 9a1.5 1.5 0 00-1.5-1.5H5.25a1.5 1.5 0 00-1.5 1.5v7.5a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5v-7.5z"
                  clipRule="evenodd"
                />
              </svg> */}
            {/* </div> */}

            {/* <div className="h-fit font-normal border-gray-300 cursor-pointer border rounded-r-lg flex items-center justify-between"> */}
              {/* <DatePicker
                ref={datePickerToRef}
                value={selectedDateTo}
                onChange={(date) => handleDateChangeTo(formatDatefordatepicker(date))}
                placeholderText="To"
                className="w-40 outline-none border-none rounded-r-lg cursor-pointer focus:outline-none focus:ring-0 bg-transparent"
                dateFormat="MM/dd/yyyy"
              /> */}
                {/* <LocalizationProvider dateAdapter={AdapterDayjs}> */}
                {/* <Space direction="vertical">
  <DatePicker
    label="To"
      // picker="month"
    // format="MM-YYYY"
    views={['year', 'month', 'day']}
    value={formattedDateTo}
    onChange={handleDateChangeTo}
    placeholder="To"
    renderInput={(params) => (
      <TextField
        {...params}
        value={formattedDateTo ? formattedDateTo.format('MM-DD-YYYY') : ''}
        sx={{
          '& .MuiOutlinedInput-root': {
            height: '60px', // Adjust height here
            display: 'flex', // Ensures proper alignment
            alignItems: 'center',
            '& input': {
              height: '40px', // Adjust the input field's height
              padding: '10px 14px', // Add padding for better appearance
            },
            '&.Mui-focused': {
              borderColor: 'blue', // Change focus border color (blue as example)
              outline: 'none', // Prevents default outline if needed
            },
            border: '1px solid #ccc', // Add default border
          },
        }}
      />
    )}
  />
</Space> */}

    {/* </LocalizationProvider> */}
              
              {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-5 h-5 mr-2 cursor-pointer"
                onClick={() => handleIconClick(datePickerToRef)}
              >
                <path d="M12.75 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM7.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM8.25 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM9.75 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM10.5 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM12.75 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM14.25 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 13.5a.75.75 0 100-1.5.75.75 0 000 1.5z" />
                <path
                  fillRule="evenodd"
                  d="M6.75 2.25A.75.75 0 017.5 3v1.5h9V3A.75.75 0 0118 3v1.5h.75a3 3 0 013 3v11.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V7.5a3 3 0 013-3H6V3a.75.75 0 01.75-.75zm13.5 9a1.5 1.5 0 00-1.5-1.5H5.25a1.5 1.5 0 00-1.5 1.5v7.5a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5v-7.5z"
                  clipRule="evenodd"
                />
              </svg> */}
            {/* </div> */}
          {/* </div> */}

          <div className="flex-grow items-center border border-gray-300 rounded-lg hover:border-[#3479E0]">
            <div className="relative w-full h-full">
              <input
                type="text"
                id="search-dropdown"
                className="block p-2.5 w-full z-20 text-sm text-gray-900 rounded-lg border-0 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-l-gray-700 focus:ring-0 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500"
                placeholder="Enter invoice #"
                value={filtering}
                // onChange={(e) =>
                //   setSelectedInvoiceNumber(e.target.value)
                // }
                onChange={e => setFiltering(e.target.value)}

              /> 
              <button
                type="submit"
                className="absolute bottom-[2.4px] right-0.5 p-2.5 text-sm font-medium h-[35px] text-white bg-gray-300 rounded-lg border   focus:ring-0 dark:bg-blue-600 dark:hover-bg-blue-700 dark:focus-ring-blue-800"
              >
                <svg
                  className="w-4 h-4 text-black "
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
                <span className="sr-only">Search</span>
              </button>
            </div>
          </div>
          <div className=" flex justify-end ">
  <button
    className="relative flex items-center font-bold mr-[0px] w-55 border-transparent border-2 text-white bg-[#25caed] hover:scale-105 transition-transform duration-300 ease-in-out focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-base px-4 focus:outline-none"
    type="button"
    onClick={() => setUploadModal(true)}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={3}
      stroke="currentColor"
      className="w-5 h-9 mr-3"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 6v12m6-6H6"
      />
    </svg>
    Upload PO
  </button>
</div>
        </div>
        )}

{uploadModal && <FileUploadModal onClose={() => setUploadModal(false)} 
handleFileChange={handleFileChange}
 fileInputRef={fileInputRef} 
 fileName={fileName}
 setFileName={setFileName}
 selectedFuelBrand={selectedFuelBrand}
 onFuelBrandChange={handleFuelBrandChange}
 handleUploadImage= {handleUploadImage}
 errorMessage={errorMessage}
 />}
  {table.getSelectedRowModel().flatRows.length > 0 && (
                              <div className={`flex flex-col items-center gap-3 mb-0  `}
                              style={{
                                position:'fixed',
                                right: '8px',
                                bottom: '12%'
                            
                              }}
                              
                              >
                                    <>
                                        <Tooltip title="View" position="top" trigger="mouseenter">
                                          <button
className="ml-1 items-center text-sm font-medium text-center focus:outline-none border-black text-black rounded-full bg-white hover:bg-gray-200 border p-2"
type="button"
                                              onClick={() =>handleView( table.getSelectedRowModel().rows[0].original.img_file)}
                                              // onClick={()=>  console.log(table.getSelectedRowModel().rows[0].original.brand_invoice_number)}
                                          >
                                              <div className="rounded-full p-1">
                                                  <AiFillEye  size={20} />
                                              </div>
                                          </button>
                                      </Tooltip>
                                      {!table.getSelectedRowModel().flatRows.some(el => el.original.invoice_status === "1") && (
                                        <Tooltip title="Download" position="top" trigger="mouseenter">
                                            <button
className="ml-1 items-center text-sm font-medium text-center focus:outline-none border border-blue-800 text-blue-800 rounded-full bg-white hover:bg-blue-300 p-2"
type="button"
                                                onClick={() =>handleDownload( table.getSelectedRowModel().rows[0].original.img_file)}

                                            >
                                                <div className="rounded-full p-1">
                                                    <FiDownload size={20} />
                                                </div>
                                            </button>
                                        </Tooltip>  )}
                                      </>


                                        <Tooltip title="Delete" position="top" trigger="mouseenter">
                                            <button
className="ml-2 items-center text-sm font-medium text-center focus:outline-none border border-red-600 text-red-600 rounded-full bg-white hover:bg-red-300 p-2"
type="button"
                                                onClick={() =>handleDelete( table.getSelectedRowModel().rows[0].original.id)}
                                                // onClick={() =>console.log( table.getSelectedRowModel().rows[0].original.brand_invoice_number)}

                                            >
                                                <div className="rounded-full p-1"

                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 24 24"
                                                        fill="currentColor"
                                                        className="w-5 h-5"
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z"
                                                            clipRule="evenodd"
                                                        />
                                                    </svg>
                                                </div>
                                            </button>
                                        </Tooltip>
                                  


                              </div>
                          )}
      
      {/* <nav className="bg-gray-50 px-4 py-0 pt-3  flex items-center justify-between">

        <div className="flex-grow flex justify-center w-full  mt-4 pb-4 space-x-4">
          <button onClick={handlePrevDay} className="">
            <FaChevronLeft size={24} className="text-gray-700" />
            </button>
          <div 
            className="h-11 text-gray-700 font-normal w-48 border border-gray-300 border-b-4 border-b-blue-400 rounded-t-md rounded-b-md flex items-center shadow"
          >
           <div 
              className='flex justify-between items-center cursor-pointer'
              onClick={() => document.getElementById('created_on').focus()}
            >
              <DatePicker
                id="created_on"
                name="created_on"
                selected={createdDate}
                required
                maxDate={new Date()}
                onChange={handleDateChange}
                dateFormat="MMMM dd, yyyy"
                placeholderText="From"
                className="w-full outline-none border-none rounded-r-lg cursor-pointer focus:outline-none focus:ring-0 bg-transparent"
                onKeyDown={(e) => { e.preventDefault(); }}
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-5 h-5 mr-2"
              >
                <path d="M12.75 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM7.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM8.25 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM9.75 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM10.5 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM12.75 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM14.25 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 13.5a.75.75 0 100-1.5.75.75 0 000 1.5z" />
                <path
                  fillRule="evenodd"
                  d="M6.75 2.25A.75.75 0 017.5 3v1.5h9V3A.75.75 0 0118 3v1.5h.75a3 3 0 013 3v11.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V7.5a3 3 0 013-3H6V3a.75.75 0 01.75-.75zm13.5 9a1.5 1.5 0 00-1.5-1.5H5.25a1.5 1.5 0 00-1.5 1.5v7.5a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5v-7.5z"
                  clipRule="evenodd"
                />
              </svg>
            </div>

          </div>
          <button onClick={handleNextDay} className="ml-2">
            <FaChevronRight size={24} className="text-gray-700" />
          </button>
        </div>
      </nav> */}

      <div className="p-0">

      {/* <div className="flex flex-col items-center">
        {imagesByDate.map((image) => (
          <div key={image.id} className="flex items-center justify-between w-80 space-x-2 mb-4 p-3 pt-1.5 pb-1.5 border border-gray-300 rounded-lg">
            <span className="font-semibold">Fuel purchased image</span>
            <div className="relative group">
              <button
                onClick={() => handleView(image.img_file)}
                className="text-gray-500 hover:text-blue-700 hover:bg-blue-200 p-2 rounded-full cursor-pointer"
              >
                <FaEye size={20} />
              </button>
            </div>
          </div>
        ))}
      </div> */}

      {/* <hr className="border-t border-gray-300 mt-4 w-full max-w-md mx-auto" /> */}

      {/* <div className="flex flex-col items-start w-80 max-w-md mx-auto my-5">
      <label className="mb-2 text-lg font-bold" htmlFor="fileInput">
        Upload Invoice*
      </label>

      <div className="flex items-center w-full relative">
        <input
          type="file"
          id="fileInput"
          className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
          onChange={handleFileChange}
          ref={fileInputRef}
          accept="image/pdf/*" // Allow all image file types
        />
        <div className="flex w-full">
          <input
            type="text"
            placeholder="Choose file"
            className="w-full p-2 border border-gray-300 rounded-l-lg cursor-pointer"
            value={fileName}
            readOnly
          />
          <label
            htmlFor="fileInput"
            className="bg-blue-500 text-white py-2 px-4 rounded-r-lg cursor-pointer mb-0"
          >
            Browse
          </label>
        </div>
      </div>
      {errorMessage && (
        <p className="text-red-500 font-medium text-sm">{errorMessage}</p>
      )}
      <button 
        onClick={handleUploadImage}
        className="w-full mt-4 bg-white border-2 font-semibold border-blue-500 text-blue-500 py-2 cursor-pointer rounded-lg hover:bg-blue-500 hover:text-white">
        Submit
      </button>
    </div> */}

    </div>
        
      {/* <nav className="flex items-center justify-between p-4 bg-gray-500 text-white mr-6 ml-6">
      <div className="flex items-center">
        <span className="text-lg font-semibold">Purchases</span>
      </div>
          <div className="flex-grow flex justify-center">
             <span className="text-lg font-semibold">{currentMonthYear}</span>
          </div>
      <div className="flex items-center space-x-2">
        <button className="p-2 bg-white text-gray-500 rounded">
          <FaSearch />
        </button>
        <button className="p-2 bg-white text-gray-500 rounded">
          <FaCalendarAlt />
        </button>
      </div>
      </nav> */}
  <div className="flex justify-between w-full ml-[45px] mt-5">
  <span className="text-xl text-[#25caed] font-bold">
  {monthName && yearName ? `${monthName} - ${yearName} Purchases` : ''}
</span>
  </div>
          <div className='px-10 mt-[25px] '>
            
             <table className="w-full table-auto bg-transparent rounded-xl mr-5 shadow-lg">
                  <thead className="bg-gray-500/20 text-gray-500">
                    {table.getHeaderGroups().map((headerGroup) => (
                      <tr key={headerGroup.id} className="text-center text-xs">
                        {headerGroup.headers.map((header, index) => {
                          const isFirstHeader = index === 0;
                          const isLastHeader = index === headerGroup.headers.length - 1;
                          const headerClasses = [
                            'p-3 font-bold uppercase',
                            isFirstHeader ? 'rounded-l-xl' : '',
                            isLastHeader ? 'rounded-r-xl' : '',
                          ];

                          const isSorted = header.column.getIsSorted();
                          const showSortingIcons = header.column.columnDef.enableSorting !== false;
                          const ArrowUpIcon = (
                            <FaArrowUpLong
                              className={`${isSorted === 'asc' ? 'text-black' : 'text-gray-400'}`}
                              size={12}
                            />
                          );
                          const ArrowDownIcon = (
                            <FaArrowDownLong
                              className={`${isSorted === 'desc' ? 'text-black' : 'text-gray-400'}`}
                              size={12}
                            />
                          );

                          return (
                            <th
                              key={header.id}
                              className={headerClasses.join(' ')}
                              colSpan={header.colSpan}
                              onClick={() => showSortingIcons && handleSortingChange(header.column.id)}
                            >
                              <div className="flex items-center justify-center">
                                {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                                {showSortingIcons && (
                                  <div className="flex items-center ml-2">
                                    {ArrowDownIcon}
                                    {ArrowUpIcon}
                                  </div>
                                )}
                              </div>
                            </th>
                          );
                        })}
                      </tr>
                    ))}
                  </thead>
                  <tbody>
                    {table.getRowModel().rows.map((row) => (
                      <>
                    
                                            <tr key={row.id} className="cursor-pointer text-center"     

                      // <tr key={row.id} className="cursor-pointer text-center hover:bg-gray-500/10"     
                                onClick={() => toggleRow(row.id)}
>
                        {row.getVisibleCells().map((cell) => (
                          <td key={cell.id} className="p-3 font-semibold text-[#081159] text-[14px]">
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </td>

                        ))}
                      </tr>
                 
                      {expandedRow === row.id && (
  <tr>
    <td
      colSpan={table.getHeaderGroups()[0].headers.length}
      className="bg-gray-100 p-4 border-t-[2px] border-[#3479E0] "
    >
      {/* Data rows */}
      <div className="">
        {row.original.fuelgrades?.length > 0 ? (
          row.original.fuelgrades.map((fuelgrade, fuelIndex) => (
            <div
              key={fuelIndex}
              className=" grid grid-cols-3 gap-1 text-sm text-gray-800 font-normal text-center flex justify-center items-center "
              style={{width:'60%', margin: "0 auto", fontFamily:'poppins'}}
            >
              <div className='text-left'>{fuelgrade.fuelgrade_name}</div>
              <div className='text-right'>{formatNumberWithCommas(fuelgrade.gross_quantity)}</div>
              <div className='text-right'>${Number(fuelgrade.unit_total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
            </div>
          ))
        ) : (
          <div className="text-gray-500 text-center">No fuel grades available.</div>
        )}
      </div>
    </td>
  </tr>
)}




                      </>  
                    ))}
                  </tbody>
              </table>  
           
            </div>

                {/* <div className="flex items-center mr-7 mt-6 gap-2 justify-end">
                  <button
                    className={`px-4 py-2 rounded-l-lg focus:outline-none flex items-center ${
                      table.getCanPreviousPage()
                        ? " hover:bg-[#114FFF] hover:text-white cursor-pointer"
                        : " cursor-not-allowed"
                    }`}
                    onClick={() => table.previousPage()}
                    disabled={!table.getCanPreviousPage()}
                  >
                    <MdNavigateBefore className="w-6 h-6" />
                    Prev
                  </button>
                  {[...Array(table.getPageCount()).keys()].map((page) => (
                    <button
                      key={page}
                      onClick={() => table.setPageIndex(Number(page))}
                      className={`px-4 py-2  focus:outline-none ${
                        currentPage === page
                          ? " rounded-full bg-[#3479E0] text-white "
                          : " rounded-full bg-white dark:text-black hover:bg-[#3479E0]/50 hover:text-white"
                      }`}
                    >
                      {page + 1}
                    </button>
                  ))}
                  <button
                    className={`px-4 py-2 rounded-r-lg  focus:outline-none flex items-center justify-center${
                      table.getCanNextPage()
                        ? " hover:bg-[#114FFF] hover:text-white cursor-pointer"
                        : "bg-gray-300 cursor-not-allowed"
                    }`}
                    onClick={() => table.nextPage()}
                    disabled={!table.getCanNextPage()}
                  >
                    Next
                    <MdNavigateNext className="w-6 h-6" />
                  </button>
                </div> */}


                <PurchaseImagesViewModel isModalOpen={isModalOpen} closeModal={closeModal} selectedImagePath={selectedImagePath} selectedInvoiceNumberforModel={selectedInvoiceNumberforModel}/>
                {openDeleteModalId && (
                  <PopUpModalForDeletePurchases
                    onClose={() => setOpenDeleteModalId(null)}
                    id={openDeleteModalId}
                    imageName={"Fuel Purchased Image"}
                    resetRowSelection={table.resetRowSelection}
                    brandInvoiceNumber = { table.getSelectedRowModel().rows[0]?.original?.brand_invoice_number}

                  />
                )}
             


    </div>
  )
}

export default Purchases;
