import axios from "axios";

// Action types
export const ADD_CUSTOMER_REQUEST = "ADD_CUSTOMER_REQUEST";
export const ADD_CUSTOMER_SUCCESS = "ADD_CUSTOMER_SUCCESS";
export const ADD_CUSTOMER_FAILURE = "ADD_CUSTOMER_FAILURE";
export const DELETE_CUSTOMER_REQUEST = "DELETE_CUSTOMER_REQUEST";
export const DELETE_CUSTOMER_SUCCESS = "DELETE_CUSTOMER_SUCCESS";
export const DELETE_CUSTOMER_FAILURE = "DELETE_CUSTOMER_FAILURE";
export const ADD_PRODUCT_REQUEST = "ADD_PRODUCT_REQUEST";
export const ADD_PRODUCT_SUCCESS = "ADD_PRODUCT_SUCCESS";
export const ADD_PRODUCT_FAILURE = "ADD_PRODUCT_FAILURE";
export const ADD_ITEM_REQUEST = "ADD_ITEM_REQUEST";
export const ADD_ITEM_SUCCESS = "ADD_ITEM_SUCCESS";
export const ADD_ITEM_FAILURE = "ADD_ITEM_FAILURE";
export const DELETE_PRODUCT_REQUEST = "DELETE_PRODUCT_REQUEST";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_FAILURE = "DELETE_PRODUCT_FAILURE";
export const DELETE_ITEM_REQUEST = "DELETE_ITEM_REQUEST";
export const DELETE_ITEM_SUCCESS = "DELETE_ITEM_SUCCESS";
export const DELETE_ITEM_FAILURE = "DELETE_ITEM_FAILURE";



// Action creators
export const deleteItemRequest = () => {
  return {
    type: DELETE_ITEM_REQUEST,
  };
};

export const deleteItemSuccess = (itemId) => {
  return {
    type: DELETE_ITEM_SUCCESS,
    payload: itemId,
  };
};

export const deleteItemFailure = (error) => {
  return {
    type: DELETE_ITEM_FAILURE,
    payload: error,
  };
};

export const deleteProductRequest = () => {
  return {
    type: DELETE_PRODUCT_REQUEST,
  };
};

export const deleteProductSuccess = (productId) => {
  return {
    type: DELETE_PRODUCT_SUCCESS,
    payload: productId,
  };
};

export const deleteProductFailure = (error) => {
  return {
    type: DELETE_PRODUCT_FAILURE,
    payload: error,
  };
};
export const addProductRequest = () => {
  return {
    type: ADD_PRODUCT_REQUEST,
  };
};

export const addProductSuccess = (product) => {
  return {
    type: ADD_PRODUCT_SUCCESS,
    payload: product,
  };
};

export const addProductFailure = (error) => {
  return {
    type: ADD_PRODUCT_FAILURE,
    payload: error,
  };
};
export const addItemRequest = () => {
  return {
    type: ADD_ITEM_REQUEST,
  };
};

export const addItemSuccess = (item) => {
  return {
    type: ADD_ITEM_SUCCESS,
    payload: item,
  };
};

export const addItemFailure = (error) => {
  return {
    type: ADD_ITEM_FAILURE,
    payload: error,
  };
};
export const addCustomerRequest = () => {
  return {
    type: ADD_CUSTOMER_REQUEST,
  };
};

export const addCustomerSuccess = (customer) => {
  return {
    type: ADD_CUSTOMER_SUCCESS,
    payload: customer,
  };
};

export const addCustomerFailure = (error) => {
  return {
    type: ADD_CUSTOMER_FAILURE,
    payload: error,
  };
};

export const deleteCustomerRequest = () => {
  return {
    type: DELETE_CUSTOMER_REQUEST,
  };
};

export const deleteCustomerSuccess = (customerId) => {
  return {
    type: DELETE_CUSTOMER_SUCCESS,
    payload: customerId,
  };
};

export const deleteCustomerFailure = (error) => {
  return {
    type: DELETE_CUSTOMER_FAILURE,
    payload: error,
  };
};

// Thunk action to add a customer
// Thunk action to add a customer
// Thunk action to add a customer
export const addCustomer = (customerData) => {
  return (dispatch) => {
    dispatch(addCustomerRequest());

    axios
      .post("http://localhost/InvoiceFlow/customer/Customer_ctrl/create_customer/", customerData)
      .then((response) => {
        if (response.status === 200) {
          // Assuming your response structure has a "status" property
          dispatch(addCustomerSuccess(response.data));
        } else {
          dispatch(addCustomerFailure("Failed to add customer"));
        }
      })
      .catch((error) => {
        dispatch(addCustomerFailure(error.message));
      });
  };
};

export const addProduct = (productData) => {
  return (dispatch) => {
    dispatch(addProductRequest());

    // Adjust the API endpoint according to your backend API
    axios
      .post("http://localhost/InvoiceFlow/product/Product_ctrl/create_product", productData)
      .then((response) => {
        // Check the structure of the response data from the server
        const product = response.data; // Assuming the response contains the product object
        dispatch(addProductSuccess(product));
      })
      .catch((error) => {
        dispatch(addProductFailure(error.message));
      });
  };
};

// Thunk action to add an item
export const addItem = (itemData) => {
  return (dispatch) => {
    dispatch(addItemRequest());

    // Adjust the API endpoint according to your backend API
    axios
      .post("http://localhost/InvoiceFlow/setup/Item_ctrl/create_items", itemData)
      .then((response) => {
        // Check the structure of the response data from the server
        const item = response.data; // Assuming the response contains the item object
        dispatch(addItemSuccess(item));
      })
      .catch((error) => {
        dispatch(addItemFailure(error.message));
      });
  };
};






// Thunk action to delete a customer
export const deleteCustomer = (customerId, businessName) => {
  return (dispatch) => {
    dispatch(deleteCustomerRequest());

    // Replace placeholders with actual values in the URL
    const apiUrl = `http://localhost/InvoiceFlow/customer/delete/${customerId}`;

    axios
      .delete(apiUrl)
      .then(() => {
        dispatch(deleteCustomerSuccess(customerId));
      })
      .catch((error) => {
        dispatch(deleteCustomerFailure(error.message));
      });
  };
};

export const deleteProduct = (productId, productName) => {
  return (dispatch) => {
    dispatch(deleteProductRequest());

    // Replace placeholders with actual values in the URL
    const apiUrl = `http://localhost/InvoiceFlow/product/delete/${productId}`;

    axios
      .delete(apiUrl)
      .then(() => {
        dispatch(deleteProductSuccess(productId));
      })
      .catch((error) => {
        dispatch(deleteProductFailure(error.message));
      });
  };
};
export const deleteItem = (itemId, itemName) => {
  return (dispatch) => {
    dispatch(deleteItemRequest());

    // Replace placeholders with actual values in the URL
    const apiUrl = `http://localhost/InvoiceFlow/items/delete/${itemId}`;

    axios
      .delete(apiUrl)
      .then(() => {
        dispatch(deleteItemSuccess(itemId));
      })
      .catch((error) => {
        dispatch(deleteItemFailure(error.message));
      });
  };
};