
import React, { useEffect, useRef } from "react";
import { useState  } from "react";
import { FaEnvelope, FaLock, FaSignInAlt } from "react-icons/fa";
// import img1 from "../../assets/images/Login.jpg"
// import img2 from "../../assets/images/snapedit_1701426729284.png";
import { IoPerson } from "react-icons/io5";
import { useNavigate, Link, resolvePath } from "react-router-dom";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup"
import Header from "../Headerside";
import { RiLoginCircleFill } from "react-icons/ri";
import {  FaEye, FaEyeSlash } from 'react-icons/fa';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import { GiCancel } from "react-icons/gi";
import { FaXmark } from "react-icons/fa6";
import { FaArrowAltCircleRight } from "react-icons/fa";
import { FaArrowRightLong } from "react-icons/fa6";
import config  from "../../config/config"
import { CoPresentOutlined } from "@mui/icons-material";

const CustomerOtpPage = ({ onLogin , handleSignUpClick ,handleSignOut  , userEmail , dummyOtp, handleTerms}) => {
  // const [email, setEmail] = useState(userEmail);
  // console.log(userEmail);
  const [password, setPassword] = useState("");
  const [timer, setTimer] = useState(300);
  const [showSignUp , setShowSignUp] = useState(false)
  const [user , setUser] = useState({contact_name : "" , contact_email : ""})
  const handleLocalSignUpClick =()=>{
    setShowSignUp(!showSignUp)
  }
  const [otp , setOtp] = useState("")
  const [otpValues, setOtpValues] = useState(["", "", "", "", "", ""]);
  const [otpExpired, setOtpExpired] = useState(false);
  const [otpError, setOtpError] = useState("")
  const navigate = useNavigate()
  const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;
 
  useEffect(()=>{
    setOtp(localStorage.getItem("userOtp"))
  },[])


//   useEffect(() => {
//   const handleUnload = () => {
//     // Perform any actions before the page is unloaded (e.g., sign out)
//     handleSignOut();
//   };

//   window.addEventListener("unload", handleUnload);

//   // Remove the event listener when the component unmounts
//   return () => {
//     window.removeEventListener("unload", handleUnload);
//   };
// }, [handleSignOut]);

const handleOtpChange = (value) => {
  const newOtpValues = value.split('').slice(0, 6);
  setOtpValues(newOtpValues);

  // Optionally, you can automatically submit the form when all OTP digits are entered
  if (newOtpValues.length === 6) {
    handleLoginClick();
  }
};

  const isOtpExpired = ()=> timer === 0;

  const handleOtpExpired =()=>{
    setOtpValues(["", "", "", "", "", ""]);
    setOtpExpired(true)
  }

  useEffect(() => {
    // Update the timer every second
    const interval = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer > 0) {
          return prevTimer - 1;
        } else {
          clearInterval(interval);
          return 0;
        }
      });
    }, 1000);
    return () => clearInterval(interval);
  }, [timer]); // Empty dependency array means this effect runs once after the initial render

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };
  const userStatus = localStorage.getItem("userStatus")

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSignOutModel=()=>{
      setIsModalOpen(!isModalOpen)
      window.location.reload();
  }
  
  const handleCancel=()=>{
    navigate('/customerportal')
}
  const handleLoginClick = async function (e) {
    if (e) {
      e.preventDefault();
      console.log(userEmail);
  
      if (isOtpExpired()) {
        handleOtpExpired();
        return;
      }

      // const enteredOtp = otpValues.join('');
      // if(enteredOtp !== dummyOtp){
      //     setOtpError("Invalid OTP")
      //     return
      // }

      // if (localStorage.getItem("userStatus") === "4") {
      //   setIsModalOpen(true); 
      //   return
      // }
  
      try {
        const response = await axios.post(`${baseURL}/${btoa("login/validateotp")}`, {

      
          email: userEmail,
          userotp1: otpValues[0] || "",
          userotp2: otpValues[1] || "",
          userotp3: otpValues[2] || "",
          userotp4: otpValues[3] || "",
          userotp5: otpValues[4] || "",
          userotp6: otpValues[5] || "",
        });

        console.log('OTP Response', response);

        
        if((response.data.isOtpValid === true) && (response.data.userStatus === 4)){
          setOtpError("")
          setIsModalOpen(!isModalOpen)
          return
        }
              setUser({contact_name : response.data.loggedin[0].contact_name , 
              contact_email : response.data.loggedin[0].contact_email})
            
              localStorage.setItem("userName" , response.data.loggedin[0].contact_name)
              localStorage.setItem("userEmail" , response.data.loggedin[0].contact_email)

              if(response.data.eulaAcceptance === '0'  ){
          
                handleTerms(true, true)
    
               } else {
                window.location.reload()

               }
            
            localStorage.setItem('user_role' , response.data.userStatus);
            localStorage.setItem('id' , response.data.loggedin[0].id);
            localStorage.setItem('status' , 'true');
            localStorage.setItem("token" , response.data.token)
            localStorage.setItem("userEmail" , response.data.loggedin[0].contact_email)
            localStorage.setItem('customer_id', response.data.loggedin[0].customer_id )
            localStorage.setItem('userName', response.data.loggedin[0].contact_name )

            if(response.data.loggedin[0].user_role === '1'){
              localStorage.setItem("user_id", response.data.loggedin[0].id)
            }
            else if(response.data.loggedin[0].user_role === "2"){
              localStorage.setItem("business_id", response.data.loggedin[0].business_id)
                localStorage.setItem("user_id", response.data.loggedin[0].owner_id)
            }else if(response.data.loggedin[0].user_role === "3"){
              localStorage.setItem("business_id", response.data.loggedin[0].business_id)
                localStorage.setItem("user_id", response.data.loggedin[0].manager_id)
            }
            else{
              localStorage.setItem("business_id", response.data.loggedin[0].business_id)
                localStorage.setItem("contact_id", response.data.loggedin[0].contact_id)
                localStorage.setItem('customer_id', response.data.loggedin[0].customer_id )
            }
      }

       catch (error) {
        console.log("error posting details", error);
        if(error.response.data.login_status === false){
          setOtpError("Invalid OTP");
        }
      }
    }

  }.bind(this);


  window.addEventListener('beforeunload', function (e) {
    // Check if the form is submitted
    const isFormSubmitted = localStorage.getItem("isFormSubmitted") === "true";
  
    if (!isFormSubmitted) {
      handleSignOut();
    }
  });
  
  // Reset the flag when the user manually refreshes
  window.addEventListener('unload', function (e) {
    localStorage.setItem("isRefreshing", "false");
    localStorage.setItem("isFormSubmitted", "false");
  });
  
  const handleResendOtp = async () => {
    console.log('resending')
    try {
      const response = await axios.post(`${baseURL}/${btoa("login/resendotp")}`, {
        email: userEmail,
      });
console.log(response, 'response') ;
     // Assuming the new OTP comes from the response
      const newOtp = response.data.otp;
      setOtp(newOtp);
      setOtpValues(["", "", "", "", "", ""]); // Reset OTP input fields
      setTimer(300); // Reset timer
      setOtpExpired(false); // Mark OTP as active  
    } catch (error) {
      console.log("Error resending OTP:", error);
    }
  };

  return (
    <div className="flex w-screen h-screen justify-center align-center items-center flex-col">
      <div className="w-full h-screen bg-[url('./assets/images/LoginImage.png')] bg-no-repeat bg-center bg-[length:100%_100%]">

        <div className="fixed flex flex-col ml-[250px] md:mt-56 z-50">
         
            <div>
              <h2 className="text-4xl  font-light tracking-widest text-black-700">
                Welcome to InvoiceFile
              </h2>
            </div>
         
          <div>
            {/* <p className="text-l mt-10 text-gray-500 tracking-widest font-sans ">
              Customer LOGIN
            </p> */}
            <form className="mt-4">
              <div className="rounded-md">
              {/* {showTooltip() && (
                  <CustomTooltip content="Invalid email address" styles={showTooltip()} />
                )} */}
                {/* <div className="flex items-center">
                  <div className="relative">
                    <IoPerson
                      className="text-gray-500 absolute top-1/2 transform -translate-y-1/2 left-3"
                      style={{ color: "#20beca", fontSize: "18px" }}
                    />
                    <input
                      id="email"
                      name="email"
                      value={userEmail}
                      className="bg-gray-200"
                      
                      // onChange={(e) => {
                      //   // handleInputChange(e);
                      //   // formik.setFieldValue('email', e.target.value.trim());
                      // }}
                      // onBlur={formik.handleBlur}
                      // type="email"
                      // required
                      // className={`input-field ${
                      //   showTooltip() ? "border-red-500" : "border-gray-300"
                      // }`}
                      placeholder="Email"
                      
                      style={{
                        borderRadius: "5px",
                        
                        border: "2px solid grey",
                        width: "300px",
                        height: "38px",
                        paddingLeft: "40px",
                        backgroundColor: isOtpExpired() ? "#f0f0f0" : "", // Conditional background color
                      }}
                      readOnly
                      
                    />
                  </div>
                </div> */}
            </div>
            <p>Please Enter 6 Digit OTP</p>
            <div className="flex mt-6 space-x-5">
              <div className="relative">
                <input
                  id="otpInput"
                  type="text"
                  maxLength="6"
                  value={otpValues.join('')}
                  onChange={(e) => handleOtpChange(e.target.value)}
                  className="input-field"
                  style={{
                    borderRadius: "5px",
                    border: "2px solid grey",
                    width: "300px",
                    height: "38px",
                    paddingLeft: "5px",
                    backgroundColor: isOtpExpired() ? "#f0f0f0" : "",
                  }}
                  disabled={isOtpExpired()}
                  
                />
              </div>
        </div>


            {/* <div className="flex mt-6 s space-x-5">{renderOtpInputs()}</div> */}
           
            <div className="flex items-center mt-2 space-x-10">
              {otpError && <div className="text-red-500">{otpError}</div>}

              {isOtpExpired() && (
           <button
           type="button"
           onClick={handleResendOtp}
           aria-label="Resend OTP"
           style={{margin:'auto', color: '#20beca', marginLeft: '220px'}}
         >
           <span className="mr-7"><b>Resend</b></span>
         </button>
         
              )}
            </div>


          
            {otpExpired && (
              <div className="text-red-500 mt-4">
                OTP has been expired.
              </div>
            )}
              {/* <div className="mt-2" onClick={handleForgotPassword}>
                <Link style={{ color: "grey", fontSize: "15px" }}>
                  Forgot Password ?
                </Link>
              </div> */}
             { !isOtpExpired() &&
              <div className="flex  mt-6 space-x-5"> Please Check Your Phone For OTP &nbsp; <b>{formatTime(timer)}</b></div>
}

              <div className="mt-5 flex ml-5" style={{ marginTop: "25px" }}>
                <button
                  type="submit"
                  className="group relative shadow-2xl shadow-[#20beca] w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  style={{
                    width: "120px",
                    borderRadius: "25px",
                    fontWeight: "bold",
                    letterSpacing: "1px",
                    backgroundColor: "#20beca",
                    height: "36px",
                    alignItems: "center",
                  }}
                  onClick={handleLoginClick}
                >
                  <div
                    style={{
                      position: "absolute",
                      left: "85px", // Adjust as needed for the positioning of the circle
                      top: "50%",
                      transform: "translateY(-50%)",
                      width: "30px",
                      height: "30px",
                      borderRadius: "50%",
                      backgroundColor: "#247eaf",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <FaEnvelope />
                  </div>
                  <span className="mr-7">SUBMIT</span>
                </button>
                <button
                  type=""
                  className="ml-6 group relative shadow-2xl shadow-[#20beca] w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  style={{
                    width: "120px",
                    borderRadius: "25px",
                    fontWeight: "bold",
                    letterSpacing: "1px",
                    backgroundColor: "#20beca",
                    height: "36px",
                    alignItems: "center",
                  }}
                  onClick={handleCancel}
                >
                  <div
                    style={{
                      position: "absolute",
                      left: "85px", // Adjust as needed for the positioning of the circle
                      top: "50%",
                      transform: "translateY(-50%)",
                      width: "30px",
                      height: "30px",
                      borderRadius: "50%",
                      backgroundColor: "#247eaf",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <RiLoginCircleFill />
                  </div>
                  <span className="mr-7" style={{fontSize:"15px"}}>CANCEL</span>
                </button>             
              </div>
            </form>
            {
              <b className="mt-4">Otp is  : {otp}</b>
            }
          </div>
        </div>
      </div>      
        </div>
  );
};

export default CustomerOtpPage;



