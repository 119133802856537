import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    message : [],
}

const scrollingMessageSlice = createSlice({
    name : "scrollingMessage",
    initialState,
    reducers : {
        setScrollingMessage(state , action){
            state.message = action.payload;
        }
    }
})

export const {setScrollingMessage} = scrollingMessageSlice.actions;
export default scrollingMessageSlice.reducer;