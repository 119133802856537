
import React, { useState, useEffect } from "react";
import axios from "axios";

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const month = monthNames[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();
  return `${month} ${day}, ${year}`;
};

const formatTime = (dateString) => {
  const date = new Date(dateString);
  let hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  return `${hours}:${minutes} ${ampm}`;
};

const formatDateTime = (dateTime) => {
  return `<b>${formatDate(dateTime)}</b> ${formatTime(dateTime)}`;
};

const DeploymentPage = ({ fromDateTime, toDateTime }) => {
  return (
    <div className="flex w-screen h-screen justify-center items-center flex-col">
      <div className="w-full h-screen bg-[url('./assets/images/LoginImage.png')] bg-no-repeat bg-center bg-[length:100%_100%]">
        <div className="fixed flex flex-col ml-[250px] md:mt-36 z-50">
          <div>
            <h2 className="text-4xl font-light tracking-widest text-black-700">
              Welcome to InvoiceFile
            </h2>
          </div>
          <div>
            <p className="text-l mt-10 text-red-500 tracking-widest font-sans">
              The Application is under deployment from <span dangerouslySetInnerHTML={{ __html: formatDateTime(fromDateTime) }} /> to <span dangerouslySetInnerHTML={{ __html: formatDateTime(toDateTime) }} />,
            <br />
               Please visit after some time.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DeploymentPage;



