import React from 'react'

const PriceLog = () => {
  return (
    <div>
      PriceLog
    </div>
  )
}

export default PriceLog
