import React from 'react'

function CustomerReport() {
  return (
    <div>
      Reports
    </div>
  )
}

export default CustomerReport
